import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

import Groz from "~shared/assets/images/25/Grozny.jpg";
import Nevsk from "~shared/assets/images/25/Nevsky.jpg";
import Petr from "~shared/assets/images/25/Petr.jpg";
import Kutuzov from "~shared/assets/images/25/Kutuzov.jpg";

const Theme25Task3 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Грозный",
      image: Groz
    },
    {
      name: "Невский",
      image: Nevsk
    },
    {
      name: "Пётр",
      image: Petr
    },
    {
      name: "Кутузов",
      image: Kutuzov
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Отметь портрет Кутузова М.И. '
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Кутузов' hideName />
    </QuizWrapper>
  );
};

export default Theme25Task3;
