import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizProverb } from "~/modules/QuizProverb";

const Theme10Task1 = () => {
  const rows = [
    {
      letters: ["Д", "К", "К", "Е", "Л", "К", "К", "К", "К", "О"],
      correctWord: "ДЕЛО"
    },
    {
      letters: ["М", "А", "К", "К", "С", "Т", "Е", "К", "Р", "А"],
      correctWord: "МАСТЕРА"
    },
    {
      letters: ["Б", "О", "К", "К", "И", "Т", "С", "К", "К", "Я"],
      correctWord: "БОИТСЯ"
    }
  ];
  return (
    <QuizWrapper
      title='Задание'
      icon='question'
      subtitle='Прочитай пословицу'
      description='Убери в каждой строке букву, которая повторяется'
      successIcon='dan-1'
    >
      <QuizProverb rows={rows} />
    </QuizWrapper>
  );
};

export default Theme10Task1;
