import React from "react";
import { QuizChangeWords } from "~/modules/QuizChangeWords";
import { QuizWrapper } from "~shared/components/QuizWrapper";

const Theme35Task2 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Поменяй местами слоги, чтобы собрать слова.'
      successIcon='rus-1'
    >
      <QuizChangeWords />
    </QuizWrapper>
  );
};

export default Theme35Task2;
