import React from "react";
export const CheckMark: React.FC = () => {
  return (
    <svg width='22' height='17' viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.33344 13.0966L2.23677 7.99998L0.507324 9.72942L7.33344 16.5555L22.0001 1.88887L20.2707 0.159424L7.33344 13.0966Z'
        fill='white'
      />
    </svg>
  );
};
