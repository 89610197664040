import React, { createContext, PropsWithChildren, useState } from "react";

type TQuizProvider = {
  success: boolean;
  setSuccess: (flag: boolean) => void;
};

export const QuizContext = createContext<TQuizProvider>({ success: false, setSuccess: () => {} });

export const QuizProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [success, setSuccess] = useState<boolean>(false);

  return <QuizContext.Provider value={{ success, setSuccess }}>{children}</QuizContext.Provider>;
};
