import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

import Bolsh from "~shared/assets/images/26/shutterstock_1838212753.jpg";
import Pash from "~shared/assets/images/26/shutterstock_2022513020.jpg";
import Manezh from "~shared/assets/images/26/shutterstock_2083887010.jpg";
import Kolomen from "~shared/assets/images/26/shutterstock_1645553575.jpg";

const Theme26Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Дом Пашкова",
      image: Pash
    },
    {
      name: "Большой театр",
      image: Bolsh
    },
    {
      name: "Манеж",
      image: Manezh
    },
    {
      name: "Коломенский дворец",
      image: Kolomen
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри фотографии и найди Большой театр.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Большой театр' hideName />
    </QuizWrapper>
  );
};

export default Theme26Task1;
