import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import QuizDrawLine, { DrawLineBlock } from "~/modules/QuizDrawLine/QuizDrawLine";
import gonchar from "~/shared/assets/images/13/gonchar.jpg";
import pryaha from "~/shared/assets/images/13/pryaha.jpeg";
import tkach from "~/shared/assets/images/13/tkach.jpeg";
import kuznets from "~/shared/assets/images/13/kuznets.jpg";

import goncharnyiKrug from "~/shared/assets/images/13/goncharnyi_krug.jpg";
import pryalka from "~/shared/assets/images/13/pryalka.jpg";
import tkatskiyStanok from "~/shared/assets/images/13/tkatskiy_stanok.jpg";
import molot from "~/shared/assets/images/13/molot_i_nakovalnya.jpg";

const firstBlocks: DrawLineBlock[] = [
  {
    name: "гончар",
    url: gonchar,
    pair: "гончарный круг"
  },
  {
    name: "пряха",
    url: pryaha,
    pair: "игла с ниткой"
  },
  {
    name: "ткач",
    url: tkach,
    pair: "ткацкий станок"
  },
  {
    name: "кузнец",
    url: kuznets,
    pair: "молот и наковальня"
  }
];

const secondBlocks: DrawLineBlock[] = [
  {
    name: "ткацкий станок",
    url: tkatskiyStanok,
    pair: "ткач"
  },
  {
    name: "гончарный круг",
    url: goncharnyiKrug,
    pair: "гончар"
  },
  {
    name: "игла с ниткой",
    url: pryalka,
    pair: "пряха"
  },
  {
    name: "молот и наковальня",
    url: molot,
    pair: "кузнец"
  }
];

const Theme13Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Помоги ремесленникам найти свои предметы.'
      description='Нажимай на ремесленника и подходящий ему предмет'
      successIcon='rus-2'
    >
      <QuizDrawLine firstCol={firstBlocks} secondCol={secondBlocks} />
    </QuizWrapper>
  );
};

export default Theme13Task1;
