import React, { createContext, PropsWithChildren, useState } from "react";

type TPosition = {
  x: number;
  y: number;
};

type TDragAndDropProvider = {
  dragId: number;
  setDragId: (id: number) => void;

  dragPosition: TPosition | null;
  setDragPosition: (position: TPosition | null) => void;

  dropPosition: TPosition | null;
  setDropPosition: (position: TPosition | null) => void;

  incorrectIds: number[];
  setIncorrectIds: React.Dispatch<React.SetStateAction<number[]>>;

  results: Map<number, number>;
};

export const DragAndDropContext = createContext<TDragAndDropProvider>({
  dragId: -1,
  setDragId: () => {},

  dragPosition: null,
  setDragPosition: () => {},

  dropPosition: null,
  setDropPosition: () => {},

  incorrectIds: [],
  setIncorrectIds: () => {},

  results: new Map<number, number>()
});

export const DragAndDropProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [dragId, setDragId] = useState<number>(-1);
  const [dragPosition, setDragPosition] = useState<TPosition | null>(null);
  const [dropPosition, setDropPosition] = useState<TPosition | null>(null);
  const [incorrectIds, setIncorrectIds] = useState<number[]>([]);
  const [results] = useState<Map<number, number>>(new Map());

  return (
    <DragAndDropContext.Provider
      value={{
        dragId,
        setDragId,
        dragPosition,
        setDragPosition,
        dropPosition,
        setDropPosition,
        incorrectIds,
        setIncorrectIds,
        results
      }}
    >
      {children}
    </DragAndDropContext.Provider>
  );
};
