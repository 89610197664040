import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTimeLine, Answer, DraggableItem, DropZone } from "~modules/QuizTimeLine";

import Don from "~shared/assets/images/15/dmitryi_donskoi.jpg";
import Groz from "~shared/assets/images/15/ivan_grozniy.jpg";
import Kalit from "~shared/assets/images/15/ivan_kalita.jpg";
import Dolg from "~shared/assets/images/15/uriy_dolgorukii.jpg";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    title: "Иван Калита",
    imgUrl: Kalit,
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 2,
    title: "Юрий Долгорукий",
    imgUrl: Dolg,
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },

  {
    id: 3,
    title: "Иван Грозный",
    imgUrl: Groz,
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 4,
    title: "Дмитрий Донской",
    imgUrl: Don,
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 2,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 3,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 4,
    correct: false,
    uncorrect: false,
    empty: true
  }
];

const answers: Answer = { 1: 2, 2: 1, 3: 4, 4: 3 };

const Theme15Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Кто был раньше, а кто позже?'
      description='Правильно расположи изображения.'
      successIcon='dan-2'
    >
      <QuizTimeLine draggableItems={draggableItems} dropZones={dropZones} answers={answers} />
    </QuizWrapper>
  );
};

export default Theme15Task1;
