import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme31Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "Маришка"
    },
    {
      name: "Танюшка"
    },
    {
      name: "Аннушка"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Как назывался первый трамвайный маршрут?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Аннушка' />
    </QuizWrapper>
  );
};

export default Theme31Task1;
