import React, { PropsWithChildren, forwardRef, useContext } from "react";
import styles from "./QuizWrapper.module.scss";
import { QuizContext, QuizProvider } from "~/providers/QuizProvider";
import { QuizSuccess, TQuizSuccess } from "~shared/components/QuizSuccess";
import { Icon, IconName } from "~shared/components/Icon";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";
import clsx from "clsx";
type Props = PropsWithChildren & {
  title: string;
  icon: IconName;
  subtitle?: string;
  description?: string;
  successIcon: TQuizSuccess["successIcon"];
  successMessage?: TQuizSuccess["message"];
  isBlockWrapper?: boolean;
};

const QuizWrapperContent = forwardRef<HTMLDivElement, Props>(
  (
    { title, icon, subtitle, description, successIcon, successMessage, children, isBlockWrapper },
    ref
  ) => {
    const { success } = useContext(QuizContext);
    const styleTitleWrapper = useAdaptiveValue(
      styles.titleWrapperMobile,
      styles.titleWrapperMobile,
      styles.titleWrapper
    );
    const styleIconWrapper = useAdaptiveValue(
      styles.iconWrapperMobile,
      styles.iconWrapperMobile,
      styles.iconWrapper
    );
    const styleDescription = useAdaptiveValue(
      styles.descriptionMobile,
      styles.descriptionMobile,
      styles.description
    );
    const styleSubtitle = useAdaptiveValue(
      styles.subtitleMobile,
      styles.subtitleMobile,
      styles.subtitle
    );

    return (
      <div
        className={clsx(styles.quizWrapper, { [styles["quizWrapper_block"]]: isBlockWrapper })}
        ref={ref}
      >
        <div className={styleTitleWrapper}>
          <span className={styleIconWrapper}>
            <Icon name={icon} />
          </span>
          <div className={styles.title}>{title}</div>
        </div>

        {!success ? (
          <>
            <div className={styleSubtitle}>{subtitle}</div>
            {description && <div className={styleDescription}>{description}</div>}
            <div className={styles.content}>{children}</div>
          </>
        ) : (
          <QuizSuccess message={successMessage} successIcon={successIcon} />
        )}
      </div>
    );
  }
);

QuizWrapperContent.displayName = "QuizWrapperContent";

export const QuizWrapper = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <QuizProvider>
      <QuizWrapperContent {...props} ref={ref} />
    </QuizProvider>
  );
});

QuizWrapper.displayName = "QuizWrapper";
