import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme44Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "Ока"
    },
    {
      name: "Десна"
    },
    {
      name: "Волга"
    },
    {
      name: "Нева"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какая река протекает в Новой Москве?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Десна' />
    </QuizWrapper>
  );
};

export default Theme44Task1;
