import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItems } from "~/modules/QuizChooseItems";

const Theme21Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "Рождество"
    },
    {
      name: "Святки"
    },
    {
      name: "8 марта"
    },
    {
      name: "23 февраля"
    },
    {
      name: "Пасха"
    },
    {
      name: "Троица"
    },
    {
      name: "1 мая"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какие праздники были главными в 17 веке в Москве?'
      description='Выбери 4 правильных ответа'
      successIcon='rus-1'
    >
      <QuizChooseItems
        type='str'
        items={items}
        correctItems={["Рождество", "Святки", "Пасха", "Троица"]}
      />
    </QuizWrapper>
  );
};

export default Theme21Task1;
