import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import ladia from "~shared/assets/images/35/ladia.jpg";
import korabl from "~shared/assets/images/35/korabl.jpg";
import teplohod from "~shared/assets/images/35/teplohod.jpg";
import lodka from "~shared/assets/images/35/lodka.jpg";

const Theme35Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Ладья",
      image: ladia
    },
    {
      name: "Корабль",
      image: korabl
    },
    {
      name: "Теплоход",
      image: teplohod
    },
    {
      name: "Лодка",
      image: lodka
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри рисунки. Отметь теплоход.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Теплоход' hideName />
    </QuizWrapper>
  );
};

export default Theme35Task1;
