import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import fedor from "~/shared/assets/images/20/Fedorov_shutterstock_1154707597.jpg";
import lomon from "~/shared/assets/images/20/Lomonosov_shutterstock_2040261980.jpg";
import pushk from "~/shared/assets/images/20/Pushkin_shutterstock_1683842689.jpg";

const Theme20Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Фёдоров",
      image: fedor
    },
    {
      name: "Ломоносов",
      image: lomon
    },
    {
      name: "Пушкин",
      image: pushk
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри фотографии. Отметь памятник Ивану Фёдорову.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Фёдоров' hideName />
    </QuizWrapper>
  );
};

export default Theme20Task2;
