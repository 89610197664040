import React, { useEffect, useState } from "react";
import styles from "./ChooseItem.module.scss";
import clsx from "clsx";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type Props = {
  type: "img" | "str";
  item: {
    name: string;
    image?: string;
  };
  widthItem?: number;
  selectedItem: string | null;
  isAnswerCorrect: boolean | null;
  hideName?: boolean;
  handleItemClick: (name: string) => void;
};

export const ChooseItem: React.FC<Props> = ({
  type,
  item,
  selectedItem,
  widthItem,
  handleItemClick,
  isAnswerCorrect,
  hideName
}) => {
  const [isItemSelectedBefore, setIsItemSelectedBefore] = useState(false);
  const isItemSelected = selectedItem === item.name;
  const shouldShowError = isItemSelected && !isAnswerCorrect;

  const itemNameStyle = useAdaptiveValue(
    styles.itemNameMobile,
    styles.itemNameMobile,
    styles.itemName
  );
  const stringItemStyle = useAdaptiveValue(
    styles.stringItemMobile,
    styles.stringItemMobile,
    styles.stringItem
  );
  useEffect(() => {
    if (isItemSelected) setIsItemSelectedBefore(true);
  }, [isItemSelected]);

  const handleClick = () => {
    if (isItemSelectedBefore) {
      return;
    }
    handleItemClick(item.name);
  };

  return (
    <>
      {type === "img" && (
        <div className={styles.itemWrapper}>
          {!hideName && <div className={itemNameStyle}>{item.name}</div>}
          <div
            className={clsx(
              styles.item,
              `${
                shouldShowError || (isItemSelectedBefore && !isAnswerCorrect) ? styles.error : ""
              }`,
              isItemSelectedBefore && styles.disabled
            )}
            style={{
              backgroundImage: `url(${item.image})`,
              backgroundSize: "cover",
              maxWidth: `${widthItem}px`,
              maxHeight: `${widthItem}px`
            }}
            onClick={handleClick}
          />
        </div>
      )}
      {type === "str" && (
        <div
          className={`${stringItemStyle} ${
            shouldShowError || (isItemSelectedBefore && !isAnswerCorrect) ? styles.error : ""
          }`}
          onClick={handleClick}
        >
          {item.name}
        </div>
      )}
    </>
  );
};
