import React from "react";
import { QuizPuzzle } from "~/shared/components/QuizPuzzle";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import img from "~shared/assets/images/7/kreml_kalita.jpg";

const Theme7Task3 = () => {
  return (
    <QuizWrapper
      title='ПАЗЛ'
      icon='puzzle'
      successIcon='rus-1'
      subtitle='Собери пазл «Кремль при Иване Калите».'
      description='Перетащи элементы пазла на соответствующие места'
    >
      <QuizPuzzle img={img} />
    </QuizWrapper>
  );
};

export default Theme7Task3;
