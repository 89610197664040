import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizMoveLetters, Answer, DraggableItem, DropZone } from "~modules/QuizMoveLetters";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    letter: "А"
  },
  {
    id: 2,
    letter: "Я"
  },
  {
    id: 3,
    letter: "З"
  },
  {
    id: 4,
    letter: "Р"
  },
  {
    id: 5,
    letter: "Ь"
  },
  {
    id: 6,
    letter: "Д"
  },
  {
    id: 7,
    letter: "Е"
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    name: "1"
  },
  {
    id: 2,
    name: "2"
  },
  {
    id: 3,
    name: "3"
  },
  {
    id: 4,
    name: "4"
  },
  {
    id: 5,
    name: "5"
  },
  {
    id: 6,
    name: "6"
  },
  {
    id: 7,
    name: "7"
  }
];
const clues: string[] = ["А=2", "Я=4", "З=1", "Р=3", "Ь=6", "Д=5", "Е=7"];

const answers: Answer = { 1: [3], 2: [1], 3: [4], 4: [2], 5: [6], 6: [5], 7: [7] };
export default function Theme43Task2() {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Расшифруй слово и ты узнаешь, как называется парк – гордость столицы.'
      description='Перетащи буквы на верные места'
      successIcon='nast-2'
    >
      <QuizMoveLetters
        draggableItems={draggableItems}
        dropZones={dropZones}
        answers={answers}
        clues={clues}
      />
    </QuizWrapper>
  );
}
