import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import QuizDrawLine, { DrawLineBlock } from "~/modules/QuizDrawLine/QuizDrawLine";
import liteyschik from "~shared/assets/images/12/liteyschik.jpg";
import gonchar from "~shared/assets/images/12/gonchar.jpg";
import sapojnik from "~shared/assets/images/12/sapojnik.jpg";
import kuznets from "~shared/assets/images/12/kuznets.jpg";

const firstBlocks: DrawLineBlock[] = [
  {
    name: "литейщик",
    pair: "liteishik"
  },
  {
    name: "гончар",
    pair: "gonchar"
  },
  {
    name: "сапожник",
    pair: "sapozhnik"
  },
  {
    name: "кузнец",
    pair: "kuznec"
  }
];

const secondBlocks: DrawLineBlock[] = [
  {
    url: sapojnik,
    name: "sapozhnik",
    pair: "сапожник"
  },
  {
    url: gonchar,
    name: "gonchar",
    pair: "гончар"
  },
  {
    url: kuznets,
    name: "kuznec",
    pair: "кузнец"
  },
  {
    url: liteyschik,
    name: "liteishik",
    pair: "литейщик"
  }
];

const Theme12Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Подбери верную картинку к каждой профессии'
      description='Нажми на текст и подходящую картинку '
      successIcon='rus-2'
    >
      <QuizDrawLine firstCol={firstBlocks} secondCol={secondBlocks} />
    </QuizWrapper>
  );
};

export default Theme12Task1;
