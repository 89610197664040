import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizFillWord } from "~modules/QuizFillWord";

const grid: { char: string; wordId: number }[][] = [
  [
    { char: "ч", wordId: -1 },
    { char: "м", wordId: -1 },
    { char: "с", wordId: 1 },
    { char: "т", wordId: 1 },
    { char: "у", wordId: 1 },
    { char: "д", wordId: 1 },
    { char: "е", wordId: 1 },
    { char: "н", wordId: 1 },
    { char: "т", wordId: 1 },
    { char: "ы", wordId: 1 }
  ],
  [
    { char: "с", wordId: -1 },
    { char: "к", wordId: 2 },
    { char: "у", wordId: 2 },
    { char: "п", wordId: 2 },
    { char: "ц", wordId: 2 },
    { char: "ы", wordId: 2 },
    { char: "т", wordId: -1 },
    { char: "с", wordId: -1 },
    { char: "в", wordId: -1 },
    { char: "г", wordId: -1 }
  ],
  [
    { char: "ф", wordId: 3 },
    { char: "а", wordId: 3 },
    { char: "б", wordId: 3 },
    { char: "р", wordId: 3 },
    { char: "и", wordId: 3 },
    { char: "к", wordId: 3 },
    { char: "а", wordId: 3 },
    { char: "н", wordId: 3 },
    { char: "т", wordId: 3 },
    { char: "ы", wordId: 3 }
  ],
  [
    { char: "к", wordId: 4 },
    { char: "р", wordId: 4 },
    { char: "е", wordId: 4 },
    { char: "с", wordId: 4 },
    { char: "т", wordId: 4 },
    { char: "ь", wordId: 4 },
    { char: "я", wordId: 4 },
    { char: "н", wordId: 4 },
    { char: "е", wordId: 4 },
    { char: "ч", wordId: -1 }
  ],
  [
    { char: "м", wordId: -1 },
    { char: "р", wordId: 5 },
    { char: "а", wordId: 5 },
    { char: "б", wordId: 5 },
    { char: "о", wordId: 5 },
    { char: "ч", wordId: 5 },
    { char: "и", wordId: 5 },
    { char: "е", wordId: 5 },
    { char: "н", wordId: -1 },
    { char: "я", wordId: -1 }
  ],
  [
    { char: "н", wordId: -1 },
    { char: "п", wordId: 6 },
    { char: "о", wordId: 6 },
    { char: "л", wordId: 6 },
    { char: "о", wordId: 6 },
    { char: "м", wordId: 6 },
    { char: "н", wordId: 6 },
    { char: "и", wordId: 6 },
    { char: "к", wordId: 6 },
    { char: "и", wordId: 6 }
  ]
];

const words: { value: string; wordId: number }[] = [
  { value: "купцы", wordId: 2 },
  { value: "фабриканты", wordId: 3 },
  { value: "рабочие", wordId: 5 },
  { value: "студенты", wordId: 1 },
  { value: "поломники", wordId: 6 },
  { value: "крестьяне", wordId: 4 }
];

const Theme30Task3 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Какие гости ежедневно приезжали в Москву?'
      description='Найди в таблице слова из списка ниже и нажми на них'
      successIcon='mash-1'
    >
      <QuizFillWord grid={grid} words={words} cellWidthSmall />
    </QuizWrapper>
  );
};

export default Theme30Task3;
