import React from "react";
export const Nast2: React.FC = () => {
  return (
    <svg
      width='133'
      height='133'
      viewBox='0 0 133 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M66.8154 132.677C103.266 132.677 132.815 103.128 132.815 66.6768C132.815 30.226 103.266 0.676819 66.8154 0.676819C30.3646 0.676819 0.81543 30.226 0.81543 66.6768C0.81543 103.128 30.3646 132.677 66.8154 132.677Z'
        fill='#DBEEEE'
      />
      <path
        d='M29.7151 111.077C28.3151 112.577 27.6152 115.577 27.1152 119.377C31.6152 122.777 36.6153 125.577 41.9153 127.777C41.8153 127.777 42.2151 124.777 42.2151 124.477C42.3151 123.277 42.0153 123.277 40.9153 122.877C39.6153 122.377 38.6152 121.177 38.3152 119.777C38.1152 118.777 38.4152 117.677 38.3152 116.677C38.2152 115.077 37.4153 113.577 36.4153 112.477C35.6153 111.577 34.1153 109.977 32.9153 109.577C31.7153 109.277 30.4151 110.277 29.7151 111.077Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.7151 122.477L33.4153 101.077C33.4153 101.077 29.0152 104.477 26.6152 114.477C28.6152 115.377 35.1151 118.977 41.7151 122.477Z'
        fill='#E7F3F5'
      />
      <path
        d='M34.5154 77.2768C34.5154 77.2768 31.9153 92.2768 40.7153 98.2768C43.2153 102.177 45.4155 100.577 56.4155 104.277L62.7153 101.177L76.8154 107.777L84.4155 109.377L90.7153 95.5768V108.177C90.7153 108.177 116.915 94.2768 102.815 62.1768C106.315 22.9768 80.8154 15.0768 65.0154 13.1768C41.5154 10.2768 29.9153 28.4768 35.2153 46.1768C40.4153 63.7768 34.5154 77.2768 34.5154 77.2768Z'
        fill='#924026'
      />
      <path
        d='M65.1153 96.3768C58.1153 95.8768 41.4153 96.5768 33.6153 100.177C30.6153 101.577 30.2153 110.777 30.4153 122.277C40.7153 128.877 52.8153 132.677 65.9153 132.677C69.9153 132.677 73.7152 132.277 77.5152 131.677C80.3152 119.577 84.6153 109.477 82.3153 104.977C79.4153 99.3768 70.6153 97.7768 65.1153 96.3768Z'
        fill='white'
      />
      <path
        d='M70.7153 122.977C70.8153 125.877 70.8155 129.277 70.9155 132.577C77.2155 132.177 83.2155 130.977 88.9155 128.977C89.1155 121.177 88.4155 113.777 85.9155 110.277C80.6155 105.577 70.5153 114.477 70.7153 122.977Z'
        fill='#FFD2A8'
      />
      <path
        d='M38.2153 29.9768C37.8153 29.9768 37.4155 29.8768 37.1155 29.6768C36.9155 29.5768 36.9154 29.3768 37.0154 29.1768C37.1154 28.9768 37.3154 28.9768 37.5154 29.0768C38.8154 29.8768 41.5154 28.1768 44.5154 24.6768C44.6154 24.5768 44.8154 24.4768 45.0154 24.6768C45.1154 24.7768 45.2154 24.9768 45.0154 25.1768C42.3154 28.2768 39.9153 29.9768 38.2153 29.9768Z'
        fill='#B36D23'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M64.7154 96.3768V81.0768C64.4154 81.1768 54.0156 84.8768 48.9156 84.4768L49.0154 95.1768C47.1154 100.877 49.7154 103.077 54.2154 103.077C60.2154 103.077 64.0154 98.7768 64.7154 96.3768Z'
        fill='#FCD1A9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.2154 61.2768C31.7154 56.4768 29.3156 51.2768 32.9156 44.6768C36.4156 39.6768 40.0156 41.9768 43.4156 46.5768L36.2154 61.2768Z'
        fill='#F9BC90'
      />
      <path
        d='M36.2154 61.6768C36.1154 61.6768 36.0156 61.6768 35.9156 61.5768C33.4156 58.8768 31.8154 56.2768 31.2154 53.7768C30.4154 50.7768 30.9156 47.7768 32.6156 44.5768C34.0156 42.5768 35.4155 41.6768 37.0155 41.5768C39.0155 41.5768 41.2154 43.0768 43.7154 46.4768C43.8154 46.5768 43.8154 46.6768 43.7154 46.8768L36.5155 61.4768C36.4155 61.5768 36.3154 61.6768 36.2154 61.6768ZM33.1156 44.9768C30.2156 50.3768 31.1155 55.2768 36.0155 60.7768L42.9156 46.7768C40.6156 43.7768 38.6156 42.3768 36.9156 42.3768C35.6156 42.3768 34.4156 43.1768 33.1156 44.9768Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.0157 73.5768C77.9157 78.0768 74.8157 82.0768 70.0157 84.3768C57.9157 90.2768 43.6158 88.7768 37.1158 83.1768C29.2158 74.3768 27.8157 63.7768 28.3157 59.6768C29.8157 51.4768 33.2156 47.7768 33.2156 47.7768C37.3156 44.2768 38.1157 24.4768 47.3157 23.8768C56.6157 23.2768 82.7156 25.8768 82.7156 25.8768L81.8157 59.0768C84.8157 52.5768 92.2157 55.4768 93.3157 61.2768C94.2157 66.9768 89.6157 74.8768 82.0157 73.5768Z'
        fill='#FCD1A9'
      />
      <path
        d='M41.2156 61.9768C41.1156 61.9768 41.1157 61.9768 41.0157 61.8768C40.0157 61.1768 39.6158 60.0768 39.9158 58.9768C40.2158 57.8768 41.1156 57.0768 42.2156 56.9768C42.4156 56.9768 42.6158 57.0768 42.6158 57.2768C42.6158 57.4768 42.5157 57.6768 42.3157 57.6768C41.5157 57.7768 40.8158 58.3768 40.6158 59.1768C40.4158 59.9768 40.7158 60.7768 41.4158 61.3768C41.6158 61.4768 41.6157 61.6768 41.5157 61.8768C41.4157 61.9768 41.3156 61.9768 41.2156 61.9768Z'
        fill='#8E3821'
      />
      <path
        d='M47.715 64.0768C47.615 64.0768 47.5152 64.0768 47.4152 64.0768C47.2152 63.9768 47.115 63.8768 47.215 63.6768C47.315 63.4768 47.4151 63.3768 47.6151 63.4768C47.9151 63.5768 48.3151 63.3768 48.6151 63.0768C48.7151 62.9768 49.0151 62.4768 48.8151 62.0768C48.7151 61.8768 48.8152 61.6768 48.9152 61.5768C49.1152 61.4768 49.3152 61.5768 49.4152 61.6768C49.7152 62.2768 49.615 62.9768 49.215 63.4768C48.715 63.7768 48.215 64.0768 47.715 64.0768Z'
        fill='#8E3821'
      />
      <path
        d='M42.4155 30.7768C36.5155 34.0768 34.8154 30.3768 34.8154 30.3768C39.8154 25.1768 39.7155 8.47681 76.9155 20.6768C95.8155 26.5768 90.2155 39.6768 81.4155 59.0768C81.4155 59.0768 85.3154 46.2768 69.8154 41.4768C54.3154 36.5768 49.2153 28.4768 49.2153 28.4768C49.2153 28.4768 44.7155 29.5768 42.4155 30.7768Z'
        fill='#924026'
      />
      <path
        d='M45.7152 40.6768C45.5152 40.6768 45.4153 40.5768 45.3153 40.4768C43.1153 38.4768 41.3154 38.6768 40.4154 38.9768C40.1154 39.0768 39.7154 38.8768 39.6154 38.5768C39.5154 38.2768 39.7153 37.8768 40.0153 37.7768C41.2153 37.3768 43.5152 37.0768 46.2152 39.5768C46.5152 39.7768 46.5152 40.1768 46.2152 40.4768C46.0152 40.6768 45.9152 40.6768 45.7152 40.6768Z'
        fill='#924027'
      />
      <path
        d='M70.1154 49.1768C69.9154 49.1768 69.8154 49.0768 69.6154 48.9768C67.7154 46.9768 65.4154 45.9768 64.1154 46.6768C63.8154 46.8768 63.4152 46.6768 63.2152 46.3768C63.0152 46.0768 63.1153 45.6768 63.5153 45.4768C65.3153 44.4768 68.2153 45.5768 70.5153 48.0768C70.7153 48.3768 70.7153 48.7768 70.5153 48.9768C70.4153 49.1768 70.3154 49.1768 70.1154 49.1768Z'
        fill='#924027'
      />
      <path
        d='M44.1155 73.8768C44.0155 73.8768 43.9156 73.8768 43.9156 73.7768C43.3156 73.2768 42.2154 73.2768 42.0154 73.3768C41.9154 73.4768 41.6154 73.4768 41.5154 73.3768C41.4154 73.2768 41.4154 72.9768 41.5154 72.8768C42.0154 72.3768 43.6155 72.6768 44.3155 73.1768C44.4155 73.2768 44.5155 73.4768 44.3155 73.6768C44.3155 73.8768 44.2155 73.8768 44.1155 73.8768Z'
        fill='#8E3821'
      />
      <path
        d='M47.1155 71.6768C47.0155 71.6768 46.9154 71.6768 46.8154 71.6768C46.2154 71.5768 45.6154 71.2768 44.8154 70.9768C43.5154 70.3768 42.1154 69.6768 40.5154 69.9768H40.4155C39.7155 69.8768 39.3154 69.4768 39.3154 68.7768C39.3154 68.5768 39.5155 68.4768 39.6155 68.4768C39.8155 68.4768 39.9155 68.6768 39.9155 68.7768C39.9155 69.1768 40.1154 69.2768 40.3154 69.2768C42.1154 68.9768 43.7154 69.7768 45.0154 70.3768C45.7154 70.6768 46.3154 70.9768 46.8154 71.0768C48.1154 71.1768 49.5153 70.5768 49.7153 70.3768C49.8153 70.2768 50.0155 70.2768 50.1155 70.2768C50.3155 70.3768 50.4154 70.5768 50.3154 70.7768C50.2154 71.0768 48.5155 71.6768 47.1155 71.6768Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M69.5156 124.477C64.6156 113.877 66.0157 95.7769 80.3157 102.077C85.8157 106.677 88.4157 113.777 88.4157 113.777C88.4157 113.777 71.8156 113.377 69.5156 124.477Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M66.6155 57.5768C67.2155 55.2768 66.3155 53.1768 64.6155 52.6768C62.8155 52.2768 60.9153 53.7768 60.2153 56.0768C59.6153 58.3768 60.5153 60.4768 62.2153 60.9768C64.0153 61.2768 66.0155 59.7768 66.6155 57.5768Z'
        fill='white'
      />
      <mask
        id='mask0_958_25132'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='60'
        y='52'
        width='7'
        height='9'
      >
        <path
          d='M60.2153 55.9768C59.6153 58.2768 60.5153 60.3768 62.2153 60.8768C64.0153 61.2768 65.9155 59.7768 66.6155 57.5768C67.2155 55.2768 66.3154 53.1768 64.5154 52.6768C64.3154 52.6768 64.1155 52.5768 63.9155 52.5768C62.4155 52.5768 60.8153 53.9768 60.2153 55.9768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_958_25132)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M67.4153 57.6768C68.0153 55.3768 67.1153 53.2768 65.4153 52.7768C63.6153 52.3768 61.7152 53.8768 61.0152 56.0768C60.4152 58.3768 61.3153 60.4768 63.1153 60.9768C64.9153 61.4768 66.8153 59.9768 67.4153 57.6768Z'
          fill='#7C170F'
        />
      </g>
      <mask
        id='mask1_958_25132'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='60'
        y='52'
        width='7'
        height='9'
      >
        <path
          d='M60.2153 55.9768C59.6153 58.2768 60.5153 60.3768 62.2153 60.8768C64.0153 61.2768 65.9155 59.7768 66.6155 57.5768C67.2155 55.2768 66.3154 53.1768 64.5154 52.6768C64.3154 52.6768 64.1155 52.5768 63.9155 52.5768C62.4155 52.5768 60.8153 53.9768 60.2153 55.9768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_958_25132)'>
        <path
          d='M61.6155 55.9768C62.2155 56.0768 62.7154 55.6768 62.8154 55.0768C62.9154 54.4768 62.5155 53.9768 61.9155 53.8768C61.3155 53.7768 60.8153 54.1768 60.7153 54.7768C60.7153 55.3768 61.1155 55.8768 61.6155 55.9768Z'
          fill='white'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.3154 48.8768C42.9154 46.7768 42.0155 44.6768 40.4155 44.2768C38.7155 43.8768 36.9154 45.2768 36.3154 47.3768C35.7154 49.4768 36.6153 51.5768 38.2153 51.9768C39.9153 52.3768 41.7154 50.9768 42.3154 48.8768Z'
        fill='white'
      />
      <mask
        id='mask2_958_25132'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='36'
        y='44'
        width='7'
        height='9'
      >
        <path
          d='M36.3154 47.4768C35.7154 49.5768 36.6153 51.6768 38.2153 52.0768C39.9153 52.4768 41.7154 51.0768 42.3154 48.9768C42.9154 46.8768 42.0155 44.7768 40.4155 44.3768C40.2155 44.3768 40.0154 44.2768 39.8154 44.2768C38.3154 44.2768 36.8154 45.5768 36.3154 47.4768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask2_958_25132)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M43.8158 49.3768C44.5158 46.9768 43.5158 44.6768 41.6158 44.2768C39.7158 43.8768 37.7157 45.3768 37.0157 47.7768C36.3157 50.1768 37.3157 52.4768 39.2157 52.8768C41.1157 53.3768 43.1158 51.7768 43.8158 49.3768Z'
          fill='#7C170F'
        />
      </g>
      <mask
        id='mask3_958_25132'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='36'
        y='44'
        width='7'
        height='9'
      >
        <path
          d='M36.3154 47.4768C35.7154 49.5768 36.6153 51.6768 38.2153 52.0768C39.9153 52.4768 41.7154 51.0768 42.3154 48.9768C42.9154 46.8768 42.0155 44.7768 40.4155 44.3768C40.2155 44.3768 40.0154 44.2768 39.8154 44.2768C38.3154 44.2768 36.8154 45.5768 36.3154 47.4768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask3_958_25132)'>
        <path
          d='M37.4155 47.2768C38.0155 47.3768 38.6153 46.9768 38.7153 46.3768C38.8153 45.7768 38.4154 45.2768 37.8154 45.1768C37.2154 45.0768 36.6153 45.4768 36.5153 46.0768C36.4153 46.6768 36.8155 47.1768 37.4155 47.2768Z'
          fill='white'
        />
      </g>
      <path
        d='M88.0154 66.5768C87.9154 66.5768 87.7155 66.5768 87.6155 66.4768C87.5155 66.3768 87.5155 66.0768 87.6155 65.9768C88.8155 64.9768 88.8155 62.9768 88.1155 61.5768C87.9155 61.1768 87.2153 60.0768 86.2153 60.3768C86.0153 60.4768 85.8154 60.2768 85.8154 60.1768C85.8154 60.0768 85.9154 59.7768 86.0154 59.7768C87.0154 59.4768 88.1153 60.0768 88.7153 61.2768C89.5153 62.8768 89.6154 65.2768 88.0154 66.5768Z'
        fill='#8E3821'
      />
      <g opacity='0.31'>
        <g opacity='0.5' filter='url(#filter0_f_958_25132)'>
          <path
            d='M47.0154 57.9768C47.5154 59.9768 46.6154 61.9768 44.8154 62.4768C43.0154 62.9768 41.2153 61.6768 40.7153 59.6768C40.2153 57.6768 41.8155 58.1768 43.6155 57.6768C45.2155 57.1768 46.4154 55.8768 47.0154 57.9768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.3'>
        <mask
          id='mask4_958_25132'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='42'
          y='57'
          width='4'
          height='4'
        >
          <path d='M45.1155 57.7769H42.2153V60.4769H45.1155V57.7769Z' fill='white' />
        </mask>
        <g mask='url(#mask4_958_25132)'>
          <path
            d='M43.5151 57.7769C45.6151 57.7769 45.615 60.1769 44.215 60.3769C42.115 60.7769 41.4151 57.7769 43.5151 57.7769Z'
            fill='white'
          />
        </g>
      </g>
      <g opacity='0.4'>
        <g opacity='0.5' filter='url(#filter1_f_958_25132)'>
          <path
            d='M65.2152 66.9769C64.5152 70.2769 61.6152 72.4768 58.7152 71.8768C55.8152 71.2768 54.1153 68.0768 54.8153 64.7768C55.5153 61.4768 58.0154 61.6768 60.9154 62.2768C63.8154 62.8768 66.0152 63.6769 65.2152 66.9769Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.4'>
        <g opacity='0.5' filter='url(#filter2_f_958_25132)'>
          <path
            d='M36.4155 59.0768C35.7155 62.3768 33.4154 64.6768 31.3154 64.2768C29.2154 63.8768 28.1154 60.7768 28.8154 57.4769C29.5154 54.1769 31.3155 54.1768 33.4155 54.6768C35.6155 55.0768 37.2155 55.7768 36.4155 59.0768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.0156 87.8768C49.0156 87.8768 49.0157 92.2768 49.1157 93.5768C49.2157 94.5768 54.2158 93.9768 56.4158 93.2768C58.3158 92.6768 64.8157 87.7768 64.8157 86.4768C61.2157 87.8768 49.7156 88.1768 49.0156 87.8768Z'
        fill='#F9836A'
      />
      <path
        d='M67.2153 120.477C56.6153 117.177 42.3154 120.177 32.0154 119.677C31.8154 119.577 31.5153 120.577 31.2153 122.177C41.5153 128.777 53.7154 132.577 66.8154 132.577C67.0154 132.577 67.1154 132.577 67.3154 132.577C67.4154 126.077 68.1153 120.677 67.2153 120.477Z'
        fill='#5EB1ED'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.4156 98.1768C38.9156 96.2768 41.2156 95.5768 42.9156 95.3768C43.9156 95.2768 44.9156 95.2768 45.9156 95.5768C46.5156 95.7768 48.0155 96.2768 48.1155 96.9768C48.1155 96.6768 46.0155 97.0768 45.8155 97.1768C44.4155 97.4768 44.8154 98.1768 44.5154 99.3768C44.3154 100.177 44.2154 100.877 44.0154 101.677C43.0154 106.277 41.9156 110.777 40.9156 115.377C40.3156 117.877 40.7154 121.277 38.7154 123.177C37.4154 124.377 35.5156 124.377 33.9156 123.777C32.1156 123.077 31.8155 121.777 32.1155 119.777C32.6155 116.277 37.5156 101.477 38.4156 98.1768Z'
        fill='#2E94D9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.2157 125.577C67.8157 117.877 70.7158 106.577 71.6158 103.777C72.0158 102.377 72.7158 100.577 73.8158 99.6768C75.0158 98.7768 76.5157 100.177 77.7157 100.677C77.6157 100.577 77.4158 98.4768 77.3158 98.1768C77.0158 97.2768 76.4159 96.4767 75.4159 96.2767L69.7157 95.6768C64.5157 100.477 61.9158 111.977 58.1158 116.977C54.1158 121.777 51.9158 126.477 54.8158 127.577C59.3158 129.277 60.6157 127.377 62.2157 125.577Z'
        fill='#2E94D9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M59.9155 123.077C59.1155 121.877 57.5154 121.477 56.3154 122.277C55.1154 123.077 54.7153 124.677 55.5153 125.877C56.3153 127.077 57.7154 127.377 59.1154 126.677C60.4154 125.877 60.6155 124.277 59.9155 123.077Z'
        fill='#FFE1B4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.9155 119.377C37.1155 118.177 35.5154 117.777 34.3154 118.577C33.1154 119.377 32.7153 120.977 33.5153 122.177C34.3153 123.377 35.9154 123.777 37.1154 122.977C38.5154 122.177 38.7155 120.577 37.9155 119.377Z'
        fill='#FFE1B4'
      />
      <defs>
        <filter
          id='filter0_f_958_25132'
          x='37.6226'
          y='53.8188'
          width='12.5298'
          height='11.7653'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25132' />
        </filter>
        <filter
          id='filter1_f_958_25132'
          x='51.6582'
          y='58.9249'
          width='16.7231'
          height='16.0505'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25132' />
        </filter>
        <filter
          id='filter2_f_958_25132'
          x='25.605'
          y='51.4359'
          width='14.02'
          height='15.8861'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25132' />
        </filter>
      </defs>
    </svg>
  );
};
