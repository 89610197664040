import React from "react";
import { QuizPuzzle } from "~/shared/components/QuizPuzzle";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import img from "~/shared/assets/images/visotka.jpg";

const Theme38Task2 = () => {
  return (
    <QuizWrapper
      title='ПАЗЛ'
      icon='puzzle'
      successIcon='rus-1'
      subtitle='Собери пазл «Высотное здание Москвы»'
      description='перетащи элементы пазла на соответствующие места'
    >
      <QuizPuzzle img={img} />
    </QuizWrapper>
  );
};

export default Theme38Task2;
