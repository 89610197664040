import React, { useEffect, useState } from "react";
import styles from "./QuizChooseWords.module.scss";
import clsx from "clsx";
import { QuizResult } from "~/shared/components/QuizResult";
import { useQuizContext } from "~/shared/hooks/useQuizContext";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type Props = {
  wordTasks: wordTask[];
};

export type wordTask = {
  text: string;
  words: word[];
  correctWord: string;
  activeWord: string;
};

type word = {
  text: string;
  active: boolean;
};
function contains(arr: any[], elem: any) {
  return arr.indexOf(elem) != -1;
}

export const QuizChooseWords: React.FC<Props> = ({ wordTasks }) => {
  const [tasks, setTasks] = useState(wordTasks);
  const [correctArray, setCorrectArray] = useState<number[]>([]);
  const [showCheckButton, setShowCheckButton] = useState(false);
  const [correct, setCorrect] = useState<boolean>(true);
  const { setSuccess } = useQuizContext();
  const [showResult, setShowResult] = useState(false);
  const containerStyle = useAdaptiveValue(
    styles.containerMobile,
    styles.containerMobile,
    styles.container
  );

  function handleWordCheck(wordIndex: number, taskIndex: number) {
    const newTasks: wordTask[] = tasks.map((task, index) => {
      if (index == taskIndex) {
        if (!contains(correctArray, index)) {
          task.activeWord = task.words[wordIndex].text;
          task.words.forEach((word) => {
            word.active = false;
          });
          task.words[wordIndex].active = true;
        } else {
          task.words.forEach((word) => {
            word.active = false;
          });
        }
      }
      return task;
    });
    setTasks(newTasks);

    setShowCheckButton(true);
    for (const task of tasks) {
      if (task.activeWord == "____") {
        setShowCheckButton(false);
      }
    }
    setShowResult(false);
  }

  function handleButtonCheck() {
    let correctttt = true;
    const arr: number[] = [];
    for (let index = 0; index <= tasks.length - 1; index++) {
      const task = tasks[index];
      correctttt = task.activeWord == task.correctWord && correctttt;

      if (task.activeWord == task.correctWord) {
        arr.push(index);
      }
      task.words.forEach((word) => {
        word.active = false;
      });
    }

    setCorrectArray(arr);
    setCorrect(correctttt);

    setShowResult(true);
  }

  useEffect(() => {
    setTimeout(() => {
      setSuccess(correct && showResult);
    }, 700);
  }, [correct, showResult, correctArray]);

  return (
    <>
      <div className={containerStyle}>
        {tasks.map((wordTask, taskIndex) => {
          return (
            <div className={styles.wordTaskWrapper} key={taskIndex}>
              <p className={styles.text}>
                {taskIndex + 1 + ". " + wordTask.text + " "}{" "}
                <span
                  className={clsx(styles.activeWord, {
                    [styles.activeWord_active]: wordTask.activeWord != "____",
                    [styles.error]: wordTask.activeWord != wordTask.correctWord && showResult,
                    [styles.correct]: wordTask.activeWord == wordTask.correctWord && showResult
                  })}
                >
                  {wordTask.activeWord}
                </span>
              </p>
              <div className={styles.wordsContainer}>
                {wordTask.words.map((word, wordIndex) => {
                  return (
                    <div
                      className={clsx(styles.word, {
                        [styles.active]: word.active,
                        [styles.error]:
                          !contains(correctArray, taskIndex) &&
                          wordTask.activeWord == word.text &&
                          showResult,
                        [styles.correct]:
                          contains(correctArray, taskIndex) && wordTask.activeWord == word.text
                      })}
                      key={wordIndex}
                      onClick={() => handleWordCheck(wordIndex, taskIndex)}
                    >
                      {word.text}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <QuizResult
        buttonVisible={showCheckButton && !showResult}
        buttonOnClick={handleButtonCheck}
        errorVisible={!correct && showResult}
      />
    </>
  );
};
