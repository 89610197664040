import React, { useState } from "react";
import { ChooseItem } from "./components/ChooseItem";
import { QuizResult } from "~/shared/components/QuizResult";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import defaultStyles from "./QuizChooseItem.module.scss";
import rebusStyles from "./QuizChooseItemRebus.module.scss";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";
import clsx from "clsx";

type Props = {
  type: "img" | "str";
  correctItem: string;
  imageRebus?: string | null;
  items: { name: string; image?: string }[];
  hideName?: boolean;
  widthItem?: number;
  taskType?: "rebus" | "default";
  sameHeight?: boolean;
};

export const QuizChooseItem: React.FC<Props> = ({
  type,
  widthItem,
  items,
  correctItem,
  imageRebus = null,
  hideName,
  taskType = "default",
  sameHeight = false
}) => {
  const styles = taskType == "default" ? defaultStyles : rebusStyles;
  const [selectedItem, setSelectedItem] = useState<null | string>(null);
  const [showResult, setShowResult] = useState(false);
  const { success, setSuccess } = useQuizContext();
  const [isWin, setIsWin] = useState(false);

  const wrapperStyle = useAdaptiveValue(styles.wrapperMobile, styles.wrapperMobile, styles.wrapper);

  const handleItemClick = (itemName: string) => {
    setSelectedItem(itemName);
    setShowResult(true);
    if (itemName === correctItem) {
      setIsWin(true);
      setTimeout(() => setSuccess(true), 700);
    } else {
      setSuccess(false);
    }
  };

  return (
    <div className={styles.QuizChooseItem}>
      {!!imageRebus && (
        <div
          className={styles.rebus}
          style={{
            backgroundImage: `url(${imageRebus})`,
            backgroundSize: "cover"
          }}
        ></div>
      )}
      <div className={clsx(wrapperStyle, { [styles.sameHeight]: sameHeight })}>
        {items.map((item, index) => (
          <ChooseItem
            type={type}
            key={index}
            item={item}
            widthItem={widthItem}
            isAnswerCorrect={isWin}
            selectedItem={selectedItem}
            hideName={hideName}
            handleItemClick={handleItemClick}
          />
        ))}
      </div>
      {showResult && (
        <>
          <QuizResult errorVisible={!success && !isWin} />
        </>
      )}
    </div>
  );
};
