import React from "react";
import styles from "./App.module.scss";
import { Link } from "react-router-dom";
import { routes } from "~/routes";

function App() {
  const total = routes.reduce((sum, theme) => sum + theme.links.length, 0);

  return (
    <nav className={styles.app}>
      <div className={styles.total}>Всего заданий: {total}</div>

      {routes.map((route) => (
        <div key={route.title}>
          <div className={styles.title}>{route.title}</div>
          <ul className={styles.list}>
            {route.links.map((item) => (
              <li key={item.title}>
                <Link to={item.link}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );
}

export default App;
