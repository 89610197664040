import React from "react";
export const Dan2: React.FC = () => {
  return (
    <svg
      width='133'
      height='133'
      viewBox='0 0 133 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M66.8157 132.677C103.266 132.677 132.816 103.128 132.816 66.6768C132.816 30.226 103.266 0.676819 66.8157 0.676819C30.3649 0.676819 0.815674 30.226 0.815674 66.6768C0.815674 103.128 30.3649 132.677 66.8157 132.677Z'
        fill='#477EA5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3155 75.2767C10.7155 73.0767 13.2155 73.8767 14.2155 73.8767C14.8155 72.9767 16.8155 71.6767 17.9155 73.1767C18.3155 72.0767 19.8155 70.5767 20.8155 70.6767C22.4155 70.8767 22.3155 72.3767 22.3155 73.7767L23.0155 68.1767C23.4155 64.7767 26.8155 63.6767 26.7155 68.2767C26.7155 68.2767 26.3154 73.5767 25.6154 77.1767L26.9155 77.7767C28.1155 79.9767 28.0155 84.4767 28.0155 84.4767C28.0155 84.4767 26.2154 89.1767 22.6154 91.0767C21.9154 91.1767 24.1154 102.877 29.6154 107.177C33.8154 107.177 45.4155 98.0767 52.4155 95.1767L52.9155 117.477C47.5155 119.877 32.9155 123.877 27.2155 123.777C14.0155 120.377 10.9155 96.0767 9.71547 91.5767C6.51547 87.8767 8.31545 78.5767 10.3155 75.2767Z'
        fill='#FCD1A9'
      />
      <path
        d='M19.7153 79.1767C19.9153 79.1767 20.1153 79.1767 20.2153 79.0767C21.4153 78.6767 22.4153 76.0767 22.5153 73.8767C22.5153 73.6767 22.4153 73.5767 22.2153 73.5767C22.0153 73.5767 21.9153 73.6767 21.9153 73.8767C21.8153 75.9767 20.9153 78.2767 20.0153 78.4767C19.8153 78.5767 18.9153 78.5767 18.5153 77.9767C18.4153 77.8767 18.2153 77.7767 18.1153 77.8767C18.0153 77.9767 17.9153 78.1767 18.0153 78.2767C18.5153 78.9767 19.2153 79.1767 19.7153 79.1767Z'
        fill='#8E3821'
      />
      <path
        d='M16.2166 80.8768C16.3166 80.8768 16.3166 80.8768 16.4166 80.8768C18.8166 80.4768 18.7165 78.1768 18.3165 74.0768C18.3165 73.8768 18.1165 73.7769 18.0165 73.7769C17.8165 73.7769 17.7166 73.9768 17.7166 74.0768C18.0166 78.4768 18.1165 79.9769 16.3165 80.2769C16.0165 80.2769 15.7165 80.1769 15.5165 79.9769C14.8165 79.2769 14.4165 77.4768 14.6165 74.8768C14.6165 74.6768 14.5165 74.5768 14.3165 74.5768C14.1165 74.5768 14.0165 74.6768 14.0165 74.8768C13.8165 77.6768 14.2165 79.5769 15.1165 80.4769C15.4165 80.6769 15.8166 80.8768 16.2166 80.8768Z'
        fill='#8E3821'
      />
      <path
        d='M12.716 81.0768C13.116 81.0768 13.516 80.9768 13.816 80.7768C14.916 80.1768 14.816 78.5768 14.716 77.8768C14.716 77.6768 14.516 77.5768 14.416 77.5768C14.216 77.5768 14.1159 77.7768 14.1159 77.8768C14.1159 78.3768 14.216 79.7768 13.516 80.1768C13.416 80.2768 12.916 80.3768 12.516 80.3768C12.316 80.2768 12.116 80.1768 12.016 79.9768C11.616 78.7768 11.216 76.7768 11.316 75.4768C11.316 75.2768 11.216 75.1768 11.016 75.1768C10.816 75.1768 10.716 75.2768 10.716 75.4768C10.616 76.8768 11.016 79.0768 11.416 80.2768C11.416 80.2768 11.416 80.2768 11.416 80.3768C11.616 80.7768 11.916 80.9768 12.416 81.0768C12.416 81.0768 12.516 81.0768 12.716 81.0768Z'
        fill='#8E3821'
      />
      <path
        d='M24.8159 77.0768C23.2159 76.3768 21.7159 75.9768 21.5159 76.2768C21.0159 77.5768 21.5159 79.6768 24.3159 80.6768C24.3159 80.6768 25.9159 77.5768 24.8159 77.0768Z'
        fill='#FCD1A9'
      />
      <path
        d='M17.9149 87.3768C18.0149 87.3768 18.2149 87.2768 18.2149 87.1768C19.3149 83.6768 23.5149 83.2768 23.6149 83.2768C23.8149 83.2768 23.9149 83.1768 23.9149 82.9768L24.0149 80.8768C24.0149 80.7768 24.0149 80.6768 23.9149 80.6768C20.9149 77.7768 21.5149 76.7768 21.8149 76.2768L25.7149 77.5768C25.9149 77.6768 26.0149 77.5768 26.1149 77.3768C26.2149 77.1768 26.1149 77.0768 25.9149 76.9768L21.8149 75.6768C21.7149 75.6768 21.5149 75.6768 21.4149 75.7768L21.3149 75.8768C20.6149 76.7768 20.4149 78.0768 23.3149 80.9768L23.2149 82.6768C22.2149 82.8768 18.6149 83.5768 17.6149 86.9768C17.5149 87.1768 17.6149 87.3768 17.9149 87.3768Z'
        fill='#8E3821'
      />
      <path
        d='M19.816 91.7769C19.816 91.7769 15.8161 92.5769 14.2161 91.7769H19.816Z'
        fill='#5EB1ED'
      />
      <path
        d='M16.4157 92.4767C18.0157 92.4767 19.7157 92.1767 19.9157 92.0767C20.1157 92.0767 20.2156 91.8767 20.1156 91.6767C20.1156 91.4767 19.9157 91.3767 19.7157 91.4767C19.7157 91.4767 15.8157 92.2767 14.3157 91.4767C14.1157 91.3767 14.0157 91.4767 13.9157 91.5767C13.8157 91.7767 13.9157 91.8767 14.0157 91.9767C14.7157 92.3767 15.5157 92.4767 16.4157 92.4767Z'
        fill='#8E3821'
      />
      <path
        d='M91.2157 101.977C84.6157 95.1767 77.6157 93.4767 73.8157 91.0767C71.4157 92.8767 57.2157 92.2767 57.2157 92.2767C50.1157 95.1767 44.6157 106.777 43.8157 113.177C43.5157 115.277 43.1157 121.077 42.8157 128.077C50.3157 130.977 58.4157 132.577 66.9157 132.577C75.3157 132.577 83.3157 130.977 90.7157 128.177C90.9157 126.077 91.1157 124.777 91.0157 124.977C90.9157 125.077 97.8157 108.777 91.2157 101.977Z'
        fill='#FF7A00'
      />
      <path
        d='M93.7153 112.277C83.6153 109.077 82.4153 121.877 82.4153 128.877C82.4153 129.577 82.4153 130.177 82.4153 130.877C88.9153 129.277 95.0153 126.777 100.515 123.477C100.215 118.477 98.7153 113.777 93.7153 112.277Z'
        fill='#FCD1A9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M54.7151 94.1768C54.7151 94.1768 63.9151 109.077 44.7151 121.777C40.8151 120.577 36.3151 107.477 36.6151 104.377C36.6151 102.577 48.5151 96.4768 54.7151 94.1768Z'
        fill='#FF7A00'
      />
      <path
        d='M73.8149 91.1767L74.1149 91.5767C60.2149 102.277 57.0149 93.1767 57.0149 93.1767C57.0149 93.1767 58.4149 82.7767 58.0149 80.9767C57.0149 75.9767 74.3149 77.6767 74.3149 77.6767C73.6149 82.4767 72.3149 87.4767 73.8149 91.1767Z'
        fill='#FCD1A9'
      />
      <mask
        id='mask0_958_24518'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='57'
        y='77'
        width='18'
        height='20'
      >
        <path
          d='M74.3149 77.7767C73.6149 82.5767 72.3149 87.5767 73.8149 91.1767L74.1149 91.5767C60.2149 102.277 57.0149 93.1767 57.0149 93.1767C57.0149 93.1767 58.4149 82.7767 58.0149 80.9767C57.4149 77.9767 63.2149 77.3767 68.0149 77.3767C71.4149 77.4767 74.3149 77.7767 74.3149 77.7767Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_958_24518)'>
        <path
          d='M50.7148 88.0767C50.7148 88.0767 62.9148 82.5767 69.0148 87.4767C75.1148 92.3767 75.2148 93.1767 72.7148 95.3767C70.2148 97.5767 87.3148 91.7767 87.8148 91.0767C88.2148 90.3767 79.9148 81.8767 78.5148 81.8767C77.1148 81.8767 53.1148 81.0767 52.5148 80.5767C51.8148 79.9767 50.7148 88.0767 50.7148 88.0767Z'
          fill='#F9836A'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M101.216 118.977C96.3161 104.977 91.6161 101.377 86.7161 98.1768L78.7161 111.177L82.5161 124.177C89.6161 122.577 102.016 122.277 101.216 118.977Z'
        fill='#FF7A00'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.3156 29.8768C38.4156 33.4768 40.2156 41.4768 42.0156 49.0768C43.7156 48.0768 41.4156 43.2768 44.2156 45.0768C44.0156 40.6768 44.7156 37.0768 46.8156 34.2768L43.3156 29.8768Z'
        fill='#924026'
      />
      <path
        d='M42.4157 56.0768L42.1156 56.3768L41.9157 55.9768C39.6157 52.3768 38.9157 48.3768 39.9157 45.6768C40.4157 44.3768 41.3156 43.3768 42.6156 42.9768C43.9156 42.5768 45.5157 42.8768 47.5157 43.9768L47.7157 44.0768V44.2768C47.8157 47.1768 46.8156 50.1768 45.1156 53.0768L42.4157 56.0768Z'
        fill='#F9BC90'
      />
      <path
        d='M46.3151 33.6768L46.6151 33.4768L46.8151 33.7768C51.9151 39.3768 59.9151 40.9768 67.5151 36.0768C69.9151 34.5768 72.4151 33.4768 74.8151 33.2768C77.2151 33.0768 79.6151 33.6768 81.8151 35.4768C84.8151 36.7768 86.3151 38.5768 87.1151 40.6768C87.9151 42.7768 87.9151 45.0768 87.8151 47.0768C87.8151 47.2768 87.8151 47.5768 87.8151 47.7768C87.8151 48.5768 87.7151 49.2768 87.8151 49.9768C87.8151 50.8768 87.9151 51.5768 88.1151 52.1768C88.3151 52.7768 88.6151 53.1768 89.2151 53.4768C89.7151 53.7768 90.5151 53.8768 91.6151 53.7768C94.7151 52.9768 97.0151 53.5768 98.6151 54.9768C100.115 56.3768 100.815 58.4768 100.615 60.5768C100.215 64.8768 96.2151 69.4768 88.6151 69.4768C84.5151 76.7768 79.7151 82.0768 71.1151 83.6768C66.2151 84.5768 61.5151 84.9768 56.6151 84.9768C53.4151 85.0768 50.3151 83.6768 47.6151 81.5768C44.8151 79.3768 42.4151 76.4768 40.6151 73.1768C38.8151 69.8768 37.6151 66.2768 37.3151 62.7768C37.0151 59.3768 37.6152 56.0768 39.4152 53.4768C42.2152 50.1768 43.4151 46.2768 43.1151 41.7768C43.0151 38.1768 44.1151 35.5768 46.3151 33.6768Z'
        fill='#FCD1A9'
      />
      <path
        d='M45.8893 34.1136L46.1893 33.9136L46.3893 34.2136C51.4893 39.8136 59.4893 41.4136 67.0893 36.5136C69.4893 35.0136 71.9893 33.9136 74.3893 33.7136C76.7893 33.5136 79.1893 34.1136 81.3893 35.9136C84.3893 37.2136 85.8893 39.0136 86.6893 41.1136C87.4893 43.2136 87.4893 45.5136 87.3893 47.5136C87.3893 47.7136 87.3893 48.0136 87.3893 48.2136C87.3893 49.0136 87.2893 49.7136 87.3893 50.4136C87.3893 51.3136 87.4893 52.0136 87.6893 52.6136C87.8893 53.2136 88.1894 53.6136 88.7894 53.9136C89.2894 54.2136 90.0893 54.3136 91.1893 54.2136C94.2893 53.4136 96.5893 54.0136 98.1893 55.4136C99.6893 56.8136 100.389 58.9136 100.189 61.0136C99.7893 65.3136 95.7893 69.9136 88.1893 69.9136C84.0893 77.2136 79.2893 82.5136 70.6893 84.1136C65.7893 85.0136 61.0893 85.4136 56.1893 85.4136C52.9893 85.5136 49.8893 84.1136 47.1893 82.0136C44.3893 79.8136 41.9893 76.9136 40.1893 73.6136C38.3893 70.3136 37.1893 66.7136 36.8893 63.2136C36.5893 59.8136 37.1894 56.5136 38.9894 53.9136C41.7894 50.6136 42.9893 46.7136 42.6893 42.2136C42.5893 38.6136 43.6893 36.0136 45.8893 34.1136Z'
        fill='#FCD1A9'
      />
      <path
        d='M89.7153 65.0768C92.3153 65.0768 94.3152 63.8768 95.1152 61.9768C95.6152 60.7768 95.4153 59.5768 94.7153 58.8768C94.2153 58.3768 93.2152 57.7768 91.1152 58.5768C90.9152 58.6768 90.9153 58.7768 90.9153 58.9768C91.0153 59.1768 91.1152 59.1768 91.3152 59.1768C92.6152 58.6768 93.6153 58.7768 94.2153 59.3768C94.8153 59.9768 94.9153 60.8768 94.5153 61.8768C93.8153 63.4768 92.0153 64.4768 89.7153 64.5768C89.5153 64.5768 89.4153 64.6768 89.4153 64.8768C89.4153 64.9768 89.5153 65.0768 89.7153 65.0768Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M94.9158 75.1768C90.5158 78.4768 85.3158 80.5768 79.7158 80.0768C84.5158 77.1768 88.0158 69.4769 88.0158 69.4769C103.216 69.5769 102.516 50.0769 90.9158 54.2769C88.2158 55.2769 87.2158 57.8768 85.5158 56.8768C85.5158 49.9768 79.9158 44.8768 80.2158 38.6768C80.2158 37.7768 79.1158 36.8768 78.3158 37.2768C68.4158 46.5768 51.6158 40.0768 43.1158 35.8768C35.6158 32.1768 33.2158 24.7769 34.9158 22.4769C36.6158 20.1769 38.2158 21.1768 40.3158 23.2768C41.0158 16.0768 46.0158 11.2769 47.9158 15.4769C49.8158 19.7769 53.6158 17.4769 53.6158 17.4769C58.5158 14.0769 56.1158 14.2769 62.7158 12.4769C75.5158 8.37685 85.2158 15.2768 90.5158 23.8768C91.9158 20.6768 92.4158 15.9768 91.5158 13.2768C95.1158 17.2768 93.7158 22.0768 91.8158 25.2768C94.9158 23.9768 96.5158 23.8768 98.9158 24.5768C104.116 26.0768 96.1158 24.8768 94.5158 27.7768C115.716 36.2768 112.216 67.4769 93.2158 77.7769C93.9158 76.8769 94.4158 76.3768 94.9158 75.1768Z'
        fill='#924026'
      />
      <path
        d='M51.315 62.3769C51.515 62.3769 51.6149 62.1769 51.6149 62.0769C51.6149 61.8769 51.415 61.7769 51.315 61.7769C49.615 61.7769 48.415 61.1769 47.815 60.1769C47.315 59.3769 47.315 58.3769 47.815 57.6769C48.115 57.0769 48.715 56.7769 49.315 56.7769C50.515 56.7769 51.415 56.4769 51.915 55.8769C52.515 55.1769 52.415 54.3769 52.415 54.2769C52.415 54.0769 52.215 53.9769 52.015 53.9769C51.815 53.9769 51.715 54.1769 51.715 54.3769C51.715 54.3769 51.815 54.9769 51.415 55.3769C51.015 55.7769 50.315 55.9769 49.415 55.9769C48.515 55.9769 47.715 56.3769 47.315 57.1769C46.715 58.0769 46.815 59.3769 47.315 60.3769C47.615 61.0769 48.715 62.3769 51.315 62.3769Z'
        fill='#8E3821'
      />
      <path
        d='M55.2153 61.9769C55.7153 61.9769 56.2153 61.4769 56.4153 60.9769C56.6153 60.3769 56.4153 59.8769 56.0153 59.4769C55.9153 59.3769 55.6153 59.3769 55.5153 59.4769C55.4153 59.5769 55.4153 59.8769 55.5153 59.9769C55.8153 60.1769 55.7153 60.5769 55.7153 60.7769C55.6153 61.0769 55.4153 61.2769 55.2153 61.2769C55.0153 61.2769 54.9153 61.4769 54.9153 61.6769C54.9153 61.7769 55.1153 61.9769 55.2153 61.9769Z'
        fill='#8E3821'
      />
      <path
        d='M50.0161 44.4768C50.2161 44.4768 50.4161 44.3768 50.5161 44.1768C50.7161 43.8768 50.7162 43.4768 50.4162 43.1768C50.4162 43.1768 50.3162 43.0768 50.2162 43.0768C49.3162 42.3768 47.3162 40.8768 45.2162 41.3768C44.8162 41.4768 44.6162 41.8768 44.7162 42.1768C44.8162 42.5768 45.2161 42.7768 45.5161 42.6768C47.0161 42.2768 48.5161 43.5768 49.3161 44.1768C49.4161 44.1768 49.4161 44.2768 49.5161 44.2768C49.7161 44.4768 49.8161 44.4768 50.0161 44.4768Z'
        fill='#7D0000'
      />
      <path
        d='M70.6153 48.677C70.8153 48.677 71.0153 48.577 71.1153 48.377C71.3153 48.077 71.3154 47.677 71.0154 47.377C70.1154 46.677 68.0154 45.677 65.9154 46.177C65.5154 46.277 65.3154 46.677 65.4154 46.977C65.5154 47.377 65.9154 47.577 66.2154 47.477C67.9154 47.077 69.6153 47.977 70.1153 48.377C70.3153 48.677 70.5153 48.677 70.6153 48.677Z'
        fill='#7D0000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M68.2152 55.9769C67.8152 57.8769 66.4152 59.1769 64.9152 58.8769C63.4152 58.5769 62.6152 56.6769 62.9152 54.7769C63.3152 52.8769 64.7152 51.5769 66.2152 51.8769C67.7152 52.1769 68.6152 54.0769 68.2152 55.9769Z'
        fill='white'
      />
      <mask
        id='mask1_958_24518'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='62'
        y='51'
        width='7'
        height='8'
      >
        <path
          d='M66.2152 51.8768C67.7152 52.1768 68.5152 54.0768 68.2152 55.9768C67.8152 57.8768 66.4152 59.1768 64.9152 58.8768C63.4152 58.5768 62.6152 56.6768 62.9152 54.7768C63.2152 53.0768 64.4152 51.8768 65.7152 51.8768C65.9152 51.8768 66.1152 51.8768 66.2152 51.8768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_958_24518)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M68.5151 56.9768C67.8151 58.6768 66.3151 59.4768 65.0151 58.7768C63.7151 58.0768 63.3151 56.1768 64.0151 54.4768C64.7151 52.7768 66.2151 51.9768 67.5151 52.6768C68.7151 53.3768 69.2151 55.2768 68.5151 56.9768Z'
          fill='#7C170F'
        />
      </g>
      <mask
        id='mask2_958_24518'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='62'
        y='51'
        width='7'
        height='8'
      >
        <path
          d='M66.2152 51.8768C67.7152 52.1768 68.5152 54.0768 68.2152 55.9768C67.8152 57.8768 66.4152 59.1768 64.9152 58.8768C63.4152 58.5768 62.6152 56.6768 62.9152 54.7768C63.2152 53.0768 64.4152 51.8768 65.7152 51.8768C65.9152 51.8768 66.1152 51.8768 66.2152 51.8768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask2_958_24518)'>
        <path
          d='M64.3152 52.6768C64.8152 52.6768 65.3152 53.0768 65.3152 53.6768C65.3152 54.1768 64.9153 54.5768 64.4153 54.5768C63.9153 54.5768 63.4153 54.1768 63.4153 53.5768C63.4153 53.0768 63.8152 52.6768 64.3152 52.6768Z'
          fill='white'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.516 51.4769C48.116 53.3769 46.716 54.6769 45.216 54.3769C43.716 54.0769 42.916 52.1769 43.216 50.2769C43.616 48.3769 45.016 47.0769 46.516 47.3769C48.016 47.7769 48.916 49.5769 48.516 51.4769Z'
        fill='white'
      />
      <mask
        id='mask3_958_24518'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='43'
        y='47'
        width='6'
        height='8'
      >
        <path
          d='M46.516 47.3768C48.016 47.6768 48.816 49.5768 48.516 51.4768C48.116 53.3768 46.716 54.6768 45.216 54.3768C43.716 54.0768 42.916 52.1768 43.216 50.2768C43.516 48.5768 44.716 47.3768 46.016 47.3768C46.216 47.3768 46.416 47.3768 46.516 47.3768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask3_958_24518)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M48.8159 52.8768C48.1159 54.5768 46.6159 55.3768 45.3159 54.6768C44.0159 53.9768 43.6159 52.0768 44.3159 50.3768C45.0159 48.6768 46.5159 47.8768 47.8159 48.5768C49.0159 49.2768 49.4159 51.1768 48.8159 52.8768Z'
          fill='#7C170F'
        />
      </g>
      <mask
        id='mask4_958_24518'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='43'
        y='47'
        width='6'
        height='8'
      >
        <path
          d='M46.516 47.3768C48.016 47.6768 48.816 49.5768 48.516 51.4768C48.116 53.3768 46.716 54.6768 45.216 54.3768C43.716 54.0768 42.916 52.1768 43.216 50.2768C43.516 48.5768 44.716 47.3768 46.016 47.3768C46.216 47.3768 46.416 47.3768 46.516 47.3768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask4_958_24518)'>
        <path
          d='M44.7161 48.1768C45.2161 48.1768 45.7161 48.5768 45.7161 49.1768C45.7161 49.6768 45.3161 50.0768 44.8161 50.0768C44.3161 50.0768 43.8161 49.6768 43.8161 49.1768C43.7161 48.5768 44.1161 48.1768 44.7161 48.1768Z'
          fill='white'
        />
      </g>
      <path
        d='M52.7151 70.0766C53.6151 70.0766 54.515 69.9766 55.615 69.6766C55.815 69.5766 55.915 69.4766 55.815 69.2766C55.715 69.0766 55.6151 68.9766 55.4151 69.0766C51.4151 70.2766 48.815 68.4766 48.615 68.0766C48.515 67.8766 48.3151 67.7766 48.2151 67.8766C48.0151 67.9766 47.9151 68.1766 48.0151 68.2766C48.1151 68.7766 49.9151 70.0766 52.7151 70.0766Z'
        fill='#8E3821'
      />
      <path
        d='M50.9158 71.7768H53.1157C53.3157 71.7768 53.4158 71.5768 53.4158 71.4768C53.4158 71.2768 53.2157 71.1768 53.1157 71.1768H50.9158C50.7158 71.1768 50.6157 71.3768 50.6157 71.4768C50.5157 71.5768 50.7158 71.7768 50.9158 71.7768Z'
        fill='#8E3821'
      />
      <g opacity='0.3'>
        <g opacity='0.5' filter='url(#filter0_f_958_24518)'>
          <path
            d='M65.6151 62.0768C68.1151 62.7768 70.2151 63.0768 69.5151 65.4768C68.8151 67.9768 66.2151 69.3768 63.7151 68.6768C61.2151 67.9768 59.8151 65.3768 60.5151 62.8768C61.2151 60.3768 63.1151 61.3768 65.6151 62.0768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.5' filter='url(#filter1_f_958_24518)'>
        <path
          d='M50.8153 56.9766C51.1153 57.0766 51.3153 57.0767 51.5153 57.2767C51.7153 57.4767 51.7153 57.7766 51.7153 58.0766C51.7153 58.7766 51.4153 59.5766 50.9153 60.1766C50.4153 60.7766 49.7153 61.0766 48.9153 61.0766C47.2153 60.9766 46.9153 59.3766 47.3153 57.9766C48.0153 56.0766 49.3153 56.5766 50.8153 56.9766Z'
          fill='#F15331'
        />
      </g>
      <g opacity='0.3'>
        <g opacity='0.5' filter='url(#filter2_f_958_24518)'>
          <path
            d='M42.7167 55.8769C45.2167 56.5769 47.3167 56.8769 46.6167 59.2769C45.9167 61.7769 43.3167 63.1769 40.8167 62.4769C38.3167 61.7769 36.9167 59.1769 37.6167 56.6769C38.4167 54.2769 40.3167 55.1769 42.7167 55.8769Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        opacity='0.5'
        d='M50.2443 57.8082C51.7443 58.5082 50.9443 60.2082 49.8443 59.9082C48.1443 59.4082 48.7443 57.1082 50.2443 57.8082Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_f_958_24518'
          x='57.3352'
          y='58.2756'
          width='15.3164'
          height='13.5811'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24518' />
        </filter>
        <filter
          id='filter1_f_958_24518'
          x='44.1575'
          y='53.6035'
          width='10.5579'
          height='10.4731'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24518' />
        </filter>
        <filter
          id='filter2_f_958_24518'
          x='34.4368'
          y='52.1141'
          width='15.3164'
          height='13.5428'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24518' />
        </filter>
      </defs>
    </svg>
  );
};
