import React, { ReactNode } from "react";
import { Test } from "./components/Test";
import { Puzzle } from "./components/Puzzle";
import { Question } from "./components/Question";
import { Game } from "./components/Game";
import { CrossWord } from "./components/CrossWord";
import { CheckMark } from "./components/CheckMark";
import { Close } from "./components/Close";

export type IconName =
  | "test"
  | "puzzle"
  | "question"
  | "game"
  | "cross-word"
  | "check-mark"
  | "close";

type Props = {
  name: IconName;
};

const icons: Record<IconName, ReactNode> = {
  test: <Test />,
  puzzle: <Puzzle />,
  question: <Question />,
  game: <Game />,
  "cross-word": <CrossWord />,
  "check-mark": <CheckMark />,
  close: <Close />
};
export const Icon: React.FC<Props> = ({ name }) => {
  return <>{icons[name]}</>;
};
