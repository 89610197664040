import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import QuizDrawLine, { DrawLineBlock } from "~/modules/QuizDrawLine/QuizDrawLine";

const firstBlocks: DrawLineBlock[] = [
  {
    name: "12 апреля",
    pair: "День космонавтики"
  },
  {
    name: "4 ноября",
    pair: "День народного единства"
  },
  {
    name: "7 января",
    pair: "Рождество"
  },
  {
    name: "9 мая",
    pair: "День Победы"
  }
];

const secondBlocks: DrawLineBlock[] = [
  {
    name: "Рождество",
    pair: "7 января"
  },
  {
    pair: "9 мая",
    name: "День Победы"
  },
  {
    pair: "4 ноября",
    name: "День народного единства"
  },
  {
    pair: "12 апреля",
    name: "День космонавтики"
  }
];

const Theme41Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Когда мы отмечаем эти праздники?'
      description='Нажимай на дату и название праздника.'
      successIcon='rus-2'
    >
      <QuizDrawLine firstCol={firstBlocks} secondCol={secondBlocks} sameHeight />
    </QuizWrapper>
  );
};

export default Theme41Task1;
