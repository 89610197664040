import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizEnterWord } from "~/modules/QuizEnterWord";
import { ImageRebus } from "~/shared/components/ImageRebus";
import img from "~/shared/assets/images/24/rebus.jpg";

const Theme24Task1 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Разгадай ребус.'
      description='Напиши отгадку в поле ниже'
      successIcon='nast-2'
    >
      <ImageRebus url={img} />
      <QuizEnterWord answer='Дворец' rebus />
    </QuizWrapper>
  );
};

export default Theme24Task1;
