import React, { useRef } from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizUnscrumbleWord } from "~/modules/QuizUnscrambleWord";

type ClueData = {
  clue: string;
  answer: string;
  row: number;
  col: number;
};

type AcrossData = Record<number, ClueData>;
type DownData = Record<number, ClueData>;

export type PuzzleData = {
  across: AcrossData;
  down: DownData;
};
export type AnswerData = {
  answer: string;
};

const data: AnswerData = {
  answer: "ИМПЕРАТОР"
};

const Theme22Task2 = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <QuizWrapper
      ref={containerRef}
      title='Тест'
      icon='test'
      subtitle='Расшифруй слово – титул Петра I, по коду в таблице. Напечатай отгаданные буквы.'
      description='Определи верные буквы и напечатай их в поле ниже'
      successIcon='nast-2'
      isBlockWrapper
    >
      <QuizUnscrumbleWord data={data} containerRef={containerRef} />
    </QuizWrapper>
  );
};

export default Theme22Task2;
