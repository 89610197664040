import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItems } from "~/modules/QuizChooseItems";

const Theme33Task2 = () => {
  const items: { name: string }[] = [
    {
      name: "Сокольники"
    },
    {
      name: "Спортивная"
    },
    {
      name: "Парк культуры"
    },
    { name: "Парк Победы" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какие станции метро входили в первую линию?'
      description='Выбери 2 правильных ответа'
      successIcon='rus-1'
    >
      <QuizChooseItems type='str' items={items} correctItems={["Сокольники", "Парк культуры"]} />
    </QuizWrapper>
  );
};

export default Theme33Task2;
