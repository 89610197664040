import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTimeLine, Answer, DraggableItem, DropZone } from "~modules/QuizTimeLine";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    title: "Отечественная война",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 2,
    title: "Великая Отечественная война",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 3,
    title: "Куликовская битва",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 4,
    title: "Смутное время",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 2,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 3,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 4,
    correct: false,
    uncorrect: false,
    empty: true
  }
];

const answers: Answer = { 1: 3, 2: 4, 3: 1, 4: 2 };

const Theme36Task3 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Что было раньше, а что позже? '
      description='Правильно расположи карточки'
      successIcon='dan-2'
    >
      <QuizTimeLine draggableItems={draggableItems} dropZones={dropZones} answers={answers} />
    </QuizWrapper>
  );
};

export default Theme36Task3;
