import React from "react";
import { QuizPuzzle } from "~/shared/components/QuizPuzzle";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import img from "~shared/assets/images/17/pamyatnik_mininu_i_pozharskomu.jpg";

const Theme17Task3 = () => {
  return (
    <QuizWrapper
      title='ПАЗЛ'
      icon='puzzle'
      successIcon='rus-1'
      subtitle='Собери памятник Минину и Пожарскому.'
      description='Перетащи элементы пазла на соответствующие места.'
    >
      <QuizPuzzle img={img} />
    </QuizWrapper>
  );
};

export default Theme17Task3;
