import React, { FC } from "react";
import styles from "./LetterSquare.module.scss";
import clsx from "clsx";

type Props = {
  letter: string;
  crossed: boolean;
  onClick: () => void;
  showResult: boolean;
  rowError: boolean;
  correctWord: string;
};

export const LetterSquare: FC<Props> = ({
  letter,
  showResult,
  crossed,
  rowError,
  correctWord,
  onClick
}) => {
  const errorLetter = correctWord.includes(letter);
  return (
    <div style={{ width: "100%" }}>
      <div
        className={clsx(
          styles.letterSquare,
          crossed && styles.crossed,
          crossed && showResult && rowError && errorLetter && styles.error
        )}
        onClick={onClick}
      >
        <div>{letter}</div>
        <div className={clsx(styles.hidden, crossed && styles.lineThrough)}></div>
      </div>
    </div>
  );
};
