import React from "react";
import styles from "./QuizResult.module.scss";
import { AppButton } from "~shared/components/AppButton";
import { Icon } from "~shared/components/Icon";

type Props = {
  buttonVisible?: boolean;
  buttonValue?: string;
  buttonOnClick?: () => void;
  errorVisible?: boolean;
  errorText?: string;
};

export const QuizResult: React.FC<Props> = ({
  buttonVisible = false,
  buttonValue = "Проверить",
  buttonOnClick,
  errorVisible = false,
  errorText = "Неверно. Попробуй ещё раз."
}) => {
  return (
    <div className={styles.quizResult}>
      {errorVisible && (
        <div className={styles.wrongTextWrapper}>
          <span className={styles.iconWrapper}>
            <Icon name='close' />
          </span>
          <div className={styles.wrongText}>{errorText}</div>
        </div>
      )}
      {buttonVisible && <AppButton value={buttonValue} onClick={buttonOnClick} />}
    </div>
  );
};
