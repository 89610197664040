import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme39Task2 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "Архитектор"
    },
    {
      name: "Врач"
    },
    {
      name: "Электрик"
    },
    { name: "Маляр" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Как называется профессия человека, который проектирует здания?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Архитектор' />
    </QuizWrapper>
  );
};

export default Theme39Task2;
