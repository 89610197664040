import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme43Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "На Красной площади"
    },
    {
      name: "Недалеко от Кремля"
    },
    {
      name: "Около МГУ"
    },
    {
      name: "На ВДНХ"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Где построен парк Зарядье?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Недалеко от Кремля' sameHeight />
    </QuizWrapper>
  );
};

export default Theme43Task1;
