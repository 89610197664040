import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme22Task4 = () => {
  const items: { name: string }[] = [
    {
      name: "Весёлые"
    },
    {
      name: "Потешные"
    },
    {
      name: "Радостные"
    },
    { name: "Скучные" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Как Петр I называл военные упражнения?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Потешные' />
    </QuizWrapper>
  );
};

export default Theme22Task4;
