import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

import Izba from "~shared/assets/images/30/izba.jpg";
import Terem from "~shared/assets/images/30/terem.jpg";
import Usad from "~shared/assets/images/30/usadba.jpg";
import Brezh from "~shared/assets/images/30/mnogoetajniy_dom.jpg";

const Theme30Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Терем",
      image: Terem
    },
    {
      name: "Изба",
      image: Izba
    },
    {
      name: "Усадьба",
      image: Usad
    },
    {
      name: "Девятиэтажный дом",
      image: Brezh
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какой дом называли «Тучерезом»?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Девятиэтажный дом' />
    </QuizWrapper>
  );
};

export default Theme30Task2;
