import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import Arka from "~shared/assets/images/26/Arka.jpg";
import Blah from "~shared/assets/images/26/Blah.jpg";
import Bolsh from "~shared/assets/images/26/Bolsh.jpg";
import Manezh from "~shared/assets/images/26/Manezh.jpg";

const Theme26Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Триумфальная арка",
      image: Arka
    },
    {
      name: "Храм Василия Блаженного",
      image: Blah
    },
    {
      name: "Большой театр",
      image: Bolsh
    },
    {
      name: "Манеж",
      image: Manezh
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри фотографии. Отметь то, что не было построено Осипом Бове.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Храм Василия Блаженного' hideName />
    </QuizWrapper>
  );
};

export default Theme26Task2;
