import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizEnterWord } from "~modules/QuizEnterWord";

const Theme28Task3 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Дополни предложение.'
      description='Напечатай пропущенное слово'
      successIcon='nast-2'
    >
      <QuizEnterWord
        question='Не отставали и наши конди́теры. Москву называли ________ сла́достей.'
        answer='СТОЛИЦЕЙ'
        secondAnswer='СТОЛИЦА'
      />
    </QuizWrapper>
  );
};

export default Theme28Task3;
