import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

import Groz from "~/shared/assets/images/28/Grozny.jpg";
import Petr from "~/shared/assets/images/28/Petr.jpg";
import Kutuzov from "~/shared/assets/images/28/Kutuzov.jpg";
import Nick from "~/shared/assets/images/28/Nikolay.jpg";

const Theme28Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Иван Грозный",
      image: Groz
    },
    {
      name: "Пётр I",
      image: Petr
    },
    {
      name: "Кутузов",
      image: Kutuzov
    },
    {
      name: "Николай I",
      image: Nick
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Отметь портрет императора, при котором было открыто движение между двумя столицами.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Николай I' />
    </QuizWrapper>
  );
};

export default Theme28Task1;
