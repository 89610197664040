import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme37Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "1 июня"
    },
    {
      name: "9 мая"
    },
    {
      name: "22 июня"
    },
    {
      name: "12 августа"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Когда мы отмечаем День Победы?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='9 мая' />
    </QuizWrapper>
  );
};

export default Theme37Task1;
