import React from "react";
import { QuizPuzzle } from "~/shared/components/QuizPuzzle";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import img from "~shared/assets/images/35/pazl.jpg";

const Theme35Task4 = () => {
  return (
    <QuizWrapper
      title='ПАЗЛ'
      icon='puzzle'
      successIcon='rus-1'
      subtitle='Собери пазл «Изображение речного вокзала».'
      description='Перетащи элементы пазла на соответствующие места'
    >
      <QuizPuzzle img={img} />
    </QuizWrapper>
  );
};

export default Theme35Task4;
