import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import QuizDrawLine, { DrawLineBlock } from "~/modules/QuizDrawLine/QuizDrawLine";

const firstBlocks: DrawLineBlock[] = [
  {
    name: "молот",
    pair: "molot"
  },
  {
    name: "наковальня",
    pair: "nakovalnya"
  },
  {
    name: "клещи",
    pair: "kleshi"
  },
  {
    name: "зубило",
    pair: "zubilo"
  }
];

const secondBlocks: DrawLineBlock[] = [
  {
    url: require("../../../shared/assets/images/zubilo.jpg"),
    name: "zubilo",
    pair: "зубило"
  },
  {
    url: require("../../../shared/assets/images/nakovalnya.jpg"),
    name: "nakovalnya",
    pair: "наковальня"
  },
  {
    url: require("../../../shared/assets/images/kleschi.jpg"),
    name: "kleshi",
    pair: "клещи"
  },
  {
    url: require("~shared/assets/images/molot.jpg"),
    name: "molot",
    pair: "молот"
  }
];

const Theme10Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Определи верное название каждого предмета.'
      description='Нажми на текст и подходящую картинку.'
      successIcon='rus-2'
    >
      <QuizDrawLine firstCol={firstBlocks} secondCol={secondBlocks} />
    </QuizWrapper>
  );
};

export default Theme10Task1;
