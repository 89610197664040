import React from "react";
export const Question: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 3.05176e-06C5.35 3.05176e-06 0 4.5 0 10C0 12.5 1.10002 14.75 2.90002 16.5L1 24L8.20001 19.5C9.40001 19.85 10.65 20 12 20C18.65 20 24 15.5 24 10C24 4.45 18.65 3.05176e-06 12 3.05176e-06ZM12 16C11.45 16 11 15.55 11 15C11 14.45 11.45 14 12 14C12.55 14 13 14.45 13 15C13 15.55 12.55 16 12 16ZM13 11.85V12C13 12.55 12.55 13 12 13C11.45 13 11 12.55 11 12V11C11 10.45 11.45 10 12 10C13.1 10 14 9.1 14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 8.55 9.55 9 9 9C8.45 9 8 8.55 8 8C8 5.8 9.8 4 12 4C14.2 4 16 5.8 16 8C16 9.85 14.7 11.4 13 11.85Z'
        fill='#068585'
      />
    </svg>
  );
};
