import React from "react";
import styles from "./../../QuizTimeLine.module.scss";
import { useDroppable } from "@dnd-kit/core";
import clsx from "clsx";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type Props = {
  id: number;
  correct: boolean;
  uncorrect: boolean;
};

export const DropContainer: React.FC<Props> = ({ id, correct, uncorrect }) => {
  const { setNodeRef, isOver } = useDroppable({
    id
  });
  const dropZoneStyle = useAdaptiveValue(
    styles.dropZoneMobile,
    styles.dropZoneMobile,
    styles.dropZone
  );
  return (
    <div
      className={clsx(dropZoneStyle, { [styles.error]: uncorrect, [styles.success]: correct })}
      ref={setNodeRef}
      style={{ opacity: isOver ? 1 : 1 }}
    ></div>
  );
};
