import React from "react";
import styles from "../QuizUnscrambleWord.module.scss";
import clsx from "clsx";

type AllowedInputTypes = "password" | "text" | "number" | "tel";

type InputProps = Required<
  Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    | "value"
    | "onChange"
    | "onFocus"
    | "onBlur"
    | "onPaste"
    | "onBeforeInput"
    | "aria-label"
    | "maxLength"
    | "autoComplete"
    | "style"
    | "inputMode"
    | "onInput"
  > & {
    ref: React.RefCallback<HTMLInputElement>;
    placeholder: string | undefined;
    className: string | undefined;
    type: AllowedInputTypes;
  }
>;

interface OTPInputProps {
  /** Value of the OTP input */
  value?: string;

  correctLetter?: string;
  /** Number of OTP inputs to be rendered */
  numInputs?: number;
  /** Callback to be called when the OTP value changes */
  onChange: (otp: string) => void;
  /** Function to render the input */
  renderInput: (inputProps: InputProps, index: number) => React.ReactNode;
  /** Whether the first input should be auto focused */
  shouldAutoFocus?: boolean;
  /** Placeholder for the inputs */
  placeholder?: string;
  /** Function to render the separator */
  renderSeparator?: ((index: number) => React.ReactNode) | React.ReactNode;
  /** Style for the container */
  containerStyle?: React.CSSProperties | string;
  /** Style for the input */
  inputStyle?: any;
  /** The type that will be passed to the input being rendered */
  inputType?: AllowedInputTypes;

  showResult: boolean;
}

const isStyleObject = (obj: unknown) => typeof obj === "object" && obj !== null;

const OTPInput = ({
  value = "",
  correctLetter,
  numInputs = 4,
  onChange,
  renderInput,
  shouldAutoFocus = false,
  inputType = "text",
  renderSeparator,
  placeholder,
  containerStyle,
  inputStyle,
  showResult
}: OTPInputProps) => {
  const [activeInput, setActiveInput] = React.useState(0);
  const inputRefs = React.useRef<Array<HTMLInputElement | null>>([]);

  const getOTPValue = () => (value ? value.toString().split("") : []);

  const isInputNum = inputType === "number" || inputType === "tel";

  React.useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
  }, [numInputs]);

  React.useEffect(() => {
    if (shouldAutoFocus) {
      inputRefs.current[0]?.focus();
    }
  }, [shouldAutoFocus]);

  const getPlaceholderValue = () => {
    if (typeof placeholder === "string") {
      if (placeholder.length === numInputs) {
        return placeholder;
      }

      if (placeholder.length > 0) {
        console.error("Length of the placeholder should be equal to the number of inputs.");
      }
    }
    return undefined;
  };

  const isInputValueValid = (value: string) => {
    const isTypeValid = isInputNum ? !isNaN(Number(value)) : typeof value === "string";
    return isTypeValid && value.trim().length === 1;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
      focusInput(activeInput + 1);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { nativeEvent } = event;

    if (!isInputValueValid(event.target.value)) {
      // @ts-expect-error - This was added previosly to handle and edge case
      // for dealing with keyCode "229 Unidentified" on Android. Check if this is
      // still needed.
      if (nativeEvent.data === null && nativeEvent.inputType === "deleteContentBackward") {
        event.preventDefault();
        changeCodeAtFocus(" ");
      }
      // Clear the input if it's not valid value because firefox allows
      // pasting non-numeric characters in a number type input
      event.target.value = " ";
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => (index: number) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const otp = getOTPValue();
    const key = [event.code, event.key, (event as any).data].filter(Boolean);

    if (key.includes("Backspace") || key.includes("Delete")) {
      event.preventDefault();
      changeCodeAtFocus(" ");
      focusInput(activeInput);
      return;
    } else if (event.code === "ArrowLeft") {
      event.preventDefault();
      focusInput(activeInput - 1);
      return;
    } else if (event.code === "ArrowRight") {
      event.preventDefault();
      focusInput(activeInput + 1);
      return;
    }
    // React does not trigger onChange when the same value is entered
    // again. So we need to focus the next input manually in this case.
    else if (key.includes(otp[activeInput])) {
      event.preventDefault();
      focusInput(activeInput + 1);
      return;
    } else if (
      key.includes("Spacebar") ||
      key.includes("Space") ||
      key.includes("ArrowUp") ||
      key.includes("ArrowDown")
    ) {
      event.preventDefault();
      return;
    }

    const currentValue = inputRefs.current?.[activeInput]?.value;
    if (currentValue === " " || !currentValue) {
      handleChange({
        target: {
          value: (event as any).data || event.nativeEvent.key
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const focusInput = (index: number) => {
    const activeInput = Math.max(Math.min(numInputs - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      inputRefs.current[activeInput]?.select();
      setActiveInput(activeInput);
    }
  };

  const changeCodeAtFocus = (value: string) => {
    const otp = getOTPValue();
    otp[activeInput] = value[0];
    handleOTPChange(otp);
  };

  const handleOTPChange = (otp: Array<string>) => {
    const otpValue = otp.join("");
    onChange(otpValue);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const otp = getOTPValue();
    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = event.clipboardData
      .getData("text/plain")
      .slice(0, numInputs - activeInput)
      .split("");
    console.log(pastedData);
    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (isInputNum && pastedData.some((value) => isNaN(Number(value)))) {
      return;
    }

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? "";
        nextActiveInput++;
      }
    }

    focusInput(nextActiveInput);
    handleOTPChange(otp);
  };
  value = value.toUpperCase();
  const aaa: string[] = [];
  return (
    <div
      style={Object.assign(
        { display: "flex", alignItems: "center" },
        isStyleObject(containerStyle) && containerStyle
      )}
      className={typeof containerStyle === "string" ? containerStyle : undefined}
    >
      {Array.from({ length: numInputs }, (_, index) => index).map((index) => {
        if (correctLetter) {
          aaa[index] = clsx(inputStyle, "input", {
            [styles.filled]: value[index] && value[index] != " ",
            [styles.error]:
              value[index] !== correctLetter[index] && showResult && value[index] != undefined,
            [styles.success]: value[index] === correctLetter[index] && showResult
          });
        }

        return (
          <React.Fragment key={index}>
            {renderInput(
              {
                value: getOTPValue()[index] ?? "",
                placeholder: getPlaceholderValue()?.[index] ?? undefined,
                ref: (element) => (inputRefs.current[index] = element),
                onChange: handleChange,
                onFocus: (event) => handleFocus(event)(index),
                onBlur: handleBlur,
                onBeforeInput: handleKeyDown,
                onPaste: handlePaste,
                autoComplete: "off",
                maxLength: 1,
                "aria-label": `Please enter OTP character ${index + 1}`,
                style: Object.assign({ width: "1em", textAlign: "center" } as const),
                className: typeof aaa[index] === "string" ? aaa[index] : undefined,
                type: inputType,
                inputMode: isInputNum ? "numeric" : "text",
                onInput: handleInputChange
              },
              index
            )}
            {index < numInputs - 1 &&
              (typeof renderSeparator === "function" ? renderSeparator(index) : renderSeparator)}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export type { OTPInputProps, InputProps, AllowedInputTypes };
export default OTPInput;
