import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme34Task1 = () => {
  const items: { name: string }[] = [
    {
      name: "Водитель"
    },
    {
      name: "Лётчик"
    },
    {
      name: "Машинист"
    },
    { name: "Спортсмен" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle=' Кем был Валерий Чкалов?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Лётчик' />
    </QuizWrapper>
  );
};

export default Theme34Task1;
