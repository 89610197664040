import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme21Task2 = () => {
  const items: { name: string }[] = [
    {
      name: "Иван Грозный"
    },
    {
      name: "Михаил Романов"
    },
    {
      name: "Алексей Михайлович"
    },
    { name: "Пётр I" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Кто перенёс празднование Нового года на 1 января?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Пётр I' sameHeight={true} />
    </QuizWrapper>
  );
};

export default Theme21Task2;
