import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme44Task2 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "Руслан"
    },
    {
      name: "Гоша"
    },
    {
      name: "Настя"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Руслан и Гоша живут на одном этаже. Настя живёт выше Гоши. Кто живёт ниже Руслан или Настя?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Руслан' />
    </QuizWrapper>
  );
};

export default Theme44Task2;
