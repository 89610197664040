import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizCrossWord, CrossWordData } from "~/modules/QuizCrossWord/QuizCrossWord";

const Theme44Task3 = () => {
  const data: CrossWordData = {
    across: {
      1: {
        clue: "Так называли высотное здание в старину.",
        answer: "ТУЧЕРЕЗ",
        row: 1,
        col: 3
      },
      2: {
        clue: "Так называют людей, которые получили новые квартиры.",
        answer: "НОВОСЕЛЫ",
        row: 3,
        col: 1
      },
      3: {
        clue: "Так называется дом, который строили себе знатные люди.",
        answer: "УСАДЬБА",
        row: 5,
        col: 6
      },
      4: {
        clue: "Так называли углублённое в землю жилище.",
        answer: "ЗЕМЛЯНКА",
        row: 7,
        col: 0
      }
    },
    down: {
      5: {
        clue: "Так назывался район, в котором появились первые пятиэтажные дома.",
        answer: "ЧЕРЕМУШКИ",
        row: 0,
        col: 6
      },
      6: {
        clue: "Дом ремесленники или крестьянина.",
        answer: "ИЗБА",
        row: 3,
        col: 11
      }
    }
  };
  return (
    <QuizWrapper
      title='Кроссворд'
      icon='cross-word'
      subtitle='Прочитай подсказки ниже и реши кроссворд.'
      description='Напечатай слова в соответствующие строки кроссворда'
      successIcon='mash-2'
    >
      <QuizCrossWord
        data={data}
        adjacentLetter={[{ row: 3, col: 6, letter: "Е", correctLetters: [] }]}
      />
    </QuizWrapper>
  );
};

export default Theme44Task3;
