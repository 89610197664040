import React from "react";
export const Rus2: React.FC = () => {
  return (
    <svg
      width='142'
      height='132'
      viewBox='0 0 142 132'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse cx='74.9675' cy='66' rx='66.0319' ry='66' fill='#C4E4FB' />
      <mask
        id='mask0_3800_36700'
        style={{ maskType: "alpha" }}
        maskUnits='userSpaceOnUse'
        x='8'
        y='0'
        width='134'
        height='132'
      >
        <ellipse cx='74.9702' cy='66' rx='66.0319' ry='66' fill='#CEF8F0' />
        <mask
          id='mask1_3800_36700'
          style={{ maskType: "alpha" }}
          maskUnits='userSpaceOnUse'
          x='8'
          y='0'
          width='134'
          height='132'
        >
          <ellipse cx='74.9702' cy='66' rx='66.0319' ry='66' fill='white' />
        </mask>
        <g mask='url(#mask1_3800_36700)'></g>
      </mask>
      <g mask='url(#mask0_3800_36700)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M90.0103 94.6578C73.433 81.9494 57.4107 87.9644 54.8843 90.7809C52.3578 94.0831 51.7552 103.619 49.2288 112.748C49.2288 112.748 49.2288 127.387 49.2288 134.465C49.2288 141.543 46.9756 154.252 46.9756 154.252C53.2524 162.456 82.4862 157.969 89.1524 157.195C89.1524 157.195 89.7867 142.61 89.6258 135.539C89.4649 128.467 88.1867 109.97 88.1867 109.97C82.3566 102.201 88.6499 105.147 90.0103 94.6578Z'
          fill='#1CB0C1'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M38.0381 109.787C30.7383 120.166 27.3587 108.423 25.6096 101.229L25.7068 100.258C26.8728 98.7041 26.97 95.9846 26.387 92.4882C26.1926 89.8659 26.7757 87.535 28.6219 85.7868C29.3021 85.0098 28.9134 84.3299 27.3587 83.8443C25.2209 83.553 23.4719 84.6213 22.403 87.1465C21.9172 88.6033 21.3342 89.4774 20.654 89.2832L17.5445 86.9522C14.7266 84.7184 11.9087 82.8731 8.99364 81.8048C7.5361 81.3191 6.85592 82.1932 7.73045 83.3587L11.5201 86.9522C12.7833 88.1177 13.4634 89.0889 12.6861 89.4774L5.49553 84.6213C3.45498 83.3587 1.22009 83.1645 1.8031 85.1069L2.38613 86.1753C1.2201 86.6609 1.60875 87.6321 2.48327 88.6033L6.95308 93.2652C7.24458 93.7508 7.34175 94.1393 6.46723 94.0422C4.91252 93.2652 3.45498 92.294 2.0946 91.1285C0.637066 89.8659 -0.528965 89.963 0.24839 91.8084C2.19178 94.6249 4.71818 97.2472 7.43892 99.6753L14.6439 104.562C21.846 119.859 26.566 133.07 44.5615 122.078C42.521 117.125 38.9126 114.837 38.0381 109.787Z'
          fill='#FFD2A8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.25218 85.7072C2.15456 85.8351 2.17921 86.018 2.30724 86.1155L10.0808 92.04C10.2088 92.1376 10.3917 92.1129 10.4894 91.985C10.587 91.857 10.5623 91.6742 10.4343 91.5766L2.66074 85.6522C2.53272 85.5546 2.3498 85.5792 2.25218 85.7072Z'
          fill='#7D0000'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.61 91.0729C20.472 90.9901 20.293 91.0348 20.2102 91.1728C18.7137 93.6669 18.304 96.2906 19.5124 99.1102C19.5758 99.2581 19.7471 99.3266 19.895 99.2632C20.0429 99.1998 20.1114 99.0286 20.048 98.8806C18.9255 96.2614 19.2927 93.8347 20.7099 91.4726C20.7927 91.3346 20.7479 91.1557 20.61 91.0729Z'
          fill='#7D0000'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M42.63 124.491L53.7352 116.126C55.8709 108.477 53.555 98.1455 55.4653 90.5083C50.0789 93.9925 43.4132 101.014 36.5142 109.852C37.1943 116.263 38.8404 118.858 42.63 124.491Z'
          fill='#1CB0C1'
        />
        <path
          d='M109.184 144.029C110.816 132.144 103.427 117.799 99.6041 109.189C94.7922 105.891 89.653 115.197 88.1202 119.866C90.1868 122.256 95.9207 133.339 98.5171 142.568C98.8891 143.89 99.3196 145.232 100.262 146.232C103.158 149.301 108.304 150.437 109.184 144.029Z'
          fill='#FFD2A8'
        />
        <path
          d='M89.2063 170.119C94.3889 164.636 100.566 146.472 100.4 137.054C97.5348 132.875 94.1311 134.402 91.245 136.972C89.183 138.809 88.1234 141.456 87.7726 144.194C87.1912 148.733 86.0755 155.476 82.4806 163.319C81.1996 166.114 79.9355 169.614 82.0131 171.879C83.5206 173.522 85.972 173.541 89.2063 170.119Z'
          fill='#FFD2A8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M75.1787 77.0413L74.2478 87.6484C72.6931 89.8822 65.8071 94.4093 61.0774 89.0889L61.5633 78.9882L75.1787 77.0413Z'
          fill='#FFD2A8'
        />
        <path
          d='M73.7856 80.1631L61.4478 82.1414L61.2251 86.5716C61.2251 86.5716 65.7997 85.9556 68.59 84.2513C71.3802 82.547 73.7856 80.1631 73.7856 80.1631Z'
          fill='#F9836A'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M51.8872 32.3443C46.3545 35.0208 43.5223 39.464 43.7592 45.4165C45.5952 44.7736 47.7009 45.0381 50.2048 47.3073C50.753 42.998 52.1113 39.5841 54.6786 37.2435L51.8872 32.3443Z'
          fill='#1D1818'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.959 32.1614C52.6622 33.5826 51.194 35.795 50.6216 39.0253C50.1593 43.4572 48.3466 47.0058 45.0609 49.7568C36.6764 57.9901 44.1979 80.137 55.9263 81.9816C60.5182 82.7927 65.0797 83.1685 69.8193 83.146C78.2181 83.1251 83.5918 78.9159 88.6902 72.6163C101.998 80.6965 110.439 54.6867 94.3505 58.8933C85.2461 58.0373 98.5942 43.2 88.3245 36.6299C84.7593 32.4538 79.6993 35.8867 74.6089 37.8891C66.4542 41.3921 58.904 38.4464 54.959 32.1614Z'
          fill='#FFD2A8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M64.321 50.2304C62.0999 53.5622 64.7487 56.6562 67.6077 53.8004C70.3537 51.0575 67.8252 45.6067 64.321 50.2304Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M68.4653 49.5341C67.6518 49.0967 66.4685 49.407 65.1463 51.1516C64.2419 52.5082 64.1449 53.8255 64.5407 54.7176C65.3204 55.1389 66.4473 54.9609 67.6089 53.8005C68.874 52.5365 69.0193 50.6975 68.4653 49.5341Z'
          fill='#7D0000'
        />
        <ellipse
          cx='0.805769'
          cy='0.866313'
          rx='0.805769'
          ry='0.866313'
          transform='matrix(-0.824445 -0.565596 -0.566981 0.823968 67.2227 50.3019)'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M49.7908 42.6986C47.5696 46.0304 50.2184 49.1244 53.0774 46.2686C55.8234 43.5257 53.2949 38.0749 49.7908 42.6986Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.0809 42.3745C53.2668 41.8101 52.0171 42.0425 50.6102 43.8989C49.8199 45.0844 49.6461 46.2397 49.879 47.1077C50.6602 47.6243 51.8497 47.4957 53.0781 46.2687C54.2157 45.1324 54.4481 43.5314 54.0809 42.3745Z'
          fill='#7D0000'
        />
        <ellipse
          cx='0.805769'
          cy='0.866313'
          rx='0.805769'
          ry='0.866313'
          transform='matrix(-0.824445 -0.565596 -0.566981 0.823968 52.6802 43.2084)'
          fill='white'
        />
        <path
          d='M59.2559 66.1754C56.4278 69.2931 52.6505 68.7003 52.6505 68.7003'
          stroke='#7D0000'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M59.0297 64.996C59.5121 66.1798 59.8299 66.4361 60.3374 66.8814'
          stroke='#7D0000'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <g opacity='0.3' filter='url(#filter0_f_3800_36700)'>
          <path
            d='M61.8648 59.1286C60.8163 61.2702 61.7051 63.855 63.85 64.9019C65.9949 65.9488 68.5836 65.0613 69.6321 62.9197C70.6805 60.778 68.8013 60.2162 66.6565 59.1693C64.5116 58.1224 62.9132 56.9869 61.8648 59.1286Z'
            fill='#F15331'
          />
        </g>
        <g opacity='0.5' filter='url(#filter1_f_3800_36700)'>
          <path
            d='M43.6127 52.6103C42.9001 54.4913 43.8496 56.5929 45.7335 57.3043C47.6173 58.0158 49.7221 57.0677 50.4347 55.1867C51.1472 53.3057 49.3065 53.5568 47.4226 52.8453C45.5388 52.1339 44.3252 50.7293 43.6127 52.6103Z'
            fill='#F15331'
          />
        </g>
        <path
          d='M93.2408 63.3192C98.601 62.2242 97.3761 69.7454 90.7828 68.6882'
          stroke='#7D0000'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M89.0835 81.4963C84.3552 83.8851 79.6622 86.3792 73.5305 82.7169C79.2592 81.3646 84.1839 77.0861 88.1705 72.202C101.427 79.8837 108.551 55.7772 92.9818 59.494C89.9732 62.0275 83.709 76.4322 82.2569 75.2377C83.6024 67.6435 80.5316 43.4734 84.429 36.8178C79.1437 39.3954 54.504 41.8694 54.504 41.8694L55.345 37.123C55.345 37.123 52.7043 42.0027 48.8237 44.248C44.9431 46.4933 40.3937 46.3942 40.3937 46.3942C40.3937 46.3942 45.9951 18.1946 71.5123 18.1603C84.3015 16.4431 97.6416 30.7639 97.6416 30.7639C97.6416 30.7639 100.876 33.9761 104.527 44.1238C108.177 54.2714 106.692 77.3087 86.9004 83.8697C87.9199 82.9751 88.463 82.5688 89.0835 81.4963Z'
          fill='#1D1818'
        />
        <path
          d='M71.0269 48.178C70.3254 47.6645 68.4647 46.766 66.6348 47.2803'
          stroke='#1D1818'
          strokeWidth='2'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M55.4852 41.5026C55.1466 40.7026 54.0104 38.9787 52.1752 38.4835'
          stroke='#1D1818'
          strokeWidth='2'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.6157 45.7128C57.6157 45.7128 56.4476 52.5374 49.9795 54.3319C43.5113 56.1263 44.8904 65.4474 54.8024 61.5914'
          stroke='#7D0000'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.9014 58.8027C72.9929 61.7239 69.4617 63.3842 65.8048 62.7382C60.9175 61.8749 57.6564 57.2191 58.521 52.3391C59.3856 47.4592 64.0485 44.203 68.9359 45.0663C73.8232 45.9296 77.0843 50.5855 76.2197 55.4654C76.002 56.694 75.5435 57.8197 74.9014 58.8027ZM74.9014 58.8027L91.1116 59.0036'
          stroke='#E82A2A'
          strokeWidth='2'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <ellipse
          cx='7.34997'
          cy='8.97415'
          rx='7.34997'
          ry='8.97415'
          transform='matrix(-0.925029 -0.379612 -0.380677 0.924825 60.6904 38.4326)'
          stroke='#E82A2A'
          strokeWidth='2'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.2738 47.8765L59.4492 49.6848'
          stroke='#E82A2A'
          strokeWidth='2'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.343 92.685C93.2081 90.6678 99.8148 109.223 103.752 115.666C100.39 120.787 97.967 122.109 89.0954 123.831C83.5098 113.588 77.8755 99.0591 82.343 92.685Z'
          fill='#1CB0C1'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.0381 109.502C30.7383 119.882 27.3587 108.139 25.6096 100.945L25.7068 99.9735C26.8728 98.4196 26.97 95.7001 26.387 92.2037C26.1926 89.5814 26.7757 87.2505 28.6219 85.5023C29.3021 84.7253 28.9134 84.0455 27.3587 83.5598C25.2209 83.2685 23.4719 84.3368 22.403 86.862C21.9172 88.3188 21.3342 89.193 20.654 88.9987L17.5445 86.6677C14.7266 84.4339 11.9087 82.5886 8.99364 81.5203C7.5361 81.0347 6.85592 81.9088 7.73045 83.0742L11.5201 86.6677C12.7833 87.8332 13.4634 88.8044 12.6861 89.1929L5.49553 84.3368C3.45498 83.0742 1.22009 82.88 1.8031 84.8224L2.38613 85.8908C1.2201 86.3764 1.60875 87.3476 2.48327 88.3188L6.95308 92.9807C7.24458 93.4663 7.34175 93.8548 6.46723 93.7577C4.91252 92.9807 3.45498 92.0095 2.0946 90.844C0.637066 89.5814 -0.528965 89.6785 0.24839 91.5239C2.19178 94.3404 4.71818 96.9627 7.43892 99.3908L14.6439 104.278C21.846 119.575 26.566 132.786 44.5615 121.794C42.521 116.841 38.9126 114.553 38.0381 109.502Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25218 85.4227C2.15456 85.5507 2.17921 85.7335 2.30724 85.8311L10.0808 91.7555C10.2088 91.8531 10.3917 91.8284 10.4894 91.7005C10.587 91.5725 10.5623 91.3897 10.4343 91.2921L2.66074 85.3677C2.53272 85.2701 2.3498 85.2947 2.25218 85.4227Z'
        fill='#7D0000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.61 90.7884C20.472 90.7056 20.293 90.7503 20.2102 90.8883C18.7137 93.3824 18.304 96.0061 19.5124 98.8257C19.5758 98.9736 19.7471 99.0421 19.895 98.9787C20.0429 98.9154 20.1114 98.7441 20.048 98.5962C18.9255 95.9769 19.2927 93.5502 20.7099 91.1881C20.7927 91.0502 20.7479 90.8712 20.61 90.7884Z'
        fill='#7D0000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.63 124.207L53.7352 115.842C55.8709 108.193 53.555 97.8611 55.4653 90.2239C50.0789 93.708 43.4132 100.73 36.5142 109.568C37.1943 115.978 38.8404 118.574 42.63 124.207Z'
        fill='#1CB0C1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.4225 104.878C49.3096 110.894 49.0101 119.276 49.0728 126.787L49.6558 126.782C49.5931 119.264 49.8977 110.979 51.9726 105.071L51.4225 104.878Z'
        fill='#0A525C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M80.8945 102.445C81.2151 108.867 84.6874 116.978 88.1727 124.136L88.6969 123.881C85.2055 116.711 81.7906 108.702 81.4768 102.416L80.8945 102.445Z'
        fill='#0A525C'
      />
      <defs>
        <filter
          id='filter0_f_3800_36700'
          x='41.4248'
          y='37.9149'
          width='48.5063'
          height='47.4265'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='10' result='effect1_foregroundBlur_3800_36700' />
        </filter>
        <filter
          id='filter1_f_3800_36700'
          x='23.376'
          y='31.6702'
          width='47.2153'
          height='45.8706'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='10' result='effect1_foregroundBlur_3800_36700' />
        </filter>
      </defs>
    </svg>
  );
};
