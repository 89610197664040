import React, { useCallback, useEffect, useRef, useState, ReactNode } from "react";
import Crossword, { CrosswordProviderImperative } from "@jaredreisinger/react-crossword";
// import Crossword,{CrosswordProviderImperative} from "~/shared/lib/Crossword/react-crossword/dist";
import { QuizResult } from "~/shared/components/QuizResult";
import { useQuizContext } from "~/shared/hooks/useQuizContext";
import styles from "./QuizCrossWord.module.scss";
import clsx from "clsx";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type GuessTextElement = Element | null;
type ClueData = {
  clue: string;
  answer: string;
  row: number;
  col: number;
};
type AcrossData = Record<number, ClueData>;
type DownData = Record<number, ClueData>;

type letter = {
  col: number;
  row: number;
  correctLetters: string[];
  letter: string;
};

export type CrossWordData = {
  across: AcrossData;
  down: DownData;
};
export type Prompt = { type: "across" | "down"; row: number; col: number; clue: string };

type Props = {
  data: CrossWordData;
  prompt?: Prompt;
  inactiveBlock?: ReactNode;
  onFillOneCell?: () => void;
  adjacentLetter?: letter[];
};

export const QuizCrossWord: React.FC<Props> = ({
  data,
  prompt,
  inactiveBlock,
  onFillOneCell,
  adjacentLetter
}) => {
  const { success, setSuccess } = useQuizContext();
  const [showResult, setShowResult] = useState(false);
  const [mobileKey, setMobileKey] = useState(false);
  const isMobile = useAdaptiveValue(true, true, false);
  const [finalSucces, setFinalSucces] = useState(false);

  const crossword = useRef<CrosswordProviderImperative>(null);

  const fillOneCell = useCallback(() => {
    if (!crossword.current || !prompt) return;
    try {
      for (let i = 0; i < prompt.clue.length; i++) {
        if (prompt.type == "down") {
          crossword.current.setGuess(prompt.row + i, prompt.col, prompt.clue[i]);
        } else {
          crossword.current.setGuess(prompt.row, prompt.col + 1, prompt.clue[i]);
        }
      }

      onFillOneCell && onFillOneCell();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleFinishClick = () => {
    if (!finalSucces) setShowResult(true);

    setTimeout(() => setSuccess(finalSucces), 700);
  };
  const correctHandler = (correct: boolean) => {
    if (crossword.current && adjacentLetter) {
      for (const letter of adjacentLetter) {
        crossword.current.setGuess(letter.row, letter.col, letter.letter);
      }
    }
    setFinalSucces(correct);
  };

  const setRectElementFill = (guessTextElement: GuessTextElement, fillStyle: string) => {
    if (!guessTextElement) return;
    const rectElement = guessTextElement?.closest(".clue-cell")?.querySelector("rect");
    if (rectElement) {
      rectElement.style.stroke = fillStyle;
    }
  };
  const setElementFill = (guessTextElement: GuessTextElement, fillStyle: string) => {
    if (!guessTextElement) return;
    const rectElement = guessTextElement?.closest(".clue-cell")?.querySelector("text");
    if (rectElement) {
      if (/\d/.test(rectElement.textContent!)) return;

      rectElement.style.fill = fillStyle;
    }
  };

  useEffect(() => {
    const clueCellsWithIncorrectGuess = document.querySelectorAll(
      ".clue-cell .guess-text-incorrect"
    );

    const clueCellsWithСorrectGuess = document.querySelectorAll(".clue-cell .guess-text-correct");

    clueCellsWithСorrectGuess.forEach((guessTextElement: GuessTextElement) => {
      const fillStyle = showResult ? "rgba(131, 181, 154, 1)" : "";
      const fillTextStyle = "rgba(6, 133, 133, 1)";
      setElementFill(guessTextElement, fillTextStyle);
      setRectElementFill(guessTextElement, fillStyle);
    });

    clueCellsWithIncorrectGuess.forEach((guessTextElement: GuessTextElement) => {
      const fillStyle = showResult ? "rgba(241, 97, 91, 1)" : "";
      const fillTextStyle = showResult ? "rgba(241, 97, 91, 1)" : "rgba(6, 133, 133, 1)";
      setElementFill(guessTextElement, fillTextStyle);
      setRectElementFill(guessTextElement, fillStyle);
    });
  }, [showResult]);
  useEffect(() => {
    setTimeout(fillOneCell, 0);
  }, [fillOneCell]);

  return (
    <>
      <div
        onFocus={() => {
          setShowResult(false);
        }}
        style={{ display: "flex" }}
      >
        <div className={styles.crosswordContainer}>
          {inactiveBlock}
          <Crossword
            data={data}
            theme={{
              allowNonSquare: true,
              numberColor: "#616C66",
              textColor: "rgba(6, 133, 133, 1)",
              cellBorder: "rgb(243, 236, 222)",
              gridBackground: "white",
              focusBackground: "rgba(216, 240, 227, 1)",
              highlightBackground: "white"
            }}
            ref={crossword}
            acrossLabel=' '
            downLabel=' '
            useStorage={false}
            onCellChange={() => {
              setMobileKey(true);
              setShowResult(false);
            }}
            onCrosswordCorrect={correctHandler}
          />
        </div>
      </div>
      {
        <>
          <QuizResult
            errorVisible={showResult}
            buttonOnClick={handleFinishClick}
            buttonVisible={!showResult && !success}
          />
        </>
      }

      <div className={clsx({ [styles.mobileInput]: mobileKey && isMobile })}></div>
    </>
  );
};
