import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import zenitchiky from "~shared/assets/images/36/zenitchiky.jpg";
import parad from "~shared/assets/images/36/parad.jpg";
import osada from "~shared/assets/images/36/osada.jpg";
import ukreplenie from "~shared/assets/images/36/ukreplenie.jpg";

const Theme36Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Зенитчики",
      image: zenitchiky
    },
    {
      name: "Парад",
      image: parad
    },
    {
      name: "Осада",
      image: osada
    },
    {
      name: "Укрепление",
      image: ukreplenie
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри фотографии защитников Москвы. Отметь зенитчиков.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Зенитчики' hideName />
    </QuizWrapper>
  );
};

export default Theme36Task2;
