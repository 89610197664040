import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import rebus from "~shared/assets/images/4/rebus4.jpg";
import koster from "~shared/assets/images/4/koster.jpg";
import ochag from "~shared/assets/images/4/ochag.jpg";
import pech from "~shared/assets/images/4/pech.jpg";

const Theme4Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Костёр",
      image: koster
    },
    {
      name: "Очаг",
      image: ochag
    },
    {
      name: "Печь",
      image: pech
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Разгадай ребус. Отметь рисунок, на котором изображена отгадка.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem
        type='img'
        items={items}
        imageRebus={rebus}
        widthItem={146}
        correctItem='Очаг'
        taskType='rebus'
      />
    </QuizWrapper>
  );
};

export default Theme4Task2;
