import React, { useEffect, useState } from "react";
import { Layer, Line, Stage } from "react-konva";
import styles from "./QuizDrawLine.module.scss";
import { QuizResult } from "~/shared/components/QuizResult";
import clsx from "clsx";
import ThirdDrawLine from "./components/ThirdDraw/ThirdDraw";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";
import { useWindowSize } from "~/shared/hooks/useWindowSize";
type DrawLineBlock = {
  name: string;
  url?: string;
  pair?: string;
  activated?: boolean;
};

type pointType = {
  x: number;
  y: number;
  block: DrawLineBlock;
};

type line = {
  type: "correct" | "uncorrected";
  startPoint: pointType;
  endPoint: pointType;
};

type Props = {
  firstColSecond: DrawLineBlock[];
  secondColSecond: DrawLineBlock[];
  firstColThird: DrawLineBlock[];
  secondColThird: DrawLineBlock[];
  firstColNextFourth: DrawLineBlock[];
  secondCoLNextFourth: DrawLineBlock[];
};

const SecondDrawLine: React.FC<Props> = ({
  firstColSecond,
  secondColSecond,
  firstColThird,
  secondColThird,
  firstColNextFourth,
  secondCoLNextFourth
}) => {
  const [lines, setLines] = useState<line[]>([]);
  const [startPoint, setStartPoint] = useState<pointType | null>(null);
  const [hasUncorrected, setHasUncorrected] = useState<boolean>(false);
  const [active, setActive] = useState(false);
  const [success, setSuccess] = useState(false);

  const { width } = useWindowSize();

  const WIDTH: number = useAdaptiveValue(width - 48, 327, 480);
  const HEIGHT: number = useAdaptiveValue(120, 120, 150);
  const PADDING: number = useAdaptiveValue(24, 24, 16);
  const POSITION_Y: number = useAdaptiveValue(60, 60, 75);

  const imgXFirst: number = useAdaptiveValue(148, 148, 200);
  const imgXSecond: number = useAdaptiveValue(179, 179, 280);

  const imageWrapperStyle = useAdaptiveValue(
    styles.imageWrapperMobile,
    styles.imageWrapperMobile,
    styles.imageWrapper
  );

  const removeUncorrectedLine = () => {
    const currentLines = [...lines];
    currentLines.pop();
    setTimeout(() => {
      setLines(currentLines);
      setHasUncorrected(false);
    }, 1000);
  };

  const startDraw = (index: number, block: DrawLineBlock, x: number) => {
    const { name } = block;
    const isChosen = lines.some(({ startPoint }) => startPoint?.block?.name === name);

    if (isChosen || hasUncorrected) return;
    const startPoint = { x: x, y: POSITION_Y + index * (HEIGHT + PADDING), block };
    setStartPoint(startPoint);
    setActive(true);
  };

  const endDraw = (index: number, block: DrawLineBlock, x: number) => {
    const { name } = block;
    const isChosen = lines.some(({ endPoint }) => endPoint?.block?.name === name);
    if (!startPoint || isChosen) return;

    const endPoint = { x: x, y: POSITION_Y + index * (HEIGHT + PADDING), block };
    const isCorrected = startPoint.block.name === endPoint.block.pair;
    const type = isCorrected ? "correct" : "uncorrected";

    setLines(
      lines.concat([
        {
          type,
          startPoint,
          endPoint
        }
      ])
    );
    firstColSecond.forEach((item) => {
      item.activated = false;
    });
    secondColSecond.forEach((item) => {
      item.activated = false;
    });

    !isCorrected && setHasUncorrected(true);
    setStartPoint(null);
    setActive(false);
  };

  useEffect(() => {
    hasUncorrected && removeUncorrectedLine();
  }, [hasUncorrected]);

  useEffect(() => {
    const success = !hasUncorrected && lines.length === firstColSecond.length;
    setTimeout(() => setSuccess(success), 700);
  }, [lines]);

  return (
    <>
      {!success && (
        <>
          <p className={styles.step}>ШАГ 1</p>
          <div className={styles.container}>
            <div className={styles.content}>
              <Stage width={WIDTH} height={firstColSecond.length * (HEIGHT + PADDING)}>
                <Layer>
                  {lines.map((line, index) => {
                    return (
                      <Line
                        key={index}
                        points={[
                          line.startPoint.x,
                          line.startPoint.y,
                          line.endPoint.x,
                          line.endPoint.y
                        ]}
                        opacity={1}
                        stroke={line.type == "correct" ? "#B4D5A8" : "#F1615B"}
                        dash={[2, 2]}
                        strokeWidth={2}
                      />
                    );
                  })}
                </Layer>
              </Stage>

              {firstColSecond.map((block, index) => {
                return (
                  <div
                    key={index}
                    style={{ top: index * (HEIGHT + PADDING) }}
                    className={clsx(imageWrapperStyle, {
                      [styles.activated]: block.activated,
                      [styles.success]:
                        lines.some(
                          ({ startPoint, type }) =>
                            type === "correct" && startPoint.block.name === block.name
                        ) ||
                        lines.some(
                          ({ endPoint, type }) =>
                            type === "correct" && endPoint.block.name === block.name
                        ),
                      [styles.error]:
                        lines.some(
                          ({ startPoint, type }) =>
                            type === "uncorrected" && startPoint.block.name === block.name
                        ) ||
                        lines.some(
                          ({ endPoint, type }) =>
                            type === "uncorrected" && endPoint.block.name === block.name
                        )
                    })}
                    onClick={() => {
                      if (active) {
                        block.activated = false;
                        endDraw(index, block, imgXFirst);
                      } else {
                        block.activated = true && !hasUncorrected;
                        startDraw(index, block, imgXFirst);
                      }
                    }}
                  >
                    <img
                      src={block.url}
                      alt={block.name}
                      className={styles.image}
                      draggable='false'
                    />
                  </div>
                );
              })}
              {secondColSecond.map((block, index) => {
                return (
                  <div
                    key={index}
                    style={{ top: index * (HEIGHT + PADDING), right: 0 }}
                    className={clsx(imageWrapperStyle, {
                      [styles.activated]: block.activated,
                      [styles.success]:
                        lines.some(
                          ({ endPoint, type }) =>
                            type === "correct" && endPoint.block.name === block.name
                        ) ||
                        lines.some(
                          ({ startPoint, type }) =>
                            type === "correct" && startPoint.block.name === block.name
                        ),
                      [styles.error]:
                        lines.some(
                          ({ endPoint, type }) =>
                            type === "uncorrected" && endPoint.block.name === block.name
                        ) ||
                        lines.some(
                          ({ startPoint, type }) =>
                            type === "uncorrected" && startPoint.block.name === block.name
                        )
                    })}
                    onClick={() => {
                      if (active) {
                        block.activated = false;
                        endDraw(index, block, imgXSecond);
                      } else {
                        block.activated = true && !hasUncorrected;
                        startDraw(index, block, imgXSecond);
                      }
                    }}
                  >
                    <div className={styles.imageText} draggable='false'>
                      {block.name}
                    </div>
                  </div>
                );
              })}
            </div>
            {<QuizResult errorVisible={hasUncorrected} />}
          </div>
        </>
      )}

      {success && (
        <ThirdDrawLine
          firstColThird={firstColThird}
          secondColThird={secondColThird}
          firstColNextFourth={firstColNextFourth}
          secondCoLNextFourth={secondCoLNextFourth}
        />
      )}
    </>
  );
};

export default SecondDrawLine;
