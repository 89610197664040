import React, { ChangeEvent, useState } from "react";
import styles from "./AppInput.module.scss";
import clsx from "clsx";

type Props = {
  onChange: (value: string) => void;
  onFocus?: () => void;
  error?: boolean;
};

export const AppInput: React.FC<Props> = ({ onChange, onFocus, error = false }) => {
  const [value, setValue] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <input
      className={clsx(styles.appInput, { [styles.error]: error })}
      type='text'
      value={value}
      onChange={handleChange}
      onFocus={onFocus}
    />
  );
};
