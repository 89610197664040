import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import sobor from "~/shared/assets/images/24/sobor.jpg";
import dom from "~/shared/assets/images/24/dom.jpg";
import bashnya from "~/shared/assets/images/24/bashnya.jpg";
import dvorec from "~/shared/assets/images/24/dvorec.jpg";

const Theme24Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Успенский собор",
      image: sobor
    },
    {
      name: "Сенатский дворец",
      image: dvorec
    },
    {
      name: "Дом Пашкова",
      image: dom
    },
    {
      name: "Боровицкая башня",
      image: bashnya
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Где находится кабинет президента России?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Сенатский дворец' />
    </QuizWrapper>
  );
};

export default Theme24Task2;
