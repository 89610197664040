import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SortableItem } from "./components/SortableItem";
import sample from "~shared/assets/images/35/Frame 7522.png";
import styles from "./QuizSorbableWord.module.scss";
import clsx from "clsx";

type Item = {
  id: number;
  name: string;
};

type Props = {
  word: Item[];
  link: string;
  idItem: number;
  setErrorGame: Dispatch<SetStateAction<boolean[]>>;
  setFinishGame: Dispatch<SetStateAction<boolean[]>>;
};

export const QuizSortableWords: React.FC<Props> = (props) => {
  const [items, setItems] = useState<Item[]>(props.word);
  const [isWin, setIsWin] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(false);

  useEffect(() => {
    if (items.every((el, i) => el.id === i + 1)) {
      setIsWin(true);
      setError(false);
      props.setErrorGame((state) => {
        const newState = [...state];
        newState[props.idItem] = false;
        return newState;
      });
      props.setFinishGame((state) => {
        const newState = [...state];
        newState[props.idItem] = true;
        return newState;
      });
    }
    if (!items.every((el, i) => el.id === i + 1) && start) {
      setError(true);
      props.setErrorGame((state) => {
        const newState = [...state];
        newState[props.idItem] = true;
        return newState;
      });
    }
  }, [items]);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 1
      }
    })
  );

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={items} strategy={horizontalListSortingStrategy} disabled={isWin}>
        <div className={styles.wrapper}>
          <div className={styles.sortableItems}>
            {items.map((item) => (
              <SortableItem
                key={item.id}
                name={item.name}
                id={item.id}
                error={error}
                success={isWin}
              />
            ))}
          </div>
          <div
            style={{
              backgroundImage: isWin ? `url(${props.link})` : `url(${sample})`
            }}
            className={clsx(styles.answer, { [styles.success]: isWin })}
          ></div>
        </div>
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;

    setItems((items) => {
      const activeIndex = items.findIndex((item) => item.id === active.id);
      const overIndex = items.findIndex((item) => item.id === over.id);
      setStart(true);

      if (activeIndex !== -1 && overIndex !== -1) {
        return arrayMove(items, activeIndex, overIndex);
      } else {
        return items;
      }
    });
  }
};
