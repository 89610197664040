import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import grozny from "~shared/assets/images/22/portret-grozny.jpg";
import lomonosov from "~shared/assets/images/22/portret-lomonosov.jpg";
import petr from "~shared/assets/images/22/portret-petr.jpg";
import pushkin from "~shared/assets/images/22/portret-pushkin.jpg";

const Theme22Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Иван Грозный",
      image: grozny
    },
    {
      name: "Ломоносов М.В.",
      image: lomonosov
    },
    {
      name: "Пётр I",
      image: petr
    },
    {
      name: "Пушкин А.С.",
      image: pushkin
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Отметь портрет Петра I.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Пётр I' hideName />
    </QuizWrapper>
  );
};

export default Theme22Task1;
