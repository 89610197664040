import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import QuizDrawLine, { DrawLineBlock } from "~/modules/QuizDrawLine/QuizDrawLine";

const firstBlocks: DrawLineBlock[] = [
  {
    name: "Настя",
    pair: "Биолог"
  },
  {
    name: "Маша",
    pair: "Корреспондент"
  },
  {
    name: "Гоша",
    pair: "Тренер"
  }
];

const secondBlocks: DrawLineBlock[] = [
  {
    name: "Биолог",
    pair: "Настя"
  },
  {
    pair: "Гоша",
    name: "Тренер"
  },
  {
    pair: "Маша",
    name: "Корреспондент"
  }
];

const Theme45Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Настя, Маша и Гоша мечтают о своих любимых профессиях. Догадайся, кто о какой профессии мечтает, если Гоша любит спорт, а Настя любит природу.'
      description='Нажми на имя и подходящую профессию'
      successIcon='rus-2'
    >
      <QuizDrawLine firstCol={firstBlocks} secondCol={secondBlocks} />
    </QuizWrapper>
  );
};

export default Theme45Task1;
