import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizEnterWord } from "~modules/QuizEnterWord";
import rebus from "~shared/assets/images/rebus45.jpg";
import { ImageRebus } from "~/shared/components/ImageRebus";

const Theme45Task2 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Разгадай ребус'
      description='Напиши отгадку в поле ниже'
      successIcon='nast-2'
    >
      <ImageRebus url={rebus} />
      <QuizEnterWord answer='профессия' />
    </QuizWrapper>
  );
};

export default Theme45Task2;
