import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "~/App";
import Theme1Task1 from "~/pages/1/1";
import Theme1Task2 from "~/pages/1/2";
import Theme10Task1 from "~/pages/10/1";
import Theme10Task2 from "~/pages/10/2";
import Theme27Task1 from "~/pages/27/1";
import Theme3Task1 from "~/pages/3/1";
import Theme4Task1 from "~/pages/4/1";
import Theme6Task1 from "~/pages/6/1";
import Theme5Task1 from "~/pages/5/1";
import Theme7Task2 from "~/pages/7/2";
import Theme7Task3 from "~/pages/7/3";
import Theme8Task1 from "~/pages/8/1";
import Theme8Task2 from "~/pages/8/2";
import Theme11Task1 from "~/pages/11/1";
import Theme12Task1 from "~/pages/12/1";
import Theme12Task2 from "~/pages/12/2";
import Theme13Task2 from "~/pages/13/2";
import Theme15Task3 from "~/pages/15/3";
import Theme9Task1 from "~/pages/9/1";
import Theme31Task1 from "~/pages/31/1";
import Theme17Task1 from "~/pages/17/1";
import Theme17Task2 from "~/pages/17/2";
import Theme18Task1 from "~/pages/18/1";
import Theme19Task2 from "~/pages/19/2";
import Theme21Task1 from "~/pages/21/1";
import Theme22Task1 from "~/pages/22/1";
import Theme23Task1 from "~/pages/23/1";
import Theme23Task2 from "~/pages/23/2";
import Theme24Task2 from "~/pages/24/2";
import Theme29Task2 from "~/pages/29/2";
import Theme32Task1 from "~/pages/32/1";
import Theme33Task1 from "~/pages/33/1";
import Theme35Task1 from "~/pages/35/1";
import Theme36Task2 from "~/pages/36/2";
import Theme37Task1 from "~/pages/37/1";
import Theme39Task2 from "~/pages/39/2";
import Theme44Task1 from "~/pages/44/1";
import Theme44Task2 from "~/pages/44/2";
import Theme41Task3 from "~/pages/41/3";
import Theme42Task1 from "~/pages/42/1";
import Theme14Task1 from "~/pages/14/1";
import Theme13Task1 from "~/pages/13/1";
import Theme5Task2 from "~/pages/5/2";
import Theme24Task1 from "~/pages/24/1";
import Theme4Task2 from "~/pages/4/2";
import Theme18Task2 from "~/pages/18/2";
import Theme27Task2 from "~/pages/27/2";
import Theme31Task2 from "~/pages/31/2";
import Theme37Task2 from "~/pages/37/2";
import Theme43Task1 from "~/pages/43/1";
import Theme38Task1 from "~/pages/38/1";
import Theme16Task1 from "~/pages/16/1";
import Theme25Task1 from "~/pages/25/1";
import Theme26Task1 from "~/pages/26/1";
import Theme28Task1 from "~/pages/28/1";
import Theme30Task2 from "~/pages/30/2";
import Theme40Task1 from "~/pages/40/1";
import Theme44Task3 from "~/pages/44/3";
import Theme28Task2 from "~/pages/28/2";
import Theme22Task2 from "~/pages/22/2";
import Theme2Task2 from "~/pages/2/2";
import Theme21Task3 from "~/pages/21/3";
import Theme43Task2 from "~/pages/43/2";
import Theme17Task3 from "~/pages/17/3";
import Theme32Task2 from "~/pages/32/2";
import Theme34Task2 from "~/pages/34/2";
import Theme35Task2 from "~/pages/35/2";
import Theme38Task2 from "~/pages/38/2";
import Theme20Task1 from "~/pages/20/1";
import Theme45Task1 from "~/pages/45/1";
import Theme25Task3 from "~/pages/25/3";
import Theme29Task1 from "~/pages/29/1";
import Theme2Task1 from "~/pages/2/1";
import Theme14Task2 from "~/pages/14/2";
import Theme19Task1 from "~/pages/19/1";
import Theme20Task3 from "~/pages/20/3";
import Theme21Task2 from "~/pages/21/2";
import Theme22Task4 from "~/pages/22/4";
import Theme24Task3 from "~/pages/24/3";
import Theme26Task2 from "~/pages/26/2";
import Theme28Task3 from "~/pages/28/3";
import Theme30Task1 from "~/pages/30/1";
import Theme7Task1 from "~/pages/7/1";
import Theme27Task3 from "~/pages/27/3";
import Theme37Task3 from "~/pages/37/3";
import Theme31Task3 from "~/pages/31/3";
import Theme33Task2 from "~/pages/33/2";
import Theme34Task1 from "~/pages/34/1";
import Theme36Task1 from "~/pages/36/1";
import Theme39Task1 from "~/pages/39/1";
import Theme40Task2 from "~/pages/40/2";
import Theme41Task2 from "~/pages/41/2";
import Theme42Task2 from "~/pages/42/2";
import Theme35Task3 from "~/pages/35/3";
import Theme5Task3 from "~/pages/5/3";
import Theme15Task1 from "~/pages/15/1";
import Theme15Task2 from "~/pages/15/2";
import Theme22Task3 from "~/pages/22/3";
import Theme25Task2 from "~/pages/25/2";
import Theme41Task1 from "~/pages/41/1";
import Theme16Task2 from "~/pages/16/2";
import Theme20Task4 from "~/pages/20/4";
import Theme24Task4 from "~/pages/24/4";
import Theme24Task5 from "~/pages/24/5";
import Theme30Task3 from "~/pages/30/3";
import Theme33Task3 from "~/pages/33/3";
import Theme35Task4 from "~/pages/35/4";
import Theme39Task3 from "~/pages/39/3";
import Theme45Task2 from "~/pages/45/2";
import Theme36Task3 from "~/pages/36/3";
import Theme20Task2 from "~/pages/20/2";
import Theme3Task2 from "~/pages/3/2";
import Theme19Task3 from "~/pages/19/3";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/1/1", element: <Theme1Task1 /> },
  { path: "/1/2", element: <Theme1Task2 /> },
  { path: "/2/1", element: <Theme2Task1 /> },
  { path: "/2/2", element: <Theme2Task2 /> },
  { path: "/3/1", element: <Theme3Task1 /> },
  { path: "/3/2", element: <Theme3Task2 /> },
  { path: "/4/1", element: <Theme4Task1 /> },
  { path: "/4/2", element: <Theme4Task2 /> },
  { path: "/5/1", element: <Theme5Task1 /> },
  { path: "/5/3", element: <Theme5Task3 /> },
  { path: "/5/2", element: <Theme5Task2 /> },
  { path: "/6/1", element: <Theme6Task1 /> },
  { path: "/7/1", element: <Theme7Task1 /> },
  { path: "/7/2", element: <Theme7Task2 /> },
  { path: "/7/3", element: <Theme7Task3 /> },
  { path: "/8/1", element: <Theme8Task1 /> },
  { path: "/8/2", element: <Theme8Task2 /> },
  { path: "/9/1", element: <Theme9Task1 /> },
  { path: "/10/1", element: <Theme10Task1 /> },
  { path: "/10/2", element: <Theme10Task2 /> },
  { path: "/11/1", element: <Theme11Task1 /> },
  { path: "/12/1", element: <Theme12Task1 /> },
  { path: "/12/2", element: <Theme12Task2 /> },
  { path: "/13/1", element: <Theme13Task1 /> },
  { path: "/13/2", element: <Theme13Task2 /> },
  { path: "/14/1", element: <Theme14Task1 /> },
  { path: "/14/2", element: <Theme14Task2 /> },
  { path: "/15/1", element: <Theme15Task1 /> },
  { path: "/15/2", element: <Theme15Task2 /> },
  { path: "/15/3", element: <Theme15Task3 /> },
  { path: "/16/1", element: <Theme16Task1 /> },
  { path: "/16/2", element: <Theme16Task2 /> },
  { path: "/17/1", element: <Theme17Task1 /> },
  { path: "/17/2", element: <Theme17Task2 /> },
  { path: "/17/3", element: <Theme17Task3 /> },
  { path: "/18/1", element: <Theme18Task1 /> },
  { path: "/18/2", element: <Theme18Task2 /> },
  { path: "/19/1", element: <Theme19Task1 /> },
  { path: "/19/2", element: <Theme19Task2 /> },
  { path: "/19/3", element: <Theme19Task3 /> },
  { path: "/20/1", element: <Theme20Task1 /> },
  { path: "/20/2", element: <Theme20Task2 /> },
  { path: "/20/3", element: <Theme20Task3 /> },
  { path: "/20/4", element: <Theme20Task4 /> },
  { path: "/21/1", element: <Theme21Task1 /> },
  { path: "/21/2", element: <Theme21Task2 /> },
  { path: "/21/3", element: <Theme21Task3 /> },
  { path: "/22/1", element: <Theme22Task1 /> },
  { path: "/22/2", element: <Theme22Task2 /> },
  { path: "/22/3", element: <Theme22Task3 /> },
  { path: "/22/4", element: <Theme22Task4 /> },
  { path: "/23/1", element: <Theme23Task1 /> },
  { path: "/23/2", element: <Theme23Task2 /> },
  { path: "/24/1", element: <Theme24Task1 /> },
  { path: "/24/2", element: <Theme24Task2 /> },
  { path: "/24/3", element: <Theme24Task3 /> },
  { path: "/24/4", element: <Theme24Task4 /> },
  { path: "/24/5", element: <Theme24Task5 /> },
  { path: "/25/1", element: <Theme25Task1 /> },
  { path: "/25/2", element: <Theme25Task2 /> },
  { path: "/25/3", element: <Theme25Task3 /> },
  { path: "/26/1", element: <Theme26Task1 /> },
  { path: "/26/2", element: <Theme26Task2 /> },
  { path: "/27/1", element: <Theme27Task1 /> },
  { path: "/27/2", element: <Theme27Task2 /> },
  { path: "/27/3", element: <Theme27Task3 /> },
  { path: "/28/1", element: <Theme28Task1 /> },
  { path: "/28/3", element: <Theme28Task3 /> },
  { path: "/29/1", element: <Theme29Task1 /> },
  { path: "/29/2", element: <Theme29Task2 /> },
  { path: "/30/1", element: <Theme30Task1 /> },
  { path: "/30/2", element: <Theme30Task2 /> },
  { path: "/30/3", element: <Theme30Task3 /> },
  { path: "/28/2", element: <Theme28Task2 /> },
  { path: "/31/1", element: <Theme31Task1 /> },
  { path: "/31/2", element: <Theme31Task2 /> },
  { path: "/31/3", element: <Theme31Task3 /> },
  { path: "/32/1", element: <Theme32Task1 /> },
  { path: "/32/2", element: <Theme32Task2 /> },
  { path: "/33/1", element: <Theme33Task1 /> },
  { path: "/33/2", element: <Theme33Task2 /> },
  { path: "/33/3", element: <Theme33Task3 /> },
  { path: "/34/1", element: <Theme34Task1 /> },
  { path: "/34/2", element: <Theme34Task2 /> },
  { path: "/35/1", element: <Theme35Task1 /> },
  { path: "/35/2", element: <Theme35Task2 /> },
  { path: "/35/3", element: <Theme35Task3 /> },
  { path: "/35/4", element: <Theme35Task4 /> },
  { path: "/36/1", element: <Theme36Task1 /> },
  { path: "/36/2", element: <Theme36Task2 /> },
  { path: "/36/3", element: <Theme36Task3 /> },
  { path: "/37/1", element: <Theme37Task1 /> },
  { path: "/37/2", element: <Theme37Task2 /> },
  { path: "37/3", element: <Theme37Task3 /> },
  { path: "/38/1", element: <Theme38Task1 /> },
  { path: "/38/2", element: <Theme38Task2 /> },
  { path: "/39/1", element: <Theme39Task1 /> },
  { path: "/39/2", element: <Theme39Task2 /> },
  { path: "/39/3", element: <Theme39Task3 /> },
  { path: "/40/1", element: <Theme40Task1 /> },
  { path: "/40/2", element: <Theme40Task2 /> },
  { path: "/41/1", element: <Theme41Task1 /> },
  { path: "/41/2", element: <Theme41Task2 /> },
  { path: "/41/3", element: <Theme41Task3 /> },
  { path: "/42/2", element: <Theme42Task2 /> },
  { path: "/42/1", element: <Theme42Task1 /> },
  { path: "/43/1", element: <Theme43Task1 /> },
  { path: "/43/2", element: <Theme43Task2 /> },
  { path: "/44/1", element: <Theme44Task1 /> },
  { path: "/44/2", element: <Theme44Task2 /> },
  { path: "/44/3", element: <Theme44Task3 /> },
  { path: "/45/1", element: <Theme45Task1 /> },
  { path: "/45/2", element: <Theme45Task2 /> }
]);

export const AppRouterProvider = () => {
  return <RouterProvider router={router} />;
};
