import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import pushkin from "~shared/assets/images/41/portret-pushkin.jpg";
import kutuzov from "~shared/assets/images/41/portret-kutuzov.jpg";
import petr from "~shared/assets/images/41/portret-petr-2.jpg";
import gagarin from "~shared/assets/images/41/portret-gagarin.jpg";

const Theme41Task3 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Пушкин А.С.",
      image: pushkin
    },
    {
      name: "Кутузов М.И.",
      image: kutuzov
    },
    {
      name: "Петр I",
      image: petr
    },
    {
      name: "Гагарин Ю.А.",
      image: gagarin
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри портреты. Отметь портрет Ю.А. Гагарина.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Гагарин Ю.А.' hideName />
    </QuizWrapper>
  );
};

export default Theme41Task3;
