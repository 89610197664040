import React, { useEffect, useState } from "react";
import { Layer, Line, Stage } from "react-konva";
import styles from "./QuizDrawLine.module.scss";
import { QuizResult } from "~/shared/components/QuizResult";
import { useQuizContext } from "~/shared/hooks/useQuizContext";
import clsx from "clsx";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";
import { useWindowSize } from "~/shared/hooks/useWindowSize";

export type DrawLineBlock = {
  name: string;
  url?: string;
  pair?: string;
  activated?: boolean;
};

type pointType = {
  x: number;
  y: number;
  block: DrawLineBlock;
};

type line = {
  type: "correct" | "uncorrected";
  startPoint: pointType;
  endPoint: pointType;
};

type Props = {
  firstCol: DrawLineBlock[];
  secondCol: DrawLineBlock[];
  sameHeight?: boolean;
};

const QuizDrawLine: React.FC<Props> = ({ firstCol, secondCol, sameHeight = false }) => {
  const [lines, setLines] = useState<line[]>([]);
  const [startPoint, setStartPoint] = useState<pointType | null>(null);
  const [hasUncorrected, setHasUncorrected] = useState<boolean>(false);
  const { setSuccess } = useQuizContext();
  const [active, setActive] = useState(false);

  const { width } = useWindowSize();

  const WIDTH: number = useAdaptiveValue(width - 48, 327, 480);
  const HEIGHT: number = useAdaptiveValue(120, 120, 150);
  const PADDING: number = useAdaptiveValue(24, 24, 16);
  const POSITION_Y: number = useAdaptiveValue(60, 60, 75);

  const imgXFirst: number = useAdaptiveValue(120, 120, 150);
  const textXFirst: number = useAdaptiveValue(150, 150, 200);
  const imgXSecond: number = useAdaptiveValue(207, 207, 330);
  const textXSecond: number = useAdaptiveValue(177, 177, 280);

  const imageWrapperStyle = useAdaptiveValue(
    styles.imageWrapperMobile,
    styles.imageWrapperMobile,
    styles.imageWrapper
  );
  const textWrapperStyle = useAdaptiveValue(
    styles.textWrapperMobile,
    styles.textWrapperMobile,
    styles.textWrapper
  );

  const textStyle = useAdaptiveValue(styles.textMobile, styles.textMobile, styles.text);

  const sameHeightStyle = useAdaptiveValue(
    styles.sameHeightMobile,
    styles.sameHeightMobile,
    styles.sameHeight
  );

  const removeUncorrectedLine = () => {
    const currentLines = [...lines];
    currentLines.pop();
    setTimeout(() => {
      setLines(currentLines);
      setHasUncorrected(false);
    }, 1000);
  };
  const startDraw = (index: number, block: DrawLineBlock, x: number) => {
    const { name } = block;
    const isChosen = lines.some(({ startPoint }) => startPoint?.block?.name === name);

    if (isChosen || hasUncorrected) return;
    const startPoint = { x: x, y: POSITION_Y + index * (HEIGHT + PADDING), block };
    setStartPoint(startPoint);
    setActive(true);
  };

  const endDraw = (index: number, block: DrawLineBlock, x: number) => {
    const { name } = block;
    const isChosen = lines.some(({ endPoint }) => endPoint?.block?.name === name);
    if (!startPoint || isChosen) return;

    const endPoint = { x: x, y: POSITION_Y + index * (HEIGHT + PADDING), block };
    const isCorrected = startPoint.block.name === endPoint.block.pair;
    const type = isCorrected ? "correct" : "uncorrected";

    setLines(
      lines.concat([
        {
          type,
          startPoint,
          endPoint
        }
      ])
    );

    firstCol.forEach((item) => {
      item.activated = false;
    });
    secondCol.forEach((item) => {
      item.activated = false;
    });

    !isCorrected && setHasUncorrected(true);
    setStartPoint(null);
    setActive(false);
  };

  useEffect(() => {
    hasUncorrected && removeUncorrectedLine();
  }, [hasUncorrected]);

  useEffect(() => {
    const success = !hasUncorrected && lines.length === firstCol.length;
    setTimeout(() => setSuccess(success), 700);
  }, [lines]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Stage width={WIDTH} height={firstCol.length * (HEIGHT + PADDING)}>
          <Layer>
            {lines.map((line, index) => {
              return (
                <Line
                  key={index}
                  points={[line.startPoint.x, line.startPoint.y, line.endPoint.x, line.endPoint.y]}
                  opacity={1}
                  stroke={line.type == "correct" ? "#B4D5A8" : "#F1615B"}
                  dash={[2, 2]}
                  strokeWidth={2}
                />
              );
            })}
          </Layer>
        </Stage>

        {firstCol.map((block, index) => {
          if (block.url) {
            return (
              <div
                key={index}
                style={{ top: index * (HEIGHT + PADDING) }}
                className={clsx(imageWrapperStyle, {
                  [styles.activated]: block.activated,
                  [styles.success]:
                    lines.some(
                      ({ startPoint, type }) =>
                        type === "correct" && startPoint.block.name === block.name
                    ) ||
                    lines.some(
                      ({ endPoint, type }) =>
                        type === "correct" && endPoint.block.name === block.name
                    ),
                  [styles.error]:
                    lines.some(
                      ({ startPoint, type }) =>
                        type === "uncorrected" && startPoint.block.name === block.name
                    ) ||
                    lines.some(
                      ({ endPoint, type }) =>
                        type === "uncorrected" && endPoint.block.name === block.name
                    )
                })}
                onClick={() => {
                  if (active) {
                    block.activated = false;
                    endDraw(index, block, imgXFirst);
                  } else {
                    block.activated = true && !hasUncorrected;
                    startDraw(index, block, imgXFirst);
                  }
                }}
              >
                <img src={block.url} alt={block.name} className={styles.image} draggable='false' />
              </div>
            );
          } else {
            return (
              <div
                key={index}
                style={{ top: index * (HEIGHT + PADDING) }}
                className={textWrapperStyle}
              >
                <div
                  className={clsx(textStyle, {
                    [styles.success]:
                      block.name === startPoint?.block.name ||
                      lines.some(
                        ({ startPoint, type }) =>
                          type === "correct" && startPoint.block.name === block.name
                      ) ||
                      lines.some(
                        ({ endPoint, type }) =>
                          type === "correct" && endPoint.block.name === block.name
                      ),
                    [styles.error]:
                      lines.some(
                        ({ startPoint, type }) =>
                          type === "uncorrected" && startPoint.block.name === block.name
                      ) ||
                      lines.some(
                        ({ endPoint, type }) =>
                          type === "uncorrected" && endPoint.block.name === block.name
                      ),
                    [sameHeightStyle]: sameHeight
                  })}
                  onClick={() => {
                    if (active) {
                      endDraw(index, block, textXFirst);
                    } else {
                      startDraw(index, block, textXFirst);
                    }
                  }}
                >
                  {block.name}
                </div>
              </div>
            );
          }
        })}
        {secondCol.map((block, index) => {
          if (block.url) {
            return (
              <div
                key={index}
                style={{ top: index * (HEIGHT + PADDING), right: 0 }}
                className={clsx(imageWrapperStyle, {
                  [styles.activated]: block.activated,
                  [styles.success]:
                    lines.some(
                      ({ endPoint, type }) =>
                        type === "correct" && endPoint.block.name === block.name
                    ) ||
                    lines.some(
                      ({ startPoint, type }) =>
                        type === "correct" && startPoint.block.name === block.name
                    ),
                  [styles.error]:
                    lines.some(
                      ({ endPoint, type }) =>
                        type === "uncorrected" && endPoint.block.name === block.name
                    ) ||
                    lines.some(
                      ({ startPoint, type }) =>
                        type === "uncorrected" && startPoint.block.name === block.name
                    )
                })}
                onClick={() => {
                  if (active) {
                    block.activated = false;
                    endDraw(index, block, imgXSecond);
                  } else {
                    block.activated = true && !hasUncorrected;
                    startDraw(index, block, imgXSecond);
                  }
                }}
              >
                <img src={block.url} alt={block.name} className={styles.image} draggable='false' />
              </div>
            );
          } else {
            return (
              <div
                key={index}
                style={{ top: index * (HEIGHT + PADDING), right: 0 }}
                className={textWrapperStyle}
              >
                <div
                  className={clsx(textStyle, {
                    [styles.success]:
                      block.name === startPoint?.block.name ||
                      lines.some(
                        ({ endPoint, type }) =>
                          type === "correct" && endPoint.block.name === block.name
                      ) ||
                      lines.some(
                        ({ startPoint, type }) =>
                          type === "correct" && startPoint.block.name === block.name
                      ),
                    [styles.error]:
                      lines.some(
                        ({ endPoint, type }) =>
                          type === "uncorrected" && endPoint.block.name === block.name
                      ) ||
                      lines.some(
                        ({ startPoint, type }) =>
                          type === "uncorrected" && startPoint.block.name === block.name
                      ),
                    [sameHeightStyle]: sameHeight
                  })}
                  onClick={() => {
                    if (active) {
                      endDraw(index, block, textXSecond);
                    } else {
                      startDraw(index, block, textXSecond);
                    }
                  }}
                >
                  {block.name}
                </div>
              </div>
            );
          }
        })}
      </div>
      {<QuizResult errorVisible={hasUncorrected} />}
    </div>
  );
};

export default QuizDrawLine;
