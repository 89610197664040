import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import pamyatnikDolgorukiy from "~shared/assets/images/5/pamyatnik-dolgorukiy.jpg";
import pamyatnikMinin from "~shared/assets/images/5/pamyatnik-minin.jpg";
import pamyatnikVladimir from "~shared/assets/images/5/pamyatnik-vladimir.jpg";
import pamyatnikZhukov from "~shared/assets/images/5/pamyatnik-zhukov.jpg";

const Theme5Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Юрий Долгорукий",
      image: pamyatnikDolgorukiy
    },
    {
      name: "Минин и Пожарский",
      image: pamyatnikMinin
    },
    {
      name: "Владимир Великий",
      image: pamyatnikVladimir
    },
    {
      name: "Маршал Жуков",
      image: pamyatnikZhukov
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Кого считают основателем Москвы? Отметь его памятник.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Юрий Долгорукий' />
    </QuizWrapper>
  );
};

export default Theme5Task1;
