type Routes = {
  title: string;
  links: {
    title: string;
    link: string;
  }[];
};

export const routes: Routes[] = [
  {
    title: "Тема 1",
    links: [
      { title: "Задание 1. Рассмотри флаги. Отметь среди них флаг России.", link: "/1/1" },
      { title: "Задание 2. Рассмотри гербы. Отметь среди них герб России.", link: "/1/2" }
    ]
  },
  {
    title: "Тема 2",
    links: [
      {
        title: "Задание 1. Вспомни, какой транспорт есть в Москве. Отметь лишнее.",
        link: "/2/1"
      },
      { title: 'Задание 2. Собери знак "Остановка трамвая".', link: "/2/2" }
    ]
  },
  {
    title: "Тема 3",
    links: [
      { title: "Задание. Отметь, самое крупное животное в парке «Лосиный Остров».", link: "/3/1" },
      { title: "Задание: Распредели животных на группы. ", link: "/3/2" }
    ]
  },
  {
    title: "Тема 4",
    links: [
      {
        title: "Задание. Рассмотри рисунки и обрати внимание, чем занимаются люди. Отметь лишнее.",
        link: "/4/1"
      },
      {
        title: "Задание. Разгадай ребус. Отметь рисунок, на котором изображена отгадка.",
        link: "/4/2"
      }
    ]
  },
  {
    title: "Тема 5",
    links: [
      {
        title: "Задание 1. Кого считают основателем Москвы? Отметь его памятник.",
        link: "/5/1"
      },
      {
        title: "Задание 2. Разгадай ребус. Отметь рисунок, на котором изображена отгадка.",
        link: "/5/2"
      },
      {
        title: "Задание: Восстанови последовательность событий.",
        link: "/5/3"
      }
    ]
  },
  {
    title: "Тема 6",
    links: [
      {
        title: "Задание: Рассмотри рисунки. Отметь изображение монастыря.",
        link: "/6/1"
      }
    ]
  },
  {
    title: "Тема 7",
    links: [
      {
        title: "Задание: С каким предметом связано прозвище Калита? Отметь.",
        link: "/7/1"
      },
      {
        title: "Задание: Рассмотри изображения. Отметь Кремль при Иване Калите.",
        link: "/7/2"
      },
      {
        title: "Задание: Собери пазл «Кремль при Иване Калите».",
        link: "/7/3"
      }
    ]
  },
  {
    title: "Тема 8",
    links: [
      { title: "Задание 1. Верно или нет? Отметь.", link: "/8/1" },
      { title: "Задание 2. Одень ратника.", link: "/8/2" }
    ]
  },
  {
    title: "Тема 9",
    links: [{ title: "Задание 1. Рассмотри рисунки. Отметь трёх ремесленников.", link: "/9/1" }]
  },
  {
    title: "Тема 10",
    links: [
      { title: "Задание. Задание: Что это? Соедини.", link: "/10/1" },
      { title: "Задание 1. Убери букву, которая повторяется. Прочитай пословицу", link: "/10/2" }
    ]
  },
  {
    title: "Тема 11",
    links: [{ title: "Задание: Какого объекта нет на территории Кремля?", link: "/11/1" }]
  },
  {
    title: "Тема 12",
    links: [
      {
        title: "Задание 1. Подбери верную картинку к каждой профессии? Установи соответствие.",
        link: "/12/1"
      },
      {
        title: "Задание 2. Расшифруй слово.",
        link: "/12/2"
      }
    ]
  },
  {
    title: "Тема 13",
    links: [
      { title: "Задание: Помоги ремесленнику найти свои предметы.", link: "/13/1" },
      { title: "Задание: Разгадай филворд. Найди старинные профессии.", link: "/13/2" }
    ]
  },
  {
    title: "Тема 14",
    links: [
      {
        title:
          "Задание: Как строился Кремль, какие стены были раньше, какие позже? Правильно расположи.",
        link: "/14/1"
      },
      {
        title: "Задание. При каком князе Кремль обнесли красной кирпичной стеной? ",
        link: "/14/2"
      }
    ]
  },
  {
    title: "Тема 15",
    links: [
      {
        title: "Задание: Кто был раньше? Правильно расположи.",
        link: "/15/1"
      },
      {
        title: "Задание: Расположи события на ленте времени.",
        link: "/15/2"
      },
      {
        title: "Задание: Рассмотри рисунки. Выбери, кто входил в войско Ивана Грозного.",
        link: "/15/3"
      }
    ]
  },
  {
    title: "Тема 16",
    links: [
      {
        title: "Задание: Верно или нет? Отметь.  ",
        link: "/16/1"
      },
      {
        title:
          "Задание. Расшифруй слово. Как называлась линия деревянных укреплений, которую построили за один год?",
        link: "/16/2"
      }
    ]
  },
  {
    title: "Тема 17",
    links: [
      { title: "Задание 1.  Когда отмечают праздник День народного единства?", link: "/17/1" },
      {
        title: "Задание 2.  Рассмотри фотографии. Отметь памятник патриарху Гермогену.",
        link: "/17/2"
      },
      {
        title: "Задание. Собери пазл. Кому посвящен памятник, изображенный на картинке?",
        link: "/17/3"
      }
    ]
  },
  {
    title: "Тема 18",
    links: [
      { title: "Задание 1. Какие товары привозили купцы из дальних стран?", link: "/18/1" },
      { title: "Задание 2. Напечатай слово, используя шифр.", link: "/18/2" }
    ]
  },
  {
    title: "Тема 19",
    links: [
      {
        title: "Задание 1. Сколько деревянных теремов было в Коломенском дворце? ",
        link: "/19/1"
      },
      {
        title: "Задание 2. Кто был раньше, а кто позже? Расположи.",
        link: "/19/2"
      },
      {
        title:
          "Задание 3. Вспомни описание Коломенского дворца. Выбери нужное слово и дополни предложения. ",
        link: "/19/3"
      }
    ]
  },
  {
    title: "Тема 20",
    links: [
      {
        title: "Задание 1: Кто с чем связан? Соедини. ",
        link: "/20/1"
      },
      {
        title: "Задание 2: Рассмотри фотографии. Отметь памятник Ивану Фёдорову.",
        link: "/20/2"
      },
      {
        title: "Задание 4. Как называется место, где хранятся книги? Разгадай ребус.",
        link: "/20/4"
      },
      {
        title: "Задание 3: Рассмотри портреты. Отметь портрет М.В. Ломоносова. ",
        link: "/20/3"
      }
    ]
  },
  {
    title: "Тема 21",
    links: [
      {
        title: "Задание 1: Какие праздники были главными в 17 веке в Москве?",
        link: "/21/1"
      },
      {
        title: "Задание 2: Кто перенёс празднование Нового года на 1 января?",
        link: "/21/2"
      },
      {
        title: "Задание 3. Собери пословицу. Поставь слова в правильной последовательности.",
        link: "/21/3"
      }
    ]
  },
  {
    title: "Тема 22",
    links: [
      {
        title: "Задание 1: Отметь портрет Петра I.",
        link: "/22/1"
      },
      {
        title: "Задание 2. Расшифруй слово – титул Петра I , по коду в таблице.",
        link: "/22/2"
      },

      {
        title: "Задание 3: Какой город стал столицей при Петре I? Отметь. ",
        link: "/22/3"
      },
      {
        title: "Задание 4: Как Петр I называл военные упражнения? Отметь.",
        link: "/22/4"
      }
    ]
  },
  {
    title: "Тема 23",
    links: [
      {
        title: "Задание 1: Где устроили особую Государеву аптеку?",
        link: "/23/1"
      },
      {
        title:
          "Задание 2: Впиши пропущенные слова в кроссворд и прочитай слово, которое получилось.",
        link: "/23/2"
      }
    ]
  },
  {
    title: "Тема 24",
    links: [
      { title: "Задание 1. Разгадай ребус. Запиши отгадку.", link: "/24/1" },
      { title: "Задание 2: Где находится кабинет президента России?", link: "/24/2" },
      { title: "Задание 3: Где короновали государей и государыней?", link: "/24/3" },
      { title: "Задание 4. Разгадай ребус. Запиши отгадку.", link: "/24/4" },
      { title: "Задание 5. Разгадай ребус. Запиши отгадку.", link: "/24/5" }
    ]
  },
  {
    title: "Тема 25",
    links: [
      {
        title: "Задание 1: Что было построено в честь победы над Наполеоном? Отметь.",
        link: "/25/1"
      },
      {
        title: "Задание 2: Кто с чем связан? Соедини. ",
        link: "/25/2"
      },
      {
        title: "Задание 3: Рассмотри портреты. Отметь портрет Кутузова М.И. ",
        link: "/25/3"
      }
    ]
  },
  {
    title: "Тема 26",
    links: [
      {
        title: "Задание 1: Рассмотри фотографии и найди Большой театр. Отметь. ",
        link: "/26/1"
      },
      {
        title: "Задание 2: Рассмотри фотографии. Отметь то, что не было построено Осипом Бове.",
        link: "/26/2"
      }
    ]
  },
  {
    title: "Тема 27",
    links: [
      { title: "Задание 1. Дополни предложение. Напечатай пропущенное слово.", link: "/27/1" },
      { title: "Задание 2. Разгадай ребус.", link: "/27/2" },
      { title: "Задание 3. Рассмотри рисунки. Отметь фонарщика. ", link: "/27/3" }
    ]
  },
  {
    title: "Тема 28",
    links: [
      {
        title:
          "Задание 1: Рассмотри портреты. Отметь портрет императора, при котором было открыто движение между двумя столицами.",
        link: "/28/1"
      },
      { title: "Задание 2. Разгадай ребус. Запиши отгадку.", link: "/28/2" },
      {
        title: "Задание 3. Дополни предложение. Напечатай пропущенное слово. ",
        link: "/28/3"
      }
    ]
  },
  {
    title: "Тема 30",
    links: [
      {
        title: "Задание 1. Отметь, какой объект нельзя найти в путеводителе по Москве. ",
        link: "/30/1"
      },
      {
        title: "Задание 2. Какой дом называли ТУЧЕРЕЗОМ? Отметь.",
        link: "/30/2"
      },
      {
        title: "Задание 3. Филворд. Какие гости ежедневно приезжали в Москву?",
        link: "/30/3"
      }
    ]
  },
  {
    title: "Тема 29",
    links: [
      {
        title: "Задание 1: Рассмотри предметы. В каком музее они могут быть? Перемести. ",
        link: "/29/1"
      },
      {
        title: "Задание 2: Напечатай слова в соответствующие строки кроссворда.",
        link: "/29/2"
      }
    ]
  },
  {
    title: "Тема 31",
    links: [
      { title: "Задание 1. Отметь, как назывался первый трамвайный маршрут.", link: "/31/1" },
      { title: "Задание 2. Разгадай ребус.", link: "/31/2" },
      { title: "Задание 3. Где проходил маршрут «Аннушка»? ", link: "/31/3" }
    ]
  },
  {
    title: "Тема 32",
    links: [
      {
        title: "Задание 1. Рассмотри предметы и отметь среди них серп.",
        link: "/32/1"
      },
      {
        title: "Задание 1. Собери пазл. Кому посвящен памятник, изображенный на картинке?",
        link: "/32/2"
      }
    ]
  },
  {
    title: "Тема 33",
    links: [
      {
        title: "Задание 1: Какое количество станций было на первой линии метро? Отметь.",
        link: "/33/1"
      },
      {
        title: "Задание 2: Какие станции метро входили в первую линию? Отметь.",
        link: "/33/2"
      },
      {
        title: "Задание 3. Пассажирский транспорт Москвы. Разгадай ребус.",
        link: "/33/3"
      }
    ]
  },
  {
    title: "Тема 34",
    links: [
      {
        title: "Задание 1: Кем был Валерий Чкалов? Отметь",
        link: "/34/1"
      },
      {
        title: "Задание 2: Что было раньше, а что позже? Расположи.",
        link: "/34/2"
      }
    ]
  },
  {
    title: "Тема 35",
    links: [
      {
        title: "Задание 1: Рассмотри рисунки. Отметь теплоход.",
        link: "/35/1"
      },
      {
        title: "Задание 2: Собери слова, поменяв местами слоги.",
        link: "/35/2"
      },
      {
        title: "Задание 3. Дополни предложение. Напечатай пропущенное слово. ",
        link: "/35/3"
      },
      {
        title: "Задание 4. Сколько в Москве речных вокзалов? Собери изображение одного из них. ",
        link: "/35/4"
      }
    ]
  },
  {
    title: "Тема 36",
    links: [
      {
        title: "Задание 1: Когда началась Великая Отечественная война? Отметь.",
        link: "/36/1"
      },
      {
        title: "Задание 2: Рассмотри фотографии защитников Москвы. Отметь зенитчиков.",
        link: "/36/2"
      },
      {
        title: "Задание 3: Что было раньше, а что позже? ",
        link: "/36/3"
      }
    ]
  },
  {
    title: "Тема 37",
    links: [
      { title: "Задание 1. Когда мы отмечаем День Победы? Отметь.", link: "/37/1" },
      { title: "Задание 2. Что бывает на День Победы? Разгадай ребус.", link: "/37/2" },
      { title: "Задание 3: Рассмотри фотографии. Отметь памятник Георгию Жукову.", link: "/37/3" }
    ]
  },
  {
    title: "Тема 38",
    links: [
      { title: "Задание 1: Где расположены некоторые высотные задания? Отметь.", link: "/38/1" },
      { title: "Задание 2: Одно из высотных зданий Москвы. Собери пазл. ", link: "/38/2" }
    ]
  },
  {
    title: "Тема 39",
    links: [
      {
        title: "Задание 1. Дополни предложение. Напечатай пропущенное слово. ",
        link: "/39/1"
      },
      {
        title: "Задание 2. Как называется профессия человека, который проектирует здания?",
        link: "/39/2"
      },
      {
        title:
          "Задание 3. Как называется район, в котором появились первые пятиэтажные дома? Разгадай ребус.",
        link: "/39/3"
      }
    ]
  },
  {
    title: "Тема 40",
    links: [
      {
        title: "Задание 1: Что было раньше, а что позже? Расположи.",
        link: "/40/1"
      },
      {
        title: "Задание 2: Какая башня самая высокая в Европе? Отметь. ",
        link: "/40/2"
      }
    ]
  },
  {
    title: "Тема 41",
    links: [
      {
        title: "Задание 1: Когда мы отмечаем эти праздники? Правильно соедини.",
        link: "/41/1"
      },
      {
        title: "Задание 2: Какие рекорды при изучении космоса поставила наша страна? Отметь.",
        link: "/41/2"
      },
      {
        title: "Задание 3: Рассмотри портреты. Отметь портрет Ю.А. Гагарина.",
        link: "/41/3"
      }
    ]
  },
  {
    title: "Тема 42",
    links: [
      {
        title: "Задание 1: Как выглядел символ Олимпийских игр в Москве?",
        link: "/42/1"
      },
      {
        title: "Задание 2: Как называется район Москвы, выстроенный к Олимпийским играм? Отметь.",
        link: "/42/2"
      }
    ]
  },
  {
    title: "Тема 43",
    links: [
      { title: "Задание 1: Где построен парк Зарядье? Отметь.", link: "/43/1" },
      {
        title: "Задание. Расшифруй слово и ты узнаешь, как называется парк – гордость столицы.",
        link: "/43/2"
      }
    ]
  },
  {
    title: "Тема 44",
    links: [
      { title: "Задание 1: Какая река протекает в Новой Москве? Отметь.", link: "/44/1" },
      { title: "Задание 2: Разгадай логическую задачу и отметь правильный ответ.", link: "/44/2" },
      { title: "Задание. Разгадай кроссворд.", link: "/44/3" }
    ]
  },
  {
    title: "Тема 45",
    links: [
      { title: "Задание 1: Разгадай логическую задачу и правильно соедини. ", link: "/45/1" },
      { title: "Задание 2. Разгадай ребус.", link: "/45/2" }
    ]
  }
];
