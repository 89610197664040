import React, { useEffect, useState } from "react";
import { DragAndDropProvider } from "~/providers/DragAndDropProvider";
import styles from "./QuizPuzzleAssembleObject.module.scss";
import { DraggableWrapper } from "~shared/components/DraggableWrapper";
import { QuizResult } from "~shared/components/QuizResult";
import { DropContainer } from "~shared/components/DropContainer";
import { useDragAndDropContext } from "~shared/hooks/useDragAndDropContext";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

export type RootObject = {
  imgUrl: string;
  imageWrapperStyle?: React.CSSProperties;
  style?: React.CSSProperties;
};

export type DraggableItem = {
  id: number;
  imgUrl: string;
  imageStyle?: React.CSSProperties;
  imageWrapperStyle?: React.CSSProperties;
};
export type DropZone = { id: number; style?: React.CSSProperties };
export type Answer = Record<number, number>;

type Props = {
  rootObject: RootObject;
  draggableItems: DraggableItem[];
  dropZones: DropZone[];
  answers: Answer;
};
const getKeyByValue = (map: Map<number, number>, value: number) => {
  let key = -1;

  map.forEach((mapValue, mapKey) => {
    if (mapValue === value) {
      key = mapKey;
    }
  });

  return key;
};
const Content: React.FC<Props> = ({ rootObject, draggableItems, dropZones, answers }) => {
  const [activeDropZone, setActiveDropZone] = useState<number | null>(null);
  const { setSuccess } = useQuizContext();
  const { dragId, results, incorrectIds, setIncorrectIds } = useDragAndDropContext();

  const containerStyle = useAdaptiveValue(
    styles.containerMobile,
    styles.containerMobile,
    styles.container
  );

  const handleClick = () => {
    setIncorrectIds([]);

    results.forEach((value, key, map) => {
      if (map.get(key) !== answers[key]) {
        setIncorrectIds((prev) => [...prev, value]);
        results.delete(key);
      }
    });
  };

  useEffect(() => {
    setActiveDropZone(dragId);
  }, [dragId]);

  useEffect(() => {
    if (results.size > 0 && incorrectIds.length === 0) {
      setTimeout(() => setSuccess(true), 700);
    }
  }, [results, incorrectIds]);

  return (
    <div
      className={containerStyle}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={styles.taskWrapper}>
        <div className={styles.task} style={rootObject.style}>
          <div className={styles.dropZoneContainer} style={rootObject.imageWrapperStyle}>
            <img src={rootObject.imgUrl} className={styles.rootImage} alt='Изображение' />
            {dropZones.map((item) => (
              <DropContainer
                className={styles.dropZone}
                key={item.id}
                id={item.id}
                style={item.style}
                canDrop={item.id === activeDropZone && results.size < Object.keys(answers).length}
              />
            ))}
          </div>

          {draggableItems.map((item) => {
            const currentKey = getKeyByValue(results, item.id);

            return (
              <DraggableWrapper
                className={styles.draggableItem}
                key={item.id}
                id={item.id}
                style={{
                  ...(results.has(currentKey) && { zIndex: currentKey }),
                  ...item.imageWrapperStyle
                }}
              >
                <img
                  className={styles.image}
                  src={item.imgUrl}
                  style={item.imageStyle}
                  alt='Изображение'
                />
              </DraggableWrapper>
            );
          })}
        </div>
      </div>

      <QuizResult
        buttonVisible={results.size === Object.keys(answers).length}
        errorVisible={incorrectIds.length > 0 && results.size !== Object.keys(answers).length}
        buttonOnClick={handleClick}
      />
    </div>
  );
};

export const QuizPuzzleAssembleObject: React.FC<Props> = ({
  rootObject,
  draggableItems,
  dropZones,
  answers
}) => {
  return (
    <DragAndDropProvider>
      <Content
        rootObject={rootObject}
        draggableItems={draggableItems}
        dropZones={dropZones}
        answers={answers}
      />
    </DragAndDropProvider>
  );
};
