import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizCrossWord, CrossWordData, Prompt } from "~/modules/QuizCrossWord/QuizCrossWord";

const Theme23Task2 = () => {
  const data: CrossWordData = {
    across: {
      1: {
        clue: "Лекарская … открыта Н.Бидлоо",
        answer: "ШКОЛА",
        row: 0,
        col: 0
      },
      2: {
        clue: "По Европе путешествовал царь … I",
        answer: "ПЕТР",
        row: 1,
        col: 4
      },
      3: {
        clue: "Что собирали исстари на Руси для лечения людей?",
        answer: "ТРАВЫ",
        row: 2,
        col: 4
      },
      4: {
        clue: "В заведениях для больных делали …",
        answer: "ОПЕРАЦИИ",
        row: 3,
        col: 2
      },
      5: {
        clue: "Имя голландца  Бидлоо …",
        answer: "НИКОЛАС",
        row: 4,
        col: 2
      },
      6: {
        clue: "… - то, что заинтересовало Петра I в Европе",
        answer: "НАУКА",
        row: 5,
        col: 3
      }
    },
    down: {}
  };

  const prompt: Prompt = { type: "down", row: 0, col: 4, clue: "АПТЕКА" };
  return (
    <QuizWrapper
      title='Кроссворд'
      icon='cross-word'
      subtitle='Прочитай подсказки ниже и реши кроссворд.'
      description='Напечатай слова в соответсвующие строки кроссворда'
      successIcon='mash-2'
    >
      <QuizCrossWord
        data={data}
        prompt={prompt}
        adjacentLetter={[
          { col: 5, row: 1, letter: "Е", correctLetters: [] },
          { col: 8, row: 2, letter: "Ы", correctLetters: [] },
          { col: 9, row: 3, letter: "И", correctLetters: [] }
        ]}
      />
    </QuizWrapper>
  );
};

export default Theme23Task2;
