import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import QuizDrawLine, { DrawLineBlock } from "~/modules/QuizDrawLine/QuizDrawLine";

const firstBlocks: DrawLineBlock[] = [
  {
    name: "Дмитрий Донской",
    pair: "Куликовская битва"
  },
  {
    name: "Михаил Ломоносов",
    pair: "Московский университет"
  },
  {
    name: "Пётр I",
    pair: "Строительство Санкт-Петербурга"
  },
  {
    name: "Михаил Кутузов",
    pair: "Бородинское сражение"
  }
];

const secondBlocks: DrawLineBlock[] = [
  {
    name: "Московский университет",
    pair: "Михаил Ломоносов"
  },
  {
    pair: "Дмитрий Донской",
    name: "Куликовская битва"
  },
  {
    pair: "Михаил Кутузов",
    name: "Бородинское сражение"
  },
  {
    pair: "Пётр I",
    name: "Строительство Санкт-Петербурга"
  }
];

const Theme25Task2 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Кто с чем связан?'
      description='Нажимай на деятеля и связанное с ним событие.'
      successIcon='rus-2'
    >
      <QuizDrawLine firstCol={firstBlocks} secondCol={secondBlocks} sameHeight />
    </QuizWrapper>
  );
};

export default Theme25Task2;
