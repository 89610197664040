import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizMoveLetters, Answer, DraggableItem, DropZone } from "~modules/QuizMoveLetters";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    letter: "Р"
  },
  {
    id: 2,
    letter: "А"
  },
  {
    id: 3,
    letter: "Г"
  },
  {
    id: 4,
    letter: "Н"
  },
  {
    id: 5,
    letter: "О"
  },
  {
    id: 6,
    letter: "Ч"
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    name: "1"
  },
  {
    id: 2,
    name: "2"
  },
  {
    id: 3,
    name: "3"
  },
  {
    id: 4,
    name: "4"
  },
  {
    id: 5,
    name: "5"
  },
  {
    id: 6,
    name: "6"
  }
];
const clues = ["Р=6", "А=5", "Г=1", "Н=3", "О=2", "Ч=4"];

const answers: Answer = { 1: [3], 2: [5], 3: [4], 4: [6], 5: [2], 6: [1] };

const Theme12Task2 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Расшифруй слово.'
      description='Перетащи буквы на верные места'
      successIcon='nast-2'
    >
      <QuizMoveLetters
        draggableItems={draggableItems}
        dropZones={dropZones}
        answers={answers}
        clues={clues}
      />
    </QuizWrapper>
  );
};

export default Theme12Task2;
