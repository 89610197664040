import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme33Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "10"
    },
    {
      name: "11"
    },
    {
      name: "12"
    },
    {
      name: "13"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какое количество станций было на первой линии метро?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='13' />
    </QuizWrapper>
  );
};

export default Theme33Task1;
