import React, { useEffect } from "react";
import { DragAndDropProvider } from "~/providers/DragAndDropProvider";
import styles from "./QuizMoveLetters.module.scss";
import { DraggableWrapper } from "~shared/components/DraggableWrapper";
import { QuizResult } from "~shared/components/QuizResult";
import { DropContainer } from "~shared/components/DropContainer";
import { useDragAndDropContext } from "~shared/hooks/useDragAndDropContext";
import clsx from "clsx";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";
import { QuizWrapper } from "~shared/components/QuizWrapper";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    letter: "О"
  },
  {
    id: 2,
    letter: "С"
  },
  {
    id: 3,
    letter: "Р"
  },
  {
    id: 4,
    letter: "К"
  },
  {
    id: 5,
    letter: "Д"
  },
  {
    id: 6,
    letter: "О"
  },
  {
    id: 7,
    letter: "М"
  },
  {
    id: 8,
    letter: "О"
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    name: "1"
  },
  {
    id: 2,
    name: "2"
  },
  {
    id: 3,
    name: "3"
  },
  {
    id: 4,
    name: "4"
  },
  {
    id: 5,
    name: "5"
  },
  {
    id: 6,
    name: "6"
  },
  {
    id: 7,
    name: "7"
  },
  {
    id: 8,
    name: "8"
  }
];

const answers: Answer = {
  1: [2],
  2: [4],
  3: [1, 6, 8],
  4: [3],
  5: [1, 6, 8],
  6: [5],
  7: [1, 6, 8],
  8: [7]
};
const clues: string[] = ["О=3", "С=1", "Р=4", "К=2", "Д=6", "О=5", "М=8", "О=7"];

export default function Theme16Task2() {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Как называлась линия деревянных укреплений, которую построили за один год?'
      description='Перетащи буквы на верные места'
      successIcon='nast-2'
    >
      <QuizMoveLetters
        draggableItems={draggableItems}
        dropZones={dropZones}
        answers={answers}
        clues={clues}
      />
    </QuizWrapper>
  );
}

export type DraggableItem = { id: number; letter: string };
export type DropZone = { id: number; name: string };
export type Answer = Record<number, number[]>;

type Props = {
  draggableItems: DraggableItem[];
  dropZones: DropZone[];
  answers: Answer;
  clues?: string[];
};
const Content: React.FC<Props> = ({ draggableItems, dropZones, answers, clues }) => {
  const { setSuccess } = useQuizContext();
  const { results, incorrectIds, setIncorrectIds } = useDragAndDropContext();

  const styleDraggableItem = useAdaptiveValue(
    styles.draggableItemMobile,
    styles.draggableItemMobile,
    styles.draggableItem
  );
  const styleClues = useAdaptiveValue(styles.cluesMobile, styles.cluesMobile, styles.clues);
  const styleDropZone = useAdaptiveValue(
    styles.dropZoneMobile,
    styles.dropZoneMobile,
    styles.dropZone
  );
  const styleDropName = useAdaptiveValue(
    styles.dropNameMobile,
    styles.dropNameMobile,
    styles.dropName
  );
  const styleDropZoneContainer = useAdaptiveValue(
    styles.dropZoneContainerMobile,
    styles.dropZoneContainerMobile,
    styles.dropZoneContainer
  );

  const handleClick = () => {
    setIncorrectIds([]);

    results.forEach((value, key, map) => {
      const aaa = map.get(key) || 0;

      if (!answers[key].includes(aaa)) {
        setIncorrectIds((prev) => [...prev, value]);
        results.delete(key);
      }
    });
  };

  useEffect(() => {
    if (results.size > 0 && incorrectIds.length === 0) {
      setTimeout(() => setSuccess(true), 700);
    }
  }, [results, incorrectIds]);

  return (
    <div className={styles.container}>
      <h2 style={{ color: "#F1615B" }}>Подсказка к шифру</h2>
      {clues && (
        <div style={{ width: "100%" }}>
          <div className={styleClues}>
            {clues.map((clue, i) => (
              <div key={i}>{clue}</div>
            ))}
          </div>
        </div>
      )}

      <div className={styleDropZoneContainer}>
        {dropZones.map((item, idx) => (
          <div className={styles.dropPoint} key={item.id}>
            <div className={clsx(styleDropName, { [styles.filled]: results.has(idx + 1) })}>
              {item.name}
            </div>
            <DropContainer className={styleDropZone} id={item.id} />
          </div>
        ))}
      </div>

      <div className={styles.draggableItems}>
        {draggableItems.map((item) => {
          return (
            <DraggableWrapper key={item.id} id={item.id}>
              <div className={styleDraggableItem}>{item.letter}</div>
            </DraggableWrapper>
          );
        })}
      </div>

      <QuizResult
        buttonVisible={results.size === Object.keys(answers).length}
        errorVisible={incorrectIds.length > 0 && results.size !== Object.keys(answers).length}
        buttonOnClick={handleClick}
      />
    </div>
  );
};

const QuizMoveLetters: React.FC<Props> = ({ draggableItems, dropZones, answers, clues }) => {
  return (
    <DragAndDropProvider>
      <Content
        draggableItems={draggableItems}
        dropZones={dropZones}
        answers={answers}
        clues={clues}
      />
    </DragAndDropProvider>
  );
};
