import { QuizWrapper } from "~shared/components/QuizWrapper";
import QuizMultiDrawLine from "~modules/QuizMultiDrawLine/QuizMultiDrawLine";
import React from "react";

import volk from "~shared/assets/images/3/volk.jpg";
import medved from "~shared/assets/images/3/medved.jpg";
import zayats from "~shared/assets/images/3/zayats.jpg";

import sokol from "~shared/assets/images/3/sokol.jpg";
import tsapl from "~shared/assets/images/3/tsaplya.jpg";
import utka from "~shared/assets/images/3/utka.jpg";

import sterlyad from "~shared/assets/images/3/sterlyad.jpg";
import shuka from "~shared/assets/images/3/schuka.jpg";
import karp from "~shared/assets/images/3/karp.jpg";

type DrawLineBlock = {
  name: string;
  url?: string;
  pair?: string;
};

const firstBlocks: DrawLineBlock[] = [
  {
    url: sterlyad,
    name: "Стерлядь",
    pair: "Рыба"
  },
  {
    url: medved,
    name: "Медведь",
    pair: "Зверь"
  },
  {
    url: tsapl,
    name: "Цапля",
    pair: "Птица"
  }
];

const secondBlocks: DrawLineBlock[] = [
  {
    name: "Рыба",
    pair: "Стерлядь"
  },
  {
    name: "Птица",
    pair: "Цапля"
  },
  {
    name: "Зверь",
    pair: "Медведь"
  }
];

const firstBlocksNext: DrawLineBlock[] = [
  {
    url: zayats,
    name: "Заяц",
    pair: "Зверь"
  },
  {
    url: sokol,
    name: "Сокол",
    pair: "Птица"
  },
  {
    url: shuka,
    name: "Щука",
    pair: "Рыба"
  }
];

const secondBlocksNext: DrawLineBlock[] = [
  {
    name: "Рыба",
    pair: "Щука"
  },
  {
    name: "Птица",
    pair: "Сокол"
  },
  {
    name: "Зверь",
    pair: "Заяц"
  }
];

const firstBlocksFinal: DrawLineBlock[] = [
  {
    url: volk,
    name: "Волк",
    pair: "Зверь"
  },
  {
    url: karp,
    name: "Карп",
    pair: "Рыба"
  },
  {
    url: utka,
    name: "Утка",
    pair: "Птица"
  }
];

const secondBlocksFinal: DrawLineBlock[] = [
  {
    name: "Рыба",
    pair: "Карп"
  },
  {
    name: "Птица",
    pair: "Утка"
  },
  {
    name: "Зверь",
    pair: "Волк"
  }
];

const Theme3Task2 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Рассмотри животных. К какой группе относится животное?'
      description='Нажимай на животное и группу'
      successIcon='rus-2'
    >
      <QuizMultiDrawLine
        firstColSecond={firstBlocks}
        secondColSecond={secondBlocks}
        firstColThird={firstBlocksNext}
        secondColThird={secondBlocksNext}
        firstColNextFourth={firstBlocksFinal}
        secondCoLNextFourth={secondBlocksFinal}
      />
    </QuizWrapper>
  );
};

export default Theme3Task2;
