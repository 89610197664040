import React, { useEffect, useRef, useState } from "react";
import styles from "./DropContainer.module.scss";
import clsx from "clsx";
import { useDragAndDropContext } from "~shared/hooks/useDragAndDropContext";

const filterUniqueValues = (map: Map<number, number>) => {
  const uniqueValuesSet = new Set();
  const keysToRemove: number[] = [];

  map.forEach((value, key) => {
    if (uniqueValuesSet.has(value)) {
      keysToRemove.push(key);
    } else {
      uniqueValuesSet.add(value);
    }
  });

  keysToRemove.forEach((key) => {
    map.delete(key);
  });
};

type Props = {
  id: number;
  className?: string;
  style?: React.CSSProperties;
  canDrop?: boolean;
};

export const DropContainer: React.FC<Props> = ({ id, className, style, canDrop = true }) => {
  const { dragPosition, setDragPosition, setDropPosition, results, dragId } =
    useDragAndDropContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const [rectPosition, setRectPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    if (!canDrop) return;
    const { width = 0, height = 0 } = ref.current?.getBoundingClientRect() || {};

    if (dragPosition && dragPosition.x !== 0 && dragPosition.y !== 0) {
      setDragPosition(null);

      const isIntersection =
        (dragPosition.x - rectPosition.x) / width > -0.5 &&
        (dragPosition.x - rectPosition.x) / width < 0.5 &&
        (dragPosition.y - rectPosition.y) / height > -0.5 &&
        (dragPosition.y - rectPosition.y) / height < 0.5;

      if (isIntersection && (results.size === 0 || !results.has(id))) {
        setDropPosition({ x: rectPosition.x, y: rectPosition.y });
        results.set(id, dragId);
        filterUniqueValues(results);
      }
    }
  }, [dragPosition]);

  useEffect(() => {
    const { x = 0, y = 0 } = ref.current?.getBoundingClientRect() || {};

    setRectPosition({ x, y });
  }, [ref.current]);

  return <div ref={ref} className={clsx(styles.dropContainer, className)} style={style} />;
};
