import React, { RefObject, useEffect, useState } from "react";
import { QuizResult } from "~/shared/components/QuizResult";
import { useQuizContext } from "~/shared/hooks/useQuizContext";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";
import OtpInput from "./components/CustomInput";
import clsx from "clsx";
import styles from "./QuizUnscrambleWord.module.scss";
import { AnswerData } from "~/pages/22/2";

type Props = {
  data: AnswerData;
  containerRef: RefObject<HTMLDivElement>;
};
const arrayCode = [
  ["", "1", "2", "3", "4"],
  ["А", "И", "Р", "П", "Э"],
  ["Б", "Щ", "Т", "У", "А"],
  ["В", "Ю", "М", "К", "Л"],
  ["Г", "Р", "Ж", "О", "Е"]
];

const numbers = ["А1", "В2", "А3", "Г4", "Г1", "Б4", "Б2", "Г3", "А2"];

export const QuizUnscrumbleWord: React.FC<Props> = ({ data, containerRef }) => {
  // const inputRef = useRef<HTMLInputElement>(null);
  const { success, setSuccess } = useQuizContext();
  // const [inputValues] = useState(new Array(data.answer.length).fill(""));
  const [showResult, setShowResult] = useState(false);
  // const [isSafari] = useState(false);
  const [otp, setOtp] = useState("");
  const inputStyle = useAdaptiveValue(styles.inputMobile, styles.inputMobile, styles.input);
  const cellStyle = useAdaptiveValue(
    styles.cellSiblingMobile,
    styles.cellSiblingMobile,
    styles.cellSibling
  );

  const isMobile = useAdaptiveValue(true, true, false);

  const [mobileKey, setMobileKey] = useState(false);

  /*
  const handleInputChange = (index: number, value: string) => {
    const scroll = window.scrollY;
    if (isMobile) window.scrollTo(0, scroll);
    const newInputValues = [...inputValues];
    newInputValues[index] = value.toUpperCase();
    setInputValues(newInputValues);
  }; 
  const focusNextInput = () => {
    const inputs = document.querySelectorAll(".input") as unknown as HTMLInputElement[];
    const currentIndex = Array.from(inputs).findIndex((input) => input.value === "");

    if (currentIndex !== -1 && currentIndex < inputs.length) {
      const el = inputs[currentIndex] as HTMLElement;
      if (el) el.focus();
    }
  };
  const focusPrevInput = () => {
    const inputs = document.querySelectorAll(".input") as unknown as HTMLInputElement[];
    const currentIndex = Array.from(inputs).findIndex((input) => input.value === "") - 1;

    if (currentIndex !== -1 && currentIndex < inputs.length) {
      let str = "";

      inputValues.forEach((letter, index) => {
        if (index == currentIndex) letter = " ";
        str += letter;
      });

      setInputValues(str.split(""));

      const el = inputs[currentIndex] as HTMLElement;
      if (el) el.focus();
    }
  };

  const handleKeyDown = (e: any) => {
    if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("iPhone") > -1) {
      setIsSafari(true);
    }
    setMobileKey(true);
    if (e.key == "Backspace" || e.key == "Delete") {
      focusPrevInput();
    } else {
      focusNextInput();
    }
  };*/
  const handleFinishClick = () => {
    setShowResult(true);

    if (otp.toUpperCase() === data.answer) setTimeout(() => setSuccess(true), 700);
  };
  /*
  useEffect(() => {
    setShowResult(false);
    if (otp.toUpperCase() === data.answer) setTimeout(() => setSuccess(true), 700);
  }, [otp]);*/

  useEffect(() => {
    document.documentElement.style.setProperty("--screenHeight", `${screen.height - 5}px`);

    let isFirstFocus = true;

    const handleFocus = () => {
      if (!containerRef.current) {
        return;
      }
      containerRef.current.classList.add("screenHeight");
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      if (isFirstFocus) {
        window.scrollTo(0, document.body.scrollHeight);
        isFirstFocus = false;
      }
    };

    const handleBlur = () => {
      isFirstFocus = true;
      if (!containerRef.current) {
        return;
      }
      containerRef.current.classList.remove("screenHeight");
    };

    document.addEventListener("focus", handleFocus);
    document.addEventListener("blur", handleBlur);

    return () => {
      document.removeEventListener("focus", handleFocus);
      document.removeEventListener("blur", handleBlur);

      if (!containerRef.current) {
        return;
      }

      containerRef.current.classList.add("screenHeight");
    };
  }, [containerRef]);

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        onFocus={() => {
          setShowResult(false);
          setMobileKey(true);
        }}
        style={{ display: "flex" }}
        className={styles.wrapper}
      >
        <div className={styles.code}>
          <table className={styles.codeTable}>
            <tbody className={styles.bodyTableCode}>
              {arrayCode.map((row, i) => (
                <tr key={i}>
                  {row.map((cell, i) => (
                    <td className={`cellSibling ${styles.td}`} key={i}>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.rowSibling}>
          {numbers.map((el, i) => (
            <div className={cellStyle} key={i}>
              {el}
            </div>
          ))}
        </div>

        <OtpInput
          inputStyle={inputStyle}
          correctLetter={data.answer}
          containerStyle={styles.inputWrapper}
          value={otp}
          onChange={setOtp}
          numInputs={9}
          renderSeparator={<span></span>}
          renderInput={(props) => <input {...props} />}
          showResult={showResult}
        />
      </div>
      <QuizResult
        errorVisible={showResult && !(otp.toUpperCase() === data.answer)}
        buttonOnClick={handleFinishClick}
        buttonVisible={!success && !showResult}
      />

      <div className={clsx({ [styles.mobileInput]: mobileKey && isMobile })}></div>
    </div>
  );
};
