import React from "react";
export const Mash2: React.FC = () => {
  return (
    <svg
      width='133'
      height='139'
      viewBox='0 0 133 139'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M66.8154 132.677C103.266 132.677 132.815 103.128 132.815 66.6768C132.815 30.226 103.266 0.676819 66.8154 0.676819C30.3646 0.676819 0.81543 30.226 0.81543 66.6768C0.81543 103.128 30.3646 132.677 66.8154 132.677Z'
        fill='#1CB0C1'
      />
      <path
        d='M33.5153 110.477C32.6153 110.977 19.9152 119.577 16.2152 125.877C14.0152 129.677 14.1152 136.477 18.6152 138.377C21.7152 139.677 28.3153 137.677 31.5153 134.977C39.6153 127.977 43.6152 121.777 43.6152 121.777C43.3152 114.977 37.3153 106.277 33.5153 110.477Z'
        fill='#F4C18F'
      />
      <path
        d='M67.9158 107.077C60.5158 106.177 46.0158 104.077 42.2158 112.577C40.4158 116.577 39.6158 121.877 38.2158 126.177C46.9158 130.377 56.6157 132.677 66.8157 132.677C72.8157 132.677 78.7157 131.877 84.3157 130.277C85.2157 127.377 85.8159 124.477 85.0159 121.377C83.4159 115.177 74.1158 107.777 67.9158 107.077Z'
        fill='#1A9A67'
      />
      <mask
        id='mask0_958_24280'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='38'
        y='106'
        width='47'
        height='27'
      >
        <path
          d='M74.1153 131.777C75.0153 131.577 76.0154 131.177 76.9154 130.777C78.6154 130.077 80.1154 128.977 81.9154 128.477C82.6154 128.277 83.4154 128.077 83.9154 127.577C84.2154 127.277 84.3154 126.877 84.4154 126.477C85.5154 121.977 84.9154 116.877 80.9154 114.077C76.8154 111.277 73.1155 107.577 68.0155 106.977C60.6155 106.077 46.1153 103.977 42.3153 112.477C41.0153 115.277 39.5154 117.977 38.7154 120.877C37.9154 123.977 40.7154 125.377 43.2154 126.677C46.3154 128.377 49.6154 129.877 52.9154 131.177C55.8154 132.277 59.3154 132.077 62.4154 132.277C66.2154 132.677 70.3153 132.777 74.1153 131.777Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_958_24280)'>
        <g opacity='0.3'>
          <path
            d='M70.4153 129.777C64.1153 129.777 57.7153 129.477 50.9153 128.977C29.9153 127.377 8.61523 123.277 -9.38477 119.577L-8.58472 115.777C23.3153 122.377 39.6153 124.177 51.2153 125.177C73.4153 126.877 92.8152 125.777 110.615 121.677L111.515 125.477C98.7154 128.377 85.1153 129.777 70.4153 129.777Z'
            fill='#47B7B2'
          />
        </g>
      </g>
      <mask
        id='mask1_958_24280'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='38'
        y='106'
        width='47'
        height='27'
      >
        <path
          d='M74.1153 131.777C75.0153 131.577 76.0154 131.177 76.9154 130.777C78.6154 130.077 80.1154 128.977 81.9154 128.477C82.6154 128.277 83.4154 128.077 83.9154 127.577C84.2154 127.277 84.3154 126.877 84.4154 126.477C85.5154 121.977 84.9154 116.877 80.9154 114.077C76.8154 111.277 73.1155 107.577 68.0155 106.977C60.6155 106.077 46.1153 103.977 42.3153 112.477C41.0153 115.277 39.5154 117.977 38.7154 120.877C37.9154 123.977 40.7154 125.377 43.2154 126.677C46.3154 128.377 49.6154 129.877 52.9154 131.177C55.8154 132.277 59.3154 132.077 62.4154 132.277C66.2154 132.677 70.3153 132.777 74.1153 131.777Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_958_24280)'>
        <g opacity='0.3'>
          <path
            d='M88.6152 121.777C77.7152 121.777 66.3152 120.877 54.1152 118.977C33.2152 115.777 12.3152 110.177 -5.38477 105.177L-4.28467 101.377C27.0153 110.377 43.1153 113.377 54.7153 115.077C76.7153 118.377 96.1153 118.677 114.215 115.977L114.815 119.877C106.315 121.077 97.7152 121.777 88.6152 121.777Z'
            fill='#47B7B2'
          />
        </g>
      </g>
      <path
        d='M88.1154 123.277C84.2154 114.377 80.4154 113.577 75.6154 116.077C71.4154 118.177 71.7155 124.977 73.2155 129.377C73.3155 130.077 73.7154 131.177 74.1154 132.277C79.7154 131.677 85.2154 130.277 90.3154 128.377C89.5154 126.077 88.7154 124.277 88.1154 123.277Z'
        fill='#FBC18F'
      />
      <path
        d='M36.4159 106.277C35.5159 106.777 20.0159 116.677 15.5159 127.677C13.9159 131.577 14.4159 135.477 16.2159 137.777C18.9159 139.077 25.0159 139.377 27.9159 136.077C35.4159 128.377 45.4159 111.477 45.4159 111.477C45.3159 104.477 40.2159 102.077 36.4159 106.277Z'
        fill='#F4C18F'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.6158 90.9768C50.6158 90.9768 50.3158 106.477 50.6158 108.877C51.1158 112.077 55.9158 114.177 62.1158 112.177C65.1158 111.177 67.4159 109.577 67.5159 107.677C67.6159 106.177 66.1159 98.9768 66.7159 92.1768L50.6158 90.9768Z'
        fill='#F4C18F'
      />
      <path
        d='M78.6155 86.9769C73.3155 90.6769 56.8156 56.4769 49.5156 39.2769C49.4156 39.1769 49.4155 38.8769 49.4155 38.7769L50.6155 29.5768C50.6155 29.1768 50.8156 28.9768 51.2156 28.8768C61.0156 25.9768 85.0155 27.5769 91.6155 39.9769C100.015 55.5769 89.4155 79.4769 78.6155 86.9769Z'
        fill='#FBE6AA'
      />
      <path
        d='M44.1155 22.4768C44.2155 15.0768 50.3156 9.17683 57.7156 9.37683C65.1156 9.47683 71.0155 15.5768 70.8155 22.9768C70.7155 30.3768 64.6156 36.2768 57.2156 36.0768C49.9156 35.9768 44.0155 29.8768 44.1155 22.4768Z'
        fill='#FBE6AA'
      />
      <path
        d='M32.0157 66.5768C32.3157 65.5768 32.7157 64.8768 33.0157 64.1768C33.1157 63.9768 33.2155 63.7768 33.3155 63.5768C34.1155 61.8768 34.9156 59.8768 34.9156 54.0768C34.9156 51.0768 36.1155 47.8768 38.1155 44.8768C40.1155 41.8768 42.9155 38.9768 46.1155 36.4768C52.5155 31.5768 60.7155 28.3768 67.8155 29.4768C72.2155 29.5768 75.6156 31.0768 78.2156 33.3768C80.8156 35.6768 82.6155 38.7768 83.8155 42.1768C86.0155 48.1768 86.6155 55.1768 87.1155 60.9768C87.2155 61.6768 87.2155 62.4768 87.3155 63.0768C91.2155 62.9768 93.9157 64.1768 95.5157 65.9768C97.1157 67.8768 97.5155 70.3768 96.8155 72.6768C96.1155 74.9768 94.5157 77.1768 92.0157 78.3768C89.6157 79.5768 86.5155 79.8768 82.8155 78.5768C79.2155 87.6768 73.4156 92.1768 67.2156 95.2768C61.0156 98.3768 45.0156 97.3768 42.4156 95.3768C39.7156 93.3768 37.4156 90.9768 34.7156 86.2768C32.1156 81.4768 30.3155 76.1768 30.8155 71.6768C31.2155 69.3768 31.5157 67.7768 32.0157 66.5768Z'
        fill='#F4C18F'
      />
      <path
        d='M51.8157 81.2768C51.8157 81.2768 49.9159 85.2768 46.0159 84.6768C41.6159 83.6768 41.2158 79.7768 41.2158 78.9768L51.8157 81.2768Z'
        fill='#7C160F'
      />
      <path
        d='M47.9155 83.3768C48.2155 83.5768 48.6154 83.9768 48.8154 84.1768C48.1154 84.6768 47.0154 84.8768 45.8154 84.5768C44.5154 84.2768 43.7154 83.5768 43.3154 82.7768C44.7154 82.3768 46.4155 82.4768 47.9155 83.3768Z'
        fill='#E4434D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M59.6154 68.9768C59.2154 73.3768 63.7155 73.4768 64.9155 69.1768C66.4155 62.8768 60.1154 64.7768 59.6154 68.9768Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M59.6158 68.7768C59.9158 66.8768 61.2157 65.4768 62.8157 65.1768C64.0157 65.3768 64.8158 66.4768 64.1158 69.1768C63.5158 70.9768 62.4158 72.0768 61.4158 72.2768C60.2158 72.1768 59.3158 70.6768 59.6158 68.7768Z'
        fill='#7C1612'
      />
      <path
        d='M63.4155 67.4768C63.4155 66.9768 63.7155 66.4768 64.1155 66.4768C64.6155 66.4768 65.0156 66.8768 65.0156 67.3768C65.0156 67.8768 64.7154 68.3768 64.3154 68.3768C63.9154 68.3768 63.5155 67.9768 63.4155 67.4768Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.1155 63.0768C37.7155 67.2768 42.0155 67.3768 43.3155 63.2768C44.7155 57.1768 38.5155 58.9768 38.1155 63.0768Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.1158 62.8768C38.4158 61.0768 39.7159 59.6768 41.2159 59.3768C42.3159 59.5768 43.1159 60.5768 42.5159 63.2768C41.9159 64.9768 40.9158 66.0768 39.9158 66.2768C38.7158 66.1768 37.8158 64.6768 38.1158 62.8768Z'
        fill='#7C1612'
      />
      <path
        d='M41.7158 61.6768C41.7158 61.1768 42.0158 60.7768 42.4158 60.6768C42.8158 60.6768 43.2157 60.9768 43.3157 61.4768C43.3157 61.9768 43.0157 62.3768 42.6157 62.4768C42.2157 62.4768 41.8158 62.1768 41.7158 61.6768Z'
        fill='white'
      />
      <path
        d='M42.9158 73.3768C43.0158 73.3768 43.1158 73.2768 43.2158 73.1768C43.3158 72.9768 43.2157 72.8768 43.1157 72.7768C42.5157 72.4768 42.3157 72.0768 42.3157 71.5768C42.3157 70.8768 42.9158 70.0768 43.7158 69.7768C43.9158 69.6768 43.9158 69.5768 43.9158 69.3768C43.8158 69.1768 43.7159 69.1768 43.5159 69.1768C42.5159 69.5768 41.7158 70.4768 41.7158 71.4768C41.7158 72.2768 42.1157 72.8768 42.8157 73.2768C42.8157 73.3768 42.9158 73.3768 42.9158 73.3768Z'
        fill='#8E3821'
      />
      <path
        d='M49.2158 74.9768C50.0158 75.0768 50.8158 73.7768 50.2158 73.0768L49.2158 74.9768Z'
        fill='#F4C18F'
      />
      <path
        d='M48.0154 74.0768C48.4154 74.0768 48.9152 73.7768 49.1152 73.3768C49.4152 72.7768 49.4152 72.1768 49.1152 71.7768C49.0152 71.6768 48.8153 71.5768 48.7153 71.7768C48.6153 71.8768 48.5153 72.0768 48.7153 72.1768C48.9153 72.4768 48.8153 72.8768 48.7153 73.1768C48.6153 73.3768 48.3154 73.6768 48.0154 73.5768C47.8154 73.5768 47.7152 73.6768 47.6152 73.8768C47.6152 74.0768 47.7153 74.1768 47.9153 74.2768C47.9153 74.0768 48.0154 74.0768 48.0154 74.0768Z'
        fill='#8E3821'
      />
      <path
        d='M67.5156 61.0768C67.6156 61.0768 67.8155 60.9768 67.9155 60.8768C68.1155 60.6768 68.1155 60.2768 67.9155 60.0768C66.7155 58.8768 65.4155 58.2769 63.6155 58.2769C63.3155 58.2769 63.0156 58.5768 63.0156 58.8768C63.0156 59.1768 63.3155 59.4769 63.6155 59.4769C65.0155 59.4769 66.1156 59.8769 67.0156 60.9769C67.2156 60.9769 67.3156 61.0768 67.5156 61.0768Z'
        fill='#7C1612'
      />
      <path
        d='M43.6154 54.2768C43.8154 54.2768 44.0154 54.1768 44.1154 53.9768C44.2154 53.6768 44.1154 53.3768 43.9154 53.1768C42.5154 52.3768 40.6154 51.7768 38.1154 52.5768C37.8154 52.6768 37.6155 52.9768 37.7155 53.2768C37.8155 53.5768 38.1154 53.7768 38.4154 53.6768C40.5154 52.9768 42.0153 53.4768 43.3153 54.1768C43.4153 54.2768 43.5154 54.2768 43.6154 54.2768Z'
        fill='#7C1612'
      />
      <path
        d='M88.1152 75.0768H88.2153C91.5153 73.8768 93.2153 71.0768 92.7153 69.1768C92.4153 67.9768 91.3153 67.3768 89.7153 67.6768C89.5153 67.6768 89.4153 67.8768 89.4153 67.9768C89.4153 68.1768 89.6153 68.2768 89.7153 68.2768C91.0153 68.0768 91.8154 68.4768 92.0154 69.3768C92.3154 70.6768 91.1152 73.3768 87.8152 74.5768C87.6152 74.6768 87.6152 74.7768 87.6152 74.9768C87.9152 74.9768 88.0152 75.0768 88.1152 75.0768Z'
        fill='#8E3821'
      />
      <g opacity='0.4'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M39.0159 70.7769C38.7159 70.8769 38.4158 70.5768 38.3158 70.3768C38.2158 70.0768 38.5159 69.7768 38.7159 69.6768C38.9159 69.5768 39.3159 69.8768 39.4159 70.0768C39.5159 70.4768 39.3159 70.7769 39.0159 70.7769Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M36.4156 70.3768C36.2156 70.4768 35.8157 70.1768 35.7157 69.9768C35.6157 69.6768 35.9156 69.3768 36.1156 69.2768C36.3156 69.1768 36.7155 69.4768 36.8155 69.6768C36.9155 69.9768 36.8156 70.2768 36.4156 70.3768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M33.8155 69.3768C33.5155 69.4768 33.2156 69.1768 33.1156 68.9768C33.0156 68.6768 33.3157 68.3768 33.5157 68.2768C33.8157 68.1768 34.1157 68.4768 34.2157 68.6768C34.4157 69.0768 34.3155 69.2768 33.8155 69.3768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M34.5154 71.3768C34.3154 71.4768 34.0153 71.2768 33.9153 71.0768C33.8153 70.7768 34.0153 70.5768 34.2153 70.4768C34.5153 70.3768 34.7152 70.5768 34.8152 70.7768C35.0152 71.0768 34.9154 71.2768 34.5154 71.3768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M37.6157 72.0769C37.3157 72.1769 37.1159 71.9769 37.0159 71.7769C36.9159 71.4769 37.1157 71.2769 37.3157 71.1769C37.6157 71.0769 37.8158 71.2769 37.9158 71.4769C38.0158 71.7769 37.9157 71.9769 37.6157 72.0769Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M53.6152 74.4768C53.8152 74.7768 54.1153 74.8768 54.4153 74.6768C54.7153 74.4768 54.8152 74.1768 54.6152 73.8768C54.4152 73.5768 54.1152 73.4768 53.8152 73.6768C53.7152 73.8768 53.7152 74.2768 53.6152 74.4768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56.0157 75.7769C56.2157 76.0769 56.5155 76.1769 56.8155 75.9769C57.1155 75.7769 57.2157 75.4769 57.0157 75.1769C56.8157 74.8769 56.5156 74.7769 56.2156 74.9769C55.9156 75.1769 55.8157 75.4769 56.0157 75.7769Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M58.5156 76.6768C58.7156 76.9768 59.0154 77.0768 59.3154 76.8768C59.6154 76.6768 59.7156 76.3768 59.5156 76.0768C59.3156 75.7768 59.0156 75.6768 58.7156 75.8768C58.5156 76.0768 58.5156 76.4768 58.5156 76.6768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M61.8152 76.0768C62.0152 76.3768 62.3153 76.4768 62.6153 76.2768C62.9153 76.0768 63.0152 75.7768 62.8152 75.4768C62.6152 75.1768 62.3154 75.0768 62.0154 75.2768C61.8154 75.4768 61.6152 75.7768 61.8152 76.0768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M61.1154 77.8767C61.2154 78.1767 61.4155 78.1767 61.7155 78.0767C62.0155 77.8767 62.0155 77.6767 61.9155 77.3767C61.7155 77.0767 61.5155 77.0768 61.2155 77.1768C60.9155 77.3768 60.9154 77.5767 61.1154 77.8767Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M57.0153 77.6768C57.1153 77.9768 57.3152 77.9768 57.6152 77.8768C57.9152 77.6768 57.9151 77.4768 57.8151 77.1768C57.6151 76.8768 57.4152 76.8768 57.1152 76.9768C56.9152 77.1768 56.8153 77.3768 57.0153 77.6768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.1154 76.4768C54.3154 76.7768 54.5154 76.7768 54.8154 76.6768C55.1154 76.4768 55.1156 76.2768 55.0156 75.9768C54.8156 75.6768 54.6154 75.6768 54.3154 75.7768C54.1154 75.8768 54.0154 76.1768 54.1154 76.4768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M59.1158 79.0768C59.2158 79.3768 59.4159 79.3768 59.7159 79.2768C60.0159 79.0768 60.0159 78.8768 59.9159 78.5768C59.7159 78.2768 59.5159 78.2768 59.2159 78.3768C59.0159 78.3768 58.9158 78.6768 59.1158 79.0768Z'
          fill='#E2401C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M64.1152 77.4768C64.3152 77.7768 64.5152 77.7768 64.8152 77.6768C65.1152 77.4768 65.1154 77.2768 65.0154 76.9768C64.9154 76.7768 64.7153 76.6768 64.4153 76.7768C64.3153 76.9768 64.3152 77.1768 64.1152 77.4768Z'
          fill='#E2401C'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.9155 35.7768C30.5155 43.8768 28.5156 43.5768 28.5156 43.5768C28.5156 43.5768 34.6156 48.7768 42.7156 48.8768C44.9156 48.1768 50.3154 47.2768 51.3154 43.4768C51.3154 46.5768 49.8154 48.5768 47.3154 50.4768C57.5154 56.4768 73.0156 56.7768 73.0156 56.3768C73.0156 56.3768 80.4156 56.6768 81.7156 52.9768L76.7156 61.8768C81.3156 62.6768 82.3154 64.0768 82.3154 64.0768C82.3154 64.0768 81.5155 73.6768 78.9155 78.4768C78.9155 78.4768 86.4156 73.1768 89.5156 58.7768C94.3156 43.2768 80.7155 28.8768 69.4155 28.5768C69.7155 27.8768 47.4155 22.7768 36.9155 35.7768Z'
        fill='#FBE6AA'
      />
      <path
        d='M82.8156 39.9768C83.0156 31.6768 89.8156 25.0768 98.1156 25.2768C106.416 25.4768 113.016 32.2768 112.916 40.5768C112.716 48.8768 105.916 55.4768 97.6156 55.3768C89.3156 55.0768 82.7156 48.2768 82.8156 39.9768Z'
        fill='#FBE6AA'
      />
      <path
        d='M96.8154 55.5768C96.9154 55.5768 97.1154 55.4768 97.1154 55.3768C97.1154 55.1768 97.0155 55.0768 96.9155 54.9768C90.8155 53.5768 88.2154 49.9768 87.1154 47.1768C85.4154 42.8768 86.2155 38.2768 87.9155 35.7768C88.0155 35.6768 88.0154 35.4768 87.8154 35.3768C87.7154 35.2768 87.5155 35.2768 87.4155 35.4768C85.6155 38.0768 84.8156 42.9768 86.5156 47.4768C87.6156 50.2768 90.3154 54.0768 96.8154 55.5768Z'
        fill='#8E3821'
      />
      <path
        d='M97.7158 52.0768C97.8158 52.0768 98.0159 51.9768 98.0159 51.8768C98.0159 51.6768 97.9157 51.5768 97.8157 51.4768C95.1157 50.8768 93.2159 49.4768 92.0159 47.3768C90.5159 44.6768 90.6158 41.1768 91.2158 39.5768C91.3158 39.3768 91.2159 39.2768 91.0159 39.1768C90.8159 39.0768 90.7157 39.1768 90.6157 39.3768C90.0157 40.9768 89.8159 44.6768 91.5159 47.6768C92.7159 49.9768 94.8158 51.4768 97.7158 52.0768Z'
        fill='#8E3821'
      />
      <path
        d='M85.7156 64.4769C85.9156 64.4769 86.0156 64.3768 86.0156 64.1768C86.3156 60.9768 86.3154 57.9768 86.3154 56.0768C86.3154 55.8768 86.2156 55.7769 86.0156 55.7769C85.8156 55.7769 85.7156 55.8768 85.7156 56.0768C85.7156 57.9768 85.7155 60.8768 85.4155 64.0768C85.4155 64.3768 85.5156 64.4769 85.7156 64.4769Z'
        fill='#8E3821'
      />
      <path
        d='M47.5159 95.8768H47.6157L50.5159 94.7768C50.7159 94.6768 50.7158 94.5768 50.7158 94.3768C50.6158 94.1768 50.5157 94.1768 50.3157 94.1768L47.4158 95.2768C47.2158 95.3768 47.2158 95.4768 47.2158 95.6768C47.3158 95.8768 47.4159 95.8768 47.5159 95.8768Z'
        fill='#8E3821'
      />
      <path
        d='M49.2156 100.077H49.3154L52.2156 98.9768C52.4156 98.8768 52.4155 98.7768 52.4155 98.5768C52.3155 98.3768 52.2156 98.3768 52.0156 98.3768L49.1155 99.4768C48.9155 99.5768 48.9155 99.6768 48.9155 99.8768C48.9155 99.9768 49.1156 100.077 49.2156 100.077Z'
        fill='#8E3821'
      />
      <path
        d='M51.3154 103.677H51.4155L53.4155 102.977C53.6155 102.877 53.6155 102.777 53.6155 102.577C53.5155 102.377 53.4156 102.377 53.2156 102.377L51.2156 103.077C51.0156 103.177 51.0156 103.277 51.0156 103.477C51.1156 103.577 51.2154 103.677 51.3154 103.677Z'
        fill='#8E3821'
      />
      <path
        d='M47.8155 25.1768H47.9156C48.1156 25.0768 48.1155 24.9768 48.1155 24.7768C46.9155 21.8768 47.2157 19.1768 49.0157 16.8768C50.3157 15.0768 52.1155 14.0768 52.1155 14.0768C52.2155 13.9768 52.3156 13.7768 52.2156 13.6768C52.1156 13.5768 51.9155 13.4768 51.8155 13.5768C51.7155 13.5768 50.0157 14.5768 48.5157 16.4768C47.2157 18.2768 45.9157 21.1768 47.5157 25.0768C47.6157 25.0768 47.7155 25.1768 47.8155 25.1768Z'
        fill='#8E3821'
      />
      <path
        d='M49.9155 51.0768C54.7155 50.3768 55.3154 45.7768 55.3154 45.6768C55.3154 45.4768 55.2156 45.3768 55.0156 45.3768C54.8156 45.3768 54.7156 45.4768 54.7156 45.6768C54.7156 45.8768 54.1154 49.8768 49.8154 50.4768C49.6154 50.4768 49.5156 50.6768 49.5156 50.7768C49.6156 50.8768 49.8155 51.0768 49.9155 51.0768Z'
        fill='#8E3821'
      />
      <path
        d='M32.3154 44.7768H32.4155C35.4155 43.6768 37.1155 41.6768 37.1155 41.6768C37.2155 41.5768 37.2155 41.3768 37.1155 41.2768C37.0155 41.1768 36.8156 41.1768 36.7156 41.2768C36.7156 41.2768 35.1156 43.1768 32.2156 44.1768C32.0156 44.2768 32.0156 44.3768 32.0156 44.5768C32.0156 44.6768 32.1154 44.7768 32.3154 44.7768Z'
        fill='#8E3821'
      />
      <path
        d='M52.3157 106.477C55.3157 106.577 58.1158 105.177 60.4158 103.277C62.7158 101.377 64.5157 98.9768 66.3157 96.6768C64.9157 98.4768 61.5159 99.6768 59.5159 100.577C57.0159 101.677 54.3158 102.177 51.7158 102.977C51.9158 104.177 52.0157 105.377 51.8157 106.677'
        fill='#F4836A'
      />
      <path
        d='M44.4155 95.6769C44.7155 95.6769 41.5155 93.5769 41.4155 91.6769C41.3155 89.3769 37.9155 86.2769 36.1155 86.5769C35.6155 86.6769 38.0155 92.0769 37.9155 95.0769C37.9155 98.4769 37.3155 100.077 37.6155 100.277C38.3155 100.577 44.4155 95.6769 44.4155 95.6769Z'
        fill='#F4C18F'
      />
      <path
        d='M44.0155 95.6768C44.0155 95.6768 43.9153 95.5768 43.8153 95.5768C43.0153 94.7768 41.2153 93.2768 41.1153 91.7768C41.0153 90.6768 40.1154 89.1768 38.7154 88.0768C37.8154 87.3768 37.0153 86.9768 36.3153 86.8768C36.4153 87.2768 36.6154 88.1768 36.9154 88.9768C37.3154 90.2768 37.8155 91.7768 38.0155 93.2768C37.8155 92.7768 37.5153 92.2768 37.1153 91.8768C36.9153 90.9768 36.6153 89.9768 36.3153 89.1768C35.7153 87.2768 35.6153 86.7768 35.8153 86.4768C35.9153 86.3768 36.0153 86.2768 36.1153 86.2768C36.9153 86.1768 38.0154 86.6768 39.2154 87.5768C40.3154 88.4768 41.7153 90.1768 41.8153 91.6768C41.9153 92.8768 43.6153 94.3768 44.3153 94.9768C44.8153 95.3768 44.9153 95.4768 44.8153 95.7768C44.8153 95.8768 44.7153 95.8768 44.6153 95.9768C44.3153 96.1768 42.9155 97.2768 41.5155 98.3768C40.3155 99.2768 39.4154 99.8768 38.9154 100.177C38.9154 99.9768 38.9154 99.6768 38.9154 99.4768C40.1154 98.6768 42.1155 97.1768 44.0155 95.6768Z'
        fill='#8E3821'
      />
      <path
        d='M55.8153 88.9768C56.5153 88.1768 56.3155 86.8768 55.0155 87.0768C53.7155 87.2768 47.4155 91.5768 46.5155 91.9768C44.0155 93.0768 37.9154 96.1768 37.9154 96.1768C36.4154 103.377 35.0153 111.277 36.3153 111.777C39.7153 113.277 41.1154 112.277 41.1154 112.277C41.1154 112.277 46.7155 113.177 53.0155 106.477C54.7155 104.377 53.2155 102.577 53.2155 102.577C54.3155 99.7768 52.5155 98.3768 52.5155 98.3768C52.5155 98.3768 53.9153 94.2768 50.3153 94.3768C50.4153 94.4768 55.1153 89.8768 55.8153 88.9768Z'
        fill='#F4C18F'
      />
      <path
        d='M51.1152 94.2768C51.8152 94.3768 52.3153 94.6768 52.7153 95.1768C53.5153 96.2768 53.1153 97.8768 53.0153 98.4768C53.4153 98.8768 54.6153 100.377 53.7153 102.677C54.0153 103.177 54.8152 104.777 53.6152 106.477C53.4152 106.377 53.1152 106.377 52.9152 106.377C54.4152 104.577 53.2152 102.877 53.1152 102.877C53.0152 102.777 53.0152 102.677 53.1152 102.577C54.1152 100.077 52.6153 98.7768 52.5153 98.7768C52.4153 98.6768 52.3152 98.5768 52.4152 98.4768C52.4152 98.4768 53.0151 96.5768 52.3151 95.5768C52.0151 95.0768 51.4153 94.8768 50.5153 94.8768C50.4153 94.8768 50.3153 94.7768 50.2153 94.6768C50.2153 94.5768 50.2151 94.3768 50.3151 94.3768C50.3151 94.3768 55.0153 89.6768 55.7153 88.8768C56.0153 88.4768 56.1153 88.0768 56.0153 87.7768C55.9153 87.5768 55.6153 87.4768 55.2153 87.4768C54.4153 87.5768 49.3151 90.4768 46.3151 92.2768C44.3151 93.4768 43.1151 94.0768 42.8151 94.2768C42.6151 94.3768 42.5152 94.2768 42.4152 94.0768C42.3152 93.8768 42.4152 93.7768 42.6152 93.6768C42.9152 93.5768 44.3153 92.6768 46.0153 91.7768C50.5153 89.1768 54.3152 87.0768 55.1152 86.8768C55.7152 86.7768 56.3153 86.9768 56.5153 87.4768C56.8153 87.9768 56.6153 88.6768 56.2153 89.2768C55.4153 89.8768 52.3152 93.0768 51.1152 94.2768Z'
        fill='#8E3821'
      />
      <g opacity='0.5'>
        <g opacity='0.5' filter='url(#filter0_f_958_24280)'>
          <path
            d='M59.0156 74.5768C61.9156 75.3768 64.6156 74.4769 63.7156 77.7769C62.8156 81.0769 59.8156 83.0769 57.0156 82.3769C54.1156 81.5769 52.5155 78.3768 53.4155 75.0768C54.2155 71.7768 56.2156 73.8768 59.0156 74.5768Z'
            fill='#EF5331'
          />
        </g>
      </g>
      <g opacity='0.5'>
        <g opacity='0.5' filter='url(#filter1_f_958_24280)'>
          <path
            d='M37.0156 68.6768C39.9156 69.4768 42.6156 68.5768 41.7156 71.8768C40.8156 75.1768 37.8156 77.1768 35.0156 76.4768C32.1156 75.6768 30.5155 72.4768 31.4155 69.1768C32.2155 65.8768 34.2156 67.9768 37.0156 68.6768Z'
            fill='#EF5331'
          />
        </g>
      </g>
      <g opacity='0.5' filter='url(#filter2_f_958_24280)'>
        <path
          d='M44.7156 69.2769C45.3156 69.1769 46.0157 69.1769 46.5157 69.3769C47.1157 69.5769 47.6156 70.0769 47.7156 70.6769C47.9156 71.5769 47.3155 72.5769 46.6155 73.2769C45.8155 74.0769 44.7155 74.5769 43.6155 74.4769C43.3155 74.4769 43.0155 74.3769 42.8155 74.1769C41.2155 72.9769 41.8156 71.1769 42.9156 70.0769C43.4156 69.7769 44.1156 69.4769 44.7156 69.2769Z'
          fill='#EF5331'
        />
      </g>
      <g opacity='0.3'>
        <path
          d='M44.2156 71.3768C44.9156 72.3768 46.7154 71.1768 45.8154 69.7768C45.0154 68.1768 43.1156 69.9768 44.2156 71.3768Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_f_958_24280'
          x='50.165'
          y='70.2723'
          width='16.7266'
          height='15.2439'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24280' />
        </filter>
        <filter
          id='filter1_f_958_24280'
          x='28.165'
          y='64.3723'
          width='16.7266'
          height='15.2439'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24280' />
        </filter>
        <filter
          id='filter2_f_958_24280'
          x='38.8364'
          y='66.2112'
          width='11.918'
          height='11.2783'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24280' />
        </filter>
      </defs>
    </svg>
  );
};
