import React, { useEffect, useState } from "react";
import LetterRow from "./components/LetterRow/LetterRow";
import styles from "./QuizProverb.module.scss";
import { QuizResult } from "~/shared/components/QuizResult";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type Props = {
  rows: {
    letters: string[];
    correctWord: string;
  }[];
};

export const QuizProverb: React.FC<Props> = ({ rows }) => {
  const [isWin, setIsWin] = useState<boolean[]>(Array(rows.length).fill(false));
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showResult, setShowResult] = useState(false);
  const { setSuccess } = useQuizContext();
  const gameStyle = useAdaptiveValue(styles.gameMobile, styles.gameMobile, styles.game);

  const success = showResult && isWin.every((el) => el === true);
  const error = showResult && isWin.some((el) => el === false);

  const isWinHandle = (index: number, value: boolean) => {
    const newResults = isWin.map((el, i) => {
      if (i === index) {
        return value;
      } else return el;
    });
    setIsWin(newResults);
    if (!selectedRows.some((el) => el === index)) {
      setSelectedRows([...selectedRows, index]);
    }
    if (showResult) setShowResult(false);
  };

  const handleCheckAllRows = () => {
    setShowResult(true);
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(success), 700);
      return;
    }
    setSuccess(success);
  }, [success]);

  return (
    <div className={styles.gameWr}>
      <div className={gameStyle}>
        {rows.map((row, index) => (
          <LetterRow
            key={index}
            letters={row.letters}
            showResult={showResult}
            correctWord={row.correctWord}
            setIsWin={(value) => isWinHandle(index, value)}
          />
        ))}
      </div>
      <div className={styles.result}>
        <QuizResult
          errorVisible={error}
          buttonValue='Проверить'
          buttonOnClick={handleCheckAllRows}
          buttonVisible={!showResult}
        />
      </div>
    </div>
  );
};
