import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import img from "~/shared/assets/images/5/rebus.jpg";
import korabl from "~/shared/assets/images/5/korabl.jpg";
import lodka from "~/shared/assets/images/5/lodka.jpg";
import plot from "~/shared/assets/images/5/plot.jpg";
import ladia from "~/shared/assets/images/5/ladia.jpg";
import { ImageRebus } from "~/shared/components/ImageRebus";

const Theme5Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Корабль",
      image: korabl
    },
    {
      name: "Плот",
      image: plot
    },
    {
      name: "Ладья",
      image: ladia
    },
    {
      name: "Лодка",
      image: lodka
    }
  ];
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Разгадай ребус. Отметь рисунок, на котором изображена отгадка.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <ImageRebus url={img} />
      <QuizChooseItem
        type='img'
        items={items}
        correctItem='Ладья'
        widthItem={111}
        taskType='rebus'
      />
    </QuizWrapper>
  );
};

export default Theme5Task2;
