import React, { useState } from "react";
import { JigsawPuzzle } from "react-jigsaw-puzzle/lib";
import "react-jigsaw-puzzle/lib/jigsaw-puzzle.css";
import styles from "./QuizPuzzle.module.scss";
import { useQuizContext } from "~/shared/hooks/useQuizContext";
import { QuizResult } from "../QuizResult";
import "./quizPuzzle.css";

type Props = {
  img: string;
};

export const QuizPuzzle: React.FC<Props> = ({ img }) => {
  const { setSuccess } = useQuizContext();
  const [isWin, setIsWin] = useState(false);

  const set = () => {
    setIsWin(true);
    setTimeout(() => setSuccess(true), 700);
  };
  const handleFinishClick = () => {
    if (isWin) setSuccess(true);
  };

  window.onload = function () {
    const puzzleParts = document.querySelectorAll(".jigsaw-puzzle__piece");
    puzzleParts.forEach((part) => {
      part.addEventListener("mouseDown", (e) => {
        e.stopPropagation();
      });
    });
  };

  return (
    <>
      <div
        className={styles.puzzle}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <JigsawPuzzle imageSrc={img} rows={3} columns={3} onSolved={set} />
      </div>
      <QuizResult buttonOnClick={handleFinishClick} buttonVisible={false} />
    </>
  );
};
