import React from "react";
import styles from "./../../QuizTimeLine.module.scss";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";
// import { useWindowSize } from "~/shared/hooks/useWindowSize";

type Props = {
  title?: string;
  imgUrl?: string;
  id: string | number;
  positionY: number;
  positionX: number;
};

export const DraggableWrapper: React.FC<Props> = ({ title, imgUrl, id, positionY, positionX }) => {
  const { setNodeRef, transform, listeners, attributes } = useDraggable({
    id: String(id),
    data: [String(id)]
  });

  const draggableItemImageStyle = useAdaptiveValue(
    styles.draggableItemImageMobile,
    styles.draggableItemImageMobile,
    styles.draggableItemImage
  );
  const draggableItemTitleStyle = useAdaptiveValue(
    styles.draggableItemTitleMobile,
    styles.draggableItemTitleMobile,
    styles.draggableItemTitle
  );
  const draggableItemStyle = useAdaptiveValue(
    styles.draggableItemMobile,
    styles.draggableItemMobile,
    styles.draggableItem
  );
  // const { width } = useWindowSize();
  return (
    <div
      className={draggableItemStyle}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        transform: CSS.Translate.toString(transform),
        left: positionX,
        top: positionY
      }}
      data-img={!imgUrl ? "noImage" : "image"}
    >
      {title && (
        <div className={draggableItemTitleStyle} data-img={!imgUrl ? "noImage" : "image"}>
          {title}
        </div>
      )}
      {imgUrl && <img className={draggableItemImageStyle} src={imgUrl} alt={title} />}
    </div>
  );
};
