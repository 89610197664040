import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme17Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "4 ноября"
    },
    {
      name: "4 октября"
    },
    {
      name: "7 ноября"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Когда отмечают праздник День народного единства?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='4 ноября' />
    </QuizWrapper>
  );
};

export default Theme17Task1;
