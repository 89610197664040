import React, { useState } from "react";
import styles from "./QuizChooseItems.module.scss";
import { QuizResult } from "~/shared/components/QuizResult";
import { ChooseItem } from "./componets/ChooseItem";
import { useQuizContext } from "~/shared/hooks/useQuizContext";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type Props = {
  type: "img" | "str";
  correctItems: string[];
  items: { name: string; image?: string }[];
};

export const QuizChooseItems: React.FC<Props> = ({ type, items, correctItems }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [correctedSelectedItems, setCorrectedSelectedItems] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);
  const { setSuccess } = useQuizContext();

  const wrapperStyle = useAdaptiveValue(styles.wrapperMobile, styles.wrapperMobile, styles.wrapper);

  const handleItemClick = (itemName: string) => {
    showError && setSelectedItems([...correctedSelectedItems]);
    setShowError(false);
    if (selectedItems.includes(itemName)) {
      setSelectedItems((prev) => prev.filter((item) => item !== itemName));
    } else {
      setSelectedItems((prev) => [...prev, itemName]);
    }
  };

  const handleCheckAllItems = () => {
    const correctedSelectedItems = selectedItems.filter((selectedItem) =>
      correctItems.some((correctItem) => correctItem === selectedItem)
    );
    setCorrectedSelectedItems((prev) => [...new Set([...prev, ...correctedSelectedItems])]);
    correctedSelectedItems.length === correctItems.length &&
    selectedItems.length === correctItems.length
      ? setTimeout(() => setSuccess(true), 700)
      : setShowError(true);
  };

  return (
    <div className={styles.QuizChooseItems}>
      <div className={wrapperStyle}>
        {items.map((item, index) => (
          <ChooseItem
            key={index}
            item={item}
            type={type}
            isActive={selectedItems.includes(item.name)}
            isSuccess={correctedSelectedItems.includes(item.name)}
            isError={showError && !correctedSelectedItems.includes(item.name)}
            handleItemClick={handleItemClick}
          />
        ))}
      </div>
      {
        <QuizResult
          errorVisible={showError}
          buttonVisible={!showError && selectedItems.length >= correctItems.length}
          buttonOnClick={handleCheckAllItems}
        />
      }
    </div>
  );
};
