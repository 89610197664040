import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import Arka from "~shared/assets/images/30/Arka.jpg";
import Blah from "~shared/assets/images/30/Blah.jpg";
import Krepost from "~shared/assets/images/30/Krepost.jpg";
import Tsar from "~shared/assets/images/30/Tsar.jpg";

const Theme30Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Триумфальная арка",
      image: Arka
    },
    {
      name: "Собор Покрова на Рву",
      image: Blah
    },
    {
      name: "Петропавловская крепость",
      image: Krepost
    },
    {
      name: "Царь-пушка",
      image: Tsar
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Отметь, какой объект нельзя найти в путеводителе по Москве.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Петропавловская крепость' hideName />
    </QuizWrapper>
  );
};

export default Theme30Task1;
