import { useWindowSize } from "~/shared/hooks/useWindowSize";

export const useAdaptiveValue = <T>(sm: T, md: T, lg: T): T => {
  const { width } = useWindowSize();

  if (width >= 560) {
    return lg;
  } else if (width >= 375) {
    return md;
  } else {
    return sm;
  }
};
