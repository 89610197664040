import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import pamyatnikDolgorukiy from "~shared/assets/images/11/pamyatnik-uriyu-dolgorukomu.jpg";
import kolokol from "~shared/assets/images/11/ts-kolokol.jpg";
import puska from "~shared/assets/images/11/ts-puska.jpg";
import uspenskiySobor from "~shared/assets/images/11/uspenskiy-sobor.jpg";

const Theme11Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Царь-пушка",
      image: puska
    },
    {
      name: "Царь-колокол",
      image: kolokol
    },
    {
      name: "Успенский собор",
      image: uspenskiySobor
    },
    {
      name: "Памятник Юрию Долгорукому",
      image: pamyatnikDolgorukiy
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какого объекта нет на территории Кремля?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Памятник Юрию Долгорукому' />
    </QuizWrapper>
  );
};

export default Theme11Task1;
