import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import chastokol from "~shared/assets/images/7/chastokol.jpg";

import kalitka from "~shared/assets/images/7/kalitka.jpg";

import koshel from "~shared/assets/images/7/koshel.jpg";

const Theme7Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Частокол",
      image: chastokol
    },
    {
      name: "Калитка",
      image: kalitka
    },
    {
      name: "Кошель",
      image: koshel
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='С каким предметом связано прозвище Калита?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Кошель' />
    </QuizWrapper>
  );
};

export default Theme7Task1;
