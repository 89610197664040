import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItems } from "~/modules/QuizChooseItems";
import arka from "~/shared/assets/images/25/1/shutterstock_2172837845.jpg";
import sobor from "~/shared/assets/images/25/1/shutterstock_677234908.jpg";
import hram from "~/shared/assets/images/25/1/shutterstock_1814411540.jpg";
import hram2 from "~/shared/assets/images/25/1/shutterstock_2128951919.jpg";

const Theme25Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Триумфальная арка",
      image: arka
    },
    {
      name: "Успенский собор",
      image: sobor
    },
    {
      name: "Храм Христа Спасителя",
      image: hram
    },
    {
      name: "Храм Василия Блаженного",
      image: hram2
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Что было построено в честь победы над Наполеоном?'
      description='Выбери 2 правильных ответа'
      successIcon='rus-1'
    >
      <QuizChooseItems
        type='img'
        items={items}
        correctItems={["Триумфальная арка", "Храм Христа Спасителя"]}
      />
    </QuizWrapper>
  );
};

export default Theme25Task1;
