import React from "react";
import styles from "./SelectButton.module.scss";

type Props = {
  userAnswers: Record<number, string>;
  index: number;
  questions: { question: string; answer: string }[];
  onClick: (index: number, value: string) => void;
  value: string;
};

export const SelectButton: React.FC<Props> = ({
  userAnswers,
  index,
  questions,
  onClick,
  value
}) => {
  return (
    <button
      className={`${styles.selectItem} ${
        userAnswers[index] === value
          ? userAnswers[index] === questions[index].answer
            ? styles.selected
            : styles.error
          : userAnswers[index] === value
          ? styles.selected
          : ""
      }`}
      onClick={() => onClick(index, value)}
    >
      {value}
    </button>
  );
};
