import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import los from "~/shared/assets/images/3/los.jpg";
import zayats from "~/shared/assets/images/3/zayats.jpg";
import belka from "~/shared/assets/images/3/belka.jpg";
import lisa from "~/shared/assets/images/3/lisa.jpg";

const Theme3Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Лось",
      image: los
    },
    {
      name: "Заяц",
      image: zayats
    },
    {
      name: "Белка",
      image: belka
    },
    {
      name: "Лиса",
      image: lisa
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Отметь самое крупное животное в парке «Лосиный Остров».'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Лось' />
    </QuizWrapper>
  );
};

export default Theme3Task1;
