import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizEnterWord } from "~modules/QuizEnterWord";
import rebus from "~shared/assets/images/rebus39.jpg";
import { ImageRebus } from "~/shared/components/ImageRebus";

const Theme39Task3 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Как называется район, в котором появились первые пятиэтажные дома?'
      description='Напиши отгадку в поле ниже'
      successIcon='nast-2'
    >
      <ImageRebus url={rebus} />
      <QuizEnterWord answer='Черемушки' />
    </QuizWrapper>
  );
};

export default Theme39Task3;
