import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import dolg from "~shared/assets/images/37/dolgorukiy_shutterstock_493906648.jpg";
import lomonosov from "~shared/assets/images/37/Lomonosov_shutterstock_2040261980.jpg";
import jukov from "~shared/assets/images/37/zhukov_shutterstock_668283226.jpg";
import mini from "~shared/assets/images/37/minin_shutterstock_468954308.jpg";

const Theme37Task3 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Долгорукий",
      image: dolg
    },
    {
      name: "Жуков",
      image: jukov
    },
    {
      name: "Ломоносов",
      image: lomonosov
    },
    {
      name: "Минин",
      image: mini
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри фотографии. Отметь памятник Георгию Жукову '
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Жуков' hideName />
    </QuizWrapper>
  );
};

export default Theme37Task3;
