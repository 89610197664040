import React, { useEffect, useState } from "react";
import { QuizSortableWords } from "~/shared/components/QuizSortableWords";
import styles from "./QuizChangeWords.module.scss";
import { useQuizContext } from "~/shared/hooks/useQuizContext";
import { QuizResult } from "~/shared/components/QuizResult";
import ladia from "~shared/assets/images/35/ladia.jpg";
import korabl from "~shared/assets/images/35/korabl.jpg";
import teplohod from "~shared/assets/images/35/teplohod.jpg";
import lodka from "~shared/assets/images/35/lodka.jpg";

export const QuizChangeWords = () => {
  const { setSuccess } = useQuizContext();
  const [errorGame, setErrorGame] = useState(new Array(4).fill(false));
  const [finishGame, setFinishGame] = useState(new Array(4).fill(false));

  const items = [
    [
      { id: 2, name: "ка" },
      { id: 1, name: "лод" }
    ],
    [
      { id: 3, name: "ход" },
      { id: 2, name: "ло" },
      { id: 1, name: "теп" }
    ],
    [
      { id: 2, name: "дья" },
      { id: 1, name: "ла" }
    ],
    [
      { id: 3, name: "лик" },
      { id: 2, name: "раб" },
      { id: 1, name: "ко" }
    ]
  ];

  useEffect(() => {
    if (finishGame.every((el) => el === true)) {
      setTimeout(() => setSuccess(true), 700);
    }
  }, [finishGame]);

  return (
    <>
      <div className={styles.wrapper}>
        {items.map((item, index) => (
          <QuizSortableWords
            key={index}
            word={item}
            idItem={index}
            setFinishGame={setFinishGame}
            setErrorGame={setErrorGame}
            link={getBackgroundImageLink(index)}
          />
        ))}
      </div>
      <QuizResult errorVisible={errorGame.some((el) => el === true)} />
    </>
  );

  function getBackgroundImageLink(index: number) {
    const links = [lodka, teplohod, ladia, korabl];
    return links[index];
  }
};
