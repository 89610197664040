import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import ohota from "~shared/assets/images/4/ohota2.jpg";
import ribalka from "~shared/assets/images/4/ribalka2.jpg";
import stroitelstvo from "~shared/assets/images/4/stroitelstvo.jpg";
import vipasSkota from "~shared/assets/images/4/vipas-skota.jpg";

const Theme4Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Охота",
      image: ohota
    },
    {
      name: "Рыбалка",
      image: ribalka
    },
    {
      name: "Строительство дома из камня",
      image: stroitelstvo
    },
    {
      name: "Выпас скота",
      image: vipasSkota
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри рисунки и обрати внимание, чем занимаются люди. Отметь лишнее.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Строительство дома из камня' />
    </QuizWrapper>
  );
};

export default Theme4Task1;
