import React, { useEffect, useState } from "react";
import styles from "./QuizFillWord.module.scss";
import clsx from "clsx";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type Props = {
  grid: { char: string; wordId: number }[][];
  words: { value: string; wordId: number }[];
  cellWidthSmall?: boolean;
};

export const QuizFillWord: React.FC<Props> = ({ grid, words, cellWidthSmall = false }) => {
  const [hoveredId, setHoveredId] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { setSuccess } = useQuizContext();

  const handleClick = (id: number) => {
    if (!selectedIds.includes(id) && id !== -1) {
      setSelectedIds([...selectedIds, id]);
    }
  };
  const gridCellStyle = useAdaptiveValue(
    styles.gridCellMobile,
    styles.gridCellMobile,
    styles.gridCell
  );
  const gridStyle = useAdaptiveValue(styles.gridMobile, styles.gridMobile, styles.grid);
  const gridRowStyle = useAdaptiveValue(styles.gridRowMobile, styles.gridRowMobile, styles.gridRow);

  useEffect(() => {
    const isSuccess = words.length === selectedIds.length;
    isSuccess && setTimeout(() => setSuccess(true), 700);
  }, [words, selectedIds]);

  return (
    <div className={styles.fillWordQuiz}>
      <div className={gridStyle}>
        {grid.map((row, rowIdx) => {
          return (
            <div key={`GridRow-${rowIdx}`} className={gridRowStyle}>
              {row.map((cell, cellIdx) => (
                <div
                  key={`GridCell-${cellIdx}`}
                  className={clsx(gridCellStyle, {
                    [styles.hoveredCell]:
                      cell.wordId === hoveredId &&
                      cell.wordId !== -1 &&
                      !selectedIds.includes(cell.wordId),
                    [styles.crossedCell]: selectedIds.includes(cell.wordId)
                  })}
                  onMouseEnter={() => setHoveredId(cell.wordId)}
                  onMouseLeave={() => setHoveredId(0)}
                  onClick={() => handleClick(cell.wordId)}
                  style={{ width: cellWidthSmall ? "27px" : "100%" }}
                >
                  {cell.char}
                </div>
              ))}
            </div>
          );
        })}
      </div>

      <div className={styles.words}>
        {words.map((item) => (
          <div
            key={item.wordId}
            className={clsx(styles.word, {
              [styles.selectedWord]: selectedIds.includes(item.wordId)
            })}
          >
            {item.value}
          </div>
        ))}
      </div>
    </div>
  );
};
