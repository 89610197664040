import React from "react";
import styles from "./QuizSuccess.module.scss";
import { Icon } from "~shared/components/Icon";
import { ChildIcon, ChildIconName } from "~shared/components/ChildIcon";
import intersect from "~shared/assets/images/intersect.png";

export type TQuizSuccess = {
  successIcon: ChildIconName;
  message?: string;
};

export const QuizSuccess: React.FC<TQuizSuccess> = ({
  successIcon = "rus-1",
  message = "Все правильно! Молодец!"
}) => {
  return (
    <div className={styles.quizSuccess}>
      <div className={styles.titleWrapper}>
        <span className={styles.iconWrapper}>
          <Icon name='check-mark' />
        </span>
        <div className={styles.message}>{message}</div>
        <span
          className={styles.intersect}
          style={{
            backgroundImage: `url(${intersect})`
          }}
        />
      </div>
      <span className={styles.successIconWrapper}>
        <ChildIcon name={successIcon} />
      </span>
    </div>
  );
};
