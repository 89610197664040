import React from "react";
export const Rus1: React.FC = () => {
  return (
    <svg
      width='164'
      height='133'
      viewBox='0 0 164 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M97.3154 132.677C133.766 132.677 163.315 103.128 163.315 66.6768C163.315 30.226 133.766 0.676819 97.3154 0.676819C60.8646 0.676819 31.3154 30.226 31.3154 66.6768C31.3154 103.128 60.8646 132.677 97.3154 132.677Z'
        fill='#9AD3D6'
      />
      <path
        d='M122.516 96.8768C119.516 94.9768 116.816 93.4768 114.216 91.8768C109.316 88.7768 103.216 87.4768 97.4156 87.5768C95.7156 87.5768 94.1156 87.3769 92.8156 88.4769C91.3156 89.5769 89.6156 90.3769 88.0156 91.4769C86.1156 92.7769 84.2155 94.2769 82.6155 95.9769C81.1155 97.4769 79.2156 99.1768 78.5156 101.177C77.1156 104.677 76.6156 108.577 76.3156 112.377C75.8156 117.877 75.7156 123.477 75.8156 129.077C82.6156 131.377 89.8156 132.677 97.4156 132.677C112.216 132.677 125.916 127.777 137.016 119.477C132.216 101.877 122.716 96.9768 122.516 96.8768Z'
        fill='#1CB0C1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6153 106.777L17.0153 106.277C17.0153 106.277 18.3153 104.377 17.5153 104.377C14.0153 103.777 10.8153 100.877 12.5153 100.277C14.5153 99.5768 20.4153 100.177 22.3153 100.277C22.3153 100.277 28.3153 101.877 30.4153 102.977C32.2153 103.977 35.1153 107.277 35.1153 107.277C35.1153 107.277 35.4153 110.577 34.3153 117.177C32.9153 116.577 31.3153 115.477 22.3153 116.377C20.1153 116.577 13.6153 106.777 13.6153 106.777Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5155 107.477L14.1155 106.977C14.1155 106.977 11.6156 107.277 9.31555 107.377C6.21555 107.477 2.41553 107.277 1.91553 108.877L6.11548 109.977C6.11548 109.977 16.5156 109.477 16.8156 108.777C17.1156 107.977 15.5155 107.477 15.5155 107.477Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3156 108.877C16.0156 109.077 12.6155 108.277 12.6155 108.277C12.6155 108.277 3.51553 107.377 1.41553 110.977C1.81553 112.177 11.6155 112.477 11.6155 112.477C11.6155 112.477 18.7155 113.777 19.1155 113.177C19.4155 112.577 16.3156 108.877 16.3156 108.877Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.0153 111.477C17.7153 111.677 14.3153 110.877 14.3153 110.877C14.3153 110.877 5.31533 108.077 3.21533 111.777C3.61533 112.977 13.2153 115.077 13.2153 115.077C13.2153 115.077 20.3153 116.377 20.7153 115.777C21.1153 115.177 18.0153 111.477 18.0153 111.477Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2157 113.977C19.4157 113.877 6.01562 110.577 6.01562 114.477L12.0156 116.677C12.0156 116.677 22.5156 117.077 23.0156 116.477C23.5156 115.877 19.2157 113.977 19.2157 113.977Z'
        fill='#FFD2A8'
      />
      <path
        d='M17.9157 113.177C18.0157 113.177 18.0156 113.177 18.1156 113.177C22.5156 110.377 30.2157 109.577 32.3157 111.077C32.4157 111.177 32.6157 111.177 32.7157 110.977C32.8157 110.877 32.8156 110.677 32.6156 110.577C30.1156 108.777 22.0157 109.977 17.7157 112.677C17.6157 112.777 17.5156 112.977 17.6156 113.077C17.7156 113.177 17.8157 113.177 17.9157 113.177Z'
        fill='#8E3821'
      />
      <path
        d='M6.11548 113.677C6.21548 113.677 6.31553 113.577 6.41553 113.477C6.51553 113.277 6.41558 113.177 6.21558 113.077L3.41553 112.077C3.51553 111.777 3.61558 111.577 3.71558 111.477C4.21558 111.077 5.41555 111.277 6.81555 111.477C7.81555 111.677 9.01558 111.777 10.2156 111.777C11.5156 111.777 12.6155 111.677 13.6155 111.377C13.6155 111.377 13.6156 111.377 13.7156 111.377C13.9156 111.377 14.0155 111.177 14.0155 111.077C14.0155 110.877 13.9156 110.777 13.7156 110.777C13.7156 110.777 13.6155 110.777 13.5155 110.777C12.6155 110.977 11.5156 111.077 10.3156 111.077C9.11555 111.077 8.0155 110.877 7.0155 110.777C5.4155 110.577 4.2155 110.377 3.5155 110.877C3.2155 111.177 3.01553 111.577 2.91553 112.077C2.91553 112.177 3.01548 112.377 3.11548 112.377L6.11548 113.477C6.01548 113.677 6.01548 113.677 6.11548 113.677Z'
        fill='#8E3821'
      />
      <path
        d='M2.81543 111.977C2.91543 111.977 3.11536 111.877 3.11536 111.777C3.11536 111.577 3.01541 111.477 2.91541 111.377L1.41541 111.077C1.91541 109.777 4.41536 109.877 7.11536 109.977C7.91536 109.977 8.61541 109.977 9.41541 109.977C11.9154 109.977 14.1155 109.277 14.2155 109.277C14.4155 109.277 14.5154 109.077 14.4154 108.877C14.4154 108.677 14.2154 108.577 14.0154 108.677C14.0154 108.677 11.8154 109.377 9.41541 109.377C8.71541 109.377 7.91545 109.377 7.21545 109.377C4.11545 109.277 1.21543 109.177 0.81543 111.277C0.81543 111.477 0.915381 111.577 1.01538 111.577L2.81543 111.977Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M76.3154 77.9768C61.9154 60.2768 71.1153 44.1768 71.6153 40.9768C73.2153 32.1768 88.1153 28.6768 87.9153 31.2768L76.3154 77.9768Z'
        fill='#362626'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M126.515 80.9768C132.015 72.4768 138.215 51.3768 134.515 34.9768C130.415 17.1768 124.315 12.1768 102.015 10.9768C68.5153 9.17681 66.5153 41.9768 64.7153 51.1768C68.0153 46.6768 82.8153 33.4768 91.0153 34.8768L96.8153 37.9768C105.915 40.1768 112.315 45.7768 117.215 41.5768L117.815 63.0768L115.515 82.5768L122.415 74.0768L126.515 80.9768Z'
        fill='#362626'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.9156 105.577C30.9156 105.577 45.6156 111.377 51.9156 112.177C56.8156 112.777 62.5156 110.277 67.1156 108.777C70.2156 107.777 77.4156 103.077 80.4156 106.077C84.1156 109.877 72.7157 117.077 73.7157 121.877C72.4157 125.077 58.7157 127.677 51.2157 126.177C44.1157 124.677 36.8156 118.077 29.0156 114.177L30.9156 105.577Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M92.1152 84.7768C92.3152 87.6768 92.0153 90.6768 94.4153 92.7768C97.4153 95.3768 101.715 94.9768 105.215 93.9768C106.215 93.6768 107.215 93.3768 107.915 92.6768C109.615 91.0768 108.415 86.1768 108.315 83.8768C108.215 82.0768 108.215 80.1768 108.115 78.3768L91.6152 78.7768C91.8152 80.7768 92.0152 82.7768 92.1152 84.7768Z'
        fill='#FFD2A8'
      />
      <mask
        id='mask0_958_23854'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='91'
        y='78'
        width='18'
        height='24'
      >
        <path
          d='M108.815 94.2768C102.115 94.9768 95.9153 99.3768 97.0153 101.177C97.0153 101.177 90.7152 96.7768 92.6152 91.3768L91.6152 78.8768L108.115 78.4768L108.815 94.2768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_958_23854)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.8154 80.4768C83.1154 80.6768 103.215 92.4768 112.215 86.7768C121.215 81.0768 110.615 72.1768 110.615 72.1768L82.8154 80.4768Z'
          fill='#F9836A'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M123.716 31.2768C125.516 43.3768 123.015 46.4768 123.015 57.3768C123.015 57.3768 128.316 46.1768 128.916 46.2768C142.216 37.1768 141.415 62.4768 124.115 58.9768C123.615 68.6768 111.415 82.5768 98.6155 82.5768C85.4155 83.0768 75.4156 69.5768 72.7156 60.6768C62.5156 60.3768 57.9155 41.2768 71.6155 44.0768C70.1155 48.8768 73.6155 57.7768 73.6155 57.7768C72.9155 51.1768 75.5155 38.2768 77.8155 36.8768C83.6155 34.1768 91.5155 33.2768 96.9155 34.2768L97.4155 38.0768C109.316 39.3768 116.016 36.0768 123.716 31.2768Z'
        fill='#FFD2A8'
      />
      <path
        d='M129.415 54.7768C131.615 54.7768 133.315 53.9768 134.315 52.3768C135.015 51.1768 135.115 49.8768 134.415 48.9768C134.015 48.3768 133.015 47.4768 130.715 47.9768C130.515 47.9768 130.315 48.2768 130.415 48.4768C130.415 48.6768 130.715 48.8768 130.915 48.7768C132.315 48.4768 133.315 48.7768 133.815 49.4768C134.315 50.1768 134.215 51.1768 133.715 52.0768C133.315 52.6768 131.915 54.5768 128.215 53.9768C128.015 53.9768 127.815 54.0768 127.715 54.2768C127.715 54.4768 127.815 54.6768 128.015 54.7768C128.515 54.7768 128.915 54.7768 129.415 54.7768Z'
        fill='#8E3821'
      />
      <path
        d='M71.2153 54.7768C71.5153 54.7768 71.7152 54.7768 72.0152 54.6768C72.2152 54.6768 72.4152 54.4768 72.3152 54.1768C72.3152 53.9768 72.1152 53.7768 71.8152 53.8768C70.3152 54.0768 68.9152 53.4768 68.1152 52.2768C67.5152 51.2768 67.4152 49.9768 67.9152 49.2768C68.4152 48.5768 69.5152 48.2768 70.8152 48.5768C71.0152 48.5768 71.2152 48.4768 71.3152 48.2768C71.3152 48.0768 71.2152 47.8768 71.0152 47.7768C68.7152 47.2768 67.7153 48.1768 67.2153 48.7768C66.5153 49.7768 66.5152 51.3768 67.4152 52.6768C68.3152 54.0768 69.7153 54.7768 71.2153 54.7768Z'
        fill='#8E3821'
      />
      <path
        d='M113.415 45.0768C113.615 45.0768 113.815 44.9768 114.015 44.7768C114.315 44.4768 114.315 43.9768 113.915 43.6768C112.915 42.7768 110.115 42.3768 108.115 43.2768C107.715 43.4768 107.515 43.8768 107.715 44.2768C107.915 44.6768 108.315 44.8768 108.715 44.6768C110.315 43.9768 112.415 44.4768 112.915 44.8768C113.015 45.0768 113.215 45.0768 113.415 45.0768Z'
        fill='#362626'
      />
      <path
        d='M89.0151 43.2768C89.3151 43.2768 89.5151 43.1768 89.6151 42.9768C89.8151 42.6768 89.8151 42.2768 89.6151 42.0768C89.3151 41.6768 87.9151 41.2768 87.6151 41.1768C86.7151 40.9768 85.3151 40.7768 84.1151 41.2768C83.7151 41.4768 83.5152 41.8768 83.7152 42.2768C83.9152 42.6768 84.3152 42.8768 84.7152 42.6768C86.0152 42.0768 88.0151 42.8768 88.5151 43.1768C88.6151 43.2768 88.7152 43.2768 88.8152 43.2768C88.8152 43.1768 88.9151 43.2768 89.0151 43.2768Z'
        fill='#362626'
      />
      <path
        d='M98.1154 42.8768L101.515 59.7768C93.7154 64.4768 88.6155 58.3768 93.8155 54.7768C99.7155 50.3768 98.1154 42.8768 98.1154 42.8768Z'
        fill='#FCD1A9'
      />
      <path
        d='M96.4154 61.7768C97.4154 61.7768 98.5155 61.5768 99.7155 61.0768C99.9155 60.9768 99.9154 60.7768 99.9154 60.6768C99.8154 60.4768 99.7154 60.4768 99.5154 60.4768C96.4154 61.8768 93.4154 60.8768 92.4154 59.1768C91.6154 57.7768 92.2154 56.2768 94.0154 54.9768C97.6154 52.2768 98.5155 48.5768 98.7155 45.8768C98.7155 45.6768 98.6154 45.5768 98.4154 45.5768C98.2154 45.5768 98.1154 45.6768 98.1154 45.8768C98.0154 48.3768 97.1155 51.9768 93.7155 54.4768C91.6155 55.9768 90.9154 57.7768 91.9154 59.4768C92.5154 60.8768 94.3154 61.7768 96.4154 61.7768Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M89.4153 48.5768C89.3153 50.6768 88.1153 52.2768 86.7153 52.2768C85.3153 52.1768 84.2153 50.4768 84.2153 48.3768C84.3153 46.2768 85.5153 44.6768 86.9153 44.6768C88.4153 44.7768 89.5153 46.5768 89.4153 48.5768Z'
        fill='white'
      />
      <mask
        id='mask1_958_23854'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='84'
        y='44'
        width='6'
        height='9'
      >
        <path
          d='M87.0154 44.7769C88.4154 44.7769 89.5154 46.5768 89.5154 48.6768C89.4154 50.7768 88.2154 52.3768 86.8154 52.3768C85.4154 52.2768 84.3154 50.5769 84.3154 48.4769C84.3154 46.3769 85.5154 44.7769 87.0154 44.7769C87.0154 44.7769 86.9154 44.7769 87.0154 44.7769Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_958_23854)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M89.7153 48.9769C89.9153 51.0769 88.9152 52.8769 87.5152 52.9769C86.1152 53.0769 84.7152 51.5769 84.5152 49.4769C84.3152 47.3769 85.3153 45.5769 86.7153 45.4769C88.2153 45.3769 89.5153 46.8769 89.7153 48.9769Z'
          fill='#7D0000'
        />
      </g>
      <mask
        id='mask2_958_23854'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='84'
        y='44'
        width='6'
        height='9'
      >
        <path
          d='M87.0154 44.7769C88.4154 44.7769 89.5154 46.5768 89.5154 48.6768C89.4154 50.7768 88.2154 52.3768 86.8154 52.3768C85.4154 52.2768 84.3154 50.5769 84.3154 48.4769C84.3154 46.3769 85.5154 44.7769 87.0154 44.7769C87.0154 44.7769 86.9154 44.7769 87.0154 44.7769Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask2_958_23854)'>
        <path
          d='M86.7154 47.2769C86.6154 47.7769 86.1153 48.0769 85.6153 47.9769C85.1153 47.8769 84.8153 47.3769 85.0153 46.8769C85.1153 46.3769 85.6153 46.0769 86.1153 46.1769C86.5153 46.2769 86.8154 46.7769 86.7154 47.2769Z'
          fill='white'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M113.216 49.4768C113.116 51.5768 111.916 53.1768 110.516 53.1768C109.116 53.1768 108.016 51.3768 108.016 49.2768C108.116 47.1768 109.316 45.5768 110.716 45.5768C112.216 45.6768 113.316 47.3768 113.216 49.4768Z'
        fill='white'
      />
      <mask
        id='mask3_958_23854'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='108'
        y='45'
        width='6'
        height='9'
      >
        <path
          d='M110.716 45.5768C112.116 45.5768 113.216 47.3768 113.216 49.4768C113.116 51.5768 111.916 53.1768 110.516 53.1768C109.116 53.0768 108.016 51.3768 108.016 49.2768C108.116 47.1768 109.316 45.5768 110.716 45.5768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask3_958_23854)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M113.515 49.5768C113.715 51.6768 112.716 53.4768 111.316 53.5768C109.916 53.6768 108.516 52.1768 108.316 50.0768C108.116 47.9768 109.115 46.1768 110.515 46.0768C111.915 45.9768 113.315 47.5768 113.515 49.5768Z'
          fill='#7D0000'
        />
      </g>
      <mask
        id='mask4_958_23854'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='108'
        y='45'
        width='6'
        height='9'
      >
        <path
          d='M110.716 45.5768C112.116 45.5768 113.216 47.3768 113.216 49.4768C113.116 51.5768 111.916 53.1768 110.516 53.1768C109.116 53.0768 108.016 51.3768 108.016 49.2768C108.116 47.1768 109.316 45.5768 110.716 45.5768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask4_958_23854)'>
        <path
          d='M110.416 48.1768C110.316 48.6768 109.816 48.9768 109.316 48.8768C108.816 48.7768 108.516 48.2768 108.716 47.7768C108.816 47.2768 109.316 46.9768 109.816 47.0768C110.216 47.0768 110.516 47.6768 110.416 48.1768Z'
          fill='white'
        />
      </g>
      <path
        d='M95.6155 49.2768H102.316C103.016 49.2768 103.516 48.7768 103.516 48.0768C103.516 47.3768 103.016 46.8768 102.316 46.8768H95.6155C94.9155 46.8768 94.4155 47.3768 94.4155 48.0768C94.4155 48.7768 94.9155 49.2768 95.6155 49.2768Z'
        fill='#E82A2A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M85.4155 122.677L81.0155 97.1768C81.0155 97.1768 76.8155 101.777 65.4155 108.777C69.2155 112.877 69.5155 121.577 74.1155 125.877L85.4155 122.677Z'
        fill='#1DB0C1'
      />
      <path
        d='M75.8157 127.677C76.0157 127.677 76.1156 127.577 76.1156 127.377L77.1156 110.277C77.1156 110.077 77.0157 109.977 76.8157 109.977C76.6157 109.977 76.5156 110.077 76.5156 110.277L75.5156 127.377C75.5156 127.577 75.6157 127.677 75.8157 127.677Z'
        fill='#1D8693'
      />
      <path
        d='M96.9155 67.7769C98.9155 67.7769 100.915 66.8769 101.615 66.3769C101.715 66.2769 101.816 66.0769 101.716 65.9769C101.616 65.8769 101.415 65.7769 101.315 65.8769C100.215 66.6769 96.3155 68.2769 93.9155 66.1769C93.8155 66.0769 93.6154 66.0769 93.5154 66.1769C93.4154 66.2769 93.4154 66.4769 93.5154 66.5769C94.5154 67.4769 95.7155 67.7769 96.9155 67.7769Z'
        fill='#8E3821'
      />
      <path
        d='M96.4153 69.8768H98.6152C98.8152 69.8768 98.9153 69.7768 98.9153 69.5768C98.9153 69.3768 98.8152 69.2769 98.6152 69.2769H96.4153C96.2153 69.2769 96.1152 69.3768 96.1152 69.5768C96.1152 69.6768 96.2153 69.8768 96.4153 69.8768Z'
        fill='#8E3821'
      />
      <path
        d='M120.215 49.2768C120.315 49.2768 120.415 49.2768 120.515 49.2768L129.315 46.9768C129.915 46.7768 130.315 46.1768 130.215 45.4768C130.015 44.8768 129.415 44.4768 128.715 44.5768L119.915 46.8768C119.315 47.0768 118.915 47.6768 119.015 48.3768C119.115 48.8768 119.615 49.2768 120.215 49.2768Z'
        fill='#E82A2A'
      />
      <path
        d='M76.9153 46.2769C77.4153 46.2769 77.8153 45.9768 78.0153 45.5768C78.3153 44.9768 78.0153 44.2769 77.4153 43.9769L71.2153 41.3768C70.6153 41.0768 69.9152 41.3769 69.6152 41.9769C69.3152 42.5769 69.6153 43.2768 70.2153 43.5768L76.4153 46.1768C76.6153 46.2768 76.8153 46.2769 76.9153 46.2769Z'
        fill='#E82A2A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M95.0156 56.0768C95.0156 56.5768 95.5157 56.9768 96.2157 56.9768C96.8157 56.9768 97.4156 56.5768 97.4156 56.0768C97.4156 55.5768 96.9157 55.1768 96.2157 55.1768C95.5157 55.1768 95.0156 55.5768 95.0156 56.0768Z'
        fill='#FCDECA'
      />
      <g opacity='0.5'>
        <g opacity='0.5' filter='url(#filter0_f_958_23854)'>
          <path
            d='M109.816 57.7768C113.016 58.3768 115.916 57.4768 115.316 60.6768C114.716 63.8768 111.616 65.9768 108.416 65.3768C105.216 64.7768 103.116 61.6768 103.716 58.4768C104.316 55.2768 106.616 57.1768 109.816 57.7768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.5'>
        <g opacity='0.5' filter='url(#filter1_f_958_23854)'>
          <path
            d='M83.3156 57.1768C86.5156 56.5768 88.8156 54.6768 89.4156 57.8768C90.0156 61.0768 87.9156 64.1769 84.7156 64.7769C81.5156 65.3769 78.4156 63.2768 77.8156 60.0768C77.2156 56.8768 80.1156 57.7768 83.3156 57.1768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        d='M110.716 61.0768C108.516 61.0768 106.315 60.2768 104.615 58.6768C102.415 56.6768 101.115 53.7768 101.015 50.5768C100.815 44.4768 105.115 39.1768 110.515 38.7768C113.015 38.5768 115.416 39.4768 117.316 41.1768C119.516 43.1768 120.816 46.0768 120.916 49.2768C121.116 55.3768 116.816 60.6768 111.416 61.0768C111.116 61.0768 110.916 61.0768 110.716 61.0768ZM118.416 49.2768C118.316 46.7768 117.315 44.4768 115.615 42.8768C114.215 41.5768 112.415 40.9768 110.615 41.0768C106.415 41.3768 103.216 45.5768 103.316 50.3768C103.416 52.8768 104.415 55.1768 106.115 56.7768C107.515 58.0768 109.315 58.6768 111.115 58.5768C115.315 58.2768 118.616 54.1768 118.416 49.2768Z'
        fill='#E82A2A'
      />
      <path
        d='M85.4156 60.3768C83.2156 60.3768 81.0157 59.5768 79.3157 57.9768C77.1157 55.9768 75.8157 53.0768 75.7157 49.8768C75.5157 43.7768 79.8157 38.4768 85.2157 38.0768C87.7157 37.8768 90.1156 38.7768 92.0156 40.4768C94.2156 42.4768 95.5156 45.3768 95.6156 48.5768C95.8156 54.6768 91.5156 59.9768 86.1156 60.3768C85.8156 60.3768 85.6156 60.3768 85.4156 60.3768ZM85.8157 40.4768C85.6157 40.4768 85.5157 40.4768 85.3157 40.4768C81.1157 40.7768 77.9156 44.9768 78.0156 49.7768C78.1156 52.2768 79.1157 54.5768 80.8157 56.1768C82.2157 57.4768 84.0157 58.0768 85.8157 57.9768C90.0157 57.6768 93.2156 53.4768 93.1156 48.6768C93.0156 46.1768 92.0157 43.8768 90.3157 42.2768C89.0157 41.1768 87.4157 40.4768 85.8157 40.4768Z'
        fill='#E82A2A'
      />
      <defs>
        <filter
          id='filter0_f_958_23854'
          x='100.613'
          y='53.6689'
          width='17.7817'
          height='14.8102'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_23854' />
        </filter>
        <filter
          id='filter1_f_958_23854'
          x='74.7363'
          y='53.069'
          width='17.7817'
          height='14.8102'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_23854' />
        </filter>
      </defs>
    </svg>
  );
};
