import React, { useState } from "react";
import styles from "./ChooseItem.module.scss";
import clsx from "clsx";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type Props = {
  type: "img" | "str";
  item: {
    name: string;
    image?: string;
  };
  isActive: boolean;
  isSuccess: boolean;
  isError: boolean;
  hideName?: boolean;
  handleItemClick: (name: string) => void;
};

export const ChooseItem: React.FC<Props> = ({
  type,
  item,
  hideName,
  isActive,
  isSuccess,
  isError,
  handleItemClick
}) => {
  const handleClick = () => {
    if (isActive) {
      setOverAfterActivated(true);
    }
    handleItemClick(item.name);
  };

  const [overAfterActivated, setOverAfterActivated] = useState(true);

  const itemNameStyle = useAdaptiveValue(
    styles.itemNameMobile,
    styles.itemNameMobile,
    styles.itemName
  );

  const stringItemStyle = useAdaptiveValue(
    styles.stringItemMobile,
    styles.stringItemMobile,
    styles.stringItem
  );
  const imageStyle = useAdaptiveValue(styles.imageMobile, styles.imageMobile, styles.image);

  return (
    <>
      {type === "img" && (
        <div className={styles.itemWrapper}>
          {!hideName && <div className={itemNameStyle}>{item.name}</div>}
          <div
            className={clsx(
              styles.item,
              { [styles.active]: isActive },
              { [styles.success]: isSuccess },
              { [styles.error]: isActive && isError },
              { [styles.psevdoOver]: overAfterActivated }
            )}
            onClick={handleClick}
            onMouseOut={() => setOverAfterActivated(false)}
          >
            <img src={item.image} alt={item.name} className={imageStyle} />
          </div>
        </div>
      )}
      {type === "str" && (
        <div
          className={clsx(
            stringItemStyle,
            { [styles.active]: isActive },
            { [styles.success]: isSuccess },
            { [styles.error]: isActive && isError },
            { [styles.psevdoOver]: overAfterActivated }
          )}
          onClick={handleClick}
          onMouseOut={() => setOverAfterActivated(false)}
        >
          {item.name}
        </div>
      )}
    </>
  );
};
