import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import kazan from "~/shared/assets/images/22/kazan.jpg";
import moskva from "~/shared/assets/images/22/moskva.jpg";
import spb from "~/shared/assets/images/22/spb.jpg";
import vladimir from "~shared/assets/images/22/vladimir.jpg";

const Theme22Task3 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Казань",
      image: kazan
    },
    {
      name: "Москва",
      image: moskva
    },
    {
      name: "Санкт-Петербург",
      image: spb
    },
    {
      name: "Владимир",
      image: vladimir
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какой город стал столицей при Петре I?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Санкт-Петербург' />
    </QuizWrapper>
  );
};

export default Theme22Task3;
