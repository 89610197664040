import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import russia from "~/shared/assets/images/1/russia.jpg";
import belarus from "~/shared/assets/images/1/belarus.jpg";
import china from "~/shared/assets/images/1/china.jpg";

const Theme1Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Беларусь",
      image: belarus
    },
    {
      name: "Россия",
      image: russia
    },
    {
      name: "Китай",
      image: china
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри флаги. Отметь среди них флаг России.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Россия' hideName />
    </QuizWrapper>
  );
};

export default Theme1Task1;
