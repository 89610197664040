import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import {
  QuizPuzzleAssembleObject,
  Answer,
  RootObject,
  DraggableItem,
  DropZone
} from "~/modules/QuizPuzzleAssembleObject";
import pryamougolnik from "~shared/assets/images/2/pryamougolnik.png";
import krug from "~shared/assets/images/2/krug.png";
import kvadrat from "~shared/assets/images/2/kvadrat.png";
import tramvai from "~shared/assets/images/2/tramvai.png";
import avtobus from "~shared/assets/images/2/avtobus.png";

const rootObject: RootObject = {
  imgUrl: pryamougolnik,
  imageWrapperStyle: { width: "30%" }
};

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    imgUrl: kvadrat,
    imageWrapperStyle: { width: "30%", height: "45%", bottom: "2%", left: "2%" },
    imageStyle: { width: "90%" }
  },
  {
    id: 2,
    imgUrl: krug,
    imageWrapperStyle: { width: "30%", height: "45%", bottom: "2%", right: "2%" },
    imageStyle: { width: "90%" }
  },
  {
    id: 3,
    imgUrl: tramvai,
    imageWrapperStyle: { width: "30%", height: "45%", top: "2%", right: "2%" },
    imageStyle: { width: "80%" }
  },
  {
    id: 4,
    imgUrl: avtobus,
    imageWrapperStyle: { width: "30%", height: "45%", top: "2%", left: "2%" },
    imageStyle: { width: "80%" }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  }
];

const answers: Answer = { 1: 1, 3: 3 };

const Theme2Task2 = () => {
  return (
    <QuizWrapper
      title='Пазл'
      icon='puzzle'
      subtitle='Собери знак "Остановка трамвая".'
      description='Перетащи верные элементы на синюю область'
      successIcon='gosha-1'
    >
      <QuizPuzzleAssembleObject
        rootObject={rootObject}
        draggableItems={draggableItems}
        dropZones={dropZones}
        answers={answers}
      />
    </QuizWrapper>
  );
};
export default Theme2Task2;
