import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import Grozny from "~shared/assets/images/20/Grozny.jpg";
import Lomonosov from "~shared/assets/images/20/Lomonosov.jpg";
import Pushkin from "~shared/assets/images/20/Pushkin.jpg";

const Theme20Task3 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Грозный",
      image: Grozny
    },
    {
      name: "Ломоносов",
      image: Lomonosov
    },
    {
      name: "Пушкин",
      image: Pushkin
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри портреты. Отметь портрет М.В. Ломоносова.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Ломоносов' hideName />
    </QuizWrapper>
  );
};

export default Theme20Task3;
