import React from "react";
export const Close: React.FC = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5554 2.24898L15.826 0.519531L8.99989 7.34564L2.17378 0.519531L0.444336 2.24898L7.27045 9.07509L0.444336 15.9012L2.17378 17.6306L8.99989 10.8045L15.826 17.6306L17.5554 15.9012L10.7293 9.07509L17.5554 2.24898Z'
        fill='white'
      />
    </svg>
  );
};
