import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseWords, wordTask } from "~/modules/QuizChooseWord";

const Theme19Task3 = () => {
  const tasks: wordTask[] = [
    {
      text: "Крыши у теремов Коломенского дворца",
      correctWord: "фигурные",
      words: [
        {
          text: "ровные",
          active: false
        },
        {
          text: "фигурные",
          active: false
        },
        {
          text: "круглые",
          active: false
        }
      ],
      activeWord: "____"
    },
    {
      text: "Над башенками блестят",
      correctWord: "флажки",
      words: [
        {
          text: "звёзды",
          active: false
        },
        {
          text: "флажки",
          active: false
        },
        {
          text: "трубы",
          active: false
        }
      ],
      activeWord: "____"
    },
    {
      text: "Крыльцо в покои стерегут четыре деревянных",
      correctWord: "льва",
      words: [
        {
          text: "льва",
          active: false
        },
        {
          text: "собаки",
          active: false
        },
        {
          text: "тигра",
          active: false
        }
      ],
      activeWord: "____"
    },
    {
      text: "В тронном зале возле царского места",
      correctWord: "львы",
      words: [
        {
          text: "тигры",
          active: false
        },
        {
          text: "гепарды",
          active: false
        },
        {
          text: "львы",
          active: false
        }
      ],
      activeWord: "____"
    }
  ];

  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Вспомни описание Коломенского дворца. Выбери нужное слово.'
      description='Дополни 4 предложения'
      successIcon='rus-1'
    >
      <QuizChooseWords wordTasks={tasks} />
    </QuizWrapper>
  );
};

export default Theme19Task3;
