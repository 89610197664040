import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import Germ from "~shared/assets/images/17/Germogen_shutterstock_1725601387.jpg";
import Vlad from "~shared/assets/images/17/Vladimir_shutterstock_1419441524.jpg";
import Your from "~shared/assets/images/17/Dolgorukiy_shutterstock_436892326.jpg";

const Theme17Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Монумент Гермогена",
      image: Germ
    },
    {
      name: "Владимир",
      image: Vlad
    },
    {
      name: "Юрий Долгорукий",
      image: Your
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Отметь памятник патриарху Гермогену.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Монумент Гермогена' hideName />
    </QuizWrapper>
  );
};

export default Theme17Task2;
