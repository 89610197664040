import React from "react";
export const Dan1: React.FC = () => {
  return (
    <svg
      width='144'
      height='140'
      viewBox='0 0 144 140'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M77.197 132.677C113.648 132.677 143.197 103.128 143.197 66.6768C143.197 30.226 113.648 0.676819 77.197 0.676819C40.7462 0.676819 11.197 30.226 11.197 66.6768C11.197 103.128 40.7462 132.677 77.197 132.677Z'
        fill='#1CB0C1'
      />
      <path
        d='M103.397 99.8768C84.797 87.1768 68.397 95.3768 65.597 98.5768C62.597 102.477 61.597 113.477 58.697 124.177C58.697 124.177 58.697 126.577 58.697 129.977C64.597 131.677 70.797 132.677 77.297 132.677C91.397 132.677 104.397 128.277 115.097 120.777C112.597 112.477 109.097 102.777 103.397 99.8768Z'
        fill='#FF7A00'
      />
      <path
        d='M72.9969 84.5768L72.397 96.4768C77.997 102.677 85.397 96.1768 87.197 93.5768L88.397 82.3768L72.9969 84.5768Z'
        fill='#FFD2A8'
      />
      <path
        d='M53.0971 135.177C31.9971 148.077 26.3971 132.577 17.9971 114.577L9.49707 108.877C6.29707 105.977 3.29711 102.977 1.09711 99.6768C0.197109 97.4768 1.59706 97.3767 3.29706 98.8767C4.89706 100.277 6.5971 101.377 8.3971 102.277C9.3971 102.377 9.29707 101.977 8.99707 101.377L3.79706 95.8767C2.79706 94.7767 2.29708 93.5768 3.69708 92.9768L2.99707 91.6768C2.29707 89.3768 4.89706 89.5767 7.29706 91.0767L15.6971 96.7768C16.5971 96.2768 15.7971 95.1768 14.2971 93.7768L9.8971 89.5767C8.8971 88.1767 9.6971 87.1768 11.3971 87.7768C14.7971 89.0768 18.0971 91.1768 21.3971 93.7768L24.9971 96.4768C25.7971 96.6768 26.4971 95.6768 27.0971 93.9768C28.3971 90.9768 30.3971 89.7767 32.8971 90.0767C34.6971 90.6767 35.1971 91.4767 34.3971 92.3767C32.1971 94.4767 31.4971 97.1768 31.7971 100.277C32.4971 104.377 32.3971 107.577 30.9971 109.377L30.8971 110.477C32.9971 118.877 36.8971 132.677 45.4971 120.477C46.4971 126.677 50.6971 129.377 53.0971 135.177Z'
        fill='#FFD2A8'
      />
      <path
        d='M12.7972 99.9768C12.8972 99.9768 12.9972 99.9768 13.0972 99.8768C13.1972 99.6768 13.1972 99.4768 12.9972 99.3768L3.89724 92.3768C3.69724 92.2768 3.49724 92.2768 3.39724 92.4768C3.29724 92.6768 3.29721 92.8768 3.49721 92.9768L12.5972 99.9768C12.6972 99.9768 12.7972 99.9768 12.7972 99.9768Z'
        fill='#7D0000'
      />
      <path
        d='M23.997 108.377H24.097C24.297 108.277 24.397 108.077 24.297 107.977C23.097 105.077 23.297 102.177 25.097 99.2768C25.197 99.0768 25.097 98.8768 24.997 98.7768C24.897 98.6768 24.597 98.7768 24.497 98.8768C22.597 101.977 22.297 105.077 23.697 108.177C23.797 108.377 23.897 108.377 23.997 108.377Z'
        fill='#7D0000'
      />
      <path
        d='M43.697 120.777C51.797 110.377 59.597 102.177 65.897 98.0768C63.697 107.077 66.397 119.177 63.897 128.177L50.897 137.977C46.397 131.377 44.497 128.377 43.697 120.777Z'
        fill='#FF7A00'
      />
      <path
        d='M60.4971 130.477H60.8971C60.8971 123.077 60.9971 113.777 62.6971 107.177L62.2971 106.977C60.6971 113.677 60.4971 123.077 60.4971 130.477Z'
        fill='#B0383F'
      />
      <path d='M72.7971 90.0768L73.0972 84.8768L82.3972 83.6768L72.7971 90.0768Z' fill='#F9836A' />
      <path
        d='M60.7969 33.4768C58.4969 36.4768 57.697 40.2768 57.897 44.9768C54.797 43.0768 52.497 43.1768 50.697 44.1768C49.297 37.8768 51.497 32.6768 56.897 28.7768L60.7969 33.4768Z'
        fill='#924026'
      />
      <path
        d='M58.9971 44.4768C59.0971 47.4768 58.0971 50.3768 56.3971 53.3768L53.797 56.3768C49.097 49.0768 51.1971 39.9768 58.9971 44.4768Z'
        fill='#FBA380'
      />
      <path
        d='M82.397 35.3768C87.397 32.2768 92.297 31.0768 96.897 34.7768C108.997 39.7768 97.497 54.7768 107.297 53.9768C119.697 50.8768 119.597 69.6768 103.897 69.5768C99.697 77.1768 94.797 82.6768 85.897 84.2768C80.897 85.1768 75.997 85.6768 70.997 85.6768C58.197 85.8768 46.097 63.7768 53.397 53.4768C56.397 49.9768 57.597 45.8768 57.297 41.0768C57.297 37.5768 58.397 34.8768 60.597 32.9768C65.897 38.8768 74.397 40.6768 82.397 35.3768Z'
        fill='#FFD2A8'
      />
      <path
        d='M80.997 55.1768C78.497 58.7768 75.097 55.9768 76.797 51.9768C79.697 46.4768 83.397 51.7768 80.997 55.1768Z'
        fill='white'
      />
      <path
        d='M80.0971 56.1768C82.0971 53.2768 79.7971 49.1768 77.3971 51.0768C77.1971 51.3768 76.9971 51.6768 76.8971 51.9768C75.2971 55.4768 77.7971 58.0768 80.0971 56.1768Z'
        fill='#7D0000'
      />
      <path
        d='M79.7746 51.4131C79.9803 50.9607 79.7804 50.427 79.3279 50.2213C78.8754 50.0155 78.3418 50.2155 78.136 50.6679C77.9302 51.1204 78.1302 51.654 78.5827 51.8598C79.0352 52.0656 79.5688 51.8656 79.7746 51.4131Z'
        fill='white'
      />
      <path
        d='M61.197 49.4768C58.697 53.0768 55.297 50.2768 56.997 46.2768C59.897 40.7768 63.597 46.0768 61.197 49.4768Z'
        fill='white'
      />
      <path
        d='M60.697 50.0768C62.697 47.1768 60.397 43.0768 57.997 44.9768C57.697 45.3768 57.3971 45.7768 57.0971 46.3768C55.5971 49.8768 57.997 52.4768 60.297 50.5768C60.397 50.3768 60.597 50.2768 60.697 50.0768Z'
        fill='#7D0000'
      />
      <g opacity='0.3'>
        <g opacity='0.5' filter='url(#filter0_f_958_24878)'>
          <path
            d='M80.0948 59.4397C82.5948 60.1397 84.6949 60.4397 83.9949 62.8397C83.2949 65.3397 80.6949 66.7397 78.1949 66.0397C75.6949 65.3397 74.2949 62.7397 74.9949 60.2397C75.6949 57.8397 77.5948 58.7397 80.0948 59.4397Z'
            fill='#F15331'
          />
        </g>
        <g opacity='0.5' filter='url(#filter1_f_958_24878)'>
          <path
            d='M58.0948 56.4397C60.5948 57.1397 62.6949 57.4397 61.9949 59.8397C61.2949 62.3397 58.6949 63.7397 56.1949 63.0397C53.6949 62.3397 52.2949 59.7397 52.9949 57.2397C53.6949 54.8397 55.5948 55.7397 58.0948 56.4397Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        d='M105.397 65.4769C108.097 65.4769 110.297 64.2769 111.097 62.2769C111.597 61.0769 111.397 59.6769 110.697 58.9769C110.197 58.4769 109.097 57.7769 106.897 58.6769C106.697 58.7769 106.597 58.9769 106.697 59.1769C106.797 59.3769 106.997 59.4768 107.197 59.3768C108.497 58.8768 109.497 58.9768 110.097 59.5768C110.697 60.1768 110.797 61.0768 110.397 62.0768C110.097 62.6768 108.997 64.7769 105.497 64.7769C105.297 64.7769 105.097 64.9769 105.097 65.1769C104.997 65.2769 105.097 65.4769 105.397 65.4769Z'
        fill='#7D0000'
      />
      <path
        d='M104.597 81.1768C124.297 70.4768 132.297 35.6768 110.197 26.8768C111.797 23.8768 120.197 25.0768 114.797 23.5768C112.297 22.7768 110.597 22.8768 107.397 24.2768C109.297 20.9768 110.797 15.9768 107.097 11.8768C107.997 14.5768 107.597 19.4768 106.097 22.7768C100.597 13.8768 90.497 6.7768 77.297 10.9768C66.097 14.0768 63.097 15.2768 58.997 13.1768C54.797 10.9768 52.0971 13.5768 52.8971 17.6768C52.3971 17.0768 51.097 16.5768 49.797 15.9768C48.697 15.4768 44.9971 15.9768 44.5971 17.0768C42.4971 24.0768 45.4971 29.8768 53.3971 34.8768C62.6971 41.8768 82.297 42.4768 93.497 36.4768C94.297 36.0768 95.597 36.9768 95.497 37.8768C95.197 44.1768 100.997 49.5768 100.997 56.6768C102.797 57.6768 103.997 54.1768 106.597 53.9768C118.997 50.8768 118.897 69.6768 103.197 69.5768C99.897 75.4768 96.097 80.1768 90.297 82.6768C97.497 85.3768 101.997 81.4768 106.597 77.9768C105.997 79.4768 105.497 79.9768 104.597 81.1768Z'
        fill='#924026'
      />
      <path
        d='M65.8971 63.0768C66.0971 63.0768 66.1971 62.8768 66.1971 62.7768C66.1971 62.6768 65.9971 62.4768 65.8971 62.4768C63.4971 62.4768 62.4971 61.2768 62.0971 60.5768C61.4971 59.5768 61.497 58.2768 61.997 57.4768C62.197 57.0768 62.6971 56.6768 63.3971 56.6768C64.5971 56.6768 65.4971 56.3768 66.0971 55.7768C66.6971 55.0768 66.5971 54.1768 66.5971 54.1768C66.5971 53.9768 66.3971 53.8768 66.1971 53.8768C65.9971 53.8768 65.8971 54.0768 65.8971 54.2768C65.8971 54.2768 65.9971 54.8768 65.5971 55.3768C65.1971 55.8768 64.497 56.0768 63.497 56.0768C62.597 56.0768 61.897 56.4768 61.497 57.1768C60.897 58.2768 60.8971 59.7768 61.5971 60.9768C61.9971 61.6768 63.1971 63.0768 65.8971 63.0768Z'
        fill='#7D0000'
      />
      <g opacity='0.4'>
        <g opacity='0.5' filter='url(#filter2_f_958_24878)'>
          <path
            d='M64.297 57.2768C65.797 57.1768 66.8971 56.0768 67.0971 58.2768C67.2971 60.4768 66.297 62.2768 64.797 62.3768C63.297 62.4768 61.997 60.8768 61.797 58.6768C61.597 56.5768 62.897 57.3768 64.297 57.2768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        d='M68.4971 60.5768C68.9971 60.5768 69.4971 60.0769 69.6971 59.4769C69.8971 58.8769 69.6971 58.2769 69.2971 57.9769C69.1971 57.8769 68.8971 57.8769 68.7971 57.9769C68.6971 58.0769 68.6971 58.3769 68.7971 58.4769C69.0971 58.7769 68.9971 59.1769 68.9971 59.2769C68.8971 59.5769 68.6972 59.8768 68.3972 59.8768C68.1972 59.8768 68.0972 60.0769 68.0972 60.2769C68.0972 60.4769 68.2971 60.5768 68.4971 60.5768Z'
        fill='#7D0000'
      />
      <path
        d='M68.3972 72.8768C73.2972 71.7768 74.0972 67.1768 74.0972 67.1768C74.0972 67.1768 64.7971 68.6768 63.2971 68.6768C64.3971 72.7768 66.3972 73.5768 68.3972 72.8768Z'
        fill='#7D0000'
      />
      <path
        d='M71.2971 68.7768C71.4971 68.0768 71.5972 67.5768 71.5972 67.5768C71.5972 67.5768 64.6971 68.7768 63.2971 68.7768C63.3971 69.2768 63.5971 69.7768 63.7971 70.2768C65.8971 69.6768 69.0971 69.0768 71.2971 68.7768Z'
        fill='white'
      />
      <path
        d='M60.4145 45.6829C60.6203 45.2305 60.4203 44.6968 59.9678 44.491C59.5153 44.2853 58.9817 44.4853 58.7759 44.9377C58.5701 45.3902 58.7701 45.9238 59.2226 46.1296C59.6751 46.3354 60.2087 46.1354 60.4145 45.6829Z'
        fill='white'
      />
      <path
        d='M52.4969 55.0768C52.5969 55.0768 52.7969 54.9768 52.7969 54.8768C53.1969 54.0768 54.197 52.2768 55.597 50.5768C57.097 48.8768 57.497 45.8768 57.597 44.5768C57.597 44.3768 57.4969 44.2768 57.2969 44.1768C57.0969 44.1768 56.997 44.2768 56.897 44.4768C56.897 45.6768 56.497 48.4768 55.097 49.9768C53.697 51.6768 52.5969 53.5768 52.1969 54.3768C52.0969 54.5768 52.197 54.7768 52.397 54.8768C52.397 54.9768 52.3969 55.0768 52.4969 55.0768Z'
        fill='#7D0000'
      />
      <path
        d='M84.6971 48.3768C84.8971 48.3768 85.0971 48.2768 85.1971 48.1768C85.4971 47.8768 85.4971 47.3768 85.2971 47.0768C84.4971 46.1768 82.4971 44.7768 80.1971 44.9768C79.7971 44.9768 79.4971 45.3768 79.4971 45.7768C79.4971 46.1768 79.8971 46.4768 80.2971 46.4768C82.0971 46.3768 83.6971 47.5768 84.0971 48.0768C84.2971 48.2768 84.4971 48.3768 84.6971 48.3768Z'
        fill='#7D0000'
      />
      <path
        d='M62.597 43.7768C62.797 43.7768 63.097 43.6768 63.197 43.4768C63.397 43.1768 63.397 42.6768 62.997 42.3768C61.997 41.6768 59.797 40.6768 57.597 41.3768C57.197 41.4768 56.997 41.8768 57.097 42.2768C57.197 42.6768 57.597 42.8768 57.997 42.7768C59.697 42.2768 61.497 43.1768 62.097 43.5768C62.297 43.6768 62.397 43.7768 62.597 43.7768Z'
        fill='#7D0000'
      />
      <path
        d='M95.6971 109.977L94.9971 109.877C94.1971 115.577 95.3971 122.877 96.9971 129.577C97.1971 129.477 97.3971 129.477 97.6971 129.377C96.0971 122.777 94.9971 115.677 95.6971 109.977Z'
        fill='#B0383F'
      />
      <defs>
        <filter
          id='filter0_f_958_24878'
          x='71.8149'
          y='55.6769'
          width='15.3164'
          height='13.5427'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24878' />
        </filter>
        <filter
          id='filter1_f_958_24878'
          x='49.8149'
          y='52.6769'
          width='15.3164'
          height='13.5427'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24878' />
        </filter>
        <filter
          id='filter2_f_958_24878'
          x='58.7766'
          y='53.9139'
          width='11.3459'
          height='11.4673'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_24878' />
        </filter>
      </defs>
    </svg>
  );
};
