import { QuizWrapper } from "~shared/components/QuizWrapper";
import QuizDoubleDrawLine from "~/modules/QuizDoubleDrawLine/QuizDoubleDrawLine";
import React from "react";

import Dino from "~shared/assets/images/skelet dinozavra.jpg";
import Sculp from "~shared/assets/images/skulptura.jpg";
import Monomah from "~shared/assets/images/shapka_monomaha.jpg";
import Rak from "~shared/assets/images/krasivaya_rakovina.jpg";
import Kareta from "~shared/assets/images/kareta.jpg";
import kartina from "~shared/assets/images/kartina.jpg";

import Oruj from "~shared/assets/images/orujehnaya palata.png";
import Zoo from "~shared/assets/images/zoo museum (1).png";
import Tret from "~shared/assets/images/tretyakovka.png";

type DrawLineBlock = {
  name: string;
  url?: string;
  pair?: string;
};

const firstBlocks: DrawLineBlock[] = [
  {
    url: Dino,
    name: "Динозавр",
    pair: "зоологический музей"
  },
  {
    url: Sculp,
    name: "скульптура",
    pair: "третьяковская галерея"
  },
  {
    url: Monomah,
    name: "шапка мономаха",
    pair: "Оружейная палата"
  }
];

const secondBlocks: DrawLineBlock[] = [
  {
    url: Oruj,
    name: "Оружейная палата",
    pair: "шапка мономаха"
  },
  {
    url: Tret,
    name: "третьяковская галерея",
    pair: "скульптура"
  },
  {
    url: Zoo,
    name: "зоологический музей",
    pair: "Динозавр"
  }
];

const firstBlocksNext: DrawLineBlock[] = [
  {
    url: Rak,
    name: "ракушка",
    pair: "зоологический музей"
  },
  {
    url: Kareta,
    name: "карета",
    pair: "Оружейная палата"
  },
  {
    url: kartina,
    name: "картина",
    pair: "третьяковская галерея"
  }
];

const secondBlocksNext: DrawLineBlock[] = [
  {
    url: Oruj,
    name: "Оружейная палата",
    pair: "карета"
  },
  {
    url: Tret,
    name: "третьяковская галерея",
    pair: "картина"
  },
  {
    url: Zoo,
    name: "зоологический музей",
    pair: "ракушка"
  }
];

const Theme29Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Рассмотри предметы. В каком музее они могут быть?'
      description='Нажимай на предмет и музей в котором он представлен'
      successIcon='rus-2'
    >
      <QuizDoubleDrawLine
        firstCol={firstBlocks}
        secondCol={secondBlocks}
        firstColNext={firstBlocksNext}
        secondCoLNext={secondBlocksNext}
      />
    </QuizWrapper>
  );
};

export default Theme29Task1;
