import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import serp from "~shared/assets/images/32/serp.jpg";
import molot from "~shared/assets/images/32/molot-2.jpg";
import topor from "~shared/assets/images/32/topor.jpg";
import pila from "~shared/assets/images/32/pila.jpg";
import kosa from "~shared/assets/images/32/kosa.jpg";

const Theme32Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Серп",
      image: serp
    },
    {
      name: "Молот",
      image: molot
    },
    {
      name: "Топор",
      image: topor
    },
    {
      name: "Пила",
      image: pila
    },
    {
      name: "Коса",
      image: kosa
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри предметы и отметь среди них серп.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Серп' hideName />
    </QuizWrapper>
  );
};

export default Theme32Task1;
