import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme14Task2 = () => {
  const items: { name: string }[] = [
    {
      name: "Юрий Долгорукий"
    },
    {
      name: "Иван Калита"
    },
    {
      name: "Дмитрий Донской"
    },
    { name: "Иван III" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='При каком князе Кремль обнесли красной кирпичной стеной? '
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Иван III' sameHeight />
    </QuizWrapper>
  );
};

export default Theme14Task2;
