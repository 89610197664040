import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItems } from "~/modules/QuizChooseItems";
import druzhinnik from "~shared/assets/images/9/druzhinnik.jpg";
import monah from "~shared/assets/images/9/monah.jpg";
import kuznets from "~shared/assets/images/9/kuznets.jpg";
import tkach from "~shared/assets/images/9/tkach.jpg";
import sapojnik from "~shared/assets/images/9/sapojnik.jpg";

const Theme9Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Кузнец",
      image: kuznets
    },
    {
      name: "Монах",
      image: monah
    },
    {
      name: "Сапожник",
      image: sapojnik
    },
    {
      name: "Воин",
      image: druzhinnik
    },
    {
      name: "Ткач",
      image: tkach
    }
  ];
  return (
    <QuizWrapper
      title='Задание'
      icon='question'
      subtitle='Рассмотри рисунки. Отметь ремесленников.'
      description='Выбери 3 правильных ответа'
      successIcon='gosha-2'
    >
      <QuizChooseItems type='img' items={items} correctItems={["Кузнец", "Сапожник", "Ткач"]} />
    </QuizWrapper>
  );
};

export default Theme9Task1;
