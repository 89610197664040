import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizEnterWord } from "~modules/QuizEnterWord";

const Theme27Task1 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Дополни предложение.'
      description='Напечатай пропущенное слово'
      successIcon='nast-2'
    >
      <QuizEnterWord
        question='Первые ______ зажглись в Кремле почти триста лет назад на Рождество.'
        answer='Фонари'
      />
    </QuizWrapper>
  );
};

export default Theme27Task1;
