import React from "react";
import styles from "./AppButton.module.scss";

type Props = {
  value: string;
  onClick?: () => void;
};

export const AppButton: React.FC<Props> = ({ value, onClick }) => {
  return (
    <button className={styles.appButton} onClick={onClick}>
      {value}
    </button>
  );
};
