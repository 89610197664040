import React, { useEffect, useState } from "react";
import { QuizResult } from "~/shared/components/QuizResult";
import styles from "./QuizTest.module.scss";
import { SelectButton } from "~/shared/components/SelectButton";
import { useQuizContext } from "~shared/hooks/useQuizContext";

type Props = {
  questions: { question: string; answer: "Да" | "Нет" }[];
};

export const QuizTest: React.FC<Props> = ({ questions }) => {
  const [userAnswers, setUserAnswers] = useState<Record<number, string>>({});
  const [showResult, setShowResult] = useState(false);
  const [error, setError] = useState(false);
  const { setSuccess } = useQuizContext();

  const handleAnswer = (index: number, answer: string) => {
    setError(false);
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: answer
    }));
  };

  useEffect(() => {
    if (
      Object.entries(userAnswers).length > 0 &&
      Object.entries(userAnswers).some(([key, value]) => value !== questions[+key].answer)
    )
      setError(true);

    if (Object.keys(userAnswers).length < questions.length) return;
    setShowResult(true);
  }, [userAnswers]);

  const success = showResult && questions.every((el, index) => el.answer === userAnswers[index]);

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(success), 700);
      return;
    }
    setSuccess(success);
  }, [success]);

  return (
    <div className={styles.quizTest}>
      <div className={styles.questionsWr}>
        {questions.map((question, index) => (
          <div key={index} className={styles.itemWr}>
            <span className={styles.text}>{question.question}</span>
            <div className={styles.buttonWr}>
              <SelectButton
                value='Да'
                userAnswers={userAnswers}
                index={index}
                questions={questions}
                onClick={handleAnswer}
              />
              <SelectButton
                value='Нет'
                userAnswers={userAnswers}
                index={index}
                questions={questions}
                onClick={handleAnswer}
              />
            </div>
          </div>
        ))}
      </div>
      <QuizResult errorVisible={error} buttonValue='Проверить' buttonVisible={false} />
    </div>
  );
};
