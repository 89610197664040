import React from "react";
export const Gosha1: React.FC = () => {
  return (
    <svg
      width='161'
      height='138'
      viewBox='0 0 161 138'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M94.9722 132.677C131.423 132.677 160.972 103.128 160.972 66.6768C160.972 30.226 131.423 0.676819 94.9722 0.676819C58.5214 0.676819 28.9722 30.226 28.9722 66.6768C28.9722 103.128 58.5214 132.677 94.9722 132.677Z'
        fill='#C4E4FB'
      />
      <path
        d='M124.672 93.1769C121.472 91.4769 119.741 87.152 110.941 85.052C102.241 84.152 85.0722 84.1769 73.7722 86.7769C67.9722 88.0769 67.9722 90.8769 67.9722 90.8769C67.9722 90.8769 65.3722 113.977 66.1722 121.477C66.2722 122.677 66.3722 124.277 66.4722 126.277C75.0722 130.377 84.7722 132.677 94.9722 132.677C105.372 132.677 115.272 130.277 124.072 125.977C126.772 110.577 129.872 95.3769 124.672 93.1769Z'
        fill='#EB3E48'
      />
      <path
        d='M114.69 86.1769C113.59 88.8769 108.291 105.702 111.191 116.302C117.564 114.39 131.635 119.396 129.272 123.177C135.572 119.277 141.272 114.377 145.972 108.677C139.472 101.077 125.565 89.4269 114.69 86.1769Z'
        fill='#EB3E48'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.672 105.477C39.072 105.577 34.572 96.3768 34.572 96.3768C32.672 95.7768 32.972 100.077 30.772 103.877C29.972 105.277 28.172 110.877 28.872 111.477C33.772 115.077 38.672 105.477 38.672 105.477Z'
        fill='#EB3E48'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.3721 100.377C35.3721 100.377 33.8721 96.9768 33.3721 95.7768C32.8721 94.5768 31.4721 93.1768 30.2721 92.9768C27.8721 92.8768 24.1721 94.1768 24.1721 94.1768C23.7721 95.1768 19.3721 96.5768 15.0721 96.8768C9.57209 97.2768 5.97209 97.5768 4.67209 99.2768C0.372091 99.6768 0.772088 102.077 0.872088 102.577C0.872088 102.677 3.17209 103.277 3.17209 103.277L3.37209 104.377L8.57209 104.977C6.67209 105.577 10.2721 107.177 13.2721 107.177C14.6721 107.177 20.7721 107.877 26.2721 106.377C26.4721 106.277 30.9721 105.277 31.7721 104.777C33.6721 103.677 35.3721 100.377 35.3721 100.377Z'
        fill='#FCD1A9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.8721 92.2769C23.9721 92.6769 25.2721 93.6769 30.1721 94.6769L23.0721 97.1769L22.0721 96.7769C21.5721 96.3769 18.0721 92.5769 21.8721 92.2769Z'
        fill='#FCD1A9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.1721 102.077C40.1721 102.077 35.9721 96.5768 31.6721 93.6768C31.1721 93.2768 29.8721 92.6768 29.3721 92.5768C24.4721 91.5768 21.6721 91.9768 19.5721 91.5768C17.4721 91.1768 16.9721 93.3768 18.3721 94.2768C19.5721 95.1768 20.6721 95.1768 21.2721 95.2768L20.5721 95.5768C18.8721 96.3768 17.9721 96.6768 15.0721 96.8768C9.57209 97.2768 5.97209 97.5768 4.67209 99.2768C0.372091 99.6768 0.772088 102.077 0.872088 102.577C0.872088 102.677 3.17209 103.277 3.17209 103.277L3.37209 104.377L8.57209 104.977C6.67209 105.577 10.2721 106.877 13.2721 107.177C19.1721 107.777 26.9721 106.977 28.2721 106.477C28.2721 106.577 36.9721 113.977 36.9721 113.977L40.1721 102.077Z'
        fill='#FCD1A9'
      />
      <path
        d='M8.27209 105.477C8.17209 105.477 8.17208 105.477 8.07208 105.377C7.97208 105.277 7.87209 105.077 7.97209 104.977C8.77209 103.877 12.0721 103.777 12.4721 103.777L16.9721 103.877C17.1721 103.877 17.2721 103.977 17.2721 104.177C17.2721 104.377 17.0721 104.477 16.9721 104.477L12.4721 104.377C11.1721 104.377 8.97208 104.577 8.57208 105.277C8.47208 105.377 8.37209 105.477 8.27209 105.477Z'
        fill='#8E3821'
      />
      <path
        d='M3.17198 104.377C3.07198 104.377 2.87197 104.277 2.87197 104.077C2.77197 103.677 2.87198 103.277 3.17198 102.977C4.97198 100.777 14.472 101.077 15.572 101.077C15.772 101.077 15.872 101.277 15.872 101.377C15.872 101.577 15.772 101.677 15.572 101.677C12.772 101.577 5.07198 101.677 3.67198 103.377C3.47198 103.577 3.47198 103.777 3.47198 103.977C3.47198 104.077 3.37198 104.277 3.17198 104.377Z'
        fill='#8E3821'
      />
      <path
        d='M4.87219 99.8768C4.77219 99.8768 4.57219 99.7768 4.57219 99.6768C4.47219 99.4768 4.5722 99.2768 4.7722 99.2768C4.9722 99.1768 9.17219 97.8768 15.8722 99.1768C16.0722 99.1768 16.1722 99.3768 16.1722 99.5768C16.1722 99.7768 15.9722 99.8768 15.7722 99.7768C9.1722 98.4768 5.0722 99.7768 4.9722 99.7768C4.9722 99.8768 4.97219 99.8768 4.87219 99.8768Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M79.472 113.377C77.172 106.577 73.872 92.9768 69.072 88.9768C62.072 94.9768 55.772 107.177 54.372 110.177C52.672 108.477 48.672 103.577 40.572 101.277L37.572 98.4768C39.072 101.377 37.772 107.277 31.672 111.177C30.672 111.877 29.272 111.777 28.772 111.477L32.072 114.377C30.572 116.377 33.372 121.577 35.172 124.477C36.972 127.277 48.172 139.777 53.972 137.477C62.872 134.877 80.972 117.777 79.472 113.377Z'
        fill='#EB3E48'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M91.872 98.4767C88.672 98.1767 87.172 91.8767 79.572 90.7767C77.772 90.4767 67.8164 89.8766 67.8164 89.8766C55.6901 78.9268 82.5899 73.3267 88.6899 74.9267C122.315 74.9267 119.816 88.3018 119.816 88.3018C119.816 88.3018 115.172 90.1768 112.972 89.8768C101.672 88.7768 101.072 91.7767 91.872 98.4767Z'
        fill='#EB3E48'
      />
      <path
        d='M110.705 87.0617L110.603 87.067C110.081 86.895 109.667 86.8169 109.248 86.6395C107.371 86.0398 106.118 85.6069 102.279 86.7024C102.075 86.7131 101.967 86.6191 101.86 86.525C101.849 86.3264 101.947 86.2218 102.044 86.1172C106.087 85.011 107.448 85.5379 109.426 86.1323C109.846 86.3097 110.26 86.3877 110.782 86.5598C110.991 86.6485 111.099 86.7426 111.007 86.9465C110.904 86.9518 110.807 87.0564 110.705 87.0617Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M91.2722 93.7768C87.1722 88.9768 81.3722 85.4768 80.9722 86.1768C85.0722 81.5768 104.872 83.8768 102.372 86.1768C102.372 86.0768 98.8722 86.7768 91.2722 93.7768Z'
        fill='#0A525C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M100.772 72.9768V85.3768C96.0722 89.2768 89.6722 92.4768 84.9722 84.2768L85.4722 72.8768H100.772V72.9768Z'
        fill='#FCD1A9'
      />
      <mask
        id='mask0_958_25816'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='84'
        y='72'
        width='17'
        height='18'
      >
        <path
          d='M85.4722 72.9768L84.9722 84.3768C89.6722 92.5768 96.0722 89.3768 100.772 85.4768V73.0768H85.4722V72.9768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_958_25816)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M78.3721 77.8768C79.0721 78.7768 100.172 80.0768 97.3721 92.6768C95.1721 102.877 114.072 82.1768 109.672 73.9768L78.3721 77.8768Z'
          fill='#F9BC90'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M73.372 65.2768C71.472 66.1768 69.072 68.0768 69.072 68.0768C70.272 62.9768 69.072 52.6768 68.272 46.9768C69.672 45.8768 74.872 47.7768 75.672 46.5768L75.772 46.4768C77.572 51.7768 78.672 56.9768 78.272 62.4768L75.672 58.2768C75.472 61.0768 75.272 64.3768 73.372 65.2768Z'
        fill='#D9A01C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.672 68.1768C110.172 67.9768 109.572 67.3768 109.572 67.3768C111.672 64.0768 115.172 59.1768 117.072 53.8768C118.272 53.6768 119.672 54.8768 120.672 54.2768H120.772C119.672 59.6768 118.172 64.7768 115.872 69.3768L116.072 64.4768C114.672 66.6768 113.172 68.3768 111.672 68.1768Z'
        fill='#D9A01C'
      />
      <path
        d='M89.5721 81.2768C100.672 82.0768 109.672 76.0768 114.872 64.2768C114.972 63.9768 116.072 59.6768 116.072 59.6768C116.072 59.6768 124.372 59.8768 125.172 51.7768C125.672 45.0768 119.672 46.2768 119.672 46.2768C120.272 34.3768 119.172 25.2768 106.072 25.8768C101.672 19.8768 81.4721 19.1768 73.9721 25.9768C72.1721 27.5768 68.7721 39.3768 68.4721 43.8768C59.6721 41.3768 62.8721 56.6768 68.2721 55.9768C69.9721 62.4768 73.5721 79.9768 89.5721 81.2768Z'
        fill='#FFD2A8'
      />
      <path
        d='M118.872 56.0768C118.772 56.0768 118.572 55.9768 118.572 55.8768C118.472 55.6768 118.572 55.4768 118.772 55.4768C121.472 54.4768 122.472 52.3768 122.172 51.2768C121.972 50.5768 121.372 50.2768 120.272 50.4768C120.072 50.4768 119.972 50.3768 119.872 50.1768C119.872 49.9768 119.972 49.8768 120.172 49.7768C121.472 49.5768 122.472 50.0768 122.772 51.0768C123.172 52.6768 121.772 55.0768 118.972 56.0768C118.972 56.0768 118.972 56.0768 118.872 56.0768Z'
        fill='#8E3821'
      />
      <path
        d='M67.572 52.3768C67.472 52.3768 67.472 52.3768 67.372 52.3768C65.372 51.2768 64.472 48.5768 65.072 47.1768C65.372 46.3768 66.072 46.0768 66.872 46.2768C67.072 46.2768 67.172 46.4768 67.072 46.6768C67.072 46.8768 66.872 46.9768 66.672 46.8768C66.172 46.7768 65.772 46.8768 65.672 47.3768C65.272 48.4768 65.872 50.7768 67.672 51.7768C67.872 51.8768 67.872 52.0768 67.772 52.1768C67.772 52.3768 67.672 52.3768 67.572 52.3768Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M76.6721 40.2768C77.5721 39.9768 78.6721 40.0768 79.5721 40.1768C80.0721 40.1768 80.5721 39.8768 80.5721 39.4768C80.6721 38.9768 80.2721 38.5768 79.8721 38.3768C78.5721 38.0768 77.3721 38.2768 76.1721 38.6768C75.6721 38.7768 75.4721 39.2768 75.5721 39.7768C75.7721 40.2768 76.4721 40.4768 76.6721 40.2768Z'
        fill='#7D0000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M105.472 44.6768C104.572 44.2768 103.572 44.1768 102.572 44.1768C102.072 44.0768 101.572 43.7768 101.672 43.3768C101.572 42.7768 102.072 42.4768 102.572 42.3768C103.872 42.2768 105.072 42.5768 106.172 43.1768C106.572 43.3768 106.772 43.8768 106.672 44.3768C106.372 44.7768 105.672 44.8768 105.472 44.6768Z'
        fill='#7D0000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.4722 26.3768C66.8722 24.6768 73.9722 14.6768 77.5722 12.5768L75.5722 12.2768C80.7722 9.07682 88.2722 7.57682 94.2722 8.47682C93.6722 7.87682 92.4722 5.27682 91.3722 4.67682C96.8722 5.07682 97.7722 7.97682 98.4722 9.07682C99.3722 9.07682 100.072 9.27682 100.372 9.47682C107.772 10.0768 112.472 11.3768 119.372 18.6768C124.272 23.8768 125.272 32.4768 124.772 35.2768C124.172 33.5768 123.672 32.5768 122.172 31.6768C122.172 31.7768 123.272 40.6768 122.572 46.0768C120.972 45.8768 118.872 46.4768 118.872 46.4768C118.872 46.4768 114.072 55.1768 113.172 58.6768C115.972 46.3768 115.272 42.1768 114.672 39.8768L113.472 40.2768C114.072 36.3768 112.972 30.2768 112.972 30.5768C112.972 33.3768 111.472 36.0768 110.972 38.9768C110.872 35.2768 111.972 31.6768 110.972 28.5768C109.372 31.1768 107.372 33.6768 105.272 35.3768C105.372 33.8768 105.772 32.4768 105.172 30.8768C104.672 32.2768 104.072 33.7768 102.972 35.6768C101.672 33.5768 100.572 31.4768 100.872 28.2768C100.272 30.5768 99.5722 32.7768 99.8722 35.1768C98.9722 33.2768 98.1722 31.3768 97.7722 28.7768C96.9722 31.0768 95.2722 33.0768 92.2722 34.6768C93.1722 33.3768 94.0722 32.1768 94.5722 30.4768C93.1722 31.8768 91.6722 33.1768 88.8722 33.8768C90.1722 31.5768 91.2722 29.0768 90.7722 25.9768C88.6722 28.0768 86.1722 30.1768 82.6722 31.7768C84.1722 30.0768 85.4722 28.2768 86.3722 26.3768C84.1722 27.2768 81.9722 28.7768 79.4722 31.2768C80.1722 29.7768 80.9722 28.2768 81.8722 26.5768C78.8722 27.7768 75.4722 35.7768 73.8722 39.0768C73.5722 36.7768 73.1722 28.5768 73.6722 26.1768C70.9722 33.8768 70.0722 48.3768 70.5722 54.2768C69.6722 50.0768 69.6722 44.3768 66.8722 43.1768C66.7722 41.7768 68.5722 27.5768 69.7722 23.8768C68.8722 23.7768 66.5722 25.8768 65.4722 26.3768Z'
        fill='#D9A01C'
      />
      <path
        d='M107.272 52.7768C107.072 52.7768 100.172 51.0768 98.0721 52.7768C95.9721 54.4768 103.872 55.1768 103.872 55.1768L107.272 52.7768Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M75.7722 49.6769C75.8722 51.6769 77.3722 53.1768 78.9722 53.0768C80.6722 52.9768 81.8722 51.2768 81.7722 49.2768C81.6722 47.2768 80.1722 45.7769 78.5722 45.8769C76.8722 45.9769 75.6722 47.6769 75.7722 49.6769Z'
        fill='white'
      />
      <mask
        id='mask1_958_25816'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='75'
        y='44'
        width='7'
        height='9'
      >
        <path
          d='M78.5726 44.8768C76.8726 44.9768 75.6727 46.6768 75.7727 48.6768C75.8727 50.6768 77.3726 52.1768 78.9726 52.0768C80.5726 51.9768 81.8727 50.2768 81.7727 48.2768C81.6727 46.3768 80.2727 44.8768 78.6727 44.8768C78.6727 44.8768 78.5726 44.8768 78.5726 44.8768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_958_25816)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M76.3726 47.8768C76.4726 49.5768 77.5726 50.9768 78.8726 50.8768C80.1726 50.7768 81.1726 49.3768 81.0726 47.5768C80.9726 45.8768 79.8726 44.4768 78.5726 44.5768C77.2726 44.6768 76.2726 46.1768 76.3726 47.8768Z'
          fill='#7D0000'
        />
      </g>
      <mask
        id='mask2_958_25816'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='75'
        y='44'
        width='7'
        height='9'
      >
        <path
          d='M78.5726 44.8768C76.8726 44.9768 75.6727 46.6768 75.7727 48.6768C75.8727 50.6768 77.3726 52.1768 78.9726 52.0768C80.5726 51.9768 81.8727 50.2768 81.7727 48.2768C81.6727 46.3768 80.2727 44.8768 78.6727 44.8768C78.6727 44.8768 78.5726 44.8768 78.5726 44.8768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask2_958_25816)'>
        <path
          d='M77.0726 46.0768C77.5726 46.1768 77.8726 46.6768 77.7726 47.1768C77.6726 47.6768 77.1726 48.0767 76.7726 47.9767C76.2726 47.8767 75.9726 47.3768 76.0726 46.8768C76.0726 46.2768 76.5726 45.9768 77.0726 46.0768Z'
          fill='white'
        />
      </g>
      <path
        d='M83.2718 50.3679C83.0718 50.3679 76.3718 48.7679 74.3718 50.4679C72.3718 52.1679 80.0024 53.7392 80.0024 53.7392L83.2718 50.3679Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M99.472 50.9768C99.572 52.9768 101.072 54.4768 102.672 54.3768C104.372 54.2768 105.572 52.5768 105.472 50.5768C105.372 48.5768 103.872 47.0768 102.272 47.1768C100.572 47.2768 99.272 48.9768 99.472 50.9768Z'
        fill='white'
      />
      <mask
        id='mask3_958_25816'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='99'
        y='46'
        width='7'
        height='8'
      >
        <path
          d='M102.172 46.1768C100.472 46.2768 99.2723 47.9768 99.3723 49.9768C99.4723 51.9768 100.972 53.4768 102.572 53.3768C104.272 53.2768 105.472 51.5768 105.372 49.5768C105.272 47.6768 103.872 46.1768 102.272 46.1768C102.272 46.1768 102.272 46.1768 102.172 46.1768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask3_958_25816)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M100.072 49.1768C100.172 50.8768 101.272 52.2768 102.572 52.1768C103.872 52.0768 104.872 50.5768 104.772 48.8768C104.672 47.1768 103.572 45.7768 102.272 45.8768C100.872 45.9768 99.9723 47.4768 100.072 49.1768Z'
          fill='#7D0000'
        />
      </g>
      <mask
        id='mask4_958_25816'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='99'
        y='46'
        width='7'
        height='8'
      >
        <path
          d='M102.172 46.1768C100.472 46.2768 99.2723 47.9768 99.3723 49.9768C99.4723 51.9768 100.972 53.4768 102.572 53.3768C104.272 53.2768 105.472 51.5768 105.372 49.5768C105.272 47.6768 103.872 46.1768 102.272 46.1768C102.272 46.1768 102.272 46.1768 102.172 46.1768Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask4_958_25816)'>
        <path
          d='M100.772 47.1768C101.272 47.2768 101.572 47.7768 101.472 48.2768C101.372 48.7768 100.872 49.1768 100.472 49.0768C100.072 48.9768 99.6724 48.4768 99.7724 47.9768C99.7724 47.4768 100.272 47.0768 100.772 47.1768Z'
          fill='white'
        />
      </g>
      <path
        d='M106.973 51.768C106.773 51.768 100.073 50.168 98.0728 51.868C96.0728 53.568 103.754 55.3019 103.754 55.3019L106.973 51.768Z'
        fill='#FFD2A8'
      />
      <path
        d='M92.0721 58.5769C91.9721 58.5769 91.8721 58.4769 91.7721 58.3769C91.6721 58.1769 91.7721 57.9769 91.9721 57.9769C92.1721 57.8769 92.2721 57.6769 92.2721 57.3769C92.2721 57.2769 92.2721 56.8769 91.9721 56.7769C91.7721 56.6769 91.7721 56.4769 91.7721 56.3769C91.8721 56.1769 92.0721 56.1769 92.1721 56.1769C92.5721 56.3769 92.8721 56.7769 92.8721 57.3769C92.8721 57.8769 92.5721 58.3769 92.1721 58.5769C92.0721 58.4769 92.0721 58.5769 92.0721 58.5769Z'
        fill='#8E3821'
      />
      <path
        d='M87.372 58.6768C87.272 58.6768 87.172 58.6768 87.172 58.5768C87.172 58.5768 86.672 58.0768 85.972 57.1768C85.172 56.1768 85.472 55.1768 86.072 54.5768C86.172 54.4768 86.272 54.3768 86.472 54.2768C87.072 53.7768 87.772 53.1768 87.872 52.1768C87.872 51.9768 88.072 51.8768 88.172 51.8768C88.372 51.8768 88.472 52.0768 88.472 52.1768C88.372 53.3768 87.472 54.1768 86.872 54.6768C86.772 54.7768 86.572 54.8768 86.572 54.9768C86.272 55.2768 85.972 55.8768 86.572 56.6768C87.272 57.5768 87.672 57.9768 87.672 58.0768C87.772 58.1768 87.772 58.3768 87.672 58.5768C87.472 58.6768 87.372 58.6768 87.372 58.6768Z'
        fill='#8E3821'
      />
      <path
        d='M32.7721 114.777C32.6721 114.777 32.6721 114.777 32.5721 114.777H32.4721C32.2721 114.777 32.1721 114.677 32.1721 114.477C32.1721 114.277 32.2721 114.177 32.4721 114.177H32.5721C32.6721 114.177 32.6721 114.177 32.6721 114.177C37.1721 114.177 39.0721 110.977 39.8721 108.277C39.8721 108.177 39.9721 108.177 39.9721 108.077C41.9721 106.677 41.8721 102.677 41.8721 102.677C41.8721 102.477 41.9721 102.377 42.1721 102.377C42.3721 102.377 42.4721 102.477 42.4721 102.677C42.4721 102.877 42.6721 106.877 40.4721 108.577C39.2721 112.577 36.5721 114.777 32.7721 114.777Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.4722 108.077C41.1722 109.577 41.6722 110.077 42.8722 110.577L40.4722 108.077Z'
        fill='#EB3E48'
      />
      <g opacity='0.5'>
        <g opacity='0.4' filter='url(#filter0_f_958_25816)'>
          <path
            d='M97.8591 57.5991C97.8591 60.4991 100.159 62.7991 102.959 62.7991C105.859 62.7991 108.159 60.3991 108.159 57.4991C108.159 54.5991 105.959 53.5991 103.059 53.5991C100.259 53.5991 97.8591 54.6991 97.8591 57.5991Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.5'>
        <g opacity='0.4' filter='url(#filter1_f_958_25816)'>
          <path
            d='M72.1244 56.1083C72.2244 58.9083 74.6244 61.0083 77.4244 60.9083C80.2244 60.8083 82.3244 58.4083 82.2244 55.6083C82.1244 52.8083 79.8244 51.9083 77.0244 52.1083C74.2244 52.3083 72.0244 53.4083 72.1244 56.1083Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.3'>
        <g opacity='0.4' filter='url(#filter2_f_958_25816)'>
          <path
            d='M85.7722 56.2768C86.3722 58.1768 87.8722 59.3768 89.1722 58.9768C90.4722 58.5768 91.0722 56.6768 90.4722 54.7768C89.8722 52.8768 89.0722 54.0768 87.7722 54.4768C86.4722 54.8768 85.1722 54.3768 85.7722 56.2768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.3'>
        <mask
          id='mask5_958_25816'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='87'
          y='54'
          width='3'
          height='3'
        >
          <path d='M89.7721 54.7768H87.6721V56.6768H89.7721V54.7768Z' fill='white' />
        </mask>
        <g mask='url(#mask5_958_25816)'>
          <path
            d='M88.072 56.4768C87.172 55.9768 87.672 54.3768 89.072 54.7768C90.572 55.2768 89.472 57.1768 88.072 56.4768Z'
            fill='white'
          />
        </g>
      </g>
      <path
        d='M89.0855 67.3398C89.9839 67.2869 90.6827 67.2458 91.4578 66.7994C91.6516 66.6879 91.7397 66.4823 91.6281 66.2885C91.5165 66.0948 91.311 66.0067 91.1172 66.1183C90.5359 66.453 89.9369 66.4883 88.9386 66.5471C88.0402 66.6 86.9186 66.2653 86.9186 66.2653C86.7131 66.1772 86.5193 66.2888 86.531 66.4885C86.4429 66.694 86.5545 66.8878 86.7542 66.876C86.8658 67.0698 88.0872 67.3986 89.0855 67.3398Z'
        fill='#7D0000'
      />
      <path
        d='M91.8263 64.574C92.6249 64.527 93.3002 64.0866 94.2456 63.1293C94.3395 63.0236 94.4217 62.7183 94.2162 62.6302C94.1105 62.5363 93.8051 62.4541 93.7171 62.6596C92.3019 64.1453 91.9026 64.1688 89.7592 63.4937C88.6318 63.0592 88.0563 63.4937 87.5748 63.8226C86.9935 64.1574 86.7057 64.3746 85.5547 63.5409C85.449 63.4469 85.1496 63.4645 85.0556 63.5702C84.9617 63.6759 84.9793 63.9754 85.085 64.0694C86.5531 65.185 87.2402 64.9442 88.0094 64.3981C88.4909 64.0692 88.7786 63.8519 89.5948 64.1044C90.5168 64.4508 91.2274 64.6093 91.8263 64.574Z'
        fill='#7D0000'
      />
      <defs>
        <filter
          id='filter0_f_958_25816'
          x='94.8591'
          y='50.5991'
          width='16.3'
          height='15.2'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25816' />
        </filter>
        <filter
          id='filter1_f_958_25816'
          x='69.1211'
          y='49.0816'
          width='16.1067'
          height='14.8301'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25816' />
        </filter>
        <filter
          id='filter2_f_958_25816'
          x='83.6216'
          y='51.7343'
          width='9.08423'
          height='9.31946'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1' result='effect1_foregroundBlur_958_25816' />
        </filter>
      </defs>
    </svg>
  );
};
