import React from "react";
import styles from "./ImageRebus.module.scss";
import clsx from "clsx";

type Props = {
  url: string | null;
  className?: string;
};

export const ImageRebus: React.FC<Props> = ({ url, className = "" }) => {
  return (
    <div
      className={clsx(styles.rebus, className)}
      style={{
        backgroundImage: `url(${url})`
      }}
    ></div>
  );
};
