import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTimeLine, Answer, DraggableItem, DropZone } from "~modules/QuizTimeLine";
import belyiKreml from "~shared/assets/images/14/belyi-kreml.jpg";
import krasnyiKreml from "~shared/assets/images/14/krasnyi-kreml.jpg";
import derevyannyiKreml from "~shared/assets/images/14/derevyannyi-kreml.jpg";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    imgUrl: belyiKreml,
    title: "Белокаменный Кремль",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 2,
    imgUrl: krasnyiKreml,
    title: "Кремль из красного кирпича",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 3,
    imgUrl: derevyannyiKreml,
    title: "Деревянный кремль",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    name: "12 век",
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 2,
    name: "14 век",
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 3,
    name: "15 век",
    correct: false,
    uncorrect: false,
    empty: true
  }
];

const answers: Answer = { 1: 3, 2: 1, 3: 2 };

const Theme14Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Как строился Кремль, какие стены были раньше, какие позже?'
      description='Правильно расположи изображения'
      successIcon='dan-2'
    >
      <QuizTimeLine draggableItems={draggableItems} dropZones={dropZones} answers={answers} />
    </QuizWrapper>
  );
};

export default Theme14Task1;
