import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizFillWord } from "~modules/QuizFillWord";

const grid: { char: string; wordId: number }[][] = [
  [
    { char: "с", wordId: 1 },
    { char: "а", wordId: 1 },
    { char: "п", wordId: 1 },
    { char: "о", wordId: 1 },
    { char: "ж", wordId: 1 },
    { char: "н", wordId: 1 },
    { char: "и", wordId: 1 },
    { char: "к", wordId: 1 }
  ],
  [
    { char: "в", wordId: -1 },
    { char: "л", wordId: -1 },
    { char: "н", wordId: -1 },
    { char: "п", wordId: 2 },
    { char: "р", wordId: 2 },
    { char: "я", wordId: 2 },
    { char: "х", wordId: 2 },
    { char: "а", wordId: 2 }
  ],
  [
    { char: "к", wordId: 3 },
    { char: "о", wordId: 3 },
    { char: "ж", wordId: 3 },
    { char: "е", wordId: 3 },
    { char: "в", wordId: 3 },
    { char: "н", wordId: 3 },
    { char: "и", wordId: 3 },
    { char: "к", wordId: 3 }
  ],
  [
    { char: "о", wordId: -1 },
    { char: "т", wordId: 4 },
    { char: "к", wordId: 4 },
    { char: "а", wordId: 4 },
    { char: "ч", wordId: 4 },
    { char: "п", wordId: -1 },
    { char: "р", wordId: -1 },
    { char: "г", wordId: -1 }
  ],
  [
    { char: "к", wordId: 5 },
    { char: "у", wordId: 5 },
    { char: "з", wordId: 5 },
    { char: "н", wordId: 5 },
    { char: "е", wordId: 5 },
    { char: "ц", wordId: 5 },
    { char: "а", wordId: -1 },
    { char: "м", wordId: -1 }
  ]
];

const words: { value: string; wordId: number }[] = [
  { value: "кузнец", wordId: 5 },
  { value: "кожевник", wordId: 3 },
  { value: "ткач", wordId: 4 },
  { value: "пряха", wordId: 2 },
  { value: "сапожник", wordId: 1 }
];

const Theme13Task2 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Найди старинные профессии.'
      description='Найди в таблице слова из списка ниже и нажми на них'
      successIcon='mash-1'
    >
      <QuizFillWord grid={grid} words={words} />
    </QuizWrapper>
  );
};

export default Theme13Task2;
