import React from "react";
export const Puzzle: React.FC = () => {
  return (
    <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 10H17.5V6C17.5 4.9 16.6 4 15.5 4H11.5V2.5C11.5 1.12 10.38 0 9 0C7.62 0 6.5 1.12 6.5 2.5V4H2.5C1.4 4 0.51 4.9 0.51 6V9.8H2C3.49 9.8 4.7 11.01 4.7 12.5C4.7 13.99 3.49 15.2 2 15.2H0.5V19C0.5 20.1 1.4 21 2.5 21H6.3V19.5C6.3 18.01 7.51 16.8 9 16.8C10.49 16.8 11.7 18.01 11.7 19.5V21H15.5C16.6 21 17.5 20.1 17.5 19V15H19C20.38 15 21.5 13.88 21.5 12.5C21.5 11.12 20.38 10 19 10Z'
        fill='#068585'
      />
    </svg>
  );
};
