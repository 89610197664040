import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizEnterWord } from "~modules/QuizEnterWord";

const Theme18Task2 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Как называли иноземных купцов? Посмотри подсказку и разгадай слово.'
      description='Напечатай ответ в поле ниже'
      successIcon='nast-2'
    >
      <QuizEnterWord question={"С-3, Г-1, Т-4, О-2, И-5"} answer='Гости' />
    </QuizWrapper>
  );
};

export default Theme18Task2;
