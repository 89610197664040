import React from "react";
export const Game: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.13043 4.34784C8.43478 4.34784 7.82608 3.73915 7.82608 3.0435C7.82608 2.34784 8.43478 1.73915 9.13043 1.73915C9.82608 1.73915 10.4348 2.34784 10.4348 3.0435C10.4348 3.73915 9.91303 4.34784 9.13043 4.34784ZM9.13043 7.8261C8.43478 7.8261 7.82608 7.2174 7.82608 6.52175C7.82608 5.8261 8.43478 5.21741 9.13043 5.21741C9.82608 5.21741 10.4348 5.8261 10.4348 6.52175C10.4348 7.2174 9.91303 7.8261 9.13043 7.8261ZM9.13043 11.3044C8.43478 11.3044 7.82608 10.6957 7.82608 10C7.82608 9.30436 8.43478 8.69566 9.13043 8.69566C9.82608 8.69566 10.4348 9.30436 10.4348 10C10.4348 10.6957 9.91303 11.3044 9.13043 11.3044ZM3.91304 4.34784C3.21739 4.34784 2.6087 3.73915 2.6087 3.0435C2.6087 2.34784 3.21739 1.73915 3.91304 1.73915C4.60869 1.73915 5.21739 2.34784 5.21739 3.0435C5.21739 3.73915 4.69565 4.34784 3.91304 4.34784ZM3.91304 7.8261C3.21739 7.8261 2.6087 7.2174 2.6087 6.52175C2.6087 5.8261 3.21739 5.21741 3.91304 5.21741C4.60869 5.21741 5.21739 5.8261 5.21739 6.52175C5.21739 7.2174 4.69565 7.8261 3.91304 7.8261ZM3.91304 11.3044C3.21739 11.3044 2.6087 10.6957 2.6087 10C2.6087 9.30436 3.21739 8.69566 3.91304 8.69566C4.60869 8.69566 5.21739 9.30436 5.21739 10C5.21739 10.6957 4.69565 11.3044 3.91304 11.3044ZM11.3043 2.12296e-05H1.73913C0.782613 2.12296e-05 5.3074e-06 0.782629 5.3074e-06 1.73915V11.3044C5.3074e-06 12.2609 0.782613 13.0435 1.73913 13.0435H11.3043C12.2609 13.0435 13.0435 12.2609 13.0435 11.3044V1.73915C13.0435 0.782629 12.2609 2.12296e-05 11.3043 2.12296e-05Z'
        fill='#068585'
      />
      <path
        d='M19.019 15.6548C18.5059 15.1277 18.5059 14.3372 19.019 13.8101C19.532 13.283 20.3016 13.283 20.8147 13.8101C21.3277 14.3372 21.3277 15.1277 20.8147 15.6548C20.3016 16.1819 19.4465 16.1819 19.019 15.6548ZM16.5392 18.2023C16.0262 17.6752 16.0262 16.8846 16.5392 16.3575C17.0523 15.8305 17.8219 15.8305 18.3349 16.3575C18.848 16.8846 18.848 17.6752 18.3349 18.2023C17.9074 18.6415 17.0523 18.6415 16.5392 18.2023ZM14.145 20.6619C13.6319 20.1348 13.6319 19.3442 14.145 18.8172C14.658 18.2901 15.4276 18.2901 15.9407 18.8172C16.4537 19.3442 16.4537 20.1348 15.9407 20.6619C15.4276 21.189 14.658 21.189 14.145 20.6619ZM15.3421 11.9654C14.8291 11.4383 14.8291 10.6477 15.3421 10.1207C15.8552 9.5936 16.6247 9.5936 17.1378 10.1207C17.6509 10.6477 17.6509 11.4383 17.1378 11.9654C16.6247 12.4924 15.8552 12.4924 15.3421 11.9654ZM12.9479 14.425C12.4348 13.8979 12.4348 13.1073 12.9479 12.5803C13.4609 12.0532 14.2305 12.0532 14.7435 12.5803C15.2566 13.1073 15.2566 13.8979 14.7435 14.425C14.2305 14.952 13.4609 14.952 12.9479 14.425ZM10.5536 16.8846C10.0405 16.3576 10.0405 15.567 10.5536 15.0399C11.0667 14.5128 11.8362 14.5128 12.3493 15.0399C12.8623 15.567 12.8623 16.3576 12.3493 16.8846C11.8362 17.4117 10.9811 17.4117 10.5536 16.8846ZM23.551 14.1615L16.8813 7.30966C16.1972 6.60691 15.1711 6.60691 14.487 7.30966L7.81732 14.1615C7.13325 14.8642 7.13325 15.9183 7.81732 16.6211L14.487 23.4729C15.1711 24.1757 16.1972 24.1757 16.8813 23.4729L23.551 16.6211C24.1495 15.9183 24.1495 14.8642 23.551 14.1615Z'
        fill='#068585'
      />
    </svg>
  );
};
