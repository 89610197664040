import React from "react";
export const Gosha2: React.FC = () => {
  return (
    <svg
      width='132'
      height='132'
      viewBox='0 0 132 132'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M66 132C102.451 132 132 102.451 132 66C132 29.5492 102.451 0 66 0C29.5492 0 0 29.5492 0 66C0 102.451 29.5492 132 66 132Z'
        fill='#87DBBF'
      />
      <path
        d='M45.2 101.099C42.3 104.899 41.2 115.399 38.5 125.899C46.9 129.799 56.3 131.999 66.1 131.999C72.8 131.999 79.2 130.999 85.3 129.199C85 125.899 84.8 123.899 84.8 123.899C77.8 114.599 84.5 115.499 86.1 102.999C66.2 87.6994 48.2 97.6995 45.2 101.099Z'
        fill='#EB3E48'
      />
      <path
        d='M44.5002 102.399C39.5002 105.599 34.6002 112.499 28.7002 120.399C33.5002 123.699 38.8002 126.399 44.5002 128.399C48.7002 117.499 56.5002 100.399 44.5002 102.399Z'
        fill='#EB3E48'
      />
      <path
        d='M77.7003 108.8C71.9003 114.8 72.1003 123 74.2003 131.5C82.3003 130.5 89.9004 128.1 96.8004 124.4C92.8004 110.2 84.5003 101.8 77.7003 108.8Z'
        fill='#EB3E48'
      />
      <path
        d='M38 125.8C38.2 125.9 38.5 126 38.7 126.1C39 120.5 39.7 114.8 41.3 110.2L40.6 110C39 114.5 38.3 120.2 38 125.8Z'
        fill='#7D1A20'
      />
      <path
        d='M44.0995 95.1001C40.8995 98.9001 43.0995 101.8 46.3995 103.8C48.9995 105.3 52.6995 108.9 54.3995 107.2C56.0995 105.5 51.6995 101.5 52.2995 98.4001C53.0995 94.5001 64.6995 97.1001 68.5995 98.4001C72.4995 99.7001 88.9995 102.6 88.9995 95.1001C85.7995 92.9001 72.8994 88.0001 65.6994 88.9001C56.5994 90.1001 47.4995 91.0001 44.0995 95.1001Z'
        fill='#EB3E48'
      />
      <path
        d='M55.0004 86.8008L54.4004 98.9008C60.1004 105.301 70.5004 99.5008 72.3004 96.8008L73.1004 81.8008L55.0004 86.8008Z'
        fill='#FFD2A8'
      />
      <path
        d='M54.5996 95.4009L54.9996 88.2009C54.9996 88.2009 74.1996 75.3009 73.0996 83.6009C71.9996 91.9009 54.5996 95.4009 54.5996 95.4009Z'
        fill='#F9836A'
      />
      <path
        d='M77.7001 92.4004C83.2001 92.4004 88.0001 91.4004 91.0001 97.8004C94.0001 104.2 96.2001 108.6 93.5001 110.5C90.8001 112.4 74.8001 104.9 69.6001 104.9C65.4001 104.9 57.2001 110.6 55.4001 107.9C53.6001 105.2 60.8001 97.0004 63.1001 95.5004C65.4001 93.9004 72.3001 92.4004 77.7001 92.4004Z'
        fill='#EB3E48'
      />
      <path
        d='M90.2 111.7L90.7 111.3C88 108 80.7 102.6 72.8 105.4C65.1 108.1 58.4 108.6 56 108.5V109.2C58.5 109.3 65.3 108.9 73.1 106.1C80.6 103.3 87.6 108.5 90.2 111.7Z'
        fill='#7D1A20'
      />
      <path
        d='M79.4002 81.3004L77.7002 80.3004C81.7002 77.6004 87.0002 72.3004 91.1002 67.4004C93.1002 67.7004 95.1002 69.8004 96.8002 69.5004H96.9002C94.1002 75.0004 90.6002 79.9004 85.9002 83.8004L87.6002 80.4004C85.3002 82.2004 82.6002 82.2004 79.4002 81.3004Z'
        fill='#D9A01C'
      />
      <path
        d='M40.3001 45.9991C40.6001 45.2991 40.7001 44.7991 41.0001 44.2991C33.9001 43.0991 32.9001 52.5991 39.0001 57.7991C39.2001 54.1991 39.8001 50.2991 40.3001 45.9991Z'
        fill='#FDB797'
      />
      <path
        d='M56.4996 88.9996C66.7996 91.2996 80.5996 85.0996 90.2996 70.8996C102.5 73.0996 107.4 61.6996 101.2 59.6996C100.8 48.1996 95.6996 34.9996 78.3996 32.6996C41.1996 22.0996 45.4996 35.1996 40.6996 45.7996C30.3996 79.7996 48.1996 87.1996 56.4996 88.9996Z'
        fill='#FFD2A8'
      />
      <g opacity='0.3'>
        <g opacity='0.5' filter='url(#filter0_f_4534_30365)'>
          <path
            d='M50.9999 57.3009C52.4999 57.1009 53.5998 55.9009 53.8998 58.1009C54.1998 60.3009 53.2999 62.3009 51.7999 62.5009C50.2999 62.7009 48.7999 61.1009 48.4999 58.9009C48.0999 56.7009 49.4999 57.5009 50.9999 57.3009Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        opacity='0.3'
        d='M49.5001 60.4008C50.5001 61.1008 51.9 59.7008 50.7 58.4008C49.5 57.1008 48.1001 59.3008 49.5001 60.4008Z'
        fill='white'
      />
      <path
        d='M49.1002 62.4992C49.2002 62.4992 49.3002 62.4992 49.4002 62.3992C49.6002 62.1992 49.5002 61.9992 49.4002 61.7992C48.2002 60.7992 48.1002 59.2992 48.2002 58.5992C48.2002 58.3992 48.4002 58.1992 48.6002 58.0992C49.6002 57.6992 51.5002 56.7992 52.6002 55.6992C52.8002 55.4992 52.8002 55.2992 52.6002 55.0992C52.4002 54.8992 52.2002 54.8992 52.0002 55.0992C51.0002 56.0992 49.0002 56.9992 48.2002 57.2992C47.7002 57.4992 47.3002 57.9992 47.3002 58.4992C47.2002 59.3992 47.4002 61.0992 48.8002 62.3992C48.9002 62.4992 49.0002 62.4992 49.1002 62.4992Z'
        fill='#7D1A20'
      />
      <path
        d='M54.2002 62.6001C54.8002 62.5001 55.3002 62.0001 55.4002 61.4001C55.6002 60.8001 55.4002 60.1001 54.9002 59.8001C54.7002 59.7001 54.5002 59.7001 54.3002 59.9001C54.2002 60.1001 54.2002 60.3001 54.4002 60.5001C54.7002 60.7001 54.6002 61.1001 54.6002 61.2001C54.5002 61.5001 54.3002 61.8001 54.1002 61.8001C53.9002 61.8001 53.7002 62.0001 53.7002 62.3001C53.8002 62.4001 54.0002 62.6001 54.2002 62.6001Z'
        fill='#7D0000'
      />
      <path
        d='M95.2004 68.4008C98.8004 67.8008 100.9 65.4008 100.8 63.4008C100.7 61.9008 99.3004 61.3008 98.1004 61.3008C97.8004 61.3008 97.6004 61.5008 97.6004 61.8008C97.6004 62.1008 97.8004 62.3008 98.1004 62.3008C98.7004 62.3008 99.8004 62.5008 99.9004 63.5008C100 64.8008 98.4004 66.9008 95.2004 67.4008C94.9004 67.4008 94.8004 67.7008 94.8004 67.9008C94.7004 68.2008 94.9004 68.4008 95.2004 68.4008Z'
        fill='#7D0000'
      />
      <path
        d='M74.9005 50.8001C74.4005 49.9001 73.6005 49.1001 72.5005 48.5001C72.0005 48.2001 71.9005 47.5001 72.1005 47.1001C72.4005 46.6001 73.1005 46.5001 73.5005 46.7001C74.8005 47.4001 75.9005 48.2001 76.6005 49.7001C76.9005 50.3001 76.9005 50.9001 76.4005 51.1001C75.9005 51.4001 75.2005 51.1001 74.9005 50.8001Z'
        fill='#7D0000'
      />
      <path
        d='M44.0001 42.9996C45.0001 42.4996 46.2001 42.2996 47.3001 42.1996C47.9001 41.9996 48.4001 41.5996 48.3001 41.1996C48.4001 40.4996 47.8001 40.1996 47.2001 40.0996C45.7001 40.0996 44.4001 40.4996 43.1001 41.2996C42.6001 41.5996 42.4001 42.1996 42.6001 42.6996C43.0001 43.1996 43.8001 43.2996 44.0001 42.9996Z'
        fill='#7D0000'
      />
      <path
        d='M83.6001 72.0008C88.1001 68.1008 92.3002 63.9008 95.8002 59.2008L94.6001 62.1008C98.3001 58.2008 100 59.9008 101.8 59.8008C104 53.9008 104.2 46.6008 104.2 46.5008C105.6 48.0008 106.1 49.2008 106.3 51.3008C107.6 48.2008 108.8 40.1008 104.3 32.8008C97.6002 21.9008 92.1001 19.5008 82.4001 16.6008C81.8001 15.5008 82.1001 11.4008 75.1001 9.30078C76.3001 10.2008 77.0002 13.5008 77.5002 14.4008C70.6002 11.8008 61.4002 11.4008 54.5002 13.6008L56.8002 14.5008C52.1002 15.9008 48.8001 17.1008 44.9001 20.8008C42.8001 20.9008 40.8001 21.9008 38.7001 23.5008C40.1001 23.2008 41.5002 23.2008 42.5002 23.6008C38.7002 26.5008 36.5001 35.0008 36.9001 41.8008L39.7001 38.0008C39.0001 43.9008 37.2001 52.1008 37.9001 57.7008C39.0001 50.2008 40.4002 45.3008 43.3002 38.1008C46.1002 32.9008 48.8001 31.5008 49.9001 30.4008C48.8001 32.9008 48.4001 35.7008 48.2001 38.4008C50.7001 35.0008 53.7001 32.5008 57.4001 31.8008C55.9001 33.6008 54.8001 35.1008 53.6001 36.6008C57.0001 34.3008 59.8002 33.1008 62.5002 32.5008C61.2002 34.5008 59.2001 36.3008 57.2001 37.9008C61.5001 36.7008 64.8001 35.0008 67.6001 33.0008C67.6001 36.6008 65.8002 39.2008 63.8002 41.6008C67.1002 41.4008 69.1002 40.3008 71.0002 38.9008C70.1002 40.6008 68.8002 41.8008 67.5002 43.2008C71.2002 42.1008 73.5002 40.1008 75.0002 37.7008C74.8002 40.8008 75.4002 43.1008 76.0002 45.4008C76.2002 42.7008 77.4001 40.2008 78.6001 37.8008C77.7001 41.4008 78.4002 44.0008 79.5002 46.7008C81.2002 44.8008 82.1002 43.3008 83.0002 41.8008C83.3002 43.7008 82.6001 45.2008 82.2001 46.9008C85.0001 45.4008 87.8001 43.0008 90.2001 40.3008C90.6001 44.0008 89.9001 48.3008 89.2001 52.5008C90.5001 49.3008 91.4002 46.2008 92.0002 43.0008C92.1002 42.7008 93.0001 50.1008 91.4001 54.3008L93.2001 52.8008C91.3001 59.4008 88.1001 65.9008 83.6001 72.0008Z'
        fill='#D9A01C'
      />
      <g opacity='0.5'>
        <g opacity='0.5' filter='url(#filter1_f_4534_30365)'>
          <path
            d='M74.1004 62.3996C77.6004 62.1996 80.5004 63.2995 80.8004 66.7995C81.0004 70.2995 78.4004 73.3995 74.8004 73.6995C71.3004 73.8995 68.2004 71.2995 67.9004 67.6995C67.7004 64.1995 70.6004 62.6996 74.1004 62.3996Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.5'>
        <g opacity='0.5' filter='url(#filter2_f_4534_30365)'>
          <path
            d='M38.7002 62.8C39.0002 62.9 39.2002 62.9 39.5002 63C42.3002 63.4 45.0002 60.9 45.4002 57.6C45.8002 54.2 43.4002 53 40.6002 52.6C40.4002 52.6 40.2002 52.6 40.0002 52.5C39.2002 56.3 38.8002 59.7 38.7002 62.8Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        d='M72.8999 55.4997C71.2999 58.0997 70.1999 57.9997 67.7999 56.6997C66.8999 56.1997 66.5998 54.5997 67.1998 52.7997C69.4998 46.9997 75.0999 51.7997 72.8999 55.4997Z'
        fill='white'
      />
      <path
        d='M71.5003 54.9006C70.7003 56.3006 70.0002 57.0006 69.2002 57.4006C68.8002 57.3006 68.3002 57.0006 67.7002 56.7006C66.8002 56.2006 66.5002 54.6006 67.1002 52.8006C68.3002 49.9006 70.3002 49.6006 71.7002 50.6006C72.3002 51.9006 72.4003 53.5006 71.5003 54.9006Z'
        fill='#7D0000'
      />
      <path
        d='M72.4086 53.4984C72.4655 52.8935 72.0659 52.3613 71.516 52.3095C70.9662 52.2578 70.4743 52.7063 70.4174 53.3111C70.3606 53.916 70.7602 54.4482 71.3101 54.4999C71.8599 54.5516 72.3518 54.1032 72.4086 53.4984Z'
        fill='white'
      />
      <path
        d='M48.5995 49.499C47.3995 52.299 46.2995 52.399 43.6995 51.399C42.6995 50.999 42.1996 49.499 42.4996 47.699C44.0996 41.599 50.2995 45.499 48.5995 49.499Z'
        fill='white'
      />
      <path
        d='M47.1995 49.0994C46.5995 50.5994 45.9995 51.3994 45.2995 51.8994C44.7995 51.7994 44.2995 51.5994 43.6995 51.3994C42.6995 50.9994 42.1996 49.4994 42.4996 47.6994C43.2996 44.6994 45.1995 44.0994 46.7995 44.7994C47.4995 45.9994 47.7995 47.4994 47.1995 49.0994Z'
        fill='#7D0000'
      />
      <path
        d='M46.8711 48.7047C47.4228 48.6784 47.8466 48.1652 47.8177 47.5584C47.7888 46.9515 47.3182 46.4809 46.7665 46.5071C46.2148 46.5334 45.791 47.0466 45.8199 47.6535C45.8488 48.2603 46.3194 48.731 46.8711 48.7047Z'
        fill='white'
      />
      <path
        d='M50.8996 73.8994C51.7996 73.8994 52.4996 73.8994 53.2996 73.4994C53.4996 73.3994 53.5996 73.1994 53.4996 72.9994C53.3996 72.7994 53.1996 72.6994 52.9996 72.7994C52.3996 73.0994 51.7996 73.0994 50.7996 73.0994C49.8996 73.0994 48.7996 72.6994 48.7996 72.6994C48.5996 72.5994 48.3996 72.6994 48.3996 72.8994C48.2996 73.0994 48.3996 73.2994 48.5996 73.2994C48.6996 73.4994 49.8996 73.8994 50.8996 73.8994Z'
        fill='#7D0000'
      />
      <path
        d='M53.8004 71.3002C54.6004 71.3002 55.3004 70.9002 56.3004 70.0002C56.4004 69.9002 56.5004 69.6002 56.3004 69.5002C56.2004 69.4002 55.9004 69.3002 55.8004 69.5002C54.3004 70.9002 53.9004 70.9002 51.8004 70.1002C50.7004 69.6002 50.1004 70.0002 49.6004 70.3002C49.0004 70.6002 48.7004 70.8002 47.6004 69.9002C47.5004 69.8002 47.2004 69.8002 47.1004 69.9002C47.0004 70.0002 47.0004 70.3002 47.1004 70.4002C48.5004 71.6002 49.2004 71.4002 50.0004 70.9002C50.5004 70.6002 50.8004 70.4002 51.6004 70.7002C52.5004 71.1002 53.2004 71.3002 53.8004 71.3002Z'
        fill='#7D0000'
      />
      <path
        d='M54.3 107.5C54.3 107.5 52.5 106.6 52.5 103.4C52.5 100.1 54.3 98.4004 54.3 98.4004C55.4 100.3 56.2 100.5 58.3 100.5L54.3 107.5Z'
        fill='#0A525C'
      />
      <path
        d='M74.8003 112C72.3003 117.1 70.8003 121.5 73.7003 131.6C73.9003 131.6 74.2003 131.5 74.4003 131.5C71.6003 121.5 73.0003 117.2 75.4003 112.3L74.8003 112Z'
        fill='#7D1A20'
      />
      <defs>
        <filter
          id='filter0_f_4534_30365'
          x='45.4307'
          y='53.7949'
          width='11.5269'
          height='11.7227'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_4534_30365' />
        </filter>
        <filter
          id='filter1_f_4534_30365'
          x='64.8906'
          y='59.377'
          width='18.9204'
          height='17.333'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_4534_30365' />
        </filter>
        <filter
          id='filter2_f_4534_30365'
          x='35.7002'
          y='49.5'
          width='12.7437'
          height='16.542'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_4534_30365' />
        </filter>
      </defs>
    </svg>
  );
};
