import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItems } from "~/modules/QuizChooseItems";

const Theme41Task2 = () => {
  const items: { name: string }[] = [
    {
      name: "Первый спутник"
    },
    {
      name: "Первый человек в космосе"
    },
    {
      name: "Первый аппарат на Марс"
    },
    { name: "Первый выход в открытый космос" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какие рекорды при изучении космоса поставила наша страна?'
      description='Выбери 3 правильных ответа'
      successIcon='rus-1'
    >
      <QuizChooseItems
        type='str'
        items={items}
        correctItems={[
          "Первый спутник",
          "Первый человек в космосе",
          "Первый выход в открытый космос"
        ]}
      />
    </QuizWrapper>
  );
};

export default Theme41Task2;
