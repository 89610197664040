import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTimeLine, Answer, DraggableItem, DropZone } from "~modules/QuizTimeLine";
import sputnik from "~shared/assets/images/40/sputnik.jpg";
import reproductor from "~shared/assets/images/40/reproductor.jpg";
import radiopriemnik from "~shared/assets/images/40/radiopriemnik.jpg";
import ostankinskaya from "~shared/assets/images/40/ostankinskaya.jpg";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    imgUrl: sputnik,
    title: "Спутник",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 2,
    imgUrl: reproductor,
    title: "Репродуктор",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 3,
    imgUrl: radiopriemnik,
    title: "Радиоприёмник",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 4,
    imgUrl: ostankinskaya,
    title: "Останкинская башня",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 2,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 3,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 4,
    correct: false,
    uncorrect: false,
    empty: true
  }
];

const answers: Answer = { 1: 2, 2: 3, 3: 4, 4: 1 };

const Theme40Task1 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Что было раньше, а что позже?'
      description='Правильно расположи изображения'
      successIcon='dan-2'
    >
      <QuizTimeLine draggableItems={draggableItems} dropZones={dropZones} answers={answers} />
    </QuizWrapper>
  );
};

export default Theme40Task1;
