import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import Borov from "~shared/assets/images/24/Borov.jpg";
import Paskov from "~shared/assets/images/24/Paskov.jpg";
import Senat from "~shared/assets/images/24/Senat.jpg";
import Uspens from "~shared/assets/images/24/Uspen.jpg";

const Theme24Task3 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Боровицкая башня",
      image: Borov
    },
    {
      name: "Дом Пашкова",
      image: Paskov
    },
    {
      name: "Сенатский дворец",
      image: Senat
    },
    {
      name: "Успенский собор",
      image: Uspens
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Где короновали государей и государыней?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Успенский собор' />
    </QuizWrapper>
  );
};

export default Theme24Task3;
