import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styles from "./SortableItem.module.scss";
import clsx from "clsx";

type Props = {
  id: number;
  name: string;
  error: boolean;
  success: boolean;
};

export const SortableItem: React.FC<Props> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div
        className={clsx(
          { [styles.success]: props.success, [styles.error]: props.error },
          `${styles.card} `
        )}
      >
        {props.name}
      </div>
    </div>
  );
};
