import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTimeLine, Answer, DraggableItem, DropZone } from "~modules/QuizTimeLine";
import kupec from "~shared/assets/images/5/plivut_kuptsy.jpg";
import tovar from "~shared/assets/images/5/obmen_tovarom.jpg";
import razboy from "~shared/assets/images/5/napadeniye_razboinikov.jpg";
import kreml from "~shared/assets/images/5/stroitelstvo_kremlya.jpg";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    imgUrl: tovar,
    title: "Обмен товаром",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 2,
    imgUrl: kupec,
    title: "Плывут купцы",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },

  {
    id: 3,
    imgUrl: kreml,
    title: "Строительство кремля",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 4,
    imgUrl: razboy,
    title: "Нападение разбойников",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 2,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 3,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 4,
    correct: false,
    uncorrect: false,
    empty: true
  }
];

const answers: Answer = { 1: 2, 2: 1, 3: 4, 4: 3 };

const Theme5Task3 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Восстанови последовательность событий'
      description='Правильно расположи изображения'
      successIcon='dan-2'
    >
      <QuizTimeLine draggableItems={draggableItems} dropZones={dropZones} answers={answers} />
    </QuizWrapper>
  );
};

export default Theme5Task3;
