import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme31Task3 = () => {
  const items: { name: string }[] = [
    {
      name: "Бульварное кольцо"
    },
    {
      name: "Земляной вал"
    },
    {
      name: "Садовое кольцо"
    },
    { name: "Болотная набережная" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Где проходил маршрут «Аннушка»? '
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='Бульварное кольцо' sameHeight />
    </QuizWrapper>
  );
};

export default Theme31Task3;
