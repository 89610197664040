import React from "react";
export const Nast1: React.FC = () => {
  return (
    <svg
      width='147'
      height='133'
      viewBox='0 0 147 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M80.7153 132.677C117.166 132.677 146.715 103.128 146.715 66.6768C146.715 30.226 117.166 0.676819 80.7153 0.676819C44.2645 0.676819 14.7153 30.226 14.7153 66.6768C14.7153 103.128 44.2645 132.677 80.7153 132.677Z'
        fill='#F1615B'
      />
      <path
        d='M53.8154 44.1768C49.1154 30.3768 51.1153 18.0768 77.2153 15.1768C98.2153 13.2768 117.815 22.4768 117.115 52.2768C116.715 68.5768 121.015 65.5768 123.715 80.0768C125.315 91.2768 108.115 100.377 108.115 100.377L108.815 92.1768C107.615 96.5768 104.715 98.6768 101.215 99.8768L84.2153 97.7768C72.5153 95.6768 63.1154 93.9768 60.0154 90.5768C52.9154 93.0768 48.6152 89.0768 48.6152 89.0768C54.2152 87.6768 58.6152 81.7768 60.6152 77.3768C67.4152 62.4768 58.5154 57.9768 53.8154 44.1768Z'
        fill='#924026'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M72.0154 89.1769L61.8154 92.0768L64.1152 88.5768C65.5152 87.8768 66.8152 87.3769 68.1152 87.4769L72.0154 89.1769Z'
        fill='#5EB1ED'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M95.5154 93.9768C96.9154 94.5768 99.0153 93.4768 99.7153 94.5768C102.315 95.4768 101.015 98.6768 100.315 100.177L95.2153 98.4768L95.5154 93.9768Z'
        fill='#5EB1ED'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.8154 95.1768C60.4154 90.2768 49.8154 101.077 44.8154 108.377C42.9154 103.977 40.8153 99.5768 38.7153 95.1768C37.0153 91.5768 35.3152 87.5768 32.6152 84.5768C31.9152 83.7768 30.9154 83.7768 29.8154 83.6768C28.3154 83.4768 26.8154 83.4768 25.3154 83.4768C24.0154 85.1768 8.81543 80.6768 5.31543 83.0768C1.11543 82.2768 0.81543 84.6768 0.81543 85.1768C0.81543 85.2768 2.81543 86.4768 2.81543 86.4768L2.71533 87.5768L7.41528 89.5768C7.41528 89.5768 10.2154 92.0768 11.3154 92.9768C12.4154 93.8768 17.4152 96.6768 23.1152 96.7768C24.8152 96.6768 26.2152 97.8768 26.6152 99.4768C28.2152 107.577 31.0153 121.877 35.7153 125.377C42.5153 130.477 44.9154 126.277 49.8154 123.177C54.7154 120.077 70.5154 102.877 70.3154 102.077C70.1154 100.577 73.3154 101.877 65.8154 95.1768Z'
        fill='#FCD1A9'
      />
      <path
        d='M102.815 99.5769C102.015 95.6769 86.115 89.2769 77.415 88.4769C65.715 87.4769 62.7151 91.9769 57.0151 95.3769C56.1151 102.677 54.515 114.477 53.915 127.077C62.115 130.777 71.2151 132.777 80.7151 132.777C88.3151 132.777 95.615 131.477 102.415 129.077C102.215 115.577 105.015 105.377 102.815 99.5769Z'
        fill='white'
      />
      <path
        d='M93.6152 92.9768C88.2152 90.7768 82.3153 88.9768 78.2153 88.2768C68.7153 86.6768 64.6154 89.4768 60.3154 92.4768C59.3154 93.1768 58.3153 93.8768 57.2153 94.4768C57.1153 94.5768 57.1154 94.5768 57.0154 94.6768C56.9154 95.3768 56.8153 96.0768 56.7153 96.8768C55.8153 104.177 54.3154 115.277 53.8154 126.877C54.0154 126.977 54.2153 127.077 54.4153 127.177C54.9153 115.477 56.3154 104.277 57.3154 96.8768C57.4154 96.1768 57.5152 95.4768 57.6152 94.8768C58.6152 94.2768 59.6152 93.5768 60.6152 92.8768C64.8152 89.9768 68.9152 87.1768 78.1152 88.7768C82.2152 89.4768 88.0153 91.2768 93.4153 93.4768C98.8153 95.6768 102.515 97.8768 102.915 99.0768C104.115 102.377 103.715 107.277 103.215 113.377C102.815 117.977 102.415 123.177 102.515 128.977C102.715 128.877 102.915 128.877 103.115 128.777C103.015 123.077 103.415 117.977 103.815 113.477C104.315 107.277 104.715 102.377 103.515 98.8768C102.715 96.8768 96.3152 94.0768 93.6152 92.9768Z'
        fill='#999999'
      />
      <path
        d='M102.816 99.5768C97.5157 94.7768 91.7156 103.077 91.0156 112.377C91.0156 112.377 106.616 120.077 110.516 125.677C115.216 123.277 119.615 120.277 123.615 116.877C118.215 109.777 108.716 103.977 102.816 99.5768Z'
        fill='#FFD2A8'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.6149 76.9768L32.6149 84.1768L25.4149 83.4768L25.6149 81.2768C25.6149 81.1768 23.0149 78.9768 26.6149 76.9768Z'
        fill='#FCD1A9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.6151 76.9768C29.9151 78.6768 31.8151 82.1768 33.1151 85.4768C32.6151 85.5768 32.2153 85.2768 31.8153 84.9768C30.1153 83.6768 27.4153 83.6768 25.3153 83.4768L25.5153 81.2768C25.3153 80.7768 22.9151 76.2768 26.6151 76.9768Z'
        fill='#FCD1A9'
      />
      <path
        d='M15.9153 91.3768C15.8153 91.3768 15.8155 91.3768 15.8155 91.3768L12.3155 89.5768C10.5155 88.4768 8.31547 87.4768 7.81547 87.8768C7.71547 87.9768 7.71533 88.2768 7.91533 88.7768C8.01533 88.9768 7.91537 89.1768 7.71537 89.1768C7.51537 89.2768 7.31547 89.1768 7.31547 88.9768C7.01547 88.1768 7.11533 87.6768 7.41533 87.3768C8.41533 86.5768 11.7154 88.3768 12.7154 88.9768L16.2154 90.7768C16.4154 90.8768 16.4155 91.0768 16.3155 91.1768C16.2155 91.2768 16.0153 91.3768 15.9153 91.3768Z'
        fill='#8E3821'
      />
      <path
        d='M15.3154 88.2769C15.2154 88.2769 15.2154 88.2769 15.3154 88.2769C12.5154 87.3769 5.21538 85.4769 3.51538 86.6769C3.31538 86.7769 3.21533 86.9769 3.21533 87.1769C3.21533 87.3769 3.01528 87.4769 2.91528 87.4769C2.71528 87.4769 2.61523 87.2769 2.61523 87.1769C2.61523 86.7769 2.81533 86.3769 3.21533 86.1769C5.51533 84.5769 14.5154 87.3769 15.5154 87.6769C15.7154 87.7769 15.8153 87.8769 15.7153 88.0769C15.5153 88.1769 15.4154 88.2769 15.3154 88.2769Z'
        fill='#8E3821'
      />
      <path
        d='M16.115 86.5768H16.0151C10.1151 83.5768 5.81509 83.6768 5.71509 83.6768C5.51509 83.6768 5.41504 83.5768 5.41504 83.3768C5.41504 83.1768 5.51509 83.0768 5.71509 83.0768C5.91509 83.0768 10.2152 82.8768 16.3152 85.9768C16.5152 86.0768 16.515 86.2768 16.415 86.3768C16.315 86.4768 16.215 86.5768 16.115 86.5768Z'
        fill='#8E3821'
      />
      <path
        d='M25.8154 92.4768C25.7154 92.4768 25.7152 92.4768 25.6152 92.3768C21.7152 89.5768 18.3154 91.8768 18.3154 91.8768C18.2154 91.9768 18.0153 91.9768 17.9153 91.7768C17.8153 91.6768 17.8154 91.4768 18.0154 91.3768C18.0154 91.3768 21.8154 88.8768 26.0154 91.8768C26.1154 91.9768 26.2152 92.1768 26.1152 92.2768C26.0152 92.4768 25.9154 92.4768 25.8154 92.4768Z'
        fill='#8E3821'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.5156 104.577C47.5156 104.577 56.3156 116.077 56.5156 114.777C56.6156 113.477 56.3157 96.0768 56.3157 96.0768C53.0157 97.7768 50.9156 100.877 47.5156 104.577Z'
        fill='#E7F3F5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M88.7156 64.1769C90.6156 62.3769 67.1155 74.4769 67.1155 74.4769C67.1155 74.4769 70.6155 81.7769 67.4155 89.3769C64.1155 94.5769 66.6156 97.1769 70.7156 97.5769C74.8156 97.9769 83.4157 95.6769 88.3157 91.2769C84.5157 86.5769 84.5156 72.6769 88.7156 64.1769Z'
        fill='#FCD1A9'
      />
      <path
        d='M78.7153 130.977C72.5153 130.677 65.3154 130.277 58.0154 128.777C57.8154 128.777 57.7153 128.577 57.7153 128.377C57.7153 128.177 57.9152 128.077 58.1152 128.077C65.4152 129.577 72.5153 129.977 78.7153 130.277C78.9153 130.277 79.0154 130.477 79.0154 130.577C79.0154 130.777 78.9153 130.977 78.7153 130.977Z'
        fill='#207EC2'
      />
      <g opacity='0.3'>
        <mask
          id='mask0_958_25250'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='48'
          y='53'
          width='3'
          height='4'
        >
          <path d='M50.7153 53.8768H48.7153V56.2768H50.7153V53.8768Z' fill='white' />
        </mask>
        <g mask='url(#mask0_958_25250)'>
          <path
            d='M50.2151 56.0768C51.1151 55.4768 50.8151 53.3768 49.4151 53.9768C48.1151 54.5768 48.9151 56.9768 50.2151 56.0768Z'
            fill='white'
          />
        </g>
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M97.1153 95.4769C97.6153 94.6769 114.715 104.177 115.715 108.677C106.415 104.877 99.7155 118.777 100.816 121.677C100.816 121.677 93.1154 116.677 90.2154 111.377C87.1154 105.977 88.8153 100.177 97.1153 95.4769Z'
        fill='white'
      />
      <path
        d='M100.315 121.777C99.1155 121.777 97.2154 119.777 94.5154 116.677C93.7154 115.777 92.8154 114.777 92.0154 113.877C91.9154 113.777 91.9154 113.577 92.0154 113.377C92.1154 113.277 92.3154 113.277 92.5154 113.377C93.3154 114.277 94.2153 115.277 95.1153 116.177C97.0153 118.277 99.3155 120.977 100.315 120.977C100.115 118.377 103.315 112.077 107.415 109.077C110.115 107.177 112.815 106.877 115.415 108.377C114.715 106.277 104.015 97.9768 97.3155 95.0768C97.1155 94.9768 97.1153 94.7768 97.1153 94.6768C97.2153 94.4768 98.1155 94.6768 98.3155 94.6768C101.315 95.9768 104.715 98.5768 108.915 101.477C111.615 103.277 116.015 106.977 116.115 108.477C116.115 108.777 116.015 108.977 115.815 109.177C115.715 109.277 115.515 109.277 115.415 109.177C113.015 107.677 110.415 107.877 107.815 109.677C103.415 112.777 100.615 119.277 101.015 121.177C101.015 121.277 101.015 121.477 100.815 121.577C100.615 121.677 100.515 121.777 100.315 121.777Z'
        fill='#999999'
      />
      <path
        d='M100.616 121.677C100.516 121.677 100.416 121.677 100.316 121.577C100.216 121.477 100.216 121.277 100.416 121.077L102.516 119.477C102.616 119.377 102.816 119.377 103.016 119.577C103.116 119.677 103.116 119.877 102.916 120.077L100.816 121.677C100.816 121.677 100.716 121.677 100.616 121.677Z'
        fill='#999999'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M88.2156 48.3768L85.3157 54.4768C85.9157 50.9768 85.0156 48.6768 81.2156 46.1768C75.4156 42.6768 60.0155 40.0768 58.9155 28.3768C52.6155 33.1768 50.6157 43.4768 49.8157 48.9768C49.5157 50.5768 48.4155 51.5768 48.4155 51.5768C48.4155 51.5768 47.2156 54.9768 47.0156 58.8768C47.5156 65.6768 48.9155 73.5768 53.9155 78.0768C64.1155 84.6768 83.1156 77.7768 88.2156 65.1768C100.516 63.4768 103.116 43.4768 88.2156 48.3768Z'
        fill='#FCD1A9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61.6152 49.0768C60.9152 53.7768 56.9153 51.8768 56.7153 47.0768C56.8153 40.1768 62.2152 44.6768 61.6152 49.0768Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.8154 49.4768C60.6154 50.5768 60.3154 51.3768 59.8154 51.7768C58.4154 51.9768 56.8153 50.0768 56.7153 47.0768C56.7153 45.3768 57.1152 44.3768 57.6152 43.8768C59.2152 43.7768 61.2154 46.6768 60.8154 49.4768Z'
        fill='#7C170F'
      />
      <path
        d='M61.2152 46.8768C61.3152 47.4768 61.0151 47.9768 60.6151 47.9768C60.2151 47.9768 59.7151 47.5768 59.6151 46.9768C59.5151 46.3768 59.8152 45.8768 60.2152 45.8768C60.6152 45.7768 61.1152 46.2768 61.2152 46.8768Z'
        fill='white'
      />
      <path
        d='M50.0154 58.4768C49.9154 58.4768 49.9154 58.4768 50.0154 58.4768C49.7154 58.4768 49.6152 58.2768 49.6152 58.1768C49.6152 57.9768 49.8153 57.8768 49.9153 57.8768C50.2153 57.8768 50.6154 57.6768 50.8154 57.2768C50.9154 57.0768 51.1153 56.5768 50.7153 56.1768C50.6153 56.0768 50.6153 55.8768 50.7153 55.6768C50.8153 55.5768 51.0153 55.5768 51.2153 55.6768C51.6153 56.1768 51.7154 56.8768 51.3154 57.4768C51.1154 58.0768 50.5154 58.4768 50.0154 58.4768Z'
        fill='#8E3821'
      />
      <path
        d='M62.2157 41.5768C62.1157 41.5768 61.9158 41.5768 61.8158 41.4768C59.6158 39.6768 58.8156 39.7768 57.1156 40.1768C56.8156 40.2768 56.5156 40.0768 56.4156 39.7768C56.3156 39.4768 56.5158 39.1768 56.8158 39.0768C58.8158 38.4768 60.1157 38.5768 62.5157 40.5768C62.8157 40.7768 62.8156 41.1768 62.6156 41.3768C62.6156 41.4768 62.4157 41.5768 62.2157 41.5768Z'
        fill='#8E3821'
      />
      <path
        d='M51.2156 50.8768C51.2156 50.8768 45.5157 51.7768 45.3157 53.4768C44.2157 56.0768 47.1157 59.0768 48.3157 59.4768C48.8157 58.6768 51.2156 50.8768 51.2156 50.8768Z'
        fill='#FCD1A9'
      />
      <path
        d='M50.1155 65.4768C49.9155 65.4768 49.7157 65.2768 49.8157 65.1768C49.8157 64.9768 50.0155 64.8768 50.1155 64.8768C50.4155 64.8768 52.3156 64.5768 53.2156 63.7768C53.5156 63.4768 53.7156 63.1768 53.7156 62.8768C53.7156 62.6768 53.9156 62.5768 54.0156 62.5768C54.2156 62.5768 54.3157 62.7768 54.3157 62.8768C54.3157 63.3768 54.0155 63.8768 53.6155 64.2768C52.6155 65.1768 50.6155 65.4768 50.1155 65.4768Z'
        fill='#8E3821'
      />
      <path
        d='M51.3154 67.3769C51.2154 67.3769 51.0153 67.2769 51.0153 67.1769C50.9153 66.9769 51.0153 66.7769 51.2153 66.7769L52.3154 66.3769C52.5154 66.2769 52.7153 66.3769 52.7153 66.5769C52.8153 66.7769 52.7153 66.9769 52.5153 66.9769L51.4152 67.3769C51.4152 67.3769 51.4154 67.3769 51.3154 67.3769Z'
        fill='#8E3821'
      />
      <path
        d='M88.2153 65.4768C88.1153 65.4768 87.9152 65.3768 87.9152 65.2768C87.9152 65.0768 88.0152 64.9768 88.1152 64.8768C94.1152 63.2768 97.1152 59.0768 97.9152 55.5768C98.5152 52.8768 97.9154 50.3768 96.3154 48.9768C94.5154 47.4768 91.7154 47.3768 88.3154 48.6768C88.1154 48.7768 87.9152 48.6768 87.9152 48.4768C87.8152 48.2768 87.9152 48.0768 88.1152 48.0768C91.7152 46.6768 94.8153 46.7768 96.7153 48.4768C98.5153 50.0768 99.2153 52.7768 98.5153 55.6768C97.7153 59.3768 94.5153 63.8768 88.2153 65.4768Z'
        fill='#8E3821'
      />
      <path
        d='M92.2153 58.4768C92.1153 58.4768 92.0153 58.4768 92.0153 58.3768C91.9153 58.2768 91.9153 58.0768 92.0153 57.8768C94.4153 55.7768 95.3152 53.6768 94.4152 52.2768C93.7152 51.1768 92.2154 50.7768 90.8154 51.2768C90.6154 51.3768 90.4152 51.2768 90.4152 51.0768C90.3152 50.8768 90.4152 50.6768 90.6152 50.6768C92.3152 49.9768 94.1153 50.5768 95.0153 51.8768C96.0153 53.5768 95.1153 55.9768 92.5153 58.2768C92.4153 58.4768 92.3153 58.4768 92.2153 58.4768Z'
        fill='#8E3821'
      />
      <g opacity='0.31'>
        <g opacity='0.5' filter='url(#filter0_f_958_25250)'>
          <path
            d='M51.2153 52.9768C51.7153 54.7768 50.8153 56.6768 49.2153 57.0768C47.6153 57.4768 45.9152 56.3768 45.4152 54.4768C44.9152 52.6768 46.4153 53.0768 48.0153 52.6768C49.7153 52.2768 50.7153 51.0768 51.2153 52.9768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <g opacity='0.3'>
        <mask
          id='mask1_958_25250'
          style={{ maskType: "luminance" }}
          maskUnits='userSpaceOnUse'
          x='45'
          y='52'
          width='4'
          height='4'
        >
          <path d='M48.0154 52.9768H45.8154V55.5768H48.0154V52.9768Z' fill='white' />
        </mask>
        <g mask='url(#mask1_958_25250)'>
          <path
            d='M47.415 55.3768C48.415 54.7768 48.2151 52.4768 46.7151 53.0768C45.2151 53.6768 45.915 56.3768 47.415 55.3768Z'
            fill='white'
          />
        </g>
      </g>
      <g opacity='0.4'>
        <g opacity='0.5' filter='url(#filter1_f_958_25250)'>
          <path
            d='M69.5153 58.1768C70.4153 61.1768 69.0152 64.1768 66.4152 64.9768C63.8152 65.7768 61.0152 63.9768 60.1152 60.9768C59.2152 57.9768 61.3152 57.0768 63.9152 56.2768C66.5152 55.4768 68.6153 55.1768 69.5153 58.1768Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M68.7153 80.0768C68.7153 80.0768 75.0153 77.5768 87.7153 65.8768C76.4153 84.0768 68.8154 84.0768 68.8154 84.0768L68.7153 80.0768Z'
        fill='#F9836A'
      />
      <path
        d='M86.7152 117.877C86.6152 116.677 86.5152 115.377 85.9152 114.377C84.9152 112.777 80.8151 112.877 79.1151 112.677C76.5151 112.377 73.9153 112.177 71.3153 111.877C69.1153 111.677 66.9152 111.477 64.7152 111.277C62.5152 111.077 60.0153 110.477 57.8153 110.677C56.2153 110.877 55.5153 112.377 55.0153 113.877C54.3153 115.777 55.3151 118.577 55.6151 120.477C55.9151 122.977 56.3153 125.477 56.5153 127.977C64.0153 130.877 72.1152 132.577 80.7152 132.577C83.7152 132.577 86.7151 132.377 89.6151 131.977C88.5151 129.277 87.7152 126.177 87.2152 122.577C87.0152 121.177 86.8152 119.577 86.7152 117.877Z'
        fill='#5EB1ED'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.3153 120.177C52.6153 121.077 53.8153 111.377 53.8153 111.377C53.8153 95.2768 57.7153 89.8767 61.3153 88.7767L66.6151 87.6768C59.9151 91.1768 59.5152 103.077 61.9152 114.177C62.5152 117.077 61.1153 119.377 58.3153 120.177Z'
        fill='#2E94D9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M59.2156 118.077C58.2156 119.077 56.5156 119.077 55.5156 118.077C54.5156 117.077 54.5156 115.377 55.5156 114.377C56.5156 113.377 58.2156 113.377 59.2156 114.377C60.4156 115.477 60.4156 117.177 59.2156 118.077Z'
        fill='#FFE1B4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M76.1151 120.777C73.1151 118.377 75.8153 99.7768 88.5153 92.7768C91.0153 91.7768 92.9152 90.4768 96.2152 92.2768L100.415 94.8768C97.5152 94.0768 90.8153 92.5768 85.5153 106.977C83.3153 113.077 84.1153 117.677 82.5153 120.277C80.8153 122.777 77.2151 121.877 76.1151 120.777Z'
        fill='#2E94D9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M80.8154 118.677C79.8154 119.677 78.1152 119.677 77.1152 118.677C76.1152 117.677 76.1152 115.977 77.1152 114.977C78.1152 113.977 79.8154 113.977 80.8154 114.977C81.9154 116.077 81.9154 117.677 80.8154 118.677Z'
        fill='#FFE1B4'
      />
      <defs>
        <filter
          id='filter0_f_958_25250'
          x='42.3174'
          y='48.9343'
          width='12.0361'
          height='11.2243'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25250' />
        </filter>
        <filter
          id='filter1_f_958_25250'
          x='56.9033'
          y='52.7349'
          width='15.8848'
          height='15.4319'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25250' />
        </filter>
      </defs>
    </svg>
  );
};
