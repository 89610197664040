import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import izba from "~shared/assets/images/6/izba.jpg";
import monastir from "~shared/assets/images/6/monastir.jpg";
import kreml from "~shared/assets/images/6/kreml.jpg";

const Theme6Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Изба",
      image: izba
    },
    {
      name: "Монастырь",
      image: monastir
    },
    {
      name: "Деревянный кремль",
      image: kreml
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри рисунки. Отметь изображение монастыря.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Монастырь' hideName />
    </QuizWrapper>
  );
};

export default Theme6Task1;
