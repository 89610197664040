import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import styles from "./DraggableWrapper.module.scss";
import clsx from "clsx";
import { useDragAndDropContext } from "~shared/hooks/useDragAndDropContext";

type TPosition = { x: number; y: number };

type Props = PropsWithChildren & {
  id: number;
  className?: string;
  style?: React.CSSProperties;
  toDefaultPosition?: boolean;
};

export const DraggableWrapper: React.FC<Props> = ({
  id,
  className = "",
  style,
  toDefaultPosition = true,
  children
}) => {
  const {
    dragId,
    setDragId,
    setDragPosition,
    dropPosition,
    setDropPosition,
    results,
    incorrectIds
  } = useDragAndDropContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const [isDrag, setIsDrag] = useState(false);

  const [rectPosition, setRectPosition] = useState<TPosition>({ x: 0, y: 0 });
  const [position, setPosition] = useState<TPosition>({ x: 0, y: 0 });
  const [offset, setOffset] = useState<TPosition>({ x: 0, y: 0 });
  const [afterDropPosition, setAfterDropPosition] = useState<TPosition>({ x: 0, y: 0 });

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDrag(true);
    setOffset({ x: event.clientX - rectPosition.x, y: event.clientY - rectPosition.y });
    setDragId(id);

    results.forEach((value, key, map) => {
      if (value === id) {
        map.delete(key);
      }
    });
  };

  const handleMouseUp = (event: React.MouseEvent) => {
    setIsDrag(false);
    setPosition({ x: 0, y: 0 });
    setAfterDropPosition({ x: 0, y: 0 });

    setDragPosition({
      x: event.clientX - offset.x + afterDropPosition.x,
      y: event.clientY - offset.y + afterDropPosition.y
    });
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    setIsDrag(true);
    setDragId(id);
    setOffset({
      x: event.changedTouches[0].clientX - rectPosition.x,
      y: event.changedTouches[0].clientY - rectPosition.y
    });

    results.forEach((value, key, map) => {
      if (value === id) {
        map.delete(key);
      }
    });
  };

  const handleTouchEnd = (event: React.TouchEvent) => {
    setIsDrag(false);
    setPosition({ x: 0, y: 0 });
    setAfterDropPosition({ x: 0, y: 0 });

    setDragPosition({
      x: event.changedTouches[0].clientX - offset.x,
      y: event.changedTouches[0].clientY - offset.y
    });
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isDrag) {
        setPosition({
          x: event.clientX - rectPosition.x - offset.x + afterDropPosition.x,
          y: event.clientY - rectPosition.y - offset.y + afterDropPosition.y
        });
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (isDrag) {
        setPosition({
          x: event.changedTouches[0].clientX - rectPosition.x - offset.x + afterDropPosition.x,
          y: event.changedTouches[0].clientY - rectPosition.y - offset.y + afterDropPosition.y
        });
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isDrag, offset, rectPosition, afterDropPosition]);

  useEffect(() => {
    const { x = 0, y = 0 } = ref.current?.getBoundingClientRect() || {};

    setRectPosition({ x, y });
  }, [ref.current]);

  useEffect(() => {
    if (dropPosition && dragId === id) {
      setPosition({ x: dropPosition.x - rectPosition.x, y: dropPosition.y - rectPosition.y });
      setAfterDropPosition({
        x: dropPosition.x - rectPosition.x,
        y: dropPosition.y - rectPosition.y
      });
      setDragId(-1);
      setDropPosition(null);
    }
  }, [dropPosition, dragId]);

  useEffect(() => {
    if (!toDefaultPosition) return;
    if (incorrectIds.includes(id)) {
      setPosition({ x: 0, y: 0 });
      setAfterDropPosition({ x: 0, y: 0 });
    }
  }, [incorrectIds, id]);

  return (
    <div
      ref={ref}
      className={clsx(styles.draggableItem, { [styles.animation]: !isDrag }, className)}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        ...(isDrag && { zIndex: 10 }),
        ...(!isDrag && { opacity: 1 }),
        ...style
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
};
