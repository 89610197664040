import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizEnterWord } from "~modules/QuizEnterWord";
import rebus from "~shared/assets/images/20/rebus2.jpg";
import { ImageRebus } from "~/shared/components/ImageRebus";

const Theme20Task4 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Как называется место, где хранятся книги?'
      description='Напиши отгадку в поле ниже'
      successIcon='nast-2'
    >
      <ImageRebus url={rebus} />
      <QuizEnterWord answer='Библиотека' />
    </QuizWrapper>
  );
};

export default Theme20Task4;
