import React, { useState, useRef } from "react";
import { AppInput } from "~shared/components/AppInput";
import styles from "./QuizEnterWord.module.scss";
import { QuizResult } from "~shared/components/QuizResult";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import clsx from "clsx";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

type Props = {
  answer: string;
  question?: string;
  imgUrl?: string;
  secondAnswer?: string;
  rebus?: boolean;
};

export const QuizEnterWord: React.FC<Props> = ({
  answer,
  question,
  imgUrl,
  secondAnswer = "",
  rebus = false
}) => {
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [error, setError] = useState(false);
  const { setSuccess } = useQuizContext();
  const fieldRef = useRef<HTMLInputElement>(null);
  const [mobileKey, setMobileKey] = useState(false);
  const isMobile = useAdaptiveValue(true, true, false);

  const handleClick = () => {
    if (
      currentAnswer.toUpperCase() === answer.toUpperCase() ||
      currentAnswer.toUpperCase() === secondAnswer.toUpperCase()
    ) {
      setTimeout(() => {
        setSuccess(true);
        setError(false);
      }, 700);
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  const handleChange = (value: string) => {
    setCurrentAnswer(value);
    setError(false);
  };

  return (
    <div
      className={styles.enterWordQuiz}
      style={{
        position: "relative",
        height: rebus ? "115px" : "",
        marginBottom: mobileKey && isMobile ? "50vh" : ""
      }}
    >
      {question && <div className={styles.question}>{question}</div>}
      {imgUrl && <img className={styles.img} src={imgUrl} alt='Задание' />}
      <div style={{ position: rebus ? "absolute" : "static" }} ref={fieldRef}>
        <AppInput
          onChange={handleChange}
          error={error}
          onFocus={() => {
            setMobileKey(true);
            if (rebus) {
              setTimeout(() => {
                window.scrollTo({
                  top: 600
                });
                console.log("window scrollY", window.scrollY);
                console.log("scrollY", window.scrollY);
              }, 50);
            }
          }}
        />
      </div>

      <div style={{ position: rebus ? "absolute" : "static", top: "24px", width: "100%" }}>
        <QuizResult
          errorVisible={error}
          buttonValue='Проверить'
          buttonOnClick={handleClick}
          buttonVisible={currentAnswer !== "" && !error}
        />
      </div>

      <div className={clsx({ [styles.mobileInput]: mobileKey && isMobile })}></div>
    </div>
  );
};
