import React, { useEffect } from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { DragAndDropProvider } from "~/providers/DragAndDropProvider";
import styles from "./Theme21Task3.module.scss";
import { DraggableWrapper } from "~shared/components/DraggableWrapper";
import { QuizResult } from "~shared/components/QuizResult";
import { DropContainer } from "~shared/components/DropContainer";
import { useDragAndDropContext } from "~shared/hooks/useDragAndDropContext";
import clsx from "clsx";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

const draggableItems: { id: number; text: string }[] = [
  {
    id: 1,
    text: "ДЕЛУ"
  },
  {
    id: 2,
    text: "ЧАС"
  },
  {
    id: 3,
    text: "ПОТЕХЕ"
  },
  {
    id: 4,
    text: "ВРЕМЯ,"
  }
];

const dropZones: { id: number; name: string }[] = [
  {
    id: 1,
    name: "1"
  },
  {
    id: 2,
    name: "2"
  },
  {
    id: 3,
    name: "3"
  },
  {
    id: 4,
    name: "4"
  }
];

const answers: Record<number, number> = { 1: 1, 2: 4, 3: 3, 4: 2 };

const Content: React.FC = () => {
  const { setSuccess } = useQuizContext();
  const { results, incorrectIds, setIncorrectIds } = useDragAndDropContext();

  const handleClick = () => {
    setIncorrectIds([]);

    results.forEach((value, key, map) => {
      if (map.get(key) !== answers[key]) {
        setIncorrectIds((prev) => [...prev, value]);
        results.delete(key);
      }
    });
  };

  const styleDraggableItem = useAdaptiveValue(
    styles.draggableItemMobile,
    styles.draggableItemMobile,
    styles.draggableItems
  );

  const styleWord = useAdaptiveValue(styles.wordMobile, styles.wordMobile, styles.word);
  const styleDropZone = useAdaptiveValue(
    styles.dropZoneMobile,
    styles.dropZoneMobile,
    styles.dropZone
  );
  const styleDropName = useAdaptiveValue(
    styles.dropNameMobile,
    styles.dropNameMobile,
    styles.dropName
  );
  const styleDropZoneContainer = useAdaptiveValue(
    styles.dropZoneContainerMobile,
    styles.dropZoneContainerMobile,
    styles.dropZoneContainer
  );

  useEffect(() => {
    if (results.size > 0 && incorrectIds.length === 0) {
      setTimeout(() => setSuccess(true), 700);
    }
  }, [results, incorrectIds]);

  return (
    <div className={styles.container}>
      <div className={styleDropZoneContainer}>
        {dropZones.map((item, idx) => (
          <div className={styles.dropPoint} key={item.id}>
            <div className={clsx(styleDropName, { [styles.filled]: results.has(idx + 1) })}>
              {item.name}
            </div>
            <DropContainer className={styleDropZone} id={item.id} />
          </div>
        ))}
      </div>

      <div className={styleDraggableItem}>
        {draggableItems.map((item) => {
          return (
            <DraggableWrapper key={item.id} id={item.id}>
              <div className={styleWord}>
                <p className={styles.word_text}>{item.text}</p>
              </div>
            </DraggableWrapper>
          );
        })}
      </div>

      <QuizResult
        buttonVisible={results.size === Object.keys(answers).length}
        errorVisible={incorrectIds.length > 0 && results.size !== Object.keys(answers).length}
        buttonOnClick={handleClick}
      />
    </div>
  );
};

export default function Theme21Task3() {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Собери пословицу.'
      description='Перетащи слова на верные места'
      successIcon='nast-2'
    >
      <DragAndDropProvider>
        <Content />
      </DragAndDropProvider>
    </QuizWrapper>
  );
}
