import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTimeLine, Answer, DraggableItem, DropZone } from "~modules/QuizTimeLine";
import donskoy from "~/shared/assets/images/19/donskoy.jpg";
import grozny from "~/shared/assets/images/19/grozny.jpg";
import dolgorukyi from "~/shared/assets/images/19/dolgorukyi.jpg";
import kalita from "~/shared/assets/images/19/kalita.jpg";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    imgUrl: donskoy,
    title: "Дмитрий Донской",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 2,
    imgUrl: dolgorukyi,
    title: "Юрий Долгорукий",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 3,
    imgUrl: kalita,
    title: "Иван Калита",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 4,
    imgUrl: grozny,
    title: "Иван Грозный",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 2,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 3,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 4,
    correct: false,
    uncorrect: false,
    empty: true
  }
];

const answers: Answer = { 1: 2, 2: 3, 3: 1, 4: 4 };

const Theme19Task2 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Кто был раньше, а кто позже?'
      description='Правильно расположи изображения'
      successIcon='dan-2'
    >
      <QuizTimeLine draggableItems={draggableItems} dropZones={dropZones} answers={answers} />
    </QuizWrapper>
  );
};

export default Theme19Task2;
