import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import olympMishka from "~/shared/assets/images/42/olymp-mishka.jpg";
import belyiMishka from "~/shared/assets/images/42/belyi-mishka.jpg";
import matreshka from "~/shared/assets/images/42/matreshka.jpg";
import volk from "~/shared/assets/images/42/volk.jpg";

const Theme42Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Волк Забивака",
      image: volk
    },
    {
      name: "Олимпийский Мишка",
      image: olympMishka
    },
    {
      name: "Матрешка",
      image: matreshka
    },
    {
      name: "Белый Мишка",
      image: belyiMishka
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Как выглядел символ Олимпийских игр в Москве?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Олимпийский Мишка' hideName />
    </QuizWrapper>
  );
};

export default Theme42Task1;
