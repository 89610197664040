import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import kremlIvanKalita from "~shared/assets/images/7/kreml-ivan-kalita.jpg";
import kremlDmitryDonskoi from "~shared/assets/images/7/kreml-dmitry-donskoi.jpg";
import kremlOsnovanie from "~shared/assets/images/7/kreml-osnovanie.jpg";

const Theme7Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Кремль при Иване Калите",
      image: kremlIvanKalita
    },
    {
      name: "Кремль при Дмитрии Донском",
      image: kremlDmitryDonskoi
    },
    {
      name: "Основание Москвы",
      image: kremlOsnovanie
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри изображения. Отметь Кремль при Иване Калите.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Кремль при Иване Калите' hideName />
    </QuizWrapper>
  );
};

export default Theme7Task2;
