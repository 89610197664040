import React, { useEffect, useState } from "react";
import { LetterSquare } from "../LetterSquare";
import styles from "./LetterRow.module.scss";

type WordRowProps = {
  letters: string[];
  correctWord: string;
  showResult: boolean;
  setIsWin: (value: boolean) => void;
};

const WordRow: React.FC<WordRowProps> = ({ letters, correctWord, setIsWin, showResult }) => {
  const [rowLetters, setRowLetters] = useState<boolean[]>(Array(letters.length).fill(false));
  const [rowError, setRowError] = useState<boolean>(false);

  const handleLetterCross = (index: number) => {
    const newRowLetters = [...rowLetters];
    newRowLetters[index] = !rowLetters[index];
    setRowLetters(newRowLetters);
  };

  useEffect(() => {
    const userWord = rowLetters.reduce(
      (word, crossed, index) => (!crossed ? word + letters[index] : word),
      ""
    );
    const resultRow = userWord === correctWord;
    resultRow ? setIsWin(true) : setIsWin(false);
    resultRow ? setRowError(false) : setRowError(true);
  }, [rowLetters]);

  return (
    <div className={styles.rowLetters}>
      {letters.map((letter, index) => (
        <LetterSquare
          key={index}
          showResult={showResult}
          rowError={rowError}
          correctWord={correctWord}
          letter={letter}
          crossed={rowLetters[index]}
          onClick={() => handleLetterCross(index)}
        />
      ))}
    </div>
  );
};

export default WordRow;
