import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItems } from "~/modules/QuizChooseItems";

const Theme38Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "Котельническая набережная"
    },
    {
      name: "Кудринская площадь"
    },
    {
      name: "Карачаровская улица"
    },
    {
      name: "Карманицкий переулок"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Где в Москве раположены жилые высотные здания?'
      description='Выбери 2 правильных ответа'
      successIcon='rus-1'
    >
      <QuizChooseItems
        type='str'
        items={items}
        correctItems={["Котельническая набережная", "Кудринская площадь"]}
      />
    </QuizWrapper>
  );
};

export default Theme38Task1;
