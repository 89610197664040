import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTimeLine, Answer, DraggableItem, DropZone } from "~modules/QuizTimeLine";
import bel from "~shared/assets/images/15/belokamennyi_kreml copy.jpg";
import osn from "~shared/assets/images/15/osnovaniye_moskvy.jpg";
import pokrov from "~shared/assets/images/15/sobor_pokrova_na_rvu.jpg";
import krasn from "~shared/assets/images/15/stroitelstvo_kremlya_is_krasnogo_kirpicha.jpg";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    imgUrl: bel,
    title: "Белокаменный Кремль",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 2,
    imgUrl: osn,
    title: "Основание Москвы",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 3,
    imgUrl: pokrov,
    title: "Собор Покрова на Рву",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 4,
    imgUrl: krasn,
    title: "Строительство Кремля из красного кирпича",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    name: "12 век",
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 2,
    name: "14 век",
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 3,
    name: "15 век",
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 4,
    name: "16 век",
    correct: false,
    uncorrect: false,
    empty: true
  }
];

const answers: Answer = { 1: 2, 2: 1, 3: 4, 4: 3 };

const Theme15Task2 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Восстанови последовательность событий.'
      description='Правильно расположи изображения'
      successIcon='dan-2'
    >
      <QuizTimeLine draggableItems={draggableItems} dropZones={dropZones} answers={answers} />
    </QuizWrapper>
  );
};

export default Theme15Task2;
