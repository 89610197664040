import React from "react";
export const Mash1: React.FC = () => {
  return (
    <svg
      width='152'
      height='133'
      viewBox='0 0 152 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M85.4263 132.677C121.877 132.677 151.426 103.128 151.426 66.6768C151.426 30.226 121.877 0.676819 85.4263 0.676819C48.9755 0.676819 19.4263 30.226 19.4263 66.6768C19.4263 103.128 48.9755 132.677 85.4263 132.677Z'
        fill='#B4D5A8'
      />
      <path
        d='M12.0265 88.1769C14.6265 86.3769 18.2265 87.0769 20.0265 89.7769C20.9265 91.1769 24.3263 94.5769 29.1263 98.2769C33.8263 101.877 38.8263 105.077 42.6263 106.377C49.2263 108.677 55.3264 108.177 60.7264 106.777C63.0264 106.177 65.2265 105.577 67.3265 104.877C67.7265 104.777 68.2263 104.577 68.6263 104.477C70.9263 103.777 73.8264 102.877 76.4264 102.677C79.6264 102.477 82.3265 104.877 82.5265 108.077C82.7265 111.277 80.8263 115.777 77.6263 115.977C76.7263 116.077 75.2263 116.377 72.6263 117.177C72.2263 117.277 71.8264 117.377 71.4264 117.577C69.3264 118.277 66.7264 119.077 63.9264 119.777C57.1264 121.477 48.1263 120.877 38.6263 117.577C33.1263 115.677 26.9264 111.177 21.9264 107.277C17.0264 103.477 12.3265 99.0769 10.3265 96.0769C8.62653 93.5769 9.32648 89.9769 12.0265 88.1769Z'
        fill='#FBC18F'
      />
      <path
        d='M21.2261 92.6769C22.1261 96.6769 14.4262 99.8769 11.8262 97.9769C10.2262 96.7769 6.926 92.1769 4.626 88.8769C3.426 87.0769 4.126 84.5769 6.126 83.6769L11.926 81.1769C14.126 80.2769 16.626 81.1769 17.626 83.2769C19.026 86.3769 20.7261 90.3769 21.2261 92.6769Z'
        fill='#FBC18F'
      />
      <path
        d='M6.52633 88.9768C7.02633 88.4768 7.72628 87.4768 7.22628 86.8768C6.72628 86.3768 5.72618 85.0768 5.12618 83.5768C4.52618 82.1768 4.02618 81.2768 3.62618 79.6768C3.62618 78.8768 3.22623 78.0768 2.42623 78.0768C1.62623 78.0768 0.926374 78.8768 0.826374 79.5768C0.726374 81.4768 1.32633 83.4768 2.02633 85.0768C2.72633 86.6768 3.72633 88.1768 4.52633 88.9768C5.12633 89.4768 6.02633 89.4768 6.52633 88.9768Z'
        fill='#FBC18F'
      />
      <path
        d='M10.026 88.2769C11.026 87.2769 10.1261 85.9769 9.82606 84.8769C9.52606 84.0769 8.92586 82.7769 8.62586 81.6769C8.12586 79.3769 7.72596 77.0769 7.72596 75.6769C7.72596 74.8769 7.12591 74.2769 6.42591 74.2769C5.62591 74.2769 4.92586 74.6769 4.62586 75.8769C4.22586 77.0769 4.42591 80.6769 4.92591 83.0769C5.32591 84.8769 6.62586 86.9769 8.12586 88.3769C8.62586 88.8769 9.42601 88.8769 10.026 88.2769Z'
        fill='#FBC18F'
      />
      <path
        d='M13.4264 87.2767C14.4264 86.2767 14.0263 84.9768 13.6263 83.9768C13.3263 83.0768 12.4263 81.9767 12.1263 80.7767C11.5263 78.2767 10.7264 75.5767 10.7264 74.2767C10.7264 73.4767 10.3265 72.6768 9.32653 72.6768C8.32653 72.6768 7.52638 73.6768 7.42638 74.4768C7.32638 76.2768 7.82638 79.3768 8.42638 81.9768C8.92638 83.8768 9.92638 85.9768 11.4264 87.4768C11.9264 87.8768 12.8264 87.7767 13.4264 87.2767Z'
        fill='#FBC18F'
      />
      <path
        d='M17.8263 87.1768C18.3263 86.6768 18.3263 85.7768 17.8263 85.1768C17.7263 85.0768 17.7262 84.5768 17.2262 83.5768C16.1262 81.3768 14.8261 78.9768 14.1261 76.5768C13.8261 75.3768 13.6262 74.4768 13.7262 73.8768C13.7262 72.8768 13.0263 72.2768 12.3263 72.1768C11.5263 72.1768 10.7261 73.0767 10.6261 73.7767C10.5261 76.0767 11.1262 79.2768 12.0262 81.4768C12.8262 83.3768 14.4261 85.5768 15.9261 87.0768C16.4261 87.7768 17.3263 87.7768 17.8263 87.1768Z'
        fill='#FBC18F'
      />
      <path
        d='M11.8267 81.9768C11.7267 81.9768 11.6265 81.8768 11.6265 81.7768C11.0265 80.4768 9.92657 77.1768 10.4266 73.7768C10.4266 73.6768 10.6266 73.5768 10.7266 73.5768C10.8266 73.5768 10.9266 73.7768 10.9266 73.8768C10.4266 77.1768 11.4267 80.3768 12.0267 81.5768C12.1267 81.6768 12.0266 81.8768 11.9266 81.9768H11.8267Z'
        fill='#7D0000'
      />
      <path
        d='M8.32672 83.2768C8.22672 83.2768 8.12653 83.1768 8.12653 83.0768C7.52653 81.7768 6.42657 78.4768 6.92657 75.0768C6.92657 74.9768 7.12662 74.8768 7.22662 74.8768C7.32662 74.8768 7.42657 75.0768 7.42657 75.1768C6.92657 78.4768 7.92667 81.6768 8.52667 82.8768C8.62667 82.9768 8.52657 83.1768 8.42657 83.2768C8.32657 83.2768 8.32672 83.2768 8.32672 83.2768Z'
        fill='#7D0000'
      />
      <path
        d='M14.6262 87.0768C13.7262 87.9768 14.7263 91.1768 15.5263 92.0768C16.4263 92.9768 18.2262 92.6768 19.1262 91.7768C19.8262 91.1768 19.9263 90.7768 20.7263 89.2768C21.4263 87.8768 22.3262 84.6768 21.1262 82.4768C19.7262 79.9768 23.1262 77.0768 21.6262 76.0768C19.5262 74.6768 17.2262 77.9768 16.9262 79.7768C16.4262 83.2768 17.6262 84.1768 14.6262 87.0768Z'
        fill='#FBC18F'
      />
      <path
        d='M14.9264 92.3768C14.8264 92.3768 14.6263 92.2768 14.6263 92.1768C13.7263 89.3768 14.1264 86.7768 15.9264 84.1768C16.0264 84.0768 16.2265 83.9768 16.3265 84.0768C16.4265 84.1768 16.5264 84.3768 16.4264 84.4768C14.7264 86.8768 14.3264 89.2768 15.2264 91.8768C15.3264 92.0768 15.2265 92.2768 15.0265 92.2768C14.9265 92.3768 14.9264 92.3768 14.9264 92.3768Z'
        fill='#7D0000'
      />
      <path
        d='M77.9265 101.277C65.2265 102.277 63.9266 108.877 61.2266 117.777C60.7266 119.377 60.0266 122.677 59.2266 127.177C67.2266 130.677 76.1265 132.577 85.4265 132.577C89.0265 132.577 92.5265 132.277 95.9265 131.777C95.8265 130.477 95.8266 129.577 96.0266 129.177L100.527 118.777C105.027 107.077 90.6265 100.377 77.9265 101.277Z'
        fill='#1A9A67'
      />
      <path
        d='M77.9265 101.277C65.2265 102.277 63.9266 108.877 61.2266 117.777C60.7266 119.377 60.0266 122.677 59.2266 127.177C67.2266 130.677 76.1265 132.577 85.4265 132.577C89.0265 132.577 92.5265 132.277 95.9265 131.777C95.8265 130.477 95.8266 129.577 96.0266 129.177L100.527 118.777C105.027 107.077 90.6265 100.377 77.9265 101.277Z'
        fill='#1A9A67'
      />
      <g opacity='0.3'>
        <path
          d='M87.926 132.177C82.026 132.677 76.2261 132.177 70.5261 130.877C67.5261 130.177 64.7262 129.277 61.8262 128.277C62.7262 128.577 63.8261 128.177 64.7261 128.277C65.6261 128.477 66.5261 128.677 67.5261 128.677C72.6261 129.077 77.8261 129.177 83.0261 128.977C85.5261 128.877 87.926 128.677 90.426 128.377C92.526 128.177 94.5261 127.877 96.5261 127.477C96.6261 127.477 96.826 127.477 96.926 127.377C96.626 128.377 96.226 129.477 96.126 130.577C96.026 131.877 94.0261 131.477 93.0261 131.577C91.3261 131.877 89.626 132.077 87.926 132.177Z'
          fill='#4ADDC2'
        />
      </g>
      <g opacity='0.3'>
        <path
          d='M88.6263 112.377C83.5263 112.377 78.5263 111.877 73.4263 111.277C70.9263 110.977 68.4264 110.577 66.0264 110.277C64.8264 110.077 63.6265 110.377 64.3265 108.877C64.6265 108.077 65.2265 107.377 65.8265 106.777C66.3265 106.177 69.0265 107.177 69.8265 107.377C71.3265 107.677 72.9263 107.877 74.4263 108.077C77.4263 108.477 80.5263 108.677 83.6263 108.877C86.8263 108.977 89.9263 108.977 93.1263 108.877C94.7263 108.777 96.3265 108.777 97.8265 108.577C98.7265 108.477 98.8263 108.477 99.4263 109.077C99.9263 109.677 101.526 111.277 100.826 111.977C100.326 112.477 98.4264 112.077 97.7264 112.177C94.6264 112.277 91.6263 112.377 88.6263 112.377Z'
          fill='#4ADDC2'
        />
      </g>
      <g opacity='0.3'>
        <path
          d='M87.8264 121.877C82.4264 121.877 77.1264 121.477 71.8264 120.777C69.0264 120.377 66.3262 119.877 63.6262 119.277C63.1262 119.177 61.2262 119.277 60.9262 118.977C60.5262 118.577 61.6264 116.377 61.8264 115.777C61.9264 115.577 61.9263 115.477 62.0263 115.377C62.3263 115.177 63.1262 115.477 63.4262 115.577C69.6262 117.077 75.9263 117.977 82.2263 118.277C85.4263 118.477 88.6263 118.477 91.7263 118.377C94.7263 118.277 97.7263 117.977 100.726 117.977C100.726 118.277 100.626 118.577 100.426 118.877C99.9262 119.777 99.5263 121.377 98.5263 121.577C97.6263 121.777 96.3264 121.577 95.3264 121.677C93.0264 121.777 90.4264 121.877 87.8264 121.877Z'
          fill='#4ADDC2'
        />
      </g>
      <path
        d='M116.827 38.6768C110.827 26.1768 86.9266 23.4769 77.0266 25.9769C76.7266 26.0769 76.4265 26.3768 76.4265 26.6768L74.7266 35.7768C74.7266 35.9768 74.7266 36.0768 74.7266 36.2768C81.2266 53.6768 96.2265 88.4768 101.626 85.0768C112.826 77.8768 124.427 54.5768 116.827 38.6768Z'
        fill='#FFE6AA'
      />
      <path
        d='M83.5261 33.3768C90.9267 33.3768 96.926 27.3774 96.926 19.9768C96.926 12.5762 90.9267 6.57678 83.5261 6.57678C76.1255 6.57678 70.126 12.5762 70.126 19.9768C70.126 27.3774 76.1255 33.3768 83.5261 33.3768Z'
        fill='#FFE6AA'
      />
      <path
        d='M87.2263 87.7768C87.0263 92.9768 86.1264 100.877 91.8264 103.477C87.4264 105.977 82.0262 106.677 77.1262 105.277C76.3262 105.077 74.1263 104.377 73.9263 103.377C73.7263 102.477 75.1262 100.677 75.1262 99.6768V86.7768C75.1262 86.6768 87.2263 87.7768 87.2263 87.7768Z'
        fill='#FBC18F'
      />
      <path
        d='M89.2263 91.0769L87.0264 93.7769L75.4263 100.377V90.2769L89.2263 91.0769Z'
        fill='#F9836A'
      />
      <path
        d='M106.326 76.2768C120.926 82.3768 127.426 61.7768 111.426 61.7768C110.726 49.4768 111.026 28.7768 93.6263 27.4768C79.8263 24.7768 60.7263 38.7768 60.1263 50.2768C59.5263 61.7768 56.7263 58.2768 55.4263 67.7768C54.0263 77.1768 62.6263 91.0768 69.1263 95.5768C82.1263 97.5768 98.0265 95.2768 106.326 76.2768Z'
        fill='#FBC18F'
      />
      <path
        d='M71.9262 79.9769C70.3262 79.9769 68.7263 79.6769 67.0263 79.1769C66.8263 79.0769 66.7263 78.9769 66.8263 78.7769C66.9263 78.5769 67.0263 78.4769 67.2263 78.5769C71.6263 79.8769 75.5263 79.4769 78.8263 77.2769C78.9263 77.1769 79.1263 77.1769 79.2263 77.3769C79.3263 77.4769 79.3262 77.6769 79.1262 77.7769C76.9262 79.2769 74.5262 79.9769 71.9262 79.9769Z'
        fill='#7D1A20'
      />
      <path
        d='M89.2265 62.1768C87.6265 66.3768 83.2264 65.9768 83.9264 61.5768C84.7264 57.2768 91.1265 55.9768 89.2265 62.1768Z'
        fill='white'
      />
      <path
        d='M85.5264 64.9768C86.6264 64.7768 87.7265 63.7768 88.3265 62.0768C89.1265 59.4768 88.4264 58.1768 87.2264 57.8768C85.8264 58.1768 84.2263 59.4768 83.9263 61.4768C83.6263 63.5768 84.4264 64.7768 85.5264 64.9768Z'
        fill='#7D0000'
      />
      <path
        d='M67.4262 57.1768C67.6262 61.2768 63.7262 62.5768 62.7262 58.5768C61.8262 54.7768 66.8262 51.2768 67.4262 57.1768Z'
        fill='white'
      />
      <path
        d='M64.6263 60.9768C65.7263 60.6768 66.8264 59.3768 66.7264 57.1768C66.5264 55.1768 65.8265 54.2768 65.0265 54.0768C63.6265 54.3768 62.3265 56.4768 62.8265 58.5768C63.1265 60.0768 63.8263 60.7768 64.6263 60.9768Z'
        fill='#7D0000'
      />
      <path
        d='M88.7168 61.2749C89.2138 61.2681 89.6111 60.8597 89.6043 60.3627C89.5976 59.8657 89.1893 59.4683 88.6923 59.4751C88.1953 59.4818 87.798 59.8902 87.8047 60.3872C87.8115 60.8842 88.2198 61.2816 88.7168 61.2749Z'
        fill='white'
      />
      <path
        d='M66.6776 56.7404C67.0813 56.5607 67.2445 56.047 67.0424 55.5929C66.8403 55.1388 66.3494 54.9163 65.9458 55.0959C65.5421 55.2756 65.3787 55.7893 65.5808 56.2434C65.7829 56.6975 66.274 56.92 66.6776 56.7404Z'
        fill='white'
      />
      <g opacity='0.5' filter='url(#filter0_f_958_25358)'>
        <path
          d='M67.7261 67.9768C67.2261 69.7768 68.1261 71.6768 69.7261 72.0768C71.3261 72.4768 73.0261 71.3768 73.5261 69.4768C74.0261 67.6768 72.526 68.0768 70.926 67.6768C69.226 67.2768 68.1261 66.1768 67.7261 67.9768Z'
          fill='#F15331'
        />
      </g>
      <path
        d='M68.3261 71.5768C68.2261 71.5768 68.2259 71.5768 68.1259 71.5768C67.2259 71.0768 66.7261 70.0768 66.8261 68.9768C66.9261 67.7768 67.626 66.8768 68.526 66.5768C68.726 66.4768 68.926 66.5768 68.926 66.7768C69.026 66.9768 68.926 67.1768 68.726 67.1768C68.126 67.3768 67.626 68.0768 67.526 68.9768C67.426 69.8768 67.826 70.6768 68.426 70.9768C68.626 71.0768 68.626 71.2768 68.526 71.3768C68.626 71.4768 68.5261 71.5768 68.3261 71.5768Z'
        fill='#7D0000'
      />
      <path
        d='M73.1265 72.1768H73.0266C72.8266 72.1768 72.7266 71.9768 72.7266 71.7768C72.7266 71.5768 72.9265 71.4768 73.1265 71.4768C73.3265 71.4768 73.6267 71.2768 73.8267 70.9768C73.9267 70.8768 74.1267 70.4768 73.8267 70.0768C73.7267 69.9768 73.7265 69.7768 73.9265 69.5768C74.0265 69.4768 74.2265 69.4768 74.4265 69.6768C74.7265 70.0768 74.8265 70.6768 74.4265 71.2768C74.0265 71.7768 73.5265 72.1768 73.1265 72.1768Z'
        fill='#7D0000'
      />
      <path
        opacity='0.3'
        d='M71.7266 68.4768C72.5266 69.9768 70.8266 70.9768 70.0266 70.0768C68.9266 68.5768 70.8266 66.9768 71.7266 68.4768Z'
        fill='white'
      />
      <g opacity='0.5' filter='url(#filter1_f_958_25358)'>
        <path
          d='M92.0264 71.5768C91.0264 74.5768 88.2262 76.2768 85.6262 75.3768C83.0262 74.5768 81.7263 71.4768 82.7263 68.5768C83.7263 65.5768 85.3263 67.5768 87.9263 68.3768C90.4263 69.2768 92.9264 68.5768 92.0264 71.5768Z'
          fill='#F15331'
        />
      </g>
      <g opacity='0.5'>
        <g opacity='0.5' filter='url(#filter2_f_958_25358)'>
          <path
            d='M67.3674 67.49C66.3674 70.39 63.3674 71.9899 60.8674 71.0899C58.3674 70.1899 57.1672 67.0899 58.1672 64.1899C59.1672 61.2899 60.7674 63.2899 63.3674 64.1899C65.8674 65.0899 68.3674 64.49 67.3674 67.49Z'
            fill='#F15331'
          />
        </g>
      </g>
      <path
        d='M92.2262 57.9768C92.0262 57.9768 91.9263 57.8768 91.8263 57.7768C90.2263 55.5768 87.3262 54.8768 86.2262 54.6768C85.9262 54.5768 85.7263 54.3768 85.8263 54.0768C85.9263 53.7768 86.1262 53.5768 86.4262 53.6768C90.2262 54.3768 92.0262 56.1768 92.7262 57.1768C92.9262 57.3768 92.8261 57.7768 92.6261 57.8768C92.4261 57.8768 92.3262 57.9768 92.2262 57.9768Z'
        fill='#7D0000'
      />
      <path
        d='M68.4259 51.7768C68.3259 51.7768 68.2259 51.7768 68.1259 51.6768C65.8259 50.3768 62.826 50.9768 61.726 51.3768C61.426 51.4768 61.126 51.2768 61.026 50.9768C60.926 50.6768 61.1259 50.3768 61.4259 50.2768C65.1259 49.2768 67.5259 50.0768 68.6259 50.6768C68.9259 50.7768 69.0261 51.1768 68.8261 51.3768C68.8261 51.6768 68.6259 51.7768 68.4259 51.7768Z'
        fill='#7D0000'
      />
      <path
        d='M111.827 73.5768C111.727 73.5768 111.527 73.4768 111.527 73.3768C111.427 73.1768 111.526 72.9768 111.726 72.9768C114.126 72.1768 116.526 69.9768 116.126 67.9768C115.926 67.0768 115.126 66.6768 113.926 66.8768C113.726 66.8768 113.627 66.7768 113.527 66.5768C113.527 66.3768 113.627 66.2768 113.827 66.1768C115.427 65.9768 116.526 66.5768 116.726 67.7768C117.126 69.6768 115.227 72.4768 111.827 73.5768Z'
        fill='#7D0000'
      />
      <g opacity='0.3'>
        <path
          d='M66.4264 66.6768C66.3264 66.4768 65.9264 66.1768 65.7264 66.2768C65.5264 66.3768 65.2265 66.7768 65.3265 66.9768C65.4265 67.1768 65.8265 67.4768 66.0265 67.3768C66.4265 67.3768 66.5264 66.9768 66.4264 66.6768Z'
          fill='#E23F1B'
        />
        <path
          d='M63.8265 66.4769C63.7265 66.2769 63.3263 65.9769 63.1263 66.0769C62.9263 66.1769 62.6264 66.5769 62.7264 66.7769C62.8264 66.9769 63.2264 67.2769 63.4264 67.1769C63.8264 66.9769 63.9265 66.6769 63.8265 66.4769Z'
          fill='#E23F1B'
        />
        <path
          d='M61.2264 65.5768C61.1264 65.3768 60.7264 65.0768 60.5264 65.1768C60.3264 65.2768 60.0263 65.6768 60.1263 65.8768C60.2263 66.0768 60.6265 66.3768 60.8265 66.2768C61.2265 66.1768 61.3264 65.9768 61.2264 65.5768Z'
          fill='#E23F1B'
        />
        <path
          d='M61.9263 67.5768C61.8263 67.3768 61.5265 67.1768 61.3265 67.3768C61.1265 67.4768 60.9263 67.7768 61.1263 67.9768C61.2263 68.1768 61.5264 68.3768 61.7264 68.1768C61.9264 68.0768 62.0263 67.8768 61.9263 67.5768Z'
          fill='#E23F1B'
        />
        <path
          d='M65.0265 68.1769C64.9265 67.9769 64.6264 67.7769 64.4264 67.9769C64.2264 68.0769 64.0264 68.3769 64.2264 68.5769C64.3264 68.7769 64.6265 68.9769 64.8265 68.7769C65.0265 68.6769 65.1265 68.4769 65.0265 68.1769Z'
          fill='#E23F1B'
        />
        <path
          d='M80.126 68.5768C80.326 68.3768 80.7261 68.4768 80.9261 68.6768C81.1261 68.8768 81.0262 69.2768 80.8262 69.4768C80.6262 69.6768 80.2262 69.5768 80.0262 69.3768C79.8262 69.1768 79.826 68.7768 80.126 68.5768Z'
          fill='#E23F1B'
        />
        <path
          d='M82.4262 69.5768C82.6262 69.3768 83.0263 69.4768 83.2263 69.6768C83.4263 69.8768 83.3262 70.2768 83.1262 70.4768C82.9262 70.6768 82.5263 70.5768 82.3263 70.3768C82.1263 70.1768 82.2262 69.8768 82.4262 69.5768Z'
          fill='#E23F1B'
        />
        <path
          d='M85.0261 70.3768C85.2261 70.1768 85.6261 70.2768 85.8261 70.4768C86.0261 70.6768 85.926 71.0768 85.726 71.2768C85.526 71.4768 85.126 71.3768 84.926 71.1768C84.826 70.8768 84.8261 70.5768 85.0261 70.3768Z'
          fill='#E23F1B'
        />
        <path
          d='M88.2267 69.4768C88.4267 69.2768 88.8267 69.3768 89.0267 69.5768C89.2267 69.7768 89.1266 70.1768 88.9266 70.3768C88.7266 70.5768 88.3266 70.4768 88.1266 70.2768C87.9266 70.0768 88.0267 69.7768 88.2267 69.4768Z'
          fill='#E23F1B'
        />
        <path
          d='M87.5265 71.4768C87.7265 71.3768 88.0264 71.3768 88.2264 71.5768C88.3264 71.7768 88.3263 72.0768 88.1263 72.2768C87.9263 72.3768 87.6265 72.3768 87.5265 72.1768C87.3265 71.9768 87.3265 71.7768 87.5265 71.4768Z'
          fill='#E23F1B'
        />
        <path
          d='M83.5265 71.5768C83.7265 71.4768 84.0264 71.4768 84.2264 71.6768C84.3264 71.8768 84.3263 72.1768 84.1263 72.3768C83.9263 72.4768 83.6265 72.4768 83.5265 72.2768C83.3265 72.0768 83.3265 71.8768 83.5265 71.5768Z'
          fill='#E23F1B'
        />
        <path
          d='M80.5261 70.5768C80.7261 70.4768 81.026 70.4768 81.226 70.6768C81.326 70.8768 81.326 71.1768 81.126 71.3768C80.926 71.4768 80.626 71.4768 80.426 71.2768C80.326 71.0768 80.3261 70.6768 80.5261 70.5768Z'
          fill='#E23F1B'
        />
        <path
          d='M85.6266 72.7769C85.8266 72.6769 86.1268 72.6769 86.3268 72.8769C86.4268 73.0769 86.4267 73.3769 86.2267 73.5769C86.0267 73.6769 85.7266 73.6769 85.6266 73.4769C85.4266 73.1769 85.4266 72.8769 85.6266 72.7769Z'
          fill='#E23F1B'
        />
        <path
          d='M90.8262 70.7769C91.0262 70.6769 91.326 70.6769 91.426 70.8769C91.526 71.0769 91.5262 71.3769 91.3262 71.5769C91.1262 71.6769 90.826 71.6769 90.626 71.4769C90.626 71.2769 90.6262 71.0769 90.8262 70.7769Z'
          fill='#E23F1B'
        />
      </g>
      <path
        d='M91.7263 24.3768C109.926 28.8768 119.326 43.2768 113.226 57.7768C108.326 69.3768 103.626 77.2768 103.626 77.2768C104.626 72.3768 106.626 62.2768 106.626 62.2768C106.626 62.2768 105.626 60.8768 101.126 59.7768L104.226 51.2768C102.826 54.9768 100.426 54.6768 99.2263 58.5768C95.0263 57.4768 89.2263 49.6768 71.7263 50.3768C72.1263 47.1768 73.8263 45.6768 74.7263 42.5768C72.8263 45.6768 71.2262 46.2768 69.6262 49.5768C63.1262 49.5768 54.4263 44.5768 54.4263 44.5768C54.4263 44.5768 58.8263 42.6768 64.2263 32.6768C69.6263 22.7768 79.9263 22.4768 91.7263 24.3768Z'
        fill='#FFE6AA'
      />
      <path
        d='M120.326 55.7768C128.61 55.7768 135.326 49.0611 135.326 40.7768C135.326 32.4925 128.61 25.7768 120.326 25.7768C112.042 25.7768 105.326 32.4925 105.326 40.7768C105.326 49.0611 112.042 55.7768 120.326 55.7768Z'
        fill='#FFE6AA'
      />
      <path
        d='M71.9262 25.3768C71.8262 25.3768 71.7261 25.2768 71.6261 25.1768C71.5261 24.9768 71.6263 24.7768 71.8263 24.7768C79.3263 21.3768 87.0261 21.3768 94.6261 24.7768C94.8261 24.8768 94.8263 25.0768 94.8263 25.1768C94.7263 25.3768 94.5262 25.3768 94.4262 25.3768C87.0262 22.0768 79.5262 22.0768 72.2262 25.3768C72.0262 25.3768 71.9262 25.3768 71.9262 25.3768Z'
        fill='#AD2E11'
      />
      <path
        d='M117.626 55.3768C112.726 54.0768 109.326 51.4768 107.326 47.4768C104.726 42.1768 105.426 36.1768 106.526 33.5768C106.626 33.3768 106.826 33.3768 106.926 33.3768C107.126 33.4768 107.126 33.6768 107.126 33.7768C106.026 36.4768 105.526 42.3768 107.926 47.1768C109.826 50.9768 113.126 53.4768 117.726 54.6768C117.926 54.6768 118.026 54.8768 117.926 55.0768C117.926 55.2768 117.826 55.3768 117.626 55.3768Z'
        fill='#AD2E11'
      />
      <path
        d='M119.526 53.9768C115.826 52.9768 113.226 50.9768 111.726 47.9768C109.726 43.9768 110.326 39.3768 111.126 37.3768C111.226 37.1768 111.426 37.1768 111.526 37.1768C111.726 37.2768 111.726 37.4768 111.726 37.5768C110.926 39.5768 110.526 44.0768 112.326 47.6768C113.726 50.5768 116.226 52.4768 119.626 53.3768C119.826 53.3768 119.926 53.5768 119.826 53.7768C119.826 53.8768 119.626 53.9768 119.526 53.9768Z'
        fill='#AD2E11'
      />
      <path
        d='M100.326 110.077C96.8263 110.077 88.0261 112.077 91.6261 123.377C92.9261 125.377 95.5262 128.277 97.7262 131.477C102.426 130.577 106.926 129.277 111.126 127.477C107.226 119.177 100.726 111.377 100.326 110.077Z'
        fill='#F4C18F'
      />
      <defs>
        <filter
          id='filter0_f_958_25358'
          x='64.5879'
          y='63.9768'
          width='12.0361'
          height='11.1818'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25358' />
        </filter>
        <filter
          id='filter1_f_958_25358'
          x='79.3892'
          y='63.98'
          width='15.8247'
          height='14.6377'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25358' />
        </filter>
        <filter
          id='filter2_f_958_25358'
          x='54.8149'
          y='59.6769'
          width='15.7783'
          height='14.6636'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='1.5' result='effect1_foregroundBlur_958_25358' />
        </filter>
      </defs>
    </svg>
  );
};
