import React from "react";
export const CrossWord: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='6.26086' width='5.21739' height='5.21739' rx='1' fill='#068585' />
      <rect x='18.7826' width='5.21739' height='5.21739' rx='1' fill='#068585' />
      <rect x='6.26086' y='6.26074' width='5.21739' height='5.21739' rx='1' fill='#068585' />
      <rect x='6.26086' y='12.5215' width='5.21739' height='5.21739' rx='1' fill='#068585' />
      <rect x='18.7826' y='12.5215' width='5.21739' height='5.21739' rx='1' fill='#068585' />
      <rect y='6.26074' width='5.21739' height='5.21739' rx='1' fill='#068585' />
      <rect x='12.5217' y='6.26074' width='5.21739' height='5.21739' rx='1' fill='#068585' />
      <rect x='18.7826' y='6.26074' width='5.21739' height='5.21739' rx='1' fill='#068585' />
      <rect x='6.26086' y='18.7822' width='5.21739' height='5.21739' rx='1' fill='#068585' />
    </svg>
  );
};
