import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizEnterWord } from "~modules/QuizEnterWord";
import rebus from "~shared/assets/images/24/rebus2.jpg";
import { ImageRebus } from "~/shared/components/ImageRebus";

const Theme24Task4 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Разгадай ребус'
      description='Напиши отгадку в поле ниже'
      successIcon='nast-2'
    >
      <ImageRebus url={rebus} />
      <QuizEnterWord answer='Бульвар' rebus />
    </QuizWrapper>
  );
};

export default Theme24Task4;
