import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import Avtobus from "~shared/assets/images/2/avtobus.jpg";
import Electr from "~shared/assets/images/2/elektrobus.jpg";
import Izvoz from "~shared/assets/images/2/izvozchik.jpg";
import Konka from "~shared/assets/images/2/konka.jpg";
import Metro from "~shared/assets/images/2/metro.jpg";
import Tramvai from "~shared/assets/images/2/tramvay.jpg";

const Theme2Task1 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Автобус",
      image: Avtobus
    },
    {
      name: "Электробус",
      image: Electr
    },
    {
      name: "Извозчик",
      image: Izvoz
    },
    {
      name: "Конка",
      image: Konka
    },
    {
      name: "Метро",
      image: Metro
    },
    {
      name: "Трамвай",
      image: Tramvai
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Вспомни, какой транспорт есть в Москве. Отметь лишнее.'
      description=''
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Конка' hideName />
    </QuizWrapper>
  );
};

export default Theme2Task1;
