import React, { useState } from "react";
import { AppInput } from "~shared/components/AppInput";
import styles from "./QuizEnterWord.module.scss";
import { QuizResult } from "~shared/components/QuizResult";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import clsx from "clsx";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

const Theme35Task3 = () => {
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Дополни предложение.'
      description='Напечатай пропущенные слова'
      successIcon='nast-2'
    >
      <QuizEnterWord
        question='Москва-реку с Волгой соединил широкий _____ имени ______. '
        answer='Канал'
        secondAnswer='Москвы'
      />
    </QuizWrapper>
  );
};

export default Theme35Task3;

type Props = {
  answer: string;
  question?: string;
  imgUrl?: string;
  secondAnswer?: string;
};

const QuizEnterWord: React.FC<Props> = ({ answer, question, imgUrl, secondAnswer = "" }) => {
  const [currentAnswerFirst, setCurrentAnswerFirst] = useState("");
  const [currentAnswerSecond, setCurrentAnswerSecond] = useState("");

  const [firstError, setFirstError] = useState(false);
  const [secondError, setSecondError] = useState(false);
  const isMobile = useAdaptiveValue(true, true, false);
  const { setSuccess } = useQuizContext();

  const [mobileKey, setMobileKey] = useState(false);

  const handleClick = () => {
    let err: boolean = false;
    if (
      currentAnswerFirst.toUpperCase() === answer.toUpperCase() ||
      currentAnswerFirst.toUpperCase() === secondAnswer.toUpperCase()
    ) {
      setFirstError(false);
    } else {
      err = true;
      setFirstError(true);
      setSuccess(false);
    }

    if (
      currentAnswerSecond.toUpperCase() === secondAnswer.toUpperCase() ||
      currentAnswerSecond.toUpperCase() === answer.toUpperCase()
    ) {
      setSecondError(false);
    } else {
      err = true;
      setSecondError(true);
      setSuccess(false);
    }

    if (!firstError && !secondError && !err) {
      setTimeout(() => {
        setSuccess(true);
      }, 700);
    }
  };

  const handleChangeFirst = (value: string) => {
    setMobileKey(true);
    setCurrentAnswerFirst(value);
    setFirstError(false);
  };

  const handleChangeSecond = (value: string) => {
    setMobileKey(true);
    setCurrentAnswerSecond(value);
    setSecondError(false);
  };

  return (
    <div className={styles.enterWordQuiz}>
      {question && <div className={styles.question}>{question}</div>}
      {imgUrl && <img className={styles.img} src={imgUrl} alt='Задание' />}
      <div style={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
        <div style={{ display: "flex", gap: "16px", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            Первое слово:
          </div>

          <AppInput onChange={handleChangeFirst} error={firstError} />
        </div>
        <div style={{ display: "flex", gap: "16px", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            Второе слово:
          </div>
          <AppInput onChange={handleChangeSecond} error={secondError} />
        </div>
      </div>
      <QuizResult
        errorVisible={firstError && secondError}
        buttonValue='Проверить'
        buttonOnClick={handleClick}
        buttonVisible={currentAnswerFirst !== "" && (!firstError || !secondError)}
      />

      <div className={clsx({ [styles.mobileInput]: mobileKey && isMobile })}></div>
    </div>
  );
};
