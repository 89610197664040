import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTimeLine, Answer, DraggableItem, DropZone } from "~modules/QuizTimeLine";
import mashina from "~/shared/assets/images/gruzovaya_mashina.jpg";
import parovoz from "~/shared/assets/images/parovoz.jpg";
import izvozchik from "~/shared/assets/images/izvozchik.jpg";
import tramvai from "~/shared/assets/images/tramvai.jpg";

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    imgUrl: mashina,
    title: "Грузовая машина",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 2,
    imgUrl: parovoz,
    title: "Паровоз",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 3,
    imgUrl: izvozchik,
    title: "Извозчик",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  },
  {
    id: 4,
    imgUrl: tramvai,
    title: "Трамвай",
    X: 0,
    Y: 0,
    correct: false,
    zone: {
      id: 0,
      correct: false,
      uncorrect: false,
      empty: false
    }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 2,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 3,
    correct: false,
    uncorrect: false,
    empty: true
  },
  {
    id: 4,
    correct: false,
    uncorrect: false,
    empty: true
  }
];

const answers: Answer = { 1: 3, 2: 2, 3: 4, 4: 1 };

const Theme34Task2 = () => {
  return (
    <QuizWrapper
      title='Игра'
      icon='game'
      subtitle='Что было раньше, а что позже?'
      description='Правильно расположи изображения'
      successIcon='dan-2'
    >
      <QuizTimeLine draggableItems={draggableItems} dropZones={dropZones} answers={answers} />
    </QuizWrapper>
  );
};

export default Theme34Task2;
