import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme18Task1 = () => {
  const items: { name: string; image?: string }[] = [
    {
      name: "шелка"
    },
    {
      name: "глиняные игрушки"
    },
    {
      name: "подковы"
    },
    {
      name: "корзины"
    },
    {
      name: "бублики"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Какие товары привозили купцы из дальних стран?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='шелка' sameHeight />
    </QuizWrapper>
  );
};

export default Theme18Task1;
