import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizTest } from "~/modules/QuizTest";

const Theme8Task1 = () => {
  const questions: { question: string; answer: "Да" | "Нет" }[] = [
    {
      question: "Кремль при Дмитрии, внуке Ивана Калиты, был белым?",
      answer: "Да"
    },
    {
      question: "Битва с Мамаем проходила на Бородинском поле?",
      answer: "Нет"
    },
    {
      question: "Князь Дмитрий после битвы на Куликовом поле получил прозвище Донской?",
      answer: "Да"
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Отметь верные утверждения.'
      description='Выбери правильные ответы'
      successIcon='nast-1'
    >
      <QuizTest questions={questions} />
    </QuizWrapper>
  );
};

export default Theme8Task1;
