import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";

const Theme19Task1 = () => {
  const items: { name: string }[] = [
    {
      name: "16"
    },
    {
      name: "20"
    },
    {
      name: "26"
    },
    { name: "36" }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Сколько деревянных теремов было в Коломенском дворце?'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='str' items={items} correctItem='26' />
    </QuizWrapper>
  );
};

export default Theme19Task1;
