import React, { ReactNode } from "react";
import { Rus1 } from "./components/Rus1";
import { Rus2 } from "./components/Rus2";
import { Dan1 } from "./components/Dan1";
import { Dan2 } from "./components/Dan2";
import { Gosha1 } from "./components/Gosha1";
import { Gosha2 } from "./components/Gosha2";
import { Nast1 } from "./components/Nast1";
import { Nast2 } from "./components/Nast2";
import { Mash1 } from "./components/Mash1";
import { Mash2 } from "./components/Mash2";

export type ChildIconName =
  | "rus-1"
  | "rus-2"
  | "dan-1"
  | "dan-2"
  | "gosha-1"
  | "gosha-2"
  | "nast-1"
  | "nast-2"
  | "mash-1"
  | "mash-2";

type Props = {
  name: ChildIconName;
};

const icons: Record<ChildIconName, ReactNode> = {
  "rus-1": <Rus1 />,
  "rus-2": <Rus2 />,
  "dan-1": <Dan1 />,
  "dan-2": <Dan2 />,
  "gosha-1": <Gosha1 />,
  "gosha-2": <Gosha2 />,
  "nast-1": <Nast1 />,
  "nast-2": <Nast2 />,
  "mash-1": <Mash1 />,
  "mash-2": <Mash2 />
};
export const ChildIcon: React.FC<Props> = ({ name }) => {
  return <>{icons[name]}</>;
};
