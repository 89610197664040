import React, { useRef } from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizCrossWord, CrossWordData, Prompt } from "~/modules/QuizCrossWord/QuizCrossWord";
import styles from "./Theme29Task2.module.scss";

const Theme29Task2 = () => {
  const data: CrossWordData = {
    across: {
      1: {
        clue: "То, что можно посмотреть в Малом театре.",
        answer: "КОМЕДИЯ",
        row: 0,
        col: 1
      },
      2: {
        clue: "То, что ставят в Большом театре.",
        answer: "ОПЕРА",
        row: 1,
        col: 0
      },
      3: {
        clue: "То, что ставят в Большом театре.",
        answer: "БАЛЕТ",
        row: 2,
        col: 0
      },
      4: {
        clue: "То, что можно посмотреть в Малом театре.",
        answer: "ДРАМА",
        row: 3,
        col: 3
      }
    },
    down: {}
  };

  const prompt: Prompt = { type: "down", row: 0, col: 4, clue: "ЕАТР" };

  const cellRef = useRef<HTMLSpanElement>(null);

  const fillEmptyCell = () => {
    if (cellRef?.current) {
      const CELL_TEXT = "Т";
      cellRef.current.innerHTML = CELL_TEXT;
    }
  };

  return (
    <QuizWrapper
      title='Кроссворд'
      icon='cross-word'
      subtitle='Прочитай подсказки ниже и реши кроссворд.'
      description='Напечатай слова в соответствующие строки кроссворда'
      successIcon='mash-2'
    >
      <QuizCrossWord
        data={data}
        prompt={prompt}
        inactiveBlock={
          <>
            <div className={styles.cellWrapper}>
              <span ref={cellRef} className={styles.cell} />
            </div>
          </>
        }
        onFillOneCell={fillEmptyCell}
        adjacentLetter={[
          { col: 7, row: 0, letter: "Я", correctLetters: [] },
          { col: 4, row: 1, letter: "А", correctLetters: [] },
          { col: 7, row: 3, letter: "А", correctLetters: [] }
        ]}
      />
    </QuizWrapper>
  );
};

export default Theme29Task2;
