import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import gerb1 from "~/shared/assets/images/1/gerby-1.jpg";
import gerb2 from "~/shared/assets/images/1/gerby-2.jpg";
import gerb3 from "~/shared/assets/images/1/gerby-3.jpg";

const Theme1Task2 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Ярославль",
      image: gerb1
    },
    {
      name: "Хабаровск",
      image: gerb3
    },
    {
      name: "Россия",
      image: gerb2
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри гербы. Отметь среди них герб России.'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Россия' hideName />
    </QuizWrapper>
  );
};

export default Theme1Task2;
