import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import {
  QuizPuzzleAssembleObject,
  Answer,
  RootObject,
  DraggableItem,
  DropZone
} from "~/modules/QuizPuzzleAssembleObject";
import ratnikGoliy from "~shared/assets/images/8/ratnik_goliy.png";
import kolchuga from "~shared/assets/images/8/kolchuga.png";
import kopiyo from "~shared/assets/images/8/kopiyo.png";
import shit from "~shared/assets/images/8/shit.png";
import shlem from "~shared/assets/images/8/shlem.png";

const rootObject: RootObject = {
  imgUrl: ratnikGoliy
};

const draggableItems: DraggableItem[] = [
  {
    id: 1,
    imgUrl: kolchuga,
    imageWrapperStyle: {
      width: "34%",
      height: "60%",
      bottom: 0,
      left: 0
    },
    imageStyle: { width: "90%" }
  },
  {
    id: 2,
    imgUrl: kopiyo,
    imageWrapperStyle: {
      width: "10%",
      height: "90%",
      top: 0,
      right: "2%"
    },
    imageStyle: { width: "40%" }
  },
  {
    id: 3,
    imgUrl: shit,
    imageWrapperStyle: {
      width: "29%",
      height: "29%",
      top: 0,
      left: 0
    },
    imageStyle: { width: "90%" }
  },
  {
    id: 4,
    imgUrl: shlem,
    imageWrapperStyle: {
      width: "22%",
      height: "27%",
      bottom: 0,
      right: "10%"
    },
    imageStyle: { width: "90%" }
  }
];

const dropZones: DropZone[] = [
  {
    id: 1,
    style: {
      width: "35%",
      height: "60%",
      top: "16%",
      left: "33%"
    }
  },
  {
    id: 2,
    style: {
      width: "10%",
      height: "90%",
      bottom: "10%",
      left: "22%"
    }
  },
  {
    id: 3,
    style: {
      width: "29%",
      height: "29%",
      right: "15%",
      bottom: "25%"
    }
  },
  {
    id: 4,
    style: {
      width: "22%",
      height: "27%",
      bottom: "71%",
      left: "39%"
    }
  }
];

const answers: Answer = { 1: 1, 2: 2, 3: 3, 4: 4 };

const Theme2Task2 = () => {
  return (
    <QuizWrapper title='Пазл' icon='puzzle' subtitle='Одень ратника.' successIcon='gosha-1'>
      <QuizPuzzleAssembleObject
        rootObject={rootObject}
        draggableItems={draggableItems}
        dropZones={dropZones}
        answers={answers}
      />
    </QuizWrapper>
  );
};
export default Theme2Task2;
