import React from "react";
export const Test: React.FC = () => {
  return (
    <svg width='20' height='25' viewBox='0 0 20 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 0.863281L0.25 5.19662V11.6966C0.25 17.7145 4.40458 23.3262 10 24.6966C15.5954 23.3262 19.75 17.7145 19.75 11.6966V5.19662L10 0.863281ZM7.83333 18.1966L3.5 13.8633L5.03292 12.3304L7.83333 15.1308L14.9671 7.99703L16.5 9.52995L7.83333 18.1966Z'
        fill='#068585'
      />
    </svg>
  );
};
