import React, { useCallback, useEffect, useState } from "react";
import { QuizResult } from "~shared/components/QuizResult";
import { useDragAndDropContext } from "~shared/hooks/useDragAndDropContext";
import { useQuizContext } from "~shared/hooks/useQuizContext";
import { DropContainer } from "./components/DropContainer";
import styles from "./QuizTimeLine.module.scss";
import clsx from "clsx";
import arrow from "~shared/assets/images/arrow.png";
import { DraggableWrapper } from "./components/DraggableWrapper";
import {
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DndContext,
  KeyboardSensor,
  DragEndEvent
} from "@dnd-kit/core";

import { useAdaptiveValue } from "~/shared/hooks/useAdaptiveValue";

export type DraggableItem = {
  id: number;
  imgUrl?: string;
  title?: string;
  X: number;
  Y: number;
  correct: boolean;
  zone: DropZone;
};
export type DropZone = {
  id: number;
  name?: string;
  correct: boolean;
  uncorrect: boolean;
  empty: boolean;
};
export type Answer = Record<number, number>;

type Props = {
  draggableItems: DraggableItem[];
  dropZones: DropZone[];
  answers: Answer;
};

export const QuizTimeLine: React.FC<Props> = ({ draggableItems, dropZones, answers }) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const [items, setItems] = useState(draggableItems);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const { setSuccess } = useQuizContext();
  const { results } = useDragAndDropContext();
  const [test, setTest] = useState(true);
  const [error, setError] = useState(false);

  const containerStyle = useAdaptiveValue(
    styles.containerMobile,
    styles.containerMobile,
    styles.container
  );

  const dropZoneContainerStyle = useAdaptiveValue(
    styles.dropZoneContainerMobile,
    styles.dropZoneContainerMobile,
    styles.dropZoneContainer
  );

  const arrowStyle = useAdaptiveValue(styles.arrowMobile, styles.arrowMobile, styles.arrow);

  const dropZoneWrapperStyle = useAdaptiveValue(
    styles.dropZoneWrapperMobile,
    styles.dropZoneWrapperMobile,
    styles.dropZoneWrapper
  );

  const dropNameStyle = useAdaptiveValue(
    styles.dropNameMobile,
    styles.dropNameMobile,
    styles.dropName
  );

  useEffect(() => {
    if (test) {
      draggableItems.forEach((item) => {
        item.X = 0;
        item.Y = 0;
        item.correct = false;
        item.zone = {
          id: 0,
          correct: false,
          uncorrect: false,
          empty: false
        };
      });

      dropZones.forEach((zone) => {
        zone.correct = false;
        zone.empty = true;
        zone.uncorrect = false;
      });
      setTest(false);
    }
  });

  function setEmpty() {
    const zones = draggableItems.map((item) => {
      return item.zone;
    });
    dropZones.forEach((zone) => {
      if (!zones.includes(zone)) {
        zone.uncorrect = false;
        zone.empty = true;
      }
    });
  }

  const onEnd = useCallback(
    (
      e: DragEndEvent & {
        activatorEvent: DragEndEvent["activatorEvent"] & { clientX: number; clientY: number };
      }
    ) => {
      let left = 0;
      const PADDING = 16;
      let height = 0;
      if (window.innerWidth >= 560) {
        left = 280;
        height = 229;
      } else {
        left = 188;
        height = 202;
      }
      // не даёт зелёному квадратику выйти из него
      if (draggableItems[Number(e.active.id) - 1].correct) {
        return;
      }
      const overId = e.over?.id || 0;

      if (e.over != null) {
        if (dropZones[Number(overId) - 1].correct || dropZones[Number(overId) - 1].uncorrect) {
          // если мы хотим засунуть в контейнер заполненный, то возвращает в дефолт

          draggableItems[Number(e.active.id) - 1].zone = {
            id: 0,
            correct: false,
            uncorrect: false,
            empty: false
          };
          setItems(
            items.map((item, index) => {
              if (index == Number(e.active.id) - 1) {
                item.X = 0;
                item.Y = 0;
              }
              return item;
            })
          );
        } else {
          setItems(
            items.map((item, index) => {
              draggableItems[Number(e.active.id) - 1].zone = dropZones[Number(overId) - 1];
              if (index == Number(e.active.id) - 1) {
                item.X = left;
                item.Y = (Number(overId) - Number(e.active.id)) * (height + PADDING);
              }
              return item;
            })
          );
          if (answers[Number(overId)] == Number(e.active.id)) {
            dropZones[Number(overId) - 1].correct = true;
            draggableItems[Number(e.active.id) - 1].correct = true;
          } else {
            dropZones[Number(overId) - 1].uncorrect = true;
          }
        }
      } else {
        // если у нас не на одном из контейнеров, то ячейка падает в дефолтное состояние
        draggableItems[Number(e.active.id) - 1].zone = {
          id: 0,
          correct: false,
          uncorrect: false,
          empty: false
        };
        setItems(
          items.map((item, index) => {
            if (index == Number(e.active.id) - 1) {
              item.X = 0;
              item.Y = 0;
            }
            return item;
          })
        );
      }

      // проверка на правильность
      let success: boolean = true;
      dropZones.forEach((item) => {
        success = success && item.correct;
      });

      setTimeout(() => setSuccess(success), 700);
      setEmpty();

      let err: boolean = false;
      dropZones.forEach((item) => {
        err = err || item.uncorrect;
      });
      setError(err);
    },
    []
  );

  return (
    <DndContext sensors={sensors} onDragEnd={onEnd}>
      <div className={containerStyle}>
        <div className={dropZoneWrapperStyle}>
          <div className={styles.draggableItems}>
            {draggableItems.map((item) => {
              return (
                <>
                  <DraggableWrapper
                    title={item.title}
                    imgUrl={item.imgUrl}
                    id={item.id}
                    key={item.id}
                    positionX={item.X}
                    positionY={item.Y}
                  />
                </>
              );
            })}
          </div>

          <div className={dropZoneContainerStyle}>
            {dropZones.map((item, idx) => (
              <div
                className={clsx(
                  styles.dropPoint,
                  {
                    [styles.success]: results.get(idx + 1) === answers[idx + 1]
                  },
                  {
                    [styles.error]:
                      results.has(idx + 1) && results.get(idx + 1) !== answers[idx + 1]
                  }
                )}
                key={item.id}
              >
                <div className={dropNameStyle}>{item.name}</div>
                <DropContainer id={idx + 1} correct={item.correct} uncorrect={item.uncorrect} />
              </div>
            ))}
            <span
              className={arrowStyle}
              style={{
                backgroundImage: `url(${arrow})`
              }}
            />
          </div>
        </div>
        <QuizResult errorVisible={error} />
      </div>
    </DndContext>
  );
};
