import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItem } from "~/modules/QuizChooseItem";
import fonar from "~shared/assets/images/27/fonarschik.jpg";
import gonchar from "~shared/assets/images/27/gonchar.jpg";
import kuzn from "~shared/assets/images/27/kuznets.jpg";
import sapoj from "~shared/assets/images/27/sapojnik.jpg";

const Theme27Task3 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Фонарщик",
      image: fonar
    },
    {
      name: "Гончар",
      image: gonchar
    },
    {
      name: "Кузнец",
      image: kuzn
    },
    {
      name: "Сапожник",
      image: sapoj
    }
  ];
  return (
    <QuizWrapper
      title='Тест'
      icon='test'
      subtitle='Рассмотри рисунки. Отметь фонарщика'
      description='Выбери правильный ответ'
      successIcon='rus-1'
    >
      <QuizChooseItem type='img' items={items} correctItem='Фонарщик' hideName />
    </QuizWrapper>
  );
};

export default Theme27Task3;
