import React from "react";
import { QuizWrapper } from "~shared/components/QuizWrapper";
import { QuizChooseItems } from "~/modules/QuizChooseItems";
import kuznets from "~shared/assets/images/15/kuznets.jpg";
import gonchar from "~shared/assets/images/15/gonchar.jpg";
import monah from "~shared/assets/images/15/monah.jpg";
import konnitsa from "~shared/assets/images/15/konnitsa.jpg";
import kuptsy from "~shared/assets/images/15/kuptsy.jpg";
import streltsy from "~shared/assets/images/15/streltsy.jpg";
import pushkari from "~shared/assets/images/15/pushkari-700.jpg";
const Theme15Task3 = () => {
  const items: { name: string; image: string }[] = [
    {
      name: "Кузнец",
      image: kuznets
    },
    {
      name: "Гончар",
      image: gonchar
    },
    {
      name: "Монах",
      image: monah
    },
    {
      name: "Конница",
      image: konnitsa
    },
    {
      name: "Стрельцы",
      image: streltsy
    },
    {
      name: "Купцы",
      image: kuptsy
    },
    {
      name: "Пушкари",
      image: pushkari
    }
  ];
  return (
    <QuizWrapper
      title='Задание'
      icon='question'
      subtitle='Рассмотри рисунки. Выбери, кто входил в войско Ивана Грозного.'
      description='Выбери 3 правильных ответа'
      successIcon='gosha-2'
    >
      <QuizChooseItems type='img' items={items} correctItems={["Конница", "Стрельцы", "Пушкари"]} />
    </QuizWrapper>
  );
};

export default Theme15Task3;
